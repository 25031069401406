<nav *ngIf="isAuth()" class="navbar py-1 navborder navbar-expand-lg navbar-dark px-3 bg-white py-1">
    <a class="navbar-brand mr-1" *ngIf="!isAuth()" [routerLink]="['/']">
        <div>
            <span class="altea-title">ALTEA</span>
            <span class="labs-title">Labs</span>
        </div>
    </a>
    <a class="navbar-brand py-0 mr-1" *ngIf="isAuth()" (click)="quitSess('groups', $event);" [routerLink]="quit_sesion ? [ '/groups' ] : []">
        <div>
            <span class="altea-title">ALTEA</span>
            <span class="labs-title">Labs</span>
        </div>
    </a>
    <button class="navbar-toggler" data-target="#my-nav" data-toggle="collapse" aria-controls="my-nav" aria-expanded="false" aria-label="Toggle navigation">
  <span class="navbar-toggler-icon"></span>
</button>
    <div id="my-nav" class="justify-content-between collapse navbar-collapse" style="max-height: 38px;">
        <div class="d-flex">
            <div class="px-0 text-center active p-2">
                <a class="option py-2" *ngIf="(auth.checkRole() != rolesAux.superadmin && auth.checkRole() != rolesAux.adminRole ) && isAuth()" style="opacity: .6;">Staff</a>
                <a class="option py-2" style="cursor: pointer;" *ngIf="auth.checkRole() == rolesAux.superadmin || auth.checkRole() == rolesAux.adminRole  && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'crew-admin'}" (click)="quitSess('crew-admin', $event);">Staff</a>
            </div>
            <div class="px-0 text-center active p-2">
                <a class="option py-2" *ngIf="(auth.checkRole() != rolesAux.superadmin && auth.checkRole() != rolesAux.adminRole ) && isAuth()" style="opacity: .6;">Students</a>
                <a class="option py-2" style="cursor: pointer;" *ngIf="auth.checkRole() == rolesAux.superadmin || auth.checkRole() == rolesAux.adminRole  && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'students-admin'}" (click)="quitSess('students-admin', $event);">Students</a>
            </div>
            <div class="px-0 text-center active p-2">
                <a class="option py-2" *ngIf="(auth.checkRole() != rolesAux.superadmin && auth.checkRole() != rolesAux.adminRole ) && isAuth()" style="opacity: .6;">Groups</a>
                <a class="option py-2" style="cursor: pointer;" *ngIf="(auth.checkRole() == rolesAux.superadmin || auth.checkRole() == rolesAux.adminRole || auth.checkRole() == rolesAux.publisherDean) && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'groups'}" (click)="quitSess('groups', $event);">Groups</a>
            </div>
            <!-- <div class="px-0 text-center active p-2">
              <a class="option py-2" *ngIf="auth.checkRole() != rolesAux.superadmin && isAuth()" style="opacity: .6;">Posator</a>
              <a class="option py-2" style="cursor: pointer;" *ngIf="auth.checkRole() == rolesAux.superadmin && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'posator'}" (click)="quitSess('posator', $event); listLab.cleanTrees()">Posator</a>
          </div>
          <div class="px-0 text-center active p-2">
              <a class="option py-2" *ngIf="auth.checkRole() != rolesAux.superadmin && auth.checkRole() != rolesAux.publisherDean && isAuth()">Grammar</a>
              <a class="option py-2" style="cursor: pointer;" *ngIf="(auth.checkRole() == rolesAux.superadmin || auth.checkRole() == rolesAux.publisherDean) && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'grammar'}" (click)="quitSess('grammar', $event); listLab.cleanTrees()">Grammar</a>
          </div>
          <div class="px-0 text-center active p-2">
              <a class="option py-2" *ngIf="auth.checkRole() != rolesAux.superadmin && auth.checkRole() != rolesAux.publisherDean && isAuth()">Terminator</a>
              <a class="option py-2" style="cursor: pointer;" *ngIf="(auth.checkRole() == rolesAux.superadmin || auth.checkRole() == rolesAux.publisherDean) && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'terminator'}" (click)="quitSess('terminator', $event); listLab.cleanTrees()">Terminator</a>
          </div>
          <div class="px-0 text-center active p-2">
              <a class="option py-2" *ngIf="auth.checkRole() != rolesAux.superadmin && isAuth()" style="opacity: .6;">Quizator</a>
              <a class="option py-2" style="cursor: pointer;" *ngIf="auth.checkRole() == rolesAux.superadmin && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'quizator'}" (click)="quitSess('quizator', $event); listLab.cleanTrees()">Quizator</a>
          </div>
          <div class="px-0 text-center active p-2">
              <a class="option py-2" *ngIf="auth.checkRole() != rolesAux.superadmin && auth.checkRole() != rolesAux.publisherDean && isAuth()" style="opacity: .6;">Writing</a>
              <a class="option py-2" style="cursor: pointer;" *ngIf="(auth.checkRole() == rolesAux.superadmin || auth.checkRole() == rolesAux.publisherDean) && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'writing'}" (click)="quitSess('writing', $event); listLab.cleanTrees()">Writing</a>
          </div>
          <div class="px-0 text-center active p-2">
              <a class="option py-2" style="opacity: .6;" *ngIf="auth.checkRole() != rolesAux.audioImporter && auth.checkRole() != rolesAux.superadmin && isAuth()">AudioGap</a>
              <a class="option py-2" style="cursor: pointer;" *ngIf="(auth.checkRole() == rolesAux.audioImporter || auth.checkRole() == rolesAux.superadmin) && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'audiogap'}" (click)="quitSess('audiogap', $event); listLab.cleanTrees()">AudioGap</a>
          </div>
          <div class="px-0 text-center active p-2">
              <a class="option py-2" *ngIf="auth.checkRole() != rolesAux.superadmin && isAuth()" style="opacity: .6;">Examinator</a>
              <a class="option py-2" style="cursor: pointer;" *ngIf="auth.checkRole() == rolesAux.superadmin && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'examinator'}" (click)="quitSess('examinator', $event); listLab.cleanTrees()">Examinator</a>
          </div> -->
        </div>
        <div class="d-flex">
            <!-- <div style="border-radius: 8px" [ngClass]="{'gapperBack': isAuth()}" class="px-4 py-2 d-flex">
                <div style="background-color: white" class="px-0 text-center mr-2 active">
                  <a class="option px-2" *ngIf="auth.checkRole() != rolesAux.superadmin && isAuth()" style="opacity: .6;">Users</a>
                  <a class="option px-2" style="cursor: pointer;" *ngIf="auth.checkRole() == rolesAux.superadmin && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'gaper'}" (click)="quitSess('gaper', $event); listLab.cleanTrees()">Gapper</a>
              </div>
              <div style="background-color: white" class="px-0 text-center active">
                  <a class="option px-2" *ngIf="auth.checkRole() != rolesAux.superadmin && isAuth()" style="opacity: .6;">Subject</a>
                  <a class="option px-2" style="cursor: pointer;" *ngIf="auth.checkRole() == rolesAux.superadmin && isAuth()" [ngClass]="{'workingPlace': auth.workingArea == 'subject'}" (click)="quitSess('subject', $event); listLab.cleanTrees()">Subject</a>
              </div>
            </div> -->
            <ul class="align-self-end my-auto">
                <button *ngIf="!isAuth()" class="btn btn-altea py-2 formBtn" style="height: max-content;" type="button" data-toggle="modal" data-target="#login">
      Inicia sesión
    </button>
                <!-- <button *ngIf="!isAuth()" class="btn btn-style-6 ml-3 d-none d-lg-inline-block formBtn" type="button" data-toggle="modal" data-target="#register">
      Regístrate
    </button> -->
                <button *ngIf="isAuth()" style="line-height: normal; height: max-content;" class="btn d-flex btn-style-6 py-2 formBtn" type="button" (click)="logOut()">
<span class="mx-auto my-auto">Cerrar sesión</span>
    </button>
            </ul>
        </div>
    </div>
</nav>