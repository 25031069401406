<div class="h-100 w-100 bg-white d-flex" style="padding: 1.5%; flex-direction: column;" [appTooltip]="200">
    <div class="w-100 d-flex justify-content-between" [appTooltip]="201">
        <div *ngIf="candidateProfile != undefined" class="title text-truncate" style="width: 30%;" [appTooltip]="202"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
            {{candidateProfile.name}} {{candidateProfile.last_name}} 
        </div>
        <div class=" d-flex" style=" width: 75%; justify-content: flex-end; padding: 6px; cursor: pointer;" *ngIf="auth.role == 'clientAdmin' || auth.role == 'superadmin'"  [appTooltip]="203">      
            <div class="studentMenu d-flex"  *ngFor="let exam of examsNames; let i = index" [ngClass]="{'studentMenuSelected': exam == selectedExamReport}" [appTooltip]="204">
                <span (click)="infoStudent = exam; selectedExamReport = exam" >
                    {{exam | titlecase}}
                </span>
            </div>
            <div *ngIf="plannerType == 'group'" class="studentMenu  d-flex" [appTooltip]="205" (click)="infoStudent = 'goals'; selectedExamReport = 'performance'"  [ngClass]="{'studentMenuSelected':  infoStudent == 'goals'}">
                Goals
            </div>
            <div *ngIf="plannerType != undefined" class="studentMenu  d-flex" [appTooltip]="206" (click)="infoStudent = 'activity'"  [ngClass]="{'studentMenuSelected':  infoStudent == 'activity'}">
                Activity
            </div>
        </div>
        <!-- <div class="h-100 d-flex my-auto justify-content-end letterColor" style="width: 60%;" *ngIf="candidateProfile != undefined && auth.role != 'clientAdmin'" [appTooltip]="207">
            <div class="groupInfo text-truncate" [ngStyle]="{'width': studentProgress == undefined ? '45%': '60%' }" [appTooltip]="208">
                {{candidateProfile.target_level}} -  {{candidateProfile.final_level}}
            </div>
            <div class="groupInfo text-truncate" [appTooltip]="209" style="width: 39%;" *ngIf="studentProgress == undefined && (auth.role == 'admin' || auth.role == 'superadmin')"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                {{candidateProfile.email}}
            </div>
        </div> -->
        <!-- <i class="my-auto fas fa-chart-bar" style="color: grey; cursor: pointer; width: 3%;" *ngIf="examsReports != 0" (click)="showPlacementReport()"></i> -->
    </div>
    <div  *ngIf="auth.role == 'superadmin' || auth.role == 'admin' && candidateProfile != undefined" style="font-size: 1.2vh; font-weight: 500; color: rgb(130, 130, 130); padding: 3px;" >
        {{candidateProfile.email}}
    </div>
    <div *ngIf="area == 'prospects' && prospectProfile != undefined " class="w-100 d-flex my-auto" style="justify-content: space-around; height: 87%;" [appTooltip]="210">
        <div class="h-100 d-flex scroll" style="width: 6%; cursor: pointer; "  (click)="prospectPage = 'profile'" [appTooltip]="211" >
            <i class="fa-solid fa-angle-left fas d-flex my-auto w-100" *ngIf="prospectPage == 'challenges'" style="color: grey;; justify-content: center;" ></i>
        </div>
        <div class="d-flex h-100" style="width: 81%; justify-content: center;" [appTooltip]="212">
            <div *ngIf="prospectPage == 'profile'" class="h-100" style="width: 90%; flex-direction: column; box-shadow: 7px 0rem 2rem 0rem #d2d1d1;" [appTooltip]="213">
                <div class="blue-header" style="font-weight: 500; padding: 6px 0px; height: 12%; position: relative;" [appTooltip]="214">
                    Prospect info
                    <span style="position: absolute; right: 1.5%; cursor: pointer; display: flex; align-items: center;" [appTooltip]="215" (click)="sendToAnalysis()">
                        +
                    </span>
                    <div *ngIf="prospectSend == true" class="groupDone" style="justify-content: center;" [appTooltip]="216"> Prospecto añadido </div>
                </div>
                <div  style="width: 100%; flex-direction: column; padding: 3%; height: 90%; justify-content: space-around;" class="d-flex letterColor" [appTooltip]="217">
                    <div class="titleResults2 d-flex " style="padding: 3px 6px;" [appTooltip]="218">
                        <div style="font-size: 1.3vh; font-weight: 600; padding: 6px 3px;" >
                            Ocupación:
                        </div>
                        <div class="groupInfo" style="padding: 6px 3px;" [appTooltip]="219">
                            {{prospectProfile.occupation}}
                        </div>
                    </div>
                    <div class="titleResults2 d-flex " style="padding: 3px 6px;" [appTooltip]="220">
                        <div style="font-size: 1.3vh; font-weight: 600; padding: 6px 3px;" >
                            Disciplina:
                        </div>
                        <div class="groupInfo" style="padding: 6px 3px;" [appTooltip]="221">
                            {{prospectProfile.discipline}} - {{prospectProfile.university}}
                        </div>
                    </div>
                    <div class="titleResults2 d-flex " style="padding: 3px 6px;" [appTooltip]="222">
                        <div style="font-size: 1.3vh; font-weight: 600; padding: 6px 3px;" >
                            Teléfono:
                        </div>
                        <div class="groupInfo" style="padding: 6px 3px;" [appTooltip]="223">  
                            {{prospectProfile.phone}}
                        </div>
                    </div>
                    <div class="titleResults2 d-flex " style="padding: 3px 6px;" [appTooltip]="222">
                        <div style="font-size: 1.3vh; font-weight: 600; padding: 6px 3px;" >
                            Email:
                        </div>
                        <div class="groupInfo" style="padding: 6px 3px;">  
                            {{prospectProfile.email}}
                        </div>
                    </div>
                    <div class="titleResults2 d-flex " style="padding: 3px 6px;" [appTooltip]="224">
                        <div style="font-size: 1.3vh; font-weight: 600; padding: 6px 3px;" >
                            Mensaje:
                        </div>
                        <div class="groupInfo" style="padding: 6px 3px;" [appTooltip]="225">
                            {{prospectProfile.message}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="prospectChallenges.length != 0 && prospectPage == 'challenges'" class="h-100" style="width: 90%; flex-direction: column; box-shadow: 7px 0rem 2rem 0rem #d2d1d1;" [appTooltip]="226">
                <div class="blue-header" style="font-weight: 500; padding: 6px 0px; height: 12%" [appTooltip]="227">
                 Desafíos
                </div>
                <div class="d-flex" style="width: 100%; height: 90%; flex-direction: column; overflow-y: scroll; padding: 3%;"[appTooltip]="228"> 
                    <div *ngFor="let area of prospectChallenges"  style="width: 100%; flex-direction: column;" [appTooltip]="229" >
                        <div class="w-100 d-flex" [appTooltip]="230">
                            <div class="d-flex" style="width: 90%; flex-direction: column;">
                                <div class="titleResults" >      
                                  {{area.name | titlecase}} challenges
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let challenge of area.challenges" class="w-100 d-flex letterColor" style="margin: 0.9% 0; color: grey; font-size: 1.2vh;" [appTooltip]="231">
                            <div style="width: 81%; padding: 0 3%;" class="d-flex" [appTooltip]="232">
                                {{challenge.challenge_name | titlecase}}
                            </div>
                            <div style="width: 15%;" class="d-flex" [appTooltip]="233">
                                {{challenge.term | titlecase}} plazo
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-100 d-flex scroll" style="width: 6%; cursor: pointer; " (click)="prospectPage = 'challenges'" [appTooltip]="234" >
            <i class="fa-solid fa-angle-right fas d-flex my-auto w-100"  *ngIf="prospectPage == 'profile' && prospectChallenges.length != 0" style="color: grey; justify-content: center;"></i>
        </div>
    </div>
    <div *ngIf="area == 'candidates' && placementResults != undefined" class="w-100" style="height: 90%" [appTooltip]="235">
        <div style="height: 75%; background-color: white; border-radius: 6px; box-shadow: 7px 0rem 2rem 0rem #d2d1d1; flex-direction: row;" class="mx-auto d-flex w-100" >    
            <div style="width: 48%; height: 100%; flex-direction: column; padding: 3%;" [appTooltip]="236">
                <div style="flex-direction: row; height: 57%;  " class="w-100 d-flex"  [appTooltip]="237">
                    <div style="width: 27%; height: 90%; flex-direction: row; background-color: #e3e3e3; ">

                    </div>
                    <div class="d-flex" style="width: 72%; flex-direction: column; padding: 3%" [appTooltip]="238">
                        <span class="text-truncate" style="font-size: 1.8vh; font-weight: 600; color: black;" [appTooltip]="239" [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                            {{user.name}} {{user.last_name}}
                        </span>
                        <span style="font-size: 1.2vh; font-weight: 500; color: rgb(130, 130, 130)" [appTooltip]="240"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                            {{user.target_level}}
                        </span>
                        <span style="font-size: 1.2vh; font-weight: 500; color: rgb(130, 130, 130)" *ngIf="auth.role == 'superadmin' || auth.role == 'admin'" [appTooltip]="241" [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                            {{user.email}}
                        </span>
                    </div>
                </div>
                <div style="flex-direction: column; font-size: 1.2vh; color: rgb(130, 130, 130); padding: 3% 0;" [appTooltip]="242">
                    <div  class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="243">
                        <div style="width: 30%" >
                            Total Score: 
                        </div>
                        <span style="font-weight: 600; color: black; font-size: 1.29vh;" [appTooltip]="244"> {{placementResults.average[0].value}}%</span>  
                    </div>
                    <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="245">
                        <div style="width: 30%">
                            Taken at:
                        </div>
                        <span style="font-weight: 600; color: black; font-size: 1.29vh;" [appTooltip]="246"> {{user.test_date}}</span>                   
                    </div>
                </div>
            </div>
            <div style="width: 54%; height: 100%; flex-direction: column; font-size: 1.2vh; color:rgb(130, 130, 130); padding: 1.2%;" [appTooltip]="247">
                <div class="w-100 d-flex h-100" style="flex-direction: column; font-size: 1.2vh; color: rgb(130, 130, 130); justify-content: center" >
                    <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="248" >
                        <div [appTooltip]="249">
                            General English Resources & Skills
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: row;">
                            <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;">
                                <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;">
                                    <div class="d-flex my-auto" style="min-width: max-content;height: 100%; background-color: #5DB6FF; border-radius: 6px;" [appTooltip]="250" [ngStyle]="{'width': placementResults.average[0].value + '%'}" > </div>
                                </div>
                            </div>
                            <div style="width: 9%; margin: 0 3%;" [appTooltip]="251">
                                {{placementResults.average[0].value}}%
                            </div>
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;">
                        <div [appTooltip]="252">
                            Field-Specific English Resources
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: row;">
                            <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;">
                                <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;" [appTooltip]="253">
                                    <div class="d-flex my-auto" style="min-width: max-content;height: 100%; background-color: #0085F2; border-radius: 6px;"  [ngStyle]="{'width':  placementResults.resources[0].value + '%'}"> </div>
                                </div>
                            </div>
                            <div style="width: 9%; margin: 0 3%;" [appTooltip]="254">
                                {{placementResults.resources[0].value}}%
                            </div>
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="255">
                        <div [appTooltip]="256">
                            Field-Specific English Skills
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="256">
                            <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;">
                                <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;" [appTooltip]="257">
                                    <div class="d-flex my-auto" style="min-width: max-content;height: 100%; background-color: #005DA7; border-radius: 6px; " [ngStyle]="{'width': placementResults.skills[0].value + '%'}" > </div>
                                </div>
                            </div>
                            <div style="width: 9%; margin: 0 3%;" [appTooltip]="258">
                                {{placementResults.skills[0].value}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100" *ngIf="studentProgress != undefined" style="height: 90%" [appTooltip]="259">
        <div *ngIf="(auth.role == 'clientAdmin' ||  auth.role == 'superadmin') && infoStudent == selectedExamReport" class="d-flex w-100 h-100" style="flex-direction: column;" [appTooltip]="260">
            <div style="height: 66%; background-color: white; border-radius: 6px; box-shadow: 7px 0rem 2rem 0rem #d2d1d1; flex-direction: row; margin: 3%; position: relative;" [appTooltip]="261" class="mx-auto d-flex w-100">    
                <div style="width: 45%; height: 100%; flex-direction: column; padding: 3%;" [appTooltip]="262">
                    <div style="flex-direction: row; height: 57%;  " class="w-100 d-flex" [appTooltip]="263">
                        <div style="width: 33%; height: 90%; flex-direction: row; background-color: #e3e3e3; ">
    
                        </div>
                        <div  class="d-flex" style="width: 81%; flex-direction: column; padding: 3% 6%;" [appTooltip]="264">
                            <span  class="text-truncate" style="font-size: 1.8vh; font-weight: 600; color: black;" [appTooltip]="265" [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                                {{userInfo.name}} {{userInfo.last_name}}
                            </span>
                            <span style="font-size: 1.2vh; font-weight: 500; color: rgb(130, 130, 130)" [appTooltip]="266" [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                                {{userInfo.email}}
                            </span>
                            <span style="font-size: 1.2vh; font-weight: 500; color: rgb(130, 130, 130)" [appTooltip]="267"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                                {{examsReports[selectedExamReport][indexReport].target_level}}
                            </span>
                        </div>
                    </div>
                    <div style="flex-direction: column; font-size: 1.2vh; color: rgb(130, 130, 130); padding: 3% 0;">
                        <div  class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="268">
                            <div style="width: 30%" >
                                Total Score: 
                            </div>
                            <span style="font-weight: 600; color: black; font-size: 1.29vh;" [appTooltip]="269"> {{examsReports[selectedExamReport][indexReport].final_score}}%</span>  
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="270">
                            <div style="width: 30%">
                                Taken at:
                            </div>
                            <span style="font-weight: 600; color: black; font-size: 1.29vh;" [appTooltip]="271"> {{examsReports[selectedExamReport][indexReport].test_date}}</span>  
                         
                        </div>
                        <!-- <div  class="w-100 d-flex" style="flex-direction: row;">
                            <div style="width: 30%">
                                Elapsed Time: 
                            </div>
                            <span style="font-weight: 600; color: black; font-size: 1.29vh;"> - </span>                    
                        </div>          -->
                    </div>
                </div>
                <div style="width: 54%; height: 100%; flex-direction: column; font-size: 1.2vh; color:rgb(130, 130, 130); padding: 1.2%;" [appTooltip]="272">
                    <div class="w-100 d-flex h-100" style="flex-direction: column; font-size: 1.2vh; color: rgb(130, 130, 130); justify-content: center" >
                        <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="273">
                            <div [appTooltip]="200">
                                General English Resources & Skills
                            </div>
                            <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="274">
                                <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;" [appTooltip]="275">
                                    <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;" >
                                        <div class="d-flex my-auto" style="min-width: max-content;height: 100%; background-color: #5DB6FF; border-radius: 6px;" [ngStyle]="{'width': examsReports[selectedExamReport][indexReport].report_accuracy.average + '%'}" > </div>
                                    </div>
                                </div>
                                <div style="width: 9%; margin: 0 3%;" [appTooltip]="276">
                                    {{examsReports[selectedExamReport][indexReport].report_accuracy.average}}%
                                </div>
                            </div>
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="277">
                            <div [appTooltip]="278">
                                Field-Specific English Resources
                            </div>
                            <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="279">
                                <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;" [appTooltip]="280">
                                    <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;">
                                        <div class="d-flex my-auto" style="min-width: max-content;height: 100%; background-color: #0085F2; border-radius: 6px;" [ngStyle]="{'width': examsReports[selectedExamReport][indexReport].report_accuracy.resource.Resources + '%'}"> </div>
                                    </div>
                                </div>
                                <div style="width: 9%; margin: 0 3%;" [appTooltip]="281">
                                    {{examsReports[selectedExamReport][indexReport].report_accuracy.resource.Resources}}%
                                </div>
                            </div>
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="282">
                            <div [appTooltip]="283">
                                Field-Specific English Skills
                            </div>
                            <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="284">
                                <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;" [appTooltip]="285">
                                    <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;">
                                        <div class="d-flex my-auto" style="min-width: max-content;height: 100%; background-color: #005DA7; border-radius: 6px; " [ngStyle]="{'width': examsReports[selectedExamReport][indexReport].report_accuracy.skill.Skills + '%'}" > </div>
                                    </div>
                                </div>
                                <div style="width: 9%; margin: 0 3%;" [appTooltip]="286">
                                    {{examsReports[selectedExamReport][indexReport].report_accuracy.skill.Skills}}%
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
                <i class="my-auto fas fa-chart-bar" style="color: grey; cursor: pointer; position: absolute; top: 6%; right: 2.1%; " [appTooltip]="287" *ngIf="examsReports != 0" (click)="showPlacementReport()"></i>
            </div>
            <div class="w-100 d-flex" style="justify-content: center;" [appTooltip]="288">
                <div class="my-auto" style="color: grey; width: 12%; cursor: pointer; font-size: 1.8vh;" [appTooltip]="289"> 
                    <i class="fa-solid fa-angle-left fas d-flex my-auto" *ngIf="examsReports[selectedExamReport].length != 0 && indexReport != 0" style="justify-content: flex-start;" (click)="indexReport = indexReport-1" ></i>
                </div>
                <div class="text-center" style="width: 18%; color: white; padding: 1.5%; cursor: pointer;">
                  
                </div>
                <div class="my-auto" style="color: grey; width: 12%; cursor: pointer; font-size: 1.8vh;" [appTooltip]="290">
                    <i class="fa-solid fa-angle-right fas d-flex my-auto" style="justify-content: flex-end;" *ngIf="examsReports[selectedExamReport].length != 0 && indexReport != (examsReports[selectedExamReport].length - 1)" (click)="indexReport = indexReport+1" ></i>
                </div>
            </div>
        </div>
        <div style="height: 100%; background-color: white; border-radius: 6px; flex-direction: row; padding: 1.2%; justify-content: space-around; " class="mx-auto d-flex w-100" *ngIf="infoStudent == 'activity'" [appTooltip]="291">
            <div class="h-100" style="width: 42%; flex-direction: column; box-shadow: 7px 0rem 2rem 0rem #d2d1d1;">
                <div class="blue-header" style="font-weight: 500; padding: 6px 0px; height: 9%" [appTooltip]="292">
                   Program content
                </div>
                <div style="width: 100%; flex-direction: column; padding: 3%; height: 90%; justify-content: space-around;" class="d-flex" [appTooltip]="293">
                    <div *ngIf="plannerType == 'group'" class="w-100" style="padding-bottom: 6px;" [appTooltip]="294">
                        <div class="w-100 titleResults">
                            Resources
                        </div>
                        <div class="titleResults2 d-flex " *ngFor="let resource of studentProgress.resources; let i = index" >
                            <div style="width: 30%;" class="my-auto" [appTooltip]="295">
                                {{resource.name | titlecase}}
                            </div>
                            <div class="d-flex my-auto" style="height: 21%; width: 60%;" [appTooltip]="296">
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color: #1C4360; ;" [appTooltip]="200" [ngStyle]="{'width': resource.percentage}"> </div>
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color: rgb(6 109 139 / 15%); " [appTooltip]="200" [ngStyle]="{'width': resource.restPercentage}"> </div>
                            </div>
                            <div class="d-flex my-auto" style="margin-left: 6%" [appTooltip]="297">
                                <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                    {{resource.value}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="plannerType == 'course'" class="w-100" style="padding-bottom: 6px;" [appTooltip]="294">
                        <div class="w-100 titleResults">
                            Resources
                        </div>
                        <div class="titleResultsCourse d-flex " *ngFor="let resource of studentProgress.resources; let i = index" >
                            <div style="width: 30%;" class="my-auto" [appTooltip]="295">
                                {{resource.name | titlecase}}
                            </div>
                            <div class="d-flex my-auto" style="height: 21%; width: 60%;" [appTooltip]="296">
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color: #1C4360; ;" [appTooltip]="200" [ngStyle]="{'width': resource.percentage}"> </div>
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color: rgb(6 109 139 / 15%); " [appTooltip]="200" [ngStyle]="{'width': resource.restPercentage}"> </div>
                            </div>
                            <div class="d-flex my-auto" style="margin-left: 6%" [appTooltip]="297">
                                <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                    {{resource.value}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="plannerType == 'group'" class="w-100" [appTooltip]="298">
                        <div class="w-100 titleResults" [appTooltip]="299">
                            Skills
                        </div>
                        <div class="titleResults2 d-flex " *ngFor="let skill of studentProgress.skills; let i = index" >
                            <div style="width: 30%; " class="my-auto" [appTooltip]="300">
                                {{skill.name | titlecase}}
                            </div>
                            <div class="d-flex my-auto" style="height: 21%; width: 60%;" [appTooltip]="301">
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color: #1C4360;" [appTooltip]="302" [ngStyle]="{'width': skill.percentage}"> </div>
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color:rgb(6 109 139 / 15%); " [appTooltip]="303" [ngStyle]="{'width': skill.restPercentage}"> </div>
                            </div>
                            <div class="d-flex my-auto" style="margin-left: 6%" [appTooltip]="304">
                                <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                    {{skill.value}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="plannerType == 'course'" class="w-100" [appTooltip]="298">
                        <div class="w-100 titleResults" [appTooltip]="299">
                            Skills
                        </div>
                        <div class="titleResultsCourse d-flex " *ngFor="let skill of studentProgress.skills; let i = index" >
                            <div style="width: 30%; " class="my-auto" [appTooltip]="300">
                                {{skill.name | titlecase}}
                            </div>
                            <div class="d-flex my-auto" style="height: 21%; width: 60%;" [appTooltip]="301">
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color: #1C4360;" [appTooltip]="302" [ngStyle]="{'width': skill.percentage}"> </div>
                                <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color:rgb(6 109 139 / 15%); " [appTooltip]="303" [ngStyle]="{'width': skill.restPercentage}"> </div>
                            </div>
                            <div class="d-flex my-auto" style="margin-left: 6%" [appTooltip]="304">
                                <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                    {{skill.value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 100%; background-color: white; border-radius: 6px; flex-direction: row; padding: 1.2%;justify-content: space-around; padding-bottom: 0;" class="mx-auto my-auto d-flex w-100" *ngIf="infoStudent == 'goals' && plannerType == 'group'" [appTooltip]="305">
            <div class="h-100" style="width: 42%; flex-direction: column; box-shadow: 7px 0rem 2rem 0rem #d2d1d1;"  *ngIf="studentGoals != undefined" [appTooltip]="306">
                <!-- <div class="h-100 w-100 d-flex" style="flex-direction: column;" *ngIf="studentGoals != undefined"> -->
                    <div class="blue-header" style="font-weight: 500; padding: 6px 0px; height: 9%" [appTooltip]="307">
                        Course Goals
                    </div>
                    <div style="width: 100%; flex-direction: column; padding: 3%; height: 90%; justify-content: space-around;" class="d-flex" [appTooltip]="308">
                        <div class="w-100" style="padding-bottom: 6px;">
                            <div class="w-100 titleResults" [appTooltip]="310">
                                Resources
                            </div>
                            <div class="titleGoals d-flex" style="justify-content: center;" [appTooltip]="311" *ngFor="let resource of studentGoals.resources; let i = index" >
                                <div style="width: 36%;" class="my-auto" [appTooltip]="312">
                                    {{resource.name | titlecase}}
                                </div>
                                <div class="d-flex my-auto" style="height: 21%; width: 60%;">
                                    <div class="w-100 d-flex"style="position: relative; background-color: #E7E6E6; height: 9px;">
                                        <div class="d-flex my-auto" style="min-width: max-content; background-color: #7698D4; height: 9px;position: absolute; z-index: 999; ;" [appTooltip]="314" [ngStyle]="{'width': resource.startValue + '%'}"> </div>
                                        <div class="d-flex my-auto greenPerformance" style="min-width: max-content; height: 9px;position: absolute; z-index: 99;" [appTooltip]="315" [ngStyle]="{'width': resource.startValue +  resource.current_by_course + '%'}"> </div>        
                                        <div class="d-flex my-auto yellowPerformance" style="min-width: max-content; height: 9px;position: absolute; z-index: 9;" [appTooltip]="316" [ngStyle]="{'width': resource.targetValue + '%'}"> </div>        
                                    </div>          
                                </div>
                            </div>
                        </div>
                        <div class="w-100" style="padding-bottom: 6px;">
                            <div class="w-100 titleResults" [appTooltip]="318">
                                Skills
                            </div>
                            <div class="titleGoals d-flex" style="justify-content: center;" *ngFor="let skill of studentGoals.skills; let i = index" >
                                <div style="width: 36%;" class="my-auto" [appTooltip]="319">
                                    {{skill.name | titlecase}}
                                </div>
                                <div class="d-flex my-auto" style="height: 21%; width: 60%;" >
                                    <div class="w-100 d-flex" style="position: relative; background-color: #E7E6E6; height: 9px;">
                                        <div class="d-flex my-auto" style="min-width: max-content; background-color: #7698D4; height: 9px;position: absolute; z-index: 999;" [appTooltip]="321" [ngStyle]="{'width': skill.startValue + '%'}"> </div>
                                        <div class="d-flex my-auto greenPerformance" style="min-width: max-content; height: 9px;position: absolute; z-index: 99;" [appTooltip]="322" [ngStyle]="{'width': skill.startValue +  skill.current_by_course + '%'}"> </div>      
                                        <div class="d-flex my-auto yellowPerformance" style="min-width: max-content; height: 9px;position: absolute; z-index: 9;" [appTooltip]="323" [ngStyle]="{'width': skill.targetValue + '%'}"> </div>      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div *ngIf="admin.selectedStudent.campus_id != 1" class="w-100">
                            <div class="w-100 titleResults">
                                Professional expertise
                            </div>
                            <div class="titleGoals d-flex" style="justify-content: center;" *ngFor="let px of studentGoals.px; let i = index" >
                                <div style="width: 36%; " class="my-auto">
                                    {{px.name | titlecase}}
                                </div>
                                <div class="d-flex my-auto" style="height: 21%; width: 60%;">
                                    <div class="w-100 d-flex" style="position: relative; background-color: #E7E6E6; height: 9px;">
                                        <div class="d-flex my-auto" style="min-width: max-content; background-color: #7698D4; height: 9px;position: absolute; z-index: 999;" [ngStyle]="{'width': px.startValue + '%'}"> </div>
                                        <div class="d-flex my-auto" style="min-width: max-content; background-color: rgb(146, 208, 80); height: 9px;position: absolute; z-index: 99;" [ngStyle]="{'width': px.startValue + px.current_by_course + '%'}"> </div>      
                                        <div class="d-flex my-auto" style="min-width: max-content; background-color: #FFCB25; height: 9px;position: absolute; z-index: 9;" [ngStyle]="{'width': px.targetValue + '%'}"> </div>     
                                    </div>
                                </div>
                                <div class="d-flex my-auto" style="margin-left: 6%">
                                    <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                        {{px.value}}
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                <!-- </div> -->
            </div>
            <div class="h-100" style="width: 42%; flex-direction: column; box-shadow: 7px 0rem 2rem 0rem #d2d1d1;" >
                <div class="blue-header" style="font-weight: 500; padding: 6px 0px; height: 9%; position: relative;" [appTooltip]="325">
                   Course performance
                   <i class="my-auto fas fa-chart-bar h-100 d-flex" style="color: white; cursor: pointer; position: absolute; font-size: 1.2vh; right: 3%; align-items: center;" [appTooltip]="326" *ngIf="examsReports != 0" (click)="showPlacementReport()"></i>
               </div>
               <div  class="d-flex" style="width: 100%; flex-direction: column; padding: 3%; height: 90%; justify-content: space-around;" *ngIf="lms.placement == false && admin.selectedStudent != undefined" [appTooltip]="327">
                    <div *ngFor="let area of studentPerformance; let i=index" class="d-flex" style="width: 100%; flex-direction: column; justify-content: space-around;" >
                        <div class="w-100 titleResults" [appTooltip]="328">
                            {{area.name}}
                        </div>
                        <div  *ngFor="let type of area.types; let j=index" class="titleGoals d-flex" style="justify-content: center; padding-bottom: 6px;" >
                            <div class="my-auto" style="width: 36%;" *ngIf="type.name != 'Grammar Index' && type.name != 'Use Of English' && type.name != 'Listening Exams' && type.name != 'Reading Exams'"  [appTooltip]="329"> {{type.name}} </div>
                            <div class="my-auto" style="width: 36%;" *ngIf="type.name == 'Use Of English'"  [appTooltip]="330"> UOE </div>
                            <div class="my-auto" style="width: 36%;" *ngIf="type.name == 'Grammar Index'"  [appTooltip]="331"> Grammar </div>
                            <div class="my-auto" style="width: 36%;" *ngIf="type.name == 'Listening Exams'"  [appTooltip]="332"> Listening </div>
                            <div class="my-auto" style="width: 36%;" *ngIf="type.name == 'Reading Exams'"  [appTooltip]="333"> Reading </div>
    
                            <div style="width: 60%; cursor: pointer; height: 15px;" class="groupInfo my-auto" *ngIf="type.name != 'Writing' && type.name != 'Speaking'" [appTooltip]="334">
                                <div class="d-flex h-100" style="background-color: rgb(240 240 240); width: 100%;">
                                    <div  style="min-width: max-content;" class="greenPerformance" [ngStyle]="{'width': type.certified + '%'}" [appTooltip]="335"> </div>
                                    <div  style="min-width: max-content;" class="orangePerformance" [ngStyle]="{'width': type.done  + '%'}" [appTooltip]="336"></div>
                                    <div  style="min-width: max-content; " class="yellowPerformance" [ngStyle]="{'width': type.assigned + '%'}" [appTooltip]="337"></div>
                                </div>
                            </div>
                            <div *ngIf="type.name == 'Writing' || type.name == 'Speaking'" style="width: 60%; cursor: pointer; height: 15px;" [appTooltip]="338">
                                <div class="d-flex h-100 mx-auto" style="width: 92%;" [ngStyle]="{'pointer-events': auth.role == 'clientAdmin' ? 'none' : ''}">
                                    <ngx-slider [(value)]="type.certified" [options]="options" (mouseup)="setWritingSpeakingPerformance(type)"></ngx-slider>       
                                </div>
                            </div>
                            <div class="d-flex my-auto" style="margin-left: 3%; width: 6%;" [appTooltip]="339">
                                <div class="h-100 w-100" style="color: rgb(181, 181, 181); font-weight: 600;">
                                    {{type.certified}}
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
           </div>
        </div>
    </div>
</div>
<app-placement-report *ngIf="showExamsReport == true && studentPerformance != undefined && studentPerformance != 0" [selectedExamReport]="selectedExamReport" [examsReports]="examsReports" [userInfo]="userInfo" [studentPerformance]="studentPerformance" ></app-placement-report>
