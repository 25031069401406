import { Component, OnInit, Injectable, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { AuthService } from 'src/app/services/auth.service';
import { SessionDataService } from 'src/app/services/session-data.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { environment, roles } from 'src/environments/environment';

// import { ImageCroppedEvent } from 'ngx-image-cropper';
import * as $ from 'jquery';
import { CryptoService } from 'src/app/services/crypto-js.service';
declare var $: any;



@Injectable()
class NeedsService {
  constructor(public service: AuthService) { }
}

const injector = Injector.create({
  providers:
    [{ provide: NeedsService, deps: [AuthService] }, { provide: AuthService, deps: [] }]
});

@Injectable({
  providedIn: 'root',
})
export class user {
  id: number = null;
  name: string = '';
  last_name: string = '';
  second_last_name: string = '';
  email: string = '';
  password: string = '';
  picture: string = '';
  date_of_birth: string = '';
  role_id: string = '';
  phone: number = 0;
  country: string = '';
  auth: NeedsService
  constructor(user_aux) {
    this.id = user_aux.id;
    this.name = user_aux.name;
    this.last_name = user_aux.last_name != null ? user_aux.last_name : '';
    this.second_last_name = user_aux.second_last_name != null ? user_aux.second_last_name : '';
    this.email = user_aux.email != undefined ? user_aux.email : '';
    this.password = user_aux.password != undefined ? user_aux.password : '';
    this.picture = user_aux.picture != undefined ? user_aux.picture : '';
    this.date_of_birth = user_aux.date_of_birth != undefined ? user_aux.date_of_birth : '';
    this.phone = user_aux.pphone != undefined && user_aux.pphone != undefined ? user_aux.pphone : 0;
    this.country = user_aux.country != undefined ? user_aux.country : '';

    this.auth = injector.get(NeedsService);
    if (this.auth) {
      this.role_id = user_aux.role_id;
      // if (roles.audioImporter == Number(user_aux.role_id)) {
      //   this.role = 'Colaborador';
      // } else if (roles.teacherRole == Number(user_aux.role_id)) {
      //   this.role = 'Profesor';
      // } else if (roles.superAdmin == Number(user_aux.role_id)) {
      //   this.role = 'Super Admin';
      // } else if (roles.deanRole == Number(user_aux.role_id)) {
      //   this.role = 'Dean';
      // } else if (roles.colaboratorRole == Number(user_aux.role_id)) {
      //   this.role = 'Colaborador';
      // } else if (roles.traineeRole == Number(user_aux.role_id)) {
      //   this.role = 'Trainee';
      // } else if (roles.testerRole == Number(user_aux.role_id)) {
      //   this.role = 'Tester';
      // } else if (roles.adminRole == Number(user_aux.role_id)) {
      //   this.role = 'Admin';
      // }
    }
  }
}
@Component({
  selector: 'app-students-admin',
  templateUrl: './students-admin.component.html',
  styleUrls: ['./students-admin.component.css']
})
export class StudentsAdminComponent implements OnInit {
  formulario: UntypedFormGroup;
  formularioModalPass: UntypedFormGroup;
  formularioModalPic: UntypedFormGroup;
  info: user = null;
  mostrarPassword = false;
  loading: boolean;
  pass: string;
  pass2: string;
  passNew: string;
  date: string;
  dateAll: string;
  datePart: string[];
  mostrar = false;
  mostrar1 = false;
  mostrar_reg: boolean = false;
  mostrar_conf: boolean = false;
  mostrar2 = false;
  registerOK: boolean;
  registerBAD: boolean;
  logEmailInfo: string = '';
  logPassInfo: string = '';
  open_users: boolean = false;
  type_users = new Array();
  selected_type_user = {
    user: 'Estudiante',
    role_id: 1
  };
  register: UntypedFormGroup;
  rolesAux;
  constructor(private http: HttpClient, private cryptoService: CryptoService, public _auth: AuthService, public sessData: SessionDataService, public _userData: UserDataService, private sanitize: DomSanitizer) {
    this.rolesAux = roles;
    this.formularioModalPic = new UntypedFormGroup({
      picture: new UntypedFormControl('', Validators.required)
    });
    this.formularioModalPass = new UntypedFormGroup({
      password: new UntypedFormControl('', Validators.minLength(8)),
      password2: new UntypedFormControl('')
    });
    this.setMember();
    this.formulario.controls["password_new"].setValidators([
      Validators.required,
      Validators.minLength(8),
      // this.noIgual.bind(this.formulario)
    ]);
    this.setNewUser();
    this.register.controls["password_confirmation"].setValidators([
      Validators.required,
      // this.noIgualReg.bind(this.register.controls["password_confirmation"])
    ]);
    // this.formularioModal.controls['password2'].setValidators(this.noIgual.bind(this.formularioModal));
  }

  not_valid_match: boolean = false;
  noIgualReg() {
    // let register: any = this;
    //console.log('dentro de check');
    if (this.register.controls["password_confirmation"].value !== this.register.controls["password"].value) {
      this.not_valid_match = true;
    } else {
      this.not_valid_match = false;
    }
    return null;
  }

  not_valid: boolean = false;
  not_valid_reg: boolean = false;
  noIgual(type?) {
    // if (this.formularioModalPass.controls['password'].value !== this.formularioModalPass.controls["password2"].value) {
    //   this.notSame = true;
    // } else {
    //   this.notSame = false;
    // }
    // let formulario: any = this;
    if (type == 'register') {
      //console.log(this.register.controls["password"].value);
      //console.log(this.register.controls["password"].value.match(new RegExp('\d', 'g')));
      //console.log(this.register.controls["password"].value.match(new RegExp('[a-zA-Z]', 'g')));
      if (this.register.controls["password"].value.match(new RegExp('[0-9]', 'g')) == null || this.register.controls['password'].value.match(new RegExp('[a-zA-Z]', 'g')) == null) {
        this.not_valid_reg = true;
      } else {
        this.not_valid_reg = false;
      }
    } else{
      if (this.formulario.controls["password_new"].value.match(new RegExp('\d', 'g')) == null || this.formulario.controls['password_new'].value.match(new RegExp('[a-zA-Z]', 'g')) == null) {
        this.not_valid = true;
      } else {
        this.not_valid = false;
      }
    }
  }

  ngOnInit(): void {
    this._auth.workingArea = 'students-admin';
    if (this.sessData.array_students.length == 0) {
      this.getUserInfo(1);
    }
    if (this.sessData.availableCountry.length == 0) {
      this._userData.getCountries().subscribe((data: any) => {
        for (let index = 0; index < data.length; index++) {
          this.sessData.availableCountry.push(data[index]);
        }
        // //console.log(this.sessData.availableCountry);
      });
    }
    this.type_users = new Array();
    let user_type = {
      user: 'Super Admin',
      role_id: roles.superadmin
    }
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.adminRole;
    // user_type.user = 'Admin';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.deanRole;
    // user_type.user = 'Dean';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.colaboratorRole;
    // user_type.user = 'Colaborador';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.colaboratorRole;
    // user_type.user = 'Colaborador (Audios)';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.teacherRole;
    // user_type.user = 'Teacher';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.traineeRole;
    // user_type.user = 'Trainee';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.student;
    // user_type.user = 'Estudiante';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = roles.testerRole;
    // user_type.user = 'Tester';
    // this.type_users.push(JSON.parse(JSON.stringify(user_type)));
    // user_type.role_id = -1;
    // user_type.user = 'All';
    // this.type_users.unshift(JSON.parse(JSON.stringify(user_type)));
    // this.new_user = false;
  }

  getUserInfo(role_id) {
    this.loading = true;
    this.getUserInfoSub = this._auth.getUsers(role_id).subscribe(async data => {
      this.sessData.array_students = new Array();
      this.loading = false;
      // //console.log(data);
      let users = JSON.parse(this.cryptoService.CryptoJSAesDecrypt('12345678A*', data));
      //console.log(users);
      users.forEach(user_get => {
        let user_aux = new user(user_get);
        this.sessData.array_students.push(user_aux);
      });
      this.sessData.array_students = this.sessData.array_students.sort((c1, c2) => {
        return c1.name.toLowerCase().localeCompare(c2.name.toLowerCase());
      });
      // this.completeInfo = JSON.stringify(this._loggedData.getUserInfo());
    }, err => {
      this.loading = false
    });
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  cleanInputs() {
    this.formularioModalPass.controls['password'].setValue('');
    this.formularioModalPass.controls['password2'].setValue('');
  }

  bool: any;
  // noIgualOld(): any {
  //   if (this.passwordOk) {
  //     return null
  //   } else {
  //     return true;
  //   }
  // }

  prevent(event) {
    event.preventDefault();
  }

  preImg = false;
  passwordOk: any;
  // userCheckPasswordSub;
  // setBoolean(control: FormControl) {
  //   this.userCheckPasswordSub = this._userData.userCheckPassword(control.value, null).subscribe(data => {
  //     this.passwordOk = data;
  //     this.formulario.controls['passwordOld'].setValue('');
  //     if (this.passwordOk == true) {
  //       $('#passChange').modal('show');
  //       // this.passChange.first.nativeElement.click();
  //     }
  //   });
  // }


  changeImage() {
    this.sessData.imageAux = this.croppedImage;
    this.formularioModalPic.controls['picture'].setValue(this.croppedImage);
    localStorage.setItem('image', this.formularioModalPic.controls['picture'].value);
  }

  fileChangeEvent(event: any): void {
    if (!event) {
      this.croppedImage = null;
      return;
    }
    if (event) {
      this.imageChangedEvent = event;
      return;
    }
  }

  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  // }

  cargarPreImg() {
    this.preImg = !this.preImg;
  }


  // tslint:disable-next-line: member-ordering
  imageAux: any = '../../../../assets/img/user-regular.svg';
  setUser(user) {
    this._auth.getUserInfo(user.id).subscribe((data: any) => {
      this.info = JSON.parse(this.cryptoService.CryptoJSAesDecrypt('12345678A*', data));
      //console.log(this.info);
      this.info.id = user.id;
      this.sessData.name = this.info.name;
      this.sessData.last_name = this.info.last_name != null ? this.info.last_name : '';
      this.sessData.imageAux = (this.info.picture != null) ? this.sanitize.bypassSecurityTrustUrl(this.info.picture) : this.imageAux;
      // Convierte la informacion de la date para presentarla mas intuitiva al usuario
      this.dateAll = this.info.date_of_birth != null ? this.info.date_of_birth : '';
      if (this.dateAll != null) {
        this.datePart = this.dateAll.split(" ");
        this.date = this.datePart[0];
      }
      // Recoge la data del usuario
      this.formulario.controls['name'].setValue(this.info.name);
      this.formulario.controls['last_name'].setValue(this.info.last_name);
      this.formulario.controls['second_last_name'].setValue(this.info.second_last_name);
      this.formulario.controls['email'].setValue(this.info.email);
      this.formulario.controls['date_of_birth'].setValue(this.date);
      this.formulario.controls['phone'].setValue(this.info.phone);
      this.formulario.controls['country'].setValue(this.info.country);
      if (this.info.country != null) {
        for (let index = 0; index < this.sessData.availableCountry.length; index++) {
          if (this.sessData.availableCountry[index].name.toLowerCase() == this.info.country.toLowerCase()) {
            this.prefix_edit = Number(this.sessData.availableCountry[index].prefix);
            break;
          }
        }
      }
      this.imageAux = this.sanitize.bypassSecurityTrustUrl(this.info.picture);
      this.selected_type_user.role_id = Number(this.info.role_id);
      this.selected_type_user.user = 'Estudiante';
      this.array_users = new Array();
    });
    // this.loading = false;
  }

  completeInfo: string;
  imageProfile: string;
  getUserInfoSub;
  alertMessPass = false;
  changePassword() {
    // if (this.notSame == true) {
    //   return;
    // } else {
    //   this.alertMessPass = true;
    //   $('#passModal').modal('show');
    //   // this.passModal.first.nativeElement.click();
    //   this.cleanInputs();
    //   setTimeout(() => {
    //     this.alertMessPass = false;
    //   }, 4000);
    // }
  }
  alertMess = false;
  changeEditSub;
  changeEdit() {
    if (this.info != null) {
      this.info.name = this.formulario.controls['name'].value ? this.formulario.controls['name'].value : null;
      this.info.last_name = this.formulario.controls['last_name'].value ? this.formulario.controls['last_name'].value : null;
      this.info.second_last_name = this.formulario.controls['second_last_name'].value ? this.formulario.controls['second_last_name'].value : null;
      this.info.email = this.formulario.controls['email'].value ? this.formulario.controls['email'].value : null;
      this.info.picture = this.formularioModalPic.controls['picture'].value ? this.formularioModalPic.controls['picture'].value : null;
      this.info.date_of_birth = this.formulario.controls['date_of_birth'].value ? this.formulario.controls['date_of_birth'].value : null;
      this.info.phone = this.formulario.controls['phone'].value ? this.formulario.controls['phone'].value : null;
      this.info.country = this.formulario.controls['country'].value ? this.formulario.controls['country'].value : null;
      let new_password;
      if (this.formulario.controls['password_new'].value !== '') {
        new_password = this.formulario.controls['password_new'].value;
      }
      let date = this.info.date_of_birth != null ? this.info.date_of_birth : null;
      // this.changeEditSub = this._userData.changeEdit(this.info.id, this.selected_type_user.role_id, this.info.name, this.info.last_name, this.info.second_last_name, this.info.email, date, this.info.phone, this.info.country, this.info.picture, new_password).subscribe(data => {
      //   for (let index = 0; index < this.sessData.array_students.length; index++) {
      //     if (this.sessData.array_students[index].id == this.info.id) {
      //       this.sessData.array_students[index].name = this.info.name;
      //       this.sessData.array_students[index].last_name = this.info.last_name;
      //       this.sessData.array_students[index].second_last_name = this.info.second_last_name;
      //       break;
      //     }
      //   }
      //   this.alertMess = true;
      //   setTimeout(() => {
      //     this.alertMess = false;
      //   }, 2000);
      // });
    }
  }

  array_users: Array<user> = new Array();
  typeAheadUser(searchValue) {
    searchValue = searchValue.toLowerCase();
    this.array_users = new Array();
    if (this.sessData.array_students.length > 0) {
      if (searchValue.length > 0) {
        for (let index = 0; index < this.sessData.array_students.length; index++) {
          if (this.sessData.array_students[index].name.toLowerCase().indexOf(searchValue) != -1
            || this.sessData.array_students[index].last_name.toLowerCase().indexOf(searchValue) != -1
            || this.sessData.array_students[index].second_last_name.toLowerCase().indexOf(searchValue) != -1) {
            this.array_users.push(this.sessData.array_students[index]);
          }
        }
      } else {
        this.array_users = new Array();
      }
    }
  }

  birth;
  auxBirth;
  auxString;
  email: any = true;
  newName = true;
  codeMail = true;
  // newEmail() {
  //   this.userData.newEmail(this.sessData.register.value.email.toString()).subscribe(data => {
  //     this.email = data;
  //   });
  // }

  // usedName;
  // checkUserName() {
  //   this.userData.checkName(this.register.controls["name"].value, this.register.controls['lastname'].value).subscribe(data => {
  //     this.usedName = !data;
  //   });
  // }

  // newUserName(usedName): any {
  //   return (control: AbstractControl): { [key: string]: boolean } | null => {
  //     if (!usedName) {
  //       return {
  //         noiguales: true
  //       };
  //     }
  //     return null;
  //   };
  // }

  selectedCountry = new Array();
  prefix = 0;
  prefix_edit = 0;
  getCountry(event, edit?) {
    this.selectedCountry = new Array();
    if (event.target.value.length >= 2) {
      for (let index = 0; index < this.sessData.availableCountry.length; index++) {
        if (this.sessData.availableCountry[index].name.toLowerCase().indexOf(event.target.value.toLowerCase()) != -1) {
          this.selectedCountry.push(this.sessData.availableCountry[index]);
        }
      }
    } else if (event.target.value.length == 0) {
      if (edit == undefined) {
        this.prefix = 0;
        this.register.controls['country'].setValue('');
      } else {
        this.prefix_edit = 0;
        this.formulario.controls['country'].setValue('');
      }
    }
  }

  setCountry(country, edit?) {
    if (edit != undefined) {
      this.prefix_edit = Number(country.prefix);
      this.formulario.controls['country'].setValue(country.name);
    } else {
      this.prefix = Number(country.prefix);
      this.register.controls['country'].setValue(country.name);
    }
    //console.log(this.register.controls['country'].value);
    this.selectedCountry = new Array();
  }

  setMember() {
    this.formulario = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
      last_name: new UntypedFormControl('', Validators.required),
      second_last_name: new UntypedFormControl(""),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")]),
      date_of_birth: new UntypedFormControl(''),
      phone: new UntypedFormControl('', [Validators.required, Validators.pattern("[0-9]{9}")]),
      password_new: new UntypedFormControl(''),
      country: new UntypedFormControl("", [
        Validators.required
      ]),
    });
  }

  archive_mess: boolean = false;
  archiveUser(user?) {
    let user_id = this.info != null ? this.info.id : null;
    if (user != undefined) {
      user_id = user.id;
    }
    this._auth.deleteUser(user_id).subscribe((data: any) => {
      if (this.info != null && this.info.id == user_id) {
        this.setMember();
        this.info = null;
      }
      for (let index = 0; index < this.array_users.length; index++) {
        if (this.array_users[index].id == user_id) {
          this.array_users.splice(index, 1);
          break;
        }
      }
      for (let index = 0; index < this.sessData.array_students.length; index++) {
        if (this.sessData.array_students[index].id == user_id) {
          this.sessData.array_students.splice(index, 1);
          break;
        }
      }
      this.archive_mess = true;
      setTimeout(() => {
        this.archive_mess = false;
      }, 1500);
    });
  }

  new_user: boolean = false;
  setEdit() {
    this.mostrar1 = false;
    this.new_user = false;
    this.open_users = false;
  }

  setNewUser() {
    this.register = new UntypedFormGroup({
      first_name: new UntypedFormControl("", [Validators.required, Validators.minLength(2)]),
      last_name: new UntypedFormControl("", [Validators.required]),
      second_last_name: new UntypedFormControl(""),
      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
      password_confirmation: new UntypedFormControl("", [Validators.required]),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")
      ]),
      phone: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{9}")
      ]),
      country: new UntypedFormControl("", [
        Validators.required
      ]),
      promotional_code: new UntypedFormControl(""),
      date_of_birth: new UntypedFormControl(''),
      // ProfessionalEnglish: new FormControl("", [Validators.required,
      // Validators.minLength(1)
      // ]),
      // EnglishCertificates: new FormControl("", [Validators.required,
      // Validators.minLength(1)
      // ]),
      // birth: new FormControl(""),
      key: new UntypedFormControl("")
    });
    this.prefix = 0;
    this.new_user = true;
  }

  Register() {
    this.auxBirth = String(this.register.value.birth);
    if (this.auxBirth == '') {
      this.auxBirth = null;
    } else {
      this.auxString = this.auxBirth.split(' ', 5);
      this.birth = this.auxString[2] + ' ' + this.auxString[1] + ' ' + this.auxString[3] + ' ' + this.auxString[4];
    }
    this.email = true;
    this.newName = true;
    this.codeMail = true;
    try {
      this.http
        .post(
          `${environment.apiUrl}/api/register`,
          {
            name: this.register.value.first_name,
            last_name: this.register.value.last_name,
            second_last_name: this.register.value.second_last_name,
            email: this.register.value.email,
            password: this.register.value.password,
            password_confirmation: this.register.value.confirmPassword,
            date_of_birth: this.register.value.date_of_birth,
            // EnglishCertificates: this.register.value.EnglishCertificates,
            // ProfessionalEnglish: this.register.value.ProfessionalEnglish,
            key: this.register.value.key,
            country: this.register.value.country,
            phone: this.register.value.phone,
            role_id: this.selected_type_user.role_id,
            is_admin: true
          },
          { responseType: "json" }
        ).subscribe((data: any) => {
          this.registerOK = true;
          let info_aux = {
            id: data,
            name: this.register.value.first_name,
            last_name: this.register.value.last_name,
            second_last_name: this.register.value.second_last_name,
            email: this.register.value.email,
            date_of_birth: this.register.value.date_of_birth,
            pphone: this.register.value.phone,
            country: this.register.value.country,
            role_id: 1
          }
          let us_aux = new user(info_aux);
          this.sessData.array_students.push(us_aux);
          this.setNewUser();
          setTimeout(() => (this.registerOK = false), 1500);
        }, err => {
          //console.log(err);
          if (err.error == 'Email already registered') {
            this.email = false;
          } else if (err.error == 'Add second last name') {
            this.newName = false;
          } else if (err.error == 'Email or key invalid') {
            this.codeMail = false;
          }
          // $("#register").modal('hide');
          this.registerBAD = true;
          setTimeout(() => (this.registerBAD = false), 1500);
        });
    } catch (error) { }
  }

  ngOnDestroy(): void {
    if (this.getUserInfoSub != undefined) {
      this.getUserInfoSub.unsubscribe();
    }
    // this.changeEditSub.unsubscribe();
    // this.userCheckPasswordSub.unsubscribe();
  }

}
