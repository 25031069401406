import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Subscription } from 'rxjs/Subscription';
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import Editor from "src/assets/js/ckeditor.js";
import { UserDataService } from 'src/app/services/user-data.service';
declare var $: any;


@Injectable({
  providedIn: 'root'
})

export class filterSet {
  filter_id = '';
  filter_name= '';
  newArea: boolean = false;
  options = new Array();
}

@Component({
  selector: 'app-grammar-importer',
  templateUrl: './grammar-importer.component.html',
  styleUrls: ['./grammar-importer.component.css']
})
export class GrammarImporterComponent implements OnInit {

  @ViewChild("myEditor") myEditor: any;
  public Editor = Editor;
  public EditorText: string = " ";

  public Config = {
    placeholder: "Type the content here!"
  };
  

  branch = [];
  level = [];
  levels = []
  fields = [];
  topicType = [];
  levelName = '';
  textEdition;
  newFilter: boolean = false;

  filtersGrammarIndex;
  newFiltersGrammarIndex = new Array();
  arrayNewFilters = new Array();

  addingSubarea: boolean = false;
  explanation = new Array();
  selectedId;
  updateExplanation: boolean = false;
  editOptions: boolean = false;
  editSubarea: boolean = false;
  viewText: boolean = false;
  dropDownFilters = new Array();
  successfullySave: boolean = false;
  message = '¡GUARDADO!';
  openLevel:boolean = true;
  openArea: boolean = false;
  openTypeArea: boolean = false;
  filterIdSelected = '';
  filterIdOptions = '';
  filterOptionsVis: boolean = false;
  searchingArea = false;
  dropDownSearch = false;
  searchFilter;
  filtersImporters = new Array();
  filterImportersSelected = [];
  showFilterOptions;
  filterToRemove;
  openOptions: boolean = false;
  warningMessage: boolean = false;

  subscription: Subscription;


  constructor(public admin: AdminService,  private userData: UserDataService) { }

  ngOnInit(): void {
    this.getGrammarIndexFilters();
    this.getAllImportsFiltersNames();

    this.subscription = this.userData.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'grammar_importer_data') {
        this.setPusherData(res.value);
      }
    });
  }


  onReady(editor) {
    console.log(this.myEditor);
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
        );

      // console.log( editor.ui);
      // console.log( editor.ui.getEditableElement())
      // console.log( editor.ui.getEditableElement().firstChild)
      // console.log( editor.ui.getEditableElement().firstChild.dataset.placeholder)
  }

  onChange({ editor }: ChangeEvent) {
    this.textEdition = editor.getData();
  }


  cleanTextEditor(){
    this.EditorText = '';
    this.textEdition =  this.EditorText;
  }

  getGrammarIndexFilters() {
    this.admin.getGrammarIndexFilters().subscribe((data: any) => {
      this.filtersGrammarIndex = data[0];
      var levels = [];
      let index = 1;
      let topics;
      let sortTopics = new Array();
      this.filtersGrammarIndex[0].show = false;
      while (index <= Object.keys(this.filtersGrammarIndex[0].levels).length) {
        levels.push(this.filtersGrammarIndex[0].levels[index]);
        index++;
      }
      this.filtersGrammarIndex[0].levels = levels;
      this.filtersGrammarIndex[0].show = false;
      for (let j = 0; j < this.filtersGrammarIndex[0].levels.length; j++) {
        let totalTopics = [];
        if (this.filtersGrammarIndex[0].levels[j].length != 0) {
          topics = Object.keys(this.filtersGrammarIndex[0].levels[j]);
          for (let i = 0; i < topics.length; i++) {
              if ( this.filtersGrammarIndex[0].levels[j][topics[i]].length != 0){
                for (let indexType = 0; indexType < this.filtersGrammarIndex[0].levels[j][topics[i]].length; indexType++){
                  let filtersAux = {
                    filter_id: '',
                    filter_name: '',
                    options: new Array()
                  }
                  if (this.filtersGrammarIndex[0].levels[j][topics[i]][indexType].filters.length != 0){
                    this.filtersGrammarIndex[0].levels[j][topics[i]][indexType].filters.forEach( (filter, index) =>{
                      if (filtersAux.filter_id == '' || filtersAux.filter_id != filter.filter_id){
                        filtersAux.filter_id = filter.filter_id;
                        filtersAux.filter_name = filter.filter_name;
                        filtersAux.options.push(filter.options[0]);
                      } else{
                        filtersAux.options.push(filter.options[0]);
                      }
                    });
                    this.filtersGrammarIndex[0].levels[j][topics[i]][indexType].filters = filtersAux;
                    this.filtersGrammarIndex[0].levels[j][topics[i]][indexType].openTopic = false;
                  }
                }
              }
              let topicsType = {
                name: topics[i],
                types: this.filtersGrammarIndex[0].levels[j][topics[i]]
              }
              totalTopics.push(topicsType);
            }
          }
          this.filtersGrammarIndex[0].levels[j] = totalTopics;
          this.filtersGrammarIndex[0].levels[j].show = false;
          this.filtersGrammarIndex[0].levels[j].name = this.courseName(j);
      }
      for (let i = 0; i <  data[1].length; i++){
        this.arrayNewFilters[i] = new Array();
        let filterAux = new filterSet();
        filterAux.filter_id = data[1][i].filter_id;
        filterAux.filter_name = data[1][i].filter_name;
        filterAux.newArea = false;
        if (data[1][i].options.length != 0){
          filterAux.options = data[1][i].options
        }
        this.newFiltersGrammarIndex.push(filterAux);
      }
    });
 
  }

  setPusherData(data){
    // let campus =  data.campus;
    let campus = 1;
    let level = data.level; //nivel
    let id = data.id; //id de subtipos en gramática dentro de áreas
    let filters = data.filters;
    for (let i = 0; i <  this.filtersGrammarIndex[0].levels[level - 1].length; i++){
      this.filtersGrammarIndex[0].levels[level - 1][i].types.forEach( type => {
        if (type.id == id){
          type.filters = filters;
        }
      });
    }
  }


  getAllImportsFiltersNames(){
    this.admin.getAllImportsFiltersNames('Grammar').subscribe((data: any) => {
      this.filtersImporters = data;
    });
  }

  includeFilter(filter){
    let filterAux = {
      filter_id:  filter['filter_id'],
      filter_name:  filter['filter_name'],
      newArea: false,
      options: filter['options']
    }
    
    let foundFilterId =  this.newFiltersGrammarIndex.find( filter => filter.filter_id == filterAux.filter_id);
    if (foundFilterId == undefined){
      // this.newFiltersGrammarIndex.push(filterAux);
      this.addNewFilter(filterAux.filter_name, filterAux.options)
    }
  }


  getGrammarIndexExplanation(option?) {
    let options_ids = [];
    this.arrayNewFilters.forEach(filter => {
      if (filter.option_id != null){
        options_ids.push(filter.option_id)
      }
    });
    let levels = Number(this.level) + 1;
    this.selectedId = undefined;
    this.admin.getGrammarIndexExplanation(levels, this.topicType['id'], options_ids).subscribe(data => {
      if (data[0] != 0){
        this.explanation = new Array();
        for(let i = 0; i < data[0].length; i++){
          let explanationAux = {
            explanation: data[0][i].explanation,
            id: data[0][i].id
          }
          this.selectedId = explanationAux.id;
          this.explanation.push(explanationAux);
        }
        let textExplanation = new Array();
        this.explanation.forEach( ex => {
          textExplanation.push(ex.explanation);
        });
        this.EditorText = textExplanation.join(" ");
        this.textEdition = String(this.EditorText);

        this.updateExplanation = true;
      } else{
        if (option != undefined){
          this.selectedId = option.explanation_id;
        }
        this.updateExplanation = false;
        this.message = 'No hay contenido';
        this.successfullySave = true;
        this.EditorText = '';
        this.textEdition =  this.EditorText;
        setTimeout(() => {
          this.successfullySave = false;
          this.message = '¡GUARDADO!';
        }, 4500);
      }
    });
  }


  selectLevel(index){
    this.updateExplanation = false;
    if (index == 'All'){
      this.filtersGrammarIndex[0].levels.map((level) =>{ level.selected = true; return level;});
    }  
  }


  courseName(level) {
    let levelName = '';
    switch (level) {
      case 0:
        levelName = 'A1';
        break;

      case 1:
        levelName = 'A2 - KET';
        break;

      case 2:
       levelName = 'B1 - PET';
        break;

      case 3:
       levelName = 'B2 - FCE';
        break;

      case 4:
       levelName = 'C1 - CAE';
        break;

      case 5:
      levelName = 'C2 - CPE';
        break;

      default:
        break;  
    }
    return levelName;
  }


  cleanFilters(){
    this.branch = [];
    this.level = [];
    this.fields = [];
    this.topicType = [];
    this.arrayNewFilters[0] = [];
    this.arrayNewFilters[1] = [];
  }

  cleanArrayNewFilters(){
    for (let i = 0; i < this.arrayNewFilters.length; i++){
      this.arrayNewFilters[i] = new Array();
    }
  }


  searchGrammarArea(search_value){
    this.searchingArea = true;
    search_value = search_value.toLowerCase();
    let searchAreaAux = new Array();

    if (search_value.length > 2) {  
      this.filtersGrammarIndex[0].levels[this.level[0]].forEach((level, i) => {
        if (level.length != 0){
          level.types.forEach( (type, j) => {
            if (type != undefined){
              if (type.name.toLowerCase().indexOf(search_value) != -1 ){
                this.dropDownSearch = true;
                type.found = true
              } else{
                type.found = false
              }
            }
          })
        }
      });
    } 
  }

  selectFilterSearch(index, type){
    this.fields = index;
    this.topicType = type;
    this.openArea = true; 
    this.dropDownSearch = false;
    this.searchFilter = type;
    this.filtersGrammarIndex[0].levels[this.level[0]].forEach((level, i) => {
      level.types.map((type) =>{ type.found = false; return});
    });
  }

  selectFilterOptions(filter_id, option){
    this.newFiltersGrammarIndex.forEach( (filter, i) =>{
      if (filter.filter_id == filter_id){
        filter.options.forEach( opt => {
          if (opt.option_id == option.option_id){
            this.arrayNewFilters[i] = opt;
          }
        });
      }
    });
  }

  openFilter(area, type){
    area.types.map( type =>{ type.openTopic = false})
    type.openTopic = true;
  }

  
  openFilterOptions(){
    this.openOptions = !this.openOptions;
  }


  newSubArea(event, index, filter?) {
    this.updateExplanation = false;
    if (event.target.value == "New" && index == undefined) {
      this.addingSubarea = true;
    } else if (event.target.value == "Remove"){
      this.filterToRemove = filter;
      this.warningMessage = true;
    } else if (event.target.value == "New" && index != undefined){
      this.newFiltersGrammarIndex[index].newArea = true;
    }
  }

  

  saveSubArea(value) {
    this.addingSubarea = false;
    var levels = new Array();
    levels = [String(Number(this.level) + 1)];
    this.admin.addGrammarIndexSubarea(levels, this.filtersGrammarIndex[0].levels[this.level[0]][this.fields[0]].name, value).subscribe((data) => {
      this.filtersGrammarIndex[0].levels[this.level[0]][this.fields[0]].types.push(data);
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

  editGrammarIndexSubarea(value, subarea){
    let subareaName = subarea.name;
    subarea.name = value;
    this.editSubarea = false;
    this.addingSubarea = false;
    this.admin.updateGrammarIndexSubarea(subarea.id, subarea.name).subscribe((data) => {     
      this.successfullySave = true
      if (data == false){
        subarea.name = subareaName;
      }
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

  addNewFilter(value, filterOptions?){
    this.newFilter = false;
    this.updateExplanation = false;
    let filterName = new Array();
    filterName.push(value);
    this.admin.addFilter(filterName, 'Grammar').subscribe((data) => {
      let filter = {
        filter_id : data[0].filter_id,
        filter_name:   data[0].filter_name,
        newArea: false,
        options: filterOptions
      }
      this.newFiltersGrammarIndex.push(filter);
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

  closeWarningMessage(){
    this.arrayNewFilters.forEach( (filter , i)=> {
      if (filter == 'Remove'){
        this.arrayNewFilters[i] = new Array();
        this.warningMessage = false;
      }
    })
  }

  removeFilter(){
    this.admin.removeFilter(this.filterToRemove.filter_id, 'Grammar').subscribe((data) => {
      this.message = JSON.parse(JSON.stringify(data));
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
      this.newFiltersGrammarIndex.forEach((filter, i) => {
        if (this.filterToRemove.filter_id == filter.filter_id){
          this.newFiltersGrammarIndex.splice(i, 1);
        }
      })
      this.closeWarningMessage()
    });
  }


  addNewFilterOptions(value, filter){
    filter.newArea = false;
    this.updateExplanation = false;
    let filter_options = new Array();
    filter_options.push(value);
    this.levels.push(this.level);
    let options_ids = [];
    this.arrayNewFilters.forEach(filter => {
      if (filter.option_id != undefined){
        options_ids.push(filter.option_id)
      }
    });
    this.admin.addFiltersOption(filter.filter_id, filter_options, 'Grammar', this.levels, this.topicType['id'], options_ids).subscribe((data) => {
      filter.options.push(data[0]);
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

  editFilterName(value, filter){
    filter.filter_name = value;
    this.editOptions = false
    this.admin.updateFilter(filter.filter_id, filter.filter_name).subscribe((data) => {
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }


  editFiltersOptions(value, option){

    option.option_name = value;
    this.editOptions = false;
    this.admin.updateFiltersOptions(option.option_id, option.option_name).subscribe((data) => {
      this.successfullySave = true;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.newFiltersGrammarIndex, event.previousIndex, event.currentIndex);
    let filters_ids =  this.newFiltersGrammarIndex.map(filter => {
      return Number(filter.filter_id);
    });
    this.admin.updateFiltersOrder(filters_ids).subscribe((data) => {
    });
  }  

  setGrammarIndexExplanation() {
    let explanation = [];
    let levels = [];
    let options_ids = [];
    this.arrayNewFilters.forEach(filter => {
      if (filter.option_id != undefined){
        options_ids.push(filter.option_id)
      }
    });
    let subarea_id  = this.topicType['id'];
    explanation.push(this.textEdition);
    levels = [String(Number(this.level) + 1)];

    if (this.updateExplanation == false){
      this.admin.setGrammarIndexExplanation(explanation, levels, subarea_id, options_ids).subscribe((data: any) => {
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
      });
    } else{
      this.explanation[0].explanation = this.textEdition;
      this.admin.updateGrammarIndexExplanation(this.explanation, levels, options_ids).subscribe((data: any) => { 
        this.successfullySave = true;
        setTimeout(() => {
          this.successfullySave = false;
        }, 4500);
      });
    }  
  }

  closeContent(){
    this.admin.closeContent('Grammar', this.explanation[0].id).subscribe((data) => {
      this.successfullySave = true;
      this.topicType['filters'] = new Array();
      this.explanation = new Array();
      this.message = 'ARCHIVADO';
      this.cleanTextEditor()
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }

}
