import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class InviteService {

    constructor(private http: HttpClient) { }

    addStudentToGroupEmail(email: string, group_id: string) {
        return this.http.put(`${environment.apiUrl}/api/progress/addStudentToGroupEmail`, { 'email': email, 'group_id': group_id });
    }

    addStudentToGroupUserName(user_name: string, group_id: string) {
        return this.http.put(`${environment.apiUrl}/api/progress/addStudentToGroupUserName`, { 'user_name': user_name, 'group_id': group_id });
    }
    searchStudent(words_search) {
        return this.http.get(`${environment.apiUrl}/api/progress/searchStudents`, { params: { 'words_search': words_search } })
    }
    createGroup(creator, name, startDate, endDate) {
        return this.http.post(`${environment.apiUrl}/api/progress/createGroup`, { 'creator': creator, 'name': name, 'startDate': startDate, 'endDate': endDate })
    }
}