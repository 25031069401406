
<div class="position-relative h-100 w-100">
    <div  class="saveMessageArrayPos" *ngIf="messageArray != undefined" >
        <div *ngFor="let message of messageArray; let i = index">
            <div class="saveMessageArray d-flex" style="flex-direction: column;" (click)="removeMessage(i)">
                {{message}}
            </div>
        </div>
    </div>
    <div style="width: 100%; margin: auto; height: 100%; color: #6e6e6e" class="d-flex">
        <div style="width: 30%; padding: 1.2%; padding-right: 0; font-size: 1.5vh;">
            <div class="searchBox d-flex">
                <i  class="fa-solid fa-magnifying-glass my-auto"></i>
                <input type="text" class="w-100" style="outline: none; border: none;" [(ngModel)]="subcatWord" (keyup)="searchSubcat($event.target.value)" (click)="dropDownSearch = true">
                <div style="height: max-content; flex-direction: column" class="d-flex dropDownSearch" *ngIf="dropDownSearch == true"  [appTooltip]="924">
                    <div *ngFor="let subcat of searchedSubcats; let i = index" style="cursor: pointer; color: grey;" class="dropDownOptions d-flex">
                        <input type="checkbox" (click)="setSearchedSubcats(subcat, $event)" [checked]="subcat.checked">
                        <span [ngClass]="{'hasCourse' : subcat.hasReading}" style="padding: 0 6%;">
                            {{subcat.subcat_name}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="container px-0 pt-2" style="height: 93%; overflow-y: scroll;" (click)="dropDownSearch = false;">
                <div *ngFor="let course of coursesCatTree;let j = index"  class="w-100 " style="display: flex; flex-direction: column;"  >
                    <div style=" margin: 0;" class="course d-flex w-100" [ngClass]="{'courseActive':  category.cat_name == course.cat_name}">
                        <a  [ngClass]="{ 'subcategorySelected': catImporter == course, 'hasCourse': course.has_content == true}" (click)="selectCat(course)">{{course.cat_name}}</a>
                        <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;"  (click)="getCatTreeCategories(course, course.subcats)"></i>
                    </div>
                    <div class="h-100 d-flex" style="width: 100%;  " *ngIf="categoriesCatTree.length > 0 && category.cat_name == course.cat_name && showSubcats">
                        <div style="width: 100%; flex-direction: column;  display: flex; padding: 1.5% 6%; padding-right: 0;" >
                            <div *ngFor="let subcat of categoriesCatTree;let k = index"  class="categories d-flex w-100"  style="display: flex;">
                                <div style="position: relative;" class="w-100">
                                    <div style=" margin: 0;" class="course d-flex w-100" >
                                        <a [ngClass]="{'hasCourse': subcat.readings.length > 0, 'assignCategory subcategorySelected': subcategory == subcat}" (click)="selectSubcat(subcat); getReadingsNames(subcat)">{{ subcat.subcat_name }}</a>
                                        <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;"  *ngIf="subcat.readings.length > 0" (click)="showReadings = !showReadings"></i>
                                    </div>
                                    <div class="d-flex" style="width: 100%;  " *ngIf="showReadings && readingsSubcat == subcat.readings">
                                        <div style="width: 100%; flex-direction: column;  display: flex; padding: 1.5%; padding-right: 0;" >
                                            <div *ngFor="let reading of readingsSubcat;  let k = index"  class="categories d-flex w-100"  style="display: flex; " [ngClass]="{'assignCategory subcategorySelected': reading == selectedReading}">
                                                <input type="checkbox" style="display: flex; align-self: baseline;" class="m-1" (click)="setCloneReading(reading, $event)" [checked]="reading.checked">
                                                <!-- <li [ngStyle]="{'color': reading.origin == 'subcat' ? '#A8143E': ''}"></li>  -->
                                                <div class="d-flex" style="flex-direction: column;">
                                                    <a (click)="getReadingCards($event, reading)" [innerHTML]="reading.name" [ngStyle]="{'color': reading.origin == 'subcat' ? '#A8143E': ''}"></a>
                                                    <span style="text-transform: capitalize;"> Type : {{reading.type}}</span>
                                                </div>
                                                <span style="margin-left: auto;"> {{reading.num_found}} </span> / <span style="float: left;"> {{reading.num_features}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-100" style="padding: 1.2%; width: 69%;">
            <div style="height: 100%; border: 1px solid #e7e7e7" class="w-100" *ngIf="readingCard != undefined">
                <div class="position-relative py-2 px-3 row no-gutters" style="border: none !important; background-color: #A8143E;">
                  <div class="px-1 d-flex w-100" style="color: white;" >
                    <!-- <select *ngIf="checkedSubcats != 0" class="inputEdit" style="color: grey; cursor: pointer; text-align: inherit!important; width: 90%;" >
                        <option style="color: #6e6e6e;" value="" disabled selected hidden> Subcats </option>
                        <option *ngFor="let subcat of checkedSubcats; let j = index" style=" color: grey" >
                            {{subcat.subcat_name | titlecase}}
                        </option>
                    </select> -->
                    <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-left: auto;" (click)="newCard()">
                        New
                    </div>
                    <!-- <div style="color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer; margin: auto" class="d-flex" >
                      <img src="https://img.icons8.com/windows/32/ffffff/edit--v1.png" style="width: 57%;" (click)="updateReading = !updateReading">
                    </div> -->
                    <!-- <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer;" (click)="udpateReadingCard();" [appTooltip]="945">
                        Update
                    </div> -->
                  </div>
                </div>
                <div class="w-100" style="height: 96%; padding: 3%;">
                    <div class="h-100 d-flex" style="flex-direction: column;overflow-y: scroll;" >
                        <div *ngFor="let content of readingCard.contents; let i = index" style="margin: 1.5% 0;">
                            <span style="font-weight: 500; text-transform: capitalize;" *ngIf="content.type != 'text'">
                                {{content.type | titlecase}} :
                            </span>
                            <span style="font-weight: 500; text-transform: capitalize;" *ngIf="content.type == 'text'">
                                {{readingCard.type | titlecase}} :
                            </span>
                            <div *ngIf="content.type == 'title' || writingTypesNames.indexOf(content.type) > -1" [innerHTML]="content.contents"> </div>
                            <div *ngIf="content.type == 'questions' || content.type == 'idiomatic expressions' || content.type == 'technical terms' || content.type == 'time references' || content.type == 'proposed solution statements' || content.type == 'transition expressions' || content.type == 'multiple choice'" class="h-100 d-flex" style="flex-direction: column;">
                                <div *ngFor="let subcontent of content.contents; let i = index">
                                    <div *ngIf="content.type == 'questions' || content.type == 'proposed solution statements'">
                                        {{subcontent.question}} ({{subcontent.correct_answer}})
                                    </div>
                                    <div *ngIf="content.type == 'multiple choice'">
                                        {{subcontent.question}} <span *ngFor="let opt of subcontent.options;"> <span *ngIf="opt !== subcontent.correct_answer"> ({{opt}}) </span> </span> <span>(%%% {{subcontent.correct_answer}} %%%)</span>
                                    </div>
                                    <div *ngIf="content.type == 'idiomatic expressions' || content.type == 'technical terms' ||  content.type ==  'transition expressions' ||  content.type ==  'time references'"  >
                                        <span *ngIf="subcontent.found"> {{subcontent.term}}  </span>
                                        <span *ngIf="!subcontent.found" style="background-color: rgb(224, 93, 93); color:white"> {{subcontent.term}} </span>: {{subcontent.def}}
                                    </div>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
                <div class="w-100" style="height: 96%; padding: 3%;" *ngIf="updateReading == true">
                    <ckeditor class="editor" id="myEditor" #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)"  (change)="onChange($event);" [(ngModel)]="EditorText" [appTooltip]="947" ></ckeditor>
                </div>
            </div>
            <div style="height: 50%;" class="w-100" *ngIf="readingCard == undefined">
                <div class="position-relative py-2 px-3 row no-gutters" style="border: none !important; background-color: #A8143E;">
                  <div class="px-1 d-flex w-100" style="color: white;" >
                    <div class="w-100 d-flex my-auto">
                            <span *ngIf="catImporter != undefined" style="margin: 0 1.5%;" [appTooltip]="938"> {{catImporter.cat_name}}  </span>
                            <span *ngIf="subcategory != undefined" style="margin: 0 1.5%;" [appTooltip]="938"> {{subcategory.subcat_name}}  </span>
                          </div>
                        <select *ngIf="checkedSubcats != 0" class="inputEdit" style="color: grey; cursor: pointer; text-align: inherit!important; width: 90%;" >
                            <option style="color: #6e6e6e;" value="" disabled selected hidden> Subcats </option>
                            <option *ngFor="let subcat of checkedSubcats; let j = index" style=" color: grey" >
                                {{subcat.subcat_name | titlecase}}
                            </option>
                        </select>
                        <select class="plans" (change)="selectPlan($event.target.value)">
                          <option *ngFor="let plan of plans" [value]="plan" [selected]="plan.selected">{{plan.name}}</option>
                        </select>
                        <select *ngIf="!newWritingType && writingTypes != undefined" class="inputEdit" style="color: grey; cursor: pointer; text-align: inherit!important;" [appTooltip]="905" (change)="selectWriting($event); openTypeArea = true">
                            <option style="color: #6e6e6e;" value="" disabled selected hidden> {{writingTypes.filter_name}} </option>
                            <option *ngFor="let option of writingTypes.options; let j = index" [ngValue]="option" style=" color: grey" >
                                {{option.option_name | titlecase}}
                            </option>
                            <option style="color: #6e6e6e;" value="new"> New </option>
                        </select>
                        <div *ngIf="newWritingType == true" class="my-auto" style="width: 30%; margin-right: 3%;" (clickOutside)="newWritingType = false;" (keyup.esc)="newWritingType = false">
                            <div class="my-auto">
                                <input type="text" style="outline: none; border: none;" [(ngModel)]="writingName" (keyup.enter)="addNewWritingType()">
                            </div>
                        </div>
                        <div *ngIf="cloneReadingsSelection.length > 0 && checkedSubcats.length > 0" class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 3%;" (click)="cloneReadingContent();">
                            Share
                        </div>
                        <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 3%;" (click)="checkReadingContent();">
                            Check
                        </div>
                        <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 3%;" (click)="cleanTextEditor();" [appTooltip]="945">
                            Clear
                        </div>
                        <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer;" *ngIf="content_parts != undefined && (catImporter != undefined || subcategory != undefined || checkedSubcats.length != 0) "  (click)="setReadingCard();">
                            Save
                        </div>
                  </div>
                </div>
                <ckeditor class="editor" id="myEditor" #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)"  (change)="onChange($event);" (click)="finalize = false" [(ngModel)]="EditorText" [appTooltip]="947" ></ckeditor>
            </div>
        </div>
        <div *ngIf="showReadingsModal" class="my-4 mx-4 p-3 readingsModal">
            <div style="height: 3%; justify-content: flex-end;" class="d-flex">
                <span style="color: grey; cursor: pointer;" (click)="closeReadingsModal()"><i class="fa-regular fa-x"></i></span>
            </div>
            <div class="d-flex" style="width: 97%;">
                <div style="width: 100%; flex-direction: column;  display: flex" (click)="dropDownSearch = true;">
                    <div *ngFor="let reading of readingsSubcat;  let k = index"  class="categories d-flex w-100"  style="display: flex; " [ngClass]="{'assignCategory subcategorySelected': reading == selectedReading}">
                        <input type="checkbox" style="display: flex; align-self: baseline;" class="m-1" (click)="setCloneReading(reading, $event)" [checked]="reading.checked">
                        <div class="d-flex" style="flex-direction: column;">
                            <a (click)="getReadingCards($event, reading)" [innerHTML]="reading.name" [ngStyle]="{'color': reading.origin == 'subcat' ? '#A8143E': ''}"></a>
                        </div>
                        <span style="margin-left: auto;"> {{reading.num_found}} </span> / <span style="float: left;"> {{reading.num_features}} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
