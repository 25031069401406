import { Directive, Input, HostListener, ElementRef } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { LmsService } from '../services/lms.service';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {

  @Input() appTooltip = -1;

  hoverTimeout;

  constructor(private admin: AdminService, private element: ElementRef) { }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.hoverTimeout = setTimeout(() => {
      this.admin.tooltipIndex = this.appTooltip;
      this.admin.showTooltip = true;

      this.setCoordinates();
    }, 250);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  setCoordinates() {
    var tooltipRect = this.element.nativeElement.getBoundingClientRect();

    var xCoord: number = tooltipRect.left + (tooltipRect.width / 2);
    var yCoord: number = tooltipRect.bottom;

    var i: number = 0;
    var safety: number = 1000;
    var tooltipMaxW: number = 15 * (window.innerWidth / 100)
    if (xCoord + tooltipMaxW / 2 > window.innerWidth) {
      var x = xCoord + tooltipMaxW / 2;
      while (x + 10 > window.innerWidth && i < safety) {
        x--;
        i++;
      }
      xCoord -= i;
    } else if (xCoord - tooltipMaxW / 2 < 0 && i < safety) {
      var x = xCoord - tooltipMaxW / 2;
      while (x - 10 < 0) {
        x++;
        i++;
      }
      xCoord += i;
    }

    if (tooltipRect.bottom + 75 > document.body.offsetHeight) {
      yCoord = tooltipRect.top;
      this.admin.tooltipPos.y = 1;
    }

    this.admin.tooltipCoord = {
      x: xCoord,
      y: yCoord,
    };
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy() {
    clearTimeout(this.hoverTimeout);
    this.admin.tooltipIndex = -1;
    this.admin.showTooltip = false;
    this.admin.tooltipCoord = {};
    this.admin.tooltipPos = { x: -1, y: -1 };
  }
}
