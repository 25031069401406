<div style="height: calc(100% - 60px);" class="w-100 row position-relative no-gutters">
    <div class="row px-5 no-gutters w-100 justify-content-start">
        <div class="px-0 text-center active p-2">
            <p class="option mb-0" style="cursor: pointer;" [ngClass]="{'workingPlace': groupsOn == true && (group_file == false || group_file == true && selected_group.id != null) }" (click)="setShowCourse('false')">Grupos</p>
        </div>
        <div class="px-0 text-center active p-2">
            <p class="option mb-0" style="cursor: pointer;" [ngClass]="{'workingPlace': groupsOn == true && group_file == true && selected_group.id == null}" (click)="setShowCourse('true')">New</p>
        </div>
        <div class="px-0 ml-3 text-center active p-2">
            <p class="option mb-0" style="cursor: pointer;" [ngClass]="{'workingPlace': !groupsOn}" (click)="groupsOn = false">Invitaciones</p>
        </div>
    </div>
    <div style="height: calc(100% - 42px);" class="w-100 row no-gutters pt-4 px-5">
        <div [ngStyle]="{'max-width': !lms.responsive ? '100%' : 'none'}" class="col-12 px-0" style="height: 80%; border: 1px solid #a6a6a6; background-color: #f2f2f2;">
            <div style="max-height: 100%;" class="position-relative p-3 bg-white w-100 h-100">
                <div *ngIf="groupsOn == true" class="w-100 justify-content-between pb-2 row no-gutters">
                    <div class="h-100 d-flex">
                        <i *ngIf="selected_group != undefined && selected_group.id != null" (click)="back()" style="font-size: 140%; color: #3c5176;cursor: pointer;" class="fas my-auto fa-arrow-left"></i>
                    </div>
                    <div *ngIf="groupsOn == true && group_file == true">
                        <span style="cursor: pointer; background-color: #28a745; color: white; font-weight: 400;" (click)="createGroup()" class="py-1 px-2"><span *ngIf="selected_group != undefined && selected_group.id == null">Create</span><span *ngIf="selected_group != undefined && selected_group.id != null">Update</span></span>
                    </div>
                </div>
                <div *ngIf="!groupsOn" class="w-100 h-100 d-flex">
                    <div style="border-radius: 6px; background-color: #f2f2f2; width: 50%; height: 80%; flex-direction: column;" class="mx-auto justify-content-between d-flex p-3 my-auto">
                        <div class="w-100">
                            <h3>Invita a alguien</h3>
                            <div class="w-100 mx-auto mb-2 row no-gutters justify-content-between">
                                <div class="col-12 row no-gutters px-0">
                                    <span style="width: max-content; font-weight: 600;" class="my-auto pr-1">Invite user:</span>
                                </div>
                                <input placeholder="Email for invitation..." [formControl]="emailInvite" type="text" class="col-8 px-2">
                                <div *ngIf="!emailInvite.valid && !emailInvite.pristine" class="form-control-feedback w-100 mb-0 mt-1 text-danger">Invalid email</div>
                            </div>
                            <div class="w-100 row no-gutters justify-content-between">
                                <div class="col-12 row no-gutters px-0">
                                    <span style="width: max-content; font-weight: 600;" class="w-100">User name:</span>
                                    <input (keyup)="invitedName = $event.target.value" [value]="invitedName" placeholder="User name for the email..." type="text" class="col-8 mb-2 px-2">
                                    <span style="width: max-content; font-weight: 600;" class="w-100">User last name:</span>
                                    <input (keyup)="invitedLastName = $event.target.value" [value]="invitedLastName" placeholder="User last name for the email..." type="text" class="col-8 px-2">
                                </div>
                            </div>
                            <div class="w-100 my-2 row no-gutters justify-content-between">
                                <div class="row col-8 no-gutters px-0">
                                    <div class="row no-gutters pb-2 w-100">
                                        <span style="width: max-content; font-weight: 600;" class="w-100">Para que area:</span>
                                        <div class="row no-gutters w-100 position-relative">
                                            <div style="border-bottom: 1px solid black !important;" class="row bg-white no-gutters w-100">
                                                <input class="col pl-2 my-auto inputEdit" type="text" placeholder="Para que area" (keyup)="typeAheadTree($event.target.value)">
                                                <i class="fas pl-2 my-auto fa-search"></i>
                                            </div>
                                            <div style="top: 100%; z-index: 10; max-height: 33vh; overflow-y: auto;min-width: 100%; width: max-content;" class="bg-white position-absolute" *ngIf="arrayAreas.length > 0">
                                                <div style="cursor: pointer;" class="w-100 area__search optForm text-truncate px-1" *ngFor="let area of arrayAreas" (click)="setInvitationArea(area)">
                                                    <span style="font-weight: 500 !important">{{area.name | uppercase}}</span>
                                                    <span class="parent__area pl-2" *ngIf="area.nameSearch != ''">({{area.nameSearch | lowercase}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters justify-content-between w-100" type="button">
                                        <span class="my-auto text-truncate pr-3" style="max-width: 95%;" [ngClass]="{'op0': invite_area.area_name == 'Degree - Subject', 'bold op1': invite_area.area_name != 'Degree - Subject'}">{{invite_area.area_name | uppercase}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 row no-gutters justify-content-between">
                                <div class="row col-8 no-gutters px-0">
                                    <div class="row no-gutters pb-2 w-100">
                                        <span style="width: max-content; font-weight: 600;" class="w-100">Curso:</span>
                                        <div class="row no-gutters w-100 position-relative">
                                            <div style="border-bottom: 1px solid black !important;" class="row bg-white no-gutters w-100">
                                                <input class="col pl-2 my-auto inputEdit" type="text" placeholder="Curso" (keyup)="typeAheadTree($event.target.value)">
                                                <i class="fas pl-2 my-auto fa-search"></i>
                                            </div>
                                            <div style="top: 100%; z-index: 10; max-height: 33vh; overflow-y: auto;width: 100%" class="bg-white position-absolute" *ngIf="arrayGroups.length > 0">
                                                <div style="cursor: pointer;" class="w-100 optForm text-truncate px-1 py-2" *ngFor="let group of arrayGroups" (click)="setInvitationGroup(group)"><span style="font-weight: 500 !important">{{group.name | uppercase}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row no-gutters justify-content-between w-100" type="button">
                                        <span class="my-auto text-truncate pr-3" style="max-width: 95%;" [ngClass]="{'op0': invite_area.group_name == 'Curso',  'bold op1': invite_area.group_name != 'Curso'}">{{invite_area.group_name | uppercase}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters justify-content-end">
                            <input *ngIf="!autoKey" (keyup)="invitedKey = $event.target.value" [value]="invitedKey" placeholder="Set your custom key" type="text" class="px-2">
                            <div class="mr-3 d-flex">
                                <label class="my-auto px-2">Use auto key</label>
                                <input [checked]="autoKey" (click)="setAuto()" class="my-auto" type="checkbox">
                            </div>
                            <div class="my-auto px-2" style="height: max-content; color: #92d050; background-color: white; border: 1px solid #92d050; cursor: pointer;" (click)="inviteUser(email)"><span>invite</span></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="groupsOn == true" style="height: calc(100% - 21px);" class="w-100">
                    <!-- <div class="position-relative row no-gutters">
                      <div *ngIf="courseName != 'Curso'">
                          <span style="cursor: pointer;" class="mr-1" (click)="setShowCourse('true')" [ngClass]="{'subrayado': showCourse && !creatingGroup}">Current groups</span>
                          <span style="cursor: pointer;" (click)="setShowCourse('false')" [ngClass]="{'subrayado': !showCourse && !creatingGroup}">Archived groups</span>
                      </div>
                  </div> -->
                    <div id="containerGroups" class="w-100" style="height: 100%;">
                        <div class="w-100 h-100">
                            <div class="w-100 row no-gutters h-100" *ngIf="group_file == true">
                                <div class="col-3 px-0 h-100">
                                    <div class="h-100 w-100">
                                        <div style="border: 1px solid #3c5176;" class="w-100 position-relative">
                                            <div style="cursor: default;" (click)="setModalGroup('area', $event)" data-toggle="modal" data-target="#group_config" class="w-100">
                                                <p style="background-color: #3c5176;" class="mb-0 w-100 text-white py-1 px-2">{{areaNameModal | uppercase}}</p>
                                            </div>
                                            <div class="w-100 pt-1 px-2 position-relative">
                                                <div *ngIf="selected_group.name != ''" class="row no-gutters justify-content-between w-100">
                                                    <p style="width: calc(100% - 27px);" class="my-auto text-truncate">{{selected_group.name | uppercase}}</p>
                                                    <i [ngClass]="{'fullArea': selected_group.active == true, 'noCourse': selected_group.active == false}" (click)="activarGrupo(selected_group)" class="fas my-auto pl-2 fa-circle"></i>
                                                </div>
                                                <p *ngIf="selected_group.name == ''" class="my-auto" style="color: #6b7996;">Name goes here</p>
                                            </div>
                                            <div class="w-100 px-2 py-1 row no-gutters justify-content-between">
                                                <div>
                                                    <div *ngIf="selected_group.teachers.length == 0">
                                                        <div class="d-flex">
                                                            <div class="d-flex">
                                                                <!-- <i style="cursor: pointer;" class="fas mr-2 fa-user"></i> -->
                                                                <p style="color: #6b7996;" class="mb-0">
                                                                    Teacher 1
                                                                </p>
                                                            </div>
                                                            <span class="my-auto px-1">-</span>
                                                            <div class="d-flex">
                                                                <!-- <i style="cursor: pointer;" class="fas mr-2 fa-user"></i> -->
                                                                <p style="color: #6b7996;" class="mb-0">
                                                                    Teacher 2
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="selected_group.teachers.length > 0">
                                                        <div class="d-flex">
                                                            <div *ngFor="let teacher of selected_group.teachers; let i = index;" class="d-flex">
                                                                <!-- <i style="cursor: pointer;" class="fas mr-2 fa-user"></i> -->
                                                                <p class="mb-0">
                                                                    {{teacher.name}}
                                                                </p>
                                                                <span *ngIf="i != selected_group.teachers.length - 1" class="my-auto px-1">-</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img (click)="setModalGroup('info', $event)" data-toggle="modal" data-target="#group_config" style="cursor: default;" class="h-100 pl-2" src="../../../assets/img/postit.png">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="w-100 position-relative justify-content-start row no-gutters">
                                          <div style="cursor: default;" (click)="setModalGroup('course', $event)" class="w-100">
                                              <p style="background-color: #3c5176;" class="mb-0 w-100 text-uppercase text-white py-1 px-2">course</p>
                                          </div>
                                          <div class="position-relative w-100 row no-gutters">
                                              <div class="btnCustom py-1 row no-gutters justify-content-between px-2 w-100">
                                                  <span style="max-width: 85%;" class="my-auto text-truncate" [ngClass]="{'bold': courseName != 'Curso'}">{{courseName | uppercase}}</span>
                                              </div>
                                          </div>
                                      </div> -->
                                        <div style="height: calc(100% - 232px);" class="w-100 py-3 position-relative">
                                            <div style="border: 1px solid #3c5176;" class="h-100 w-100">
                                                <div style="cursor: default; background-color: #3c5176;" (click)="setModalGroup('date', $event)" data-toggle="modal" data-target="#group_config" class="w-100 px-2 row no-gutters justify-content-between">
                                                    <div class="row no-gutters" *ngIf="selected_group.start_month != selected_group.end_month">
                                                        <p class="mb-0 text-uppercase text-white py-1">{{selected_group.start_month}}</p>
                                                        <span class="text-white px-1 my-auto">-</span>
                                                        <p class="mb-0 text-uppercase text-white py-1">{{selected_group.end_month}}</p>
                                                    </div>
                                                    <div class="row no-gutters" *ngIf="selected_group.start_month == selected_group.end_month">
                                                        <p class="mb-0 text-uppercase text-white py-1">{{selected_group.start_month}}</p>
                                                    </div>
                                                    <div class="pl-2 d-flex" *ngIf="selected_group.start_year != selected_group.end_year">
                                                        <p class="mb-0 text-uppercase text-white py-1">{{selected_group.start_year}}</p>
                                                        <span class="text-white px-1 my-auto">-</span>
                                                        <p class="mb-0 text-uppercase text-white py-1">{{selected_group.end_year}}</p>
                                                    </div>
                                                    <div class="pl-2" *ngIf="selected_group.start_year == selected_group.end_year">
                                                        <p class="mb-0 text-uppercase text-white py-1">{{selected_group.start_year}}</p>
                                                    </div>
                                                </div>
                                                <div style="height: calc(100% - 29px);" class="row p-2 w-100 no-gutters">
                                                    <p class="w-100 mb-0"><i class="fas fa-calendar-alt"></i> Days</p>
                                                    <div style="height: calc(100% - 21px);" class="w-100">
                                                        <div class="w-100 h-100" *ngIf="selected_group.days.length == 0">
                                                            <div style="height: max-content;" class="w-100 py-1">
                                                                <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                    <div class="d-flex">
                                                                        <i class="fas pr-2 my-auto fa-calendar-day"></i>
                                                                        <p class="mb-0">
                                                                            Day 1, 16:00 - 18:00
                                                                        </p>
                                                                    </div>
                                                                    <!-- <i class="fas my-auto fa-minus" style="cursor: pointer;" (click)="cancelDay(i)"></i> -->
                                                                </div>
                                                                <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                    <div class="d-flex">
                                                                        <i class="fas pr-2 my-auto fa-calendar-day"></i>
                                                                        <p class="mb-0">
                                                                            Day 2, 17:00 - 19:00
                                                                        </p>
                                                                    </div>
                                                                    <!-- <i class="fas my-auto fa-minus" style="cursor: pointer;" (click)="cancelDay(i)"></i> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style="max-height: 100%; overflow-y: auto;" class="w-100 h-100" *ngIf="selected_group.days.length > 0">
                                                            <div style="height: max-content;" class="w-100 py-1" *ngFor="let day of selected_group.days; let i = index;">
                                                                <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                    <div class="d-flex">
                                                                        <i class="fas pr-2 my-auto fa-calendar-day"></i>
                                                                        <p class="mb-0">
                                                                            {{day.day}}, {{day.startHour}} - {{day.endHour}}
                                                                        </p>
                                                                    </div>
                                                                    <!-- <i class="fas my-auto fa-minus" style="cursor: pointer;" (click)="cancelDay(i)"></i> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="border: 1px solid #3c5176;" class="w-100 position-relative justify-content-start row no-gutters">
                                            <div style="cursor: default;" class="w-100">
                                                <p style="background-color: #3c5176;" class="mb-0 w-100 text-uppercase text-white py-1 px-2">Meeting Room</p>
                                            </div>
                                            <div class="w-100 position-relative">
                                                <div class="row no-gutters p-2 w-100">
                                                    <span>URL: </span>
                                                    <p *ngIf="selected_group.id != null && selected_group.invitation_url != ''" class="col text-truncate pl-2 pr-0 my-auto">
                                                        {{selected_group.invitation_url}}
                                                    </p>
                                                    <p style="color: #6b7996;" *ngIf="selected_group.id == null || selected_group.invitation_url == ''" class="col pl-2 text-truncate pr-0 my-auto">
                                                        <span>https://</span><span>www.</span>urldeinvitacionparaelcurso.com
                                                    </p>
                                                    <i *ngIf="selected_group.id != null && selected_group.invitation_url != ''" style="cursor: pointer;" (click)="copyGroupUrl(selected_group)" matTooltip="Copiar URL" matTooltipPosition="above" style="font-size: 18px;" class="fas pl-2 position-relative fa-copy">
                                                  <span *ngIf="success_copy == true" class="position-absolute url__copy py-1 px-2">
                                                      URL copiada!
                                                  </span>
                                                  </i>
                                                </div>
                                                <div class="row no-gutters px-2 w-100">
                                                    <span>PDF:</span>
                                                    <div class="col pl-2 pr-0 d-flex">
                                                        <div *ngIf="selected_group != undefined && selected_group.id != null" style="cursor: default;" class="d-flex" matTooltip="Cargar PDF" matTooltipPosition="above">
                                                            <label style="cursor: pointer; font-size: 100%; font-weight: 400; text-transform: none !important; letter-spacing: normal !important;" class="mb-0 px-0">
                                                              <input (click)="resetValueFile($event)"  (change)="loadGroupPDF($event, selected_group)" hidden="true" accept="pdf/*" type="file"/>
                                                              Cargar
                                                              <i style="font-size: 18px;" class="fas pl-1 fa-file-pdf"></i>
                                                          </label>
                                                        </div>
                                                        <div *ngIf="selected_group != undefined && selected_group.id != null" (click)="donwLoadPDF(selected_group)" matTooltip="Ver PDF" matTooltipPosition="above" style="cursor: default;" class="d-flex pl-3">
                                                            <span class="pr-2">Descargar</span>
                                                            <i style="font-size: 18px;" class="fas fa-file-download"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row no-gutters p-2 w-100">
                                                    <div class="row no-gutters w-100">
                                                        <span>LINK:</span>
                                                        <p style="color: #6b7996;" class="col text-truncate pl-2 pr-0 my-auto">
                                                            <span>https://</span><span>www.</span>urldeaccesoparaelcurso.com
                                                        </p>
                                                        <i *ngIf="false" style="cursor: pointer;" (click)="copyGroupUrl(selected_group)" matTooltip="Copiar URL" matTooltipPosition="above" style="font-size: 18px;" class="fas pl-2 position-relative fa-copy">
                                                      <span *ngIf="success_copy == true" class="position-absolute url__copy py-1 px-2">
                                                          URL copiada!
                                                      </span>
                                                      </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 pl-3 pr-0 h-100">
                                    <div style="border: 1px solid #3c5176;" class="h-100 w-100">
                                        <div style="height: 100%;" class="w-100">
                                            <div class="h-100 w-100">
                                                <div class="w-100 h-100">
                                                    <div class="w-100 h-100">
                                                        <div (click)="setModalGroup('students', $event)" data-toggle="modal" data-target="#group_config" class="w-100">
                                                            <p style="background-color: #3c5176;" class="mb-0 w-100 text-uppercase text-white py-1 px-2">students</p>
                                                        </div>
                                                        <div style="overflow-x: auto; max-height: calc(100% - 33px); height: calc(100% - 33px);" class="w-100 p-2">
                                                            <div class="w-100 h-100 student__grid" *ngIf="selected_group.users.length == 0">
                                                                <div class="w-100">
                                                                    <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                        <div class="d-flex">
                                                                            <i style="cursor: pointer; font-size: 18px;" class="fas mr-2 fa-user"></i>
                                                                            <p class="mb-0">
                                                                                Student 1
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="w-100">
                                                                    <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                        <div class="d-flex">
                                                                            <i style="cursor: pointer; font-size: 18px;" class="fas mr-2 fa-user"></i>
                                                                            <p class="mb-0">
                                                                                Student 2
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="w-100">
                                                                    <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                        <div class="d-flex">
                                                                            <i style="cursor: pointer; font-size: 18px;" class="fas mr-2 fa-user"></i>
                                                                            <p class="mb-0">
                                                                                Student 3
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="w-100 h-100 student__grid" *ngIf="selected_group.users.length > 0">
                                                                <div class="w-100" *ngFor="let user of selected_group.users; let i = index;">
                                                                    <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                        <div class="d-flex">
                                                                            <i (click)="setStudentFile(user)" style="cursor: pointer; font-size: 18px;" class="fas mr-2 fa-user"></i>
                                                                            <p class="mb-0">
                                                                                {{user.last_name}}, {{user.name}}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3 pl-3 pr-0 h-100">
                                    <div style="border: 1px solid #3c5176;" class="h-100 w-100">
                                        <div style="height: 100%;" class="w-100">
                                            <div class="h-100 w-100">
                                                <div class="w-100 h-100">
                                                    <div class="w-100 h-100">
                                                        <div class="w-100">
                                                            <p style="background-color: #3c5176;" class="mb-0 w-100 text-uppercase text-white py-1 px-2">Potential Users</p>
                                                        </div>
                                                        <div style="height: calc(100% - 33px); background-color: #e8e8e8;" class="w-100 p-2">
                                                            <div  class="w-100 h-100">
                                                                <div class="w-100 h-100 p-2" style="max-height: 100%; overflow-y: auto;">
                                                                    <div *ngFor="let user of potential_users" class="w-100">
                                                                        <div  class="row py-1 px-2 mb-2 bg-white areaBox w-100 no-gutters" [ngClass]="{'disabled': user.group_id_invite != null || selected_group.id == null}">
                                                                            <div class="row w-100 justify-content-between no-gutters">
                                                                                <div class="w-100 row no-gutters justify-content-between">
                                                                                    <div class="d-flex">
                                                                                        <span (click)="viewGroupFile(group)">{{user.name}} {{user.last_name}} : {{user.email}} </span>
                                                                                    </div>
                                                                                    <div class="d-flex position-relative">
                                                                                        <div class="ml-2" *ngIf="user.group_id_invite == null && selected_group.id != null">
                                                                                            <i  style="cursor: pointer;" (click)="inviteUserToGroup(user)" >Invite</i>
                                                                                        </div>
                                                                                        <div class="ml-2">
                                                                                            <i style="cursor: pointer;" (click)="openUserInfo(user)" class="fas fa-info-circle"></i>
                                                                                        </div>
                                                                                        <div *ngIf="user.showInfo" [style.width]="containerWidth" style="top: 100%; right: 0; resize: none; z-index: 10;background-color: white;"  class="position-absolute" rows="4">
                                                                                            certificate: {{user.certificate}}
                                                                                            certificate_score: {{user.score}}
                                                                                            academic: {{user.academic}}
                                                                                            industry: {{user.industry}}
                                                                                            begin_year: {{user.begin_year}}
                                                                                            begin_month: {{user.begin_month}}
                                                                                            end_year: {{user.end_year}}
                                                                                            end_month: {{user.end_month}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 row no-gutters h-100" *ngIf="!group_file">
                                <div class="col-4 px-0 h-100">
                                    <div class="h-100 w-100">
                                        <div style="border: 1px solid #3c5176;" class="w-100 h-100 position-relative">
                                            <div style="cursor: default;" class="w-100">
                                                <p style="background-color: #3c5176;" class="mb-0 w-100 text-uppercase text-white py-1 px-2">group search</p>
                                            </div>
                                            <div style="height: calc(100% - 30px);" class="w-100 p-2">
                                                <div class="row no-gutters w-100">
                                                    <!-- <div *ngIf="!area_search" (click)="inputSearch()" style="height: max-content;" class="position-relative my-auto w-100">
                                                        <div style="border-bottom: 1px solid black !important;" class="py-1 row no-gutters justify-content-between w-100">
                                                            <span class="my-auto col text-truncate pr-3" style="max-width: 95%; font-size: 110%;">{{areaNameModalSearch | uppercase}}</span>
                                                            <span *ngIf="loading_courses == true" class="spinner-border my-auto" style="width: 1rem; height: 1rem;" role="status"></span>
                                                        </div>
                                                    </div> -->
                                                    <div class="row no-gutters w-100 position-relative">
                                                        <div style="border-bottom: 1px solid black !important;" class="row no-gutters w-100">
                                                            <input id="area_search" (keyup.esc)="area_search = false;" class="col px-0 my-auto inputEdit" type="text" placeholder="Search area..." (keyup)="typeAheadTree($event.target.value, true)">
                                                            <i class="fas pl-2 my-auto fa-search"></i>
                                                        </div>
                                                        <div style="top: 100%; z-index: 10; max-height: 33vh; overflow-y: auto; width: 100%;" class="bg-white position-absolute" *ngIf="arrayAreas.length > 0">
                                                            <div style="cursor: pointer;" class="w-100 optForm area__search text-truncate px-1" [ngClass]="{'bg-prev': area.isCourse == true}" *ngFor="let area of arrayAreas" (click)="setAreaGroup(area)">
                                                                <span *ngIf="area.isCourse" class="search_tag" style="background-color: red;">Crs</span>
                                                                <span *ngIf="area.isGroup" class="search_tag" style="background-color: blue;">Grp</span>
                                                                <span *ngIf="area.isUser" class="search_tag" style="background-color: green;">Usr</span>
                                                                <span *ngIf="area.isCourse != undefined" >{{area.name | titlecase}}</span>
                                                                <span *ngIf="area.isCourse == undefined" >{{area.name | uppercase}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="course_id_search != ''">
                                                    <div class="pt-2">
                                                        Cursos
                                                    </div>
                                                    <div class="w-100 position-relative justify-content-start row no-gutters">
                                                        <div (clickOutside)="dropdown_course_search = false;" class="position-relative w-100 row no-gutters">
                                                            <div class="btnCustom py-1 row no-gutters justify-content-between px-2 w-100" type="button" (click)="dropdown_course_search=!dropdown_course_search">
                                                                <span style="max-width: 85%;" class="my-auto text-truncate">{{courseNameSearch | uppercase}}</span>
                                                                <!-- <i class="fas fa-chevron-down my-auto"></i> -->
                                                            </div>
                                                            <div class="d-none bg-white" style="z-index: 10; width: max-content; min-width: 100%; position: absolute; top: 100%; left: 0; max-height: 50vh; overflow-y: auto;" [ngClass]="{'d-block': dropdown_course_search}">
                                                                <div class="optionDrop justify-content-between row no-gutters px-1 py-2" style="cursor: default" [ngClass]="{'selOpt': course_id_search == course.id}" *ngFor="let course of courses_search" (click)="selectCourseSearch(course);">
                                                                    <p class="mb-0">
                                                                        {{course.name | uppercase}}
                                                                    </p>
                                                                    <i [ngClass]="{'fullArea': course.num_groups > 0, 'noCourse': course.num_groups == 0}" class="fas my-auto pl-2 fa-circle"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div *ngIf="placementTest != undefined">  -->
                                                        <div class="pt-2">
                                                            Placement Test
                                                        </div>
                                                        <div class="w-100 position-relative justify-content-start row no-gutters">
                                                            <div class="position-relative w-100 row no-gutters">
                                                                <div class="btnCustom py-1 row no-gutters justify-content-between px-2 w-100" type="button">
                                                                    <span style="max-width: 85%;" class="my-auto text-truncate">{{placementTest}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8 pl-3 pr-0 h-100">
                                    <div style="border: 1px solid #3c5176;" class="h-100 w-100">
                                        <div style="height: 100%;" class="w-100">
                                            <div class="h-100 w-100">
                                                <div class="w-100 h-100">
                                                    <div class="w-100 h-100">
                                                        <div class="w-100">
                                                            <p style="background-color: #3c5176;" class="mb-0 w-100 text-uppercase text-white py-1 px-2">groups</p>
                                                        </div>
                                                        <div style="height: calc(100% - 33px); background-color: #e8e8e8;" class="w-100 p-2">
                                                            <div [formGroup]="groupInfo" class="w-100 h-100">
                                                                <div class="w-100 h-100 p-2" style="max-height: 100%; overflow-y: auto;">
                                                                    <div *ngFor="let group of groups" class="w-100">
                                                                        <div style="cursor: pointer;" *ngIf="group.filtered == true;" class="row py-1 px-2 mb-2 bg-white areaBox w-100 no-gutters" (click)="viewGroupFile(group)">
                                                                            <div class="row w-100 justify-content-between no-gutters">
                                                                                <div class="w-100 row no-gutters justify-content-between">
                                                                                    <div class="d-flex">
                                                                                        <span  >{{group.name | uppercase}}</span>
                                                                                        <!-- <div class="d-flex pl-3">
                                                                                            <span>(PDF) </span>
                                                                                            <div class="px-2 d-flex" matTooltip="Cargar PDF" matTooltipPosition="above">
                                                                                                <label style="cursor: pointer;" class="mb-0 px-0">
                                                                                                  <input  (change)="loadGroupPDF($event, group)" hidden="true" accept="pdf/*" type="file"/>
                                                                                                      <i style="font-size: 18px;" class="fas fa-file-pdf"></i>
                                                                                               </label>
                                                                                            </div>
                                                                                            <i style="cursor: pointer;" *ngIf="group.pdf != ''" (click)="donwLoadPDF(group)" matTooltip="Ver PDF" matTooltipPosition="above" style="font-size: 18px;" class="fas fa-file-download"></i>
                                                                                        </div>
                                                                                        <div class="d-flex pl-3">
                                                                                            <span>(URL) </span>
                                                                                            <i style="cursor: pointer;" (click)="copyGroupUrl(group)" matTooltip="Copiar URL" matTooltipPosition="above" style="font-size: 18px;" class="fas px-2 position-relative fa-copy">
                                                                                          <span *ngIf="success_copy == true" class="position-absolute url__copy py-1 px-2">
                                                                                              URL copiada!
                                                                                          </span>
                                                                                          </i>
                                                                                        </div> -->
                                                                                    </div>
                                                                                    <div class="d-flex position-relative">
                                                                                        <div *ngIf="group.start != '' && group.end != ''">
                                                                                            <span class="mr-1"><span class="pr-1">From:</span>{{group.start}}</span>
                                                                                            <span><span class="pr-1">to:</span>{{group.end}}</span>
                                                                                        </div>
                                                                                        <div class="ml-2">
                                                                                            <i style="cursor: pointer;" (click)="group.showInfo = !group.showInfo" class="fas fa-info-circle"></i>
                                                                                        </div>
                                                                                        <textarea *ngIf="group.showInfo" [style.width]="containerWidth" [formControl]="groupInfo.get(setInputName(group.name))" style="top: 100%; right: 0; resize: none; z-index: 10;" [formControlName]="setInputName(group.name)" class="position-absolute" rows="4"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row w-100 no-gutters justify-content-start">
                                                                                    <div class="mr-2">Max: <span [ngClass]="{'bold': group.max_users > 0}">{{group.max_users}}</span>
                                                                                    </div>
                                                                                    <div class="d-flex">
                                                                                        <i style="cursor: pointer;" [ngClass]="{'numUsersNotLimit':  group.users.length > 0 && (group.max_users == 0 || group.max_users >= group.users.length + 2), 'numUsersLimit': group.users.length == group.max_users || group.users.length == 0, 'numUsersNearLimit': group.users.length == (group.max_users - 1)}"
                                                                                            class="fas pr-1 fa-user"></i>
                                                                                        <span [ngClass]="{'bold': group.users.length > 0}">{{group.users.length}}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row justify-content-end no-gutters w-100">
                                                                                    <label [ngClass]="{'disabled': group.archived}" class="switch my-auto">
                                                                                              <input [disabled]="group.archived" [checked]="group.active" (click)="activarGrupo(group)" type="checkbox">
                                                                                              <span class="slider">
                                                                                                  <span style="background: transparent;" class="h-100 d-flex w-100 position-relative">
                                                                                                 </span>
                                                                                              </span>
                                                                                            </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div (click)="group_alert=false" *ngIf="group_alert" style="white-space: pre-wrap; color: #721c24 !important; background-color: #f8d7da !important; border-color: #f5c6cb !important;" class="alert mt-3 mb-0 alert-danger alert-dismissible py-2 fade show">
    <span class="alert-close" data-dismiss="alert"></span>
    <strong>ADMIN:</strong>{{group_mess}}
</div>
<div (click)="groupCreated=false;" *ngIf="groupCreated" class="alert mt-3 mb-0 alert-success alert-dismissible py-2 fade show text-center">
    <span class="alert-close" data-dismiss="alert"></span>
    <strong>ADMIN:</strong> Grupo
    <span>{{group_finish}}</span> correctamente
</div>
<div *ngIf="success_pdf.active == true" class="alert mt-3 mb-0 alert-success alert-dismissible py-2 fade show text-center">
    <span class="alert-close" data-dismiss="alert"></span>
    <strong>ADMIN:</strong> PDF guardado correctamente
</div>
<div *ngIf="sending_pdf.active == true" class="alert mt-3 mb-0 alert-success alert-dismissible py-2 fade show text-center">
    <span class="alert-close" data-dismiss="alert"></span>
    <strong>ADMIN:</strong>Enviando PDF
</div>
<div *ngIf="succesInvitation" class="alert mt-3 mb-0 alert-success py-2 alert-dismissible fade show text-center">
    <span class="alert-close" data-dismiss="alert"></span>
    <strong>ADMIN:</strong> Invitacion enviada correctamente
</div>
<div data-backdrop="static" class="modal fade" id="group_config" tabindex="-1" style="z-index: 10000;" role="dialog">
    <div style="max-width: none; background-color: #00000059;" class="modal-dialog d-flex w-100 m-0 h-100" role="document">
        <div style="height: 424px; max-height: 65%; width: 666px; max-width: 56%; border: 1px solid #a6a6a6; background-color: #f2f2f2;" class="modal-content mx-auto my-auto">
            <i data-dismiss="modal" style="font-size: 100%; cursor: pointer; color: #3c5176; top: 0; left: 0; right: 0; bottom: 0; height: max-content; width: max-content; z-index: 1;" class="fas position-absolute ml-auto mr-2 mt-2 mb-auto fa-times"></i>
            <div *ngIf="show_area_config == true" class="position-relative p-3 bg-white w-100 h-100">
                <div class="row no-gutters justify-content-start py-2">
                    <div style="height: max-content;" class="my-auto w-100">
                        <div class="row no-gutters pb-2 w-100">
                            <div class="row no-gutters w-100 position-relative">
                                <div style="border-bottom: 1px solid black !important;" class="row no-gutters w-100">
                                    <input class="col px-0 my-auto inputEdit" type="text" placeholder="Search area..." (keyup)="typeAheadTree($event.target.value)">
                                    <i class="fas pl-2 my-auto fa-search"></i>
                                </div>
                                <div style="top: 100%; z-index: 10; max-height: 33vh; overflow-y: auto;min-width: 100%; width: max-content;" class="bg-white position-absolute" *ngIf="arrayAreas.length > 0">
                                    <div style="cursor: pointer;" class="w-100 area__search optForm text-truncate px-1" *ngFor="let area of arrayAreas" (click)="setAreaGroup(area, 'file'); selectCourse(area)">
                                        <span *ngIf="area.isCourse" class="search_tag" style="background-color: red;">Crs</span>
                                        <span style="font-weight: 500 !important">{{area.name | uppercase}}</span>
                                        <span class="parent__area pl-2" *ngIf="area.nameSearch != ''">({{area.nameSearch | lowercase}})</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btnCustom row no-gutters justify-content-between px-2 w-100" type="button" (click)="dropdown_areaModal=!dropdown_areaModal">
                            <span class="my-auto col text-truncate pr-3" style="max-width: 95%; font-size: 130%;">{{courseName | uppercase}}</span>
                            <span *ngIf="loading_courses == true" class="spinner-border my-auto" style="width: 1rem; height: 1rem;" role="status"></span>
                        </div>
                    </div>
                </div>
                <!-- <div class="w-100 position-relative justify-content-start row no-gutters">
                    <div (clickOutside)="dropdown_course = false;" class="position-relative w-100 row no-gutters">
                        <div class="btnCustom py-1 row no-gutters justify-content-between px-2 w-100" type="button" (click)="dropdown_course=!dropdown_course">
                            <span style="max-width: 85%;" class="my-auto text-truncate">{{courseName | uppercase}}</span>
                            <i class="fas fa-chevron-down my-auto"></i>
                        </div>
                        <div class="d-none bg-white" style="z-index: 10; width: max-content; min-width: 100%; position: absolute; top: 100%; left: 0; max-height: 50vh; overflow-y: auto;" [ngClass]="{'d-block': dropdown_course}">
                            <div style="cursor: default;" class="optionDrop justify-content-between row no-gutters px-1 py-2" [ngClass]="{'selOpt': course_id == course.id}" *ngFor="let course of courses" (click)="selectCourse(course);">
                                <p class="mb-0">
                                    {{course.name | uppercase}}
                                </p>
                                <i [ngClass]="{'fullArea': course.num_groups > 0, 'noCourse': course.num_groups == 0}" class="fas my-auto pl-2 fa-circle"></i>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="row mt-2 mb-3 no-gutters justify-content-center">
                    <div style="border: 1px solid #6b7996;" class="row bg-white col-12 p-2 no-gutters">
                        <input style="color: #6b7996;" id="groupName" (keyup)="setGroupName($event.target.value)" type="text" value="{{selected_group.name}}" placeholder="Set group name" class="pl-2 col inputEdit">
                    </div>
                </div>
                <div style="flex-direction: column;" class="w-100 justify-content-between d-flex h-100">
                    <div class="w-100">
                        <div class="row no-gutters w-100 justify-content-between">
                            <div class="w-100">
                                <div class="row w-100 justify-content-between no-gutters">
                                    <div class="w-100 position-relative">
                                        <div style="border-bottom: 1px solid black !important;" class="w-100 row no-gutters">
                                            <input type="text" placeholder="Search for teachers" #searchTeachersNewGroup (keyup)="searchTeachersNew(searchTeachersNewGroup.value)" class="col px-0 inputEdit">
                                            <i class="fas pl-2 my-auto fa-search"></i>
                                        </div>
                                        <div class="bg-white" style="max-height: 33vh; overflow-y: auto; z-index: 10; width: max-content; min-width: 100%; position: absolute; top: 100%; left: 0;">
                                            <div (click)="selectTeacherNew(teacher)" style="cursor: default;" class="optionDrop justify-content-between row no-gutters px-1 py-2" *ngFor="let teacher of teacherSearch">
                                                <p class="mb-0">
                                                    {{teacher.last_name}}, {{teacher.name}}
                                                </p>
                                                <span class="my-auto pl-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row pt-2 w-100 no-gutters">
                                        <p class="w-100 mb-0"><i class="fas fa-user-friends"></i> Added teachers</p>
                                        <div class="enrolled w-100 py-2">
                                            <div class="w-100 py-1" *ngFor="let teacher of selected_group.teachers; let i = index;">
                                                <div class="row no-gutters d-flex w-100 justify-content-between">
                                                    <div class="d-flex">
                                                        <i style="cursor: pointer;" class="fas mr-2 fa-user"></i>
                                                        <p class="mb-0">
                                                            {{teacher.last_name}}, {{teacher.name}}
                                                        </p>
                                                    </div>
                                                    <i class="fas my-auto fa-minus" style="cursor: pointer;" (click)="cancelAddTeacher(teacher.id)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="teacher_delete == true" class="alert mt-3 alert-success alert-dismissible py-3 fade show text-center">
                    <span class="alert-close" data-dismiss="alert"></span>
                    <strong>ADMIN:</strong> Profesor eliminado correctamente
                </div>
                <div *ngIf="teacher_added == true" class="alert mt-3 alert-success alert-dismissible py-3 fade show text-center">
                    <span class="alert-close" data-dismiss="alert"></span>
                    <strong>ADMIN:</strong> Profesor añadido correctamente
                </div>
            </div>
            <div *ngIf="show_group_date_config == true" class="position-relative p-3 bg-white w-100 h-100">
                <div [formGroup]="dateRange" style="flex-direction: column;" class="w-100 justify-content-between d-flex h-100">
                    <div class="w-100">
                        <div class="row justify-content-between no-gutters">
                            <!-- <div class="col-3 px-0">
                              <div class="w-100 row no-gutters">
                                  <span style="width: max-content;" class="my-auto pr-1">Max users:</span>
                                  <input #users type="text" class="col px-2" (keyup)="setMaxUsers(users.value)">
                              </div>
                          </div> -->
                            <div class="col-6 pl-0 pr-1">
                                <div class="w-100">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>Enter a date range</mat-label>
                                        <mat-date-range-input [rangePicker]="picker">
                                            <input matStartDate (dateInput)="setDate($event)" (dateChange)="setDate($event)" formControlName="start" placeholder="Start date">
                                            <input matEndDate (dateInput)="setDate($event)" (dateChange)="setDate($event)" formControlName="end" placeholder="End date">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                        <!-- <mat-date-range-picker *ngIf="lms.responsive == true" touchUi #picker></mat-date-range-picker> -->

                                        <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                                        <mat-error *ngIf="dateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                                    </mat-form-field>
                                </div>
                                <div style="border-top: 1px solid black; border-bottom: 1px solid black;" class="pt-2 pb-3 w-100">
                                    <div class="row no-gutters w-100">
                                        <div class="col px-0">
                                            <mat-form-field class="w-100">
                                                <mat-label>Days</mat-label>
                                                <mat-select formControlName="days">
                                                    <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="pl-2 my-auto">
                                            <div class="my-auto px-2" style="height: max-content; color: #92d050; background-color: white; border: 1px solid #92d050; cursor: pointer; font-size: 110%;" (click)="confirmDay()"><span>Add</span></div>
                                        </div>
                                    </div>
                                    <div class="w-100">
                                        <div class="row pb-2 no-gutters w-100">
                                            <p class="col px-0 mb-0">Start</p>
                                            <div class="pl-2 d-flex">
                                                <input type="text" size="3" class="pl-2" formControlName="hourStart">
                                                <span class="px-1">:</span>
                                                <input type="text" size="3" class="pl-2" formControlName="minStart">
                                            </div>
                                        </div>
                                        <div class="w-100 row no-gutters">
                                            <p class="col px-0 mb-0">End</p>
                                            <div class="d-flex">
                                                <input type="text" size="3" class="pl-2" formControlName="hourEnd">
                                                <span class="px-1">:</span>
                                                <input type="text" size="3" class="pl-2" formControlName="minEnd">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6 pl-1 pr-0">
                                <div class="row w-100 h-100 no-gutters">
                                    <p class="w-100 mb-0"><i class="fas fa-calendar-alt"></i> Added days</p>
                                    <div style="height: calc(100% - 21px);" class="w-100 py-2">
                                        <div style="overflow-y: auto;" class="w-100 h-100">
                                            <div style="height: max-content;" class="w-100 py-1" *ngFor="let day of selected_group.days; let i = index;">
                                                <div class="row no-gutters d-flex w-100 justify-content-between">
                                                    <div class="d-flex">
                                                        <i class="fas pr-2 my-auto fa-calendar-day"></i>
                                                        <p class="mb-0">
                                                            {{day.day}}, {{day.startHour}} - {{day.endHour}}
                                                        </p>
                                                    </div>
                                                    <i class="fas my-auto fa-minus" style="cursor: pointer;" (click)="cancelDay(i)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="show_group_students_config == true" style="min-height: 400px;" class="position-relative p-3 bg-white w-100 h-100">
                <div style="flex-direction: column;" class="w-100 justify-content-between d-flex px-2 h-100">
                    <div class="w-100">
                        <div class="row no-gutters justify-content-between">
                            <div class="w-100">
                                <div class="row w-100 justify-content-between no-gutters">
                                    <div class="row w-100 no-gutters">
                                        <div class="col-6 pl-0 pr-1">
                                            <div style="border-bottom: 1px solid black !important;" class="w-100 position-relative row no-gutters">
                                                <input type="text" placeholder="Search for users" #searchUsersNewGroup (keyup)="searchUsersNew(searchUsersNewGroup.value)" class="col px-0 inputEdit">
                                                <i class="fas pl-2 my-auto fa-search"></i>
                                                <div class="bg-white" style="max-height: 33vh; overflow-y: auto; z-index: 10; width: max-content; min-width: 100%; position: absolute; top: 100%; left: 0;">
                                                    <div (click)="selectUserNew(user)" style="cursor: default;" class="optionDrop justify-content-between row no-gutters px-1 py-2" *ngFor="let user of userSearch">
                                                        <p class="mb-0">
                                                            {{user.last_name}}, {{user.name}}
                                                        </p>
                                                        <span class="my-auto pl-2"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pl-1 pr-0">
                                            <div class="row w-100 no-gutters">
                                                <p class="w-100 mb-0"><i class="fas fa-user-friends"></i> Added users</p>
                                                <div style="height: calc(400px - 21px);" class="w-100 py-2">
                                                    <div *ngIf="selected_group.users.length > 0" class="h-100" style="overflow-y: auto; max-height: 100%;">
                                                        <div class="w-100 py-1" *ngFor="let user of selected_group.users; let i = index;">
                                                            <div class="row no-gutters d-flex w-100 justify-content-between">
                                                                <div class="d-flex">
                                                                    <i style="cursor: pointer;" class="fas mr-2 fa-user"></i>
                                                                    <p class="mb-0">
                                                                        {{user.last_name}}, {{user.name}}
                                                                    </p>
                                                                </div>
                                                                <i class="fas my-auto fa-minus" style="cursor: pointer;" (click)="cancelAddUser(user.id)"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="user_delete == true" class="alert mt-3 alert-success alert-dismissible py-3 fade show text-center">
                    <span class="alert-close" data-dismiss="alert"></span>
                    <strong>ADMIN:</strong> Usuario eliminado correctamente
                </div>
                <div *ngIf="user_added == true" class="alert mt-3 alert-success alert-dismissible py-3 fade show text-center">
                    <span class="alert-close" data-dismiss="alert"></span>
                    <strong>ADMIN:</strong> Usuario añadido correctamente
                </div>
            </div>
            <div *ngIf="show_group_info_config" style="width: 400px; height: 300px;" class="position-relative p-3 bg-white w-100 h-100">
                <div style="flex-direction: column;" class="w-100 justify-content-between d-flex px-2 h-100">
                    <div class="w-100 h-100">
                        <p class="w-100 mb-0 pb-2">Notes:</p>
                        <div style="height: calc(100% - 30px);" class="row no-gutters justify-content-start">
                            <textarea #description style="background-color: #fffbc1; resize: none;" type="text" class="w-100 h-100 px-2" (keyup)="setDescription(description.value)"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="show_student_file" style="width: 400px; height: 300px;" class="position-relative p-3 bg-white w-100 h-100">
                <div style="flex-direction: column;" class="w-100 row no-gutters px-2 h-100">
                    <div class="w-100 col-4">

                    </div>
                    <div class="w-100 col-4">

                    </div>
                    <div class="w-100 col-4">

                    </div>
                    <div class="row no-gutters justify-content-end">
                        <span style="cursor: pointer; background-color: #28a745; color: white; font-weight: 400;" (click)="updateUserInfo()" class="py-1 px-2"><span>Update</span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>