<div class="search-container" style="height: 7%;">

  <input type="text" placeholder="Buscar palabra..." [(ngModel)]="searchTerm" class="search-input">
</div>


<div *ngFor="let cat of data">
  <div class="words-list-container">
    <div class="word-item" style="cursor: pointer;" *ngFor="let subcat of cat.subcats">
      ({{ cat.cat_name }}) {{ subcat.subcat_name }}
    </div>
  </div>
</div>
