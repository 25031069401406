import { Injectable, OnInit } from '@angular/core';
import { UserDataService } from './user-data.service';
import { LandingDataService } from './landing-data.service';
// import * as jwt_decode from 'jwt-decode';
import { AuthService } from './auth.service';
import { NavigationDataService } from './navigation-data.service';

@Injectable({
  providedIn: 'root'
})
export class StackCount {
  stack: number;
  stackPercent: string;
}
@Injectable({
  providedIn: 'root'
})
export class Stats {
  success: number;
  successPercent: string;
  successPercentPrint: string;
  errors: number;
  lapses: number[];
  avgTime: number;
}

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {
  name: any;
  last_name: any;
  imageAux: any = '../../assets/img/user-regular.svg';
  constructor(private userData: UserDataService, private landingData: LandingDataService, private auth: AuthService, private navigationData: NavigationDataService) {
    // try {
    //   this.token = jwt_decode(localStorage.getItem('access_token'));
    // } catch (error) {
    //   this.token = '';
    // }
    for (let i = 0; i < 8; i++) {
      this.outword[i] = false;
      this.addedword[i] = false;
    }

    // if (this.auth.isAuthenticated()) {
    //   this.getUserLabs();
    // }
    this.buildCatTree();
    // this.getCardsUnlog();
    this.getCardsLog();
    this.createStatArrays();
  }

  //CatTree Load -------------------------------------------------------------------------------/
  level = '';
  element: string;
  rating = '';
  faculty = '';
  env: string;
  id: string;

  catTree;

  buildCatTree() {
    this.landingData.getCatTree()
      .subscribe((data: any) => {
        this.catTree = data;
      });
  }
  campus: number = 0;
  switchCampus() {
    if (this.campus == 0) {
      this.campus = 1;
    } else {
      this.campus = 0;
    }
  }


  filter() {
    //console.log('dentro');
    this.navigationData.getFilterLabs(this.rating, this.numWords, this.env, this.id, this.level, this.faculty).subscribe(data => {
      this.navigationData.labs = data['labs'];
      //console.log(data);
    });
  }

  setRating(rating: string) {
    this.rating = rating;
  }

  numWords;
  setNumWords(numWords: Number) {
    this.numWords = numWords;
  }



  //User info Storage --------------------------------------------------------------------------/
  userLabsInfo: any[] = [];
  currentFlyerId: number = 0;
  cartLabs: any[] = [];
  totalOffer: number = 0;
  total: number = 0;
  labsIds = [];
  wishlistLabs: any[] = [];
  pagination = false;
  addedLab: boolean = false;
  inWishlist: boolean = false;

  alertMess = false;
  countsUser = [];
  numStudentsUser = [];
  roundRatingUser = [];
  roundRatingElseUser = [];
  ratingUser = [];
  auxArray;
  discount: number;


  checkCart() {
    this.addedLab = false;

    for (let i = 0; i < this.cartLabs.length; i++) {
      if (Number(this.currentFlyerId) === this.cartLabs[i].id) {
        this.addedLab = true;

        break;
      }
    }
  }

  checkWishlist() {
    this.inWishlist = false;
    for (let i = 0; i < this.wishlistLabs.length; i++) {
      if (Number(this.currentFlyerId) === this.wishlistLabs[i].id) {
        this.inWishlist = true;

        break;
      }
    }
  }

  getUserCart() {
    this.userData.getUserCart(this.auth.userData['id']).subscribe(data => {
      this.cartLabs = [];
      this.auxArray = [];
      this.labsIds = [];
      this.auxArray = data;
      this.totalOffer = 0;
      this.total = 0;
      for (let i = 0; i < this.auxArray.length; i++) {
        this.cartLabs[i] = this.auxArray[i][0];
        this.totalOffer = this.totalOffer + Number(this.cartLabs[i].offer_price);
        this.labsIds.push(this.cartLabs[i].id);
        this.total = this.total + Number(this.cartLabs[i].price);
      }
    });
  }

  paginationWish = false;
  getUserWishList() {
    this.userData.getUserWishlist(this.auth.userData['id']).subscribe(data => {
      this.wishlistLabs = [];
      this.auxArray = [];
      this.auxArray = data;
      for (let i = 0; i < this.auxArray.length; i++) {
        this.wishlistLabs[i] = { 'lab': this.auxArray[i][0] };
      }

      //Condicion de la paginacion mylabs DESEOS
      if (this.wishlistLabs.length > 15) {
        this.paginationWish = true;

      } else {
        this.paginationWish = false;
      }
    });
  }


  //Navigation data Storage --------------------------------------------------------------------/



  //Landing Default Cards Load -----------------------------------------------------------------/

  catCardsLog: any = [];
  subCardsLog: any = [];
  disCardsLog: any = [];

  catCardsUnlog: any = [];
  disCardsUnlog: any = [];
  subCardsUnlog: any = [];
  numStudents;
  ratings;

  numCardsUnlog = new Array(6);

  loadingUnlogCat: boolean;
  loadingUnlogSub: boolean;
  loadingUnlogDis: boolean;
  roundRating = [];
  roundRatingElse = [];
  counts = [];
  card = [];

  cardsProfessional: any = [];
  cardsCertificates: any = [];
  loadingLabs: boolean = true;
  // getCardsUnlog() {
  //   this.loadingLabs = true;
  //   this.landingData.getLabsLanding().subscribe(data => {
  //     //console.log(data);
  //     this.cardsProfessional = data['professional'];
  //     this.cardsCertificates = data['certificates'];

  //     this.loadingLabs = false;
  //   });
  //   // this.landingData.getLoggedCards('degree').subscribe(data => {
  //   //   this.subCardsUnlog = data;
  //   //   this.loadingUnlogSub = false;
  //   // }
  //   // )
  //   // this.landingData.getLoggedCards('subject').subscribe(data => {
  //   //   this.disCardsUnlog = data;
  //   //   this.loadingUnlogDis = false;
  //   // });
  // }


  loadingLogCat: boolean;
  loadingLogSub: boolean;
  loadingLogDis: boolean;
  getCardsLog() {
    this.loadingLogCat = true;
    this.loadingLogSub = true;
    this.loadingLogDis = true;
    // this.landingData.getLoggedCards('faculty').subscribe(data => {
    //   this.catCardsLog = data;
    //   this.loadingLogCat = false;
    // });
    // this.landingData.getLoggedCards('degree').subscribe(data => {
    //   this.subCardsLog = data;
    //   this.loadingLogSub = false;
    // });
    // this.landingData.getLoggedCards('subject').subscribe(data => {
    //   this.disCardsLog = data;
    //   this.loadingLogDis = false;
    // });
  }

  //Stack Info Storage -------------------------------------------------------------------/

  secuence: any[] = [];
  stacks: StackCount[] = new Array(8);
  stackScore: Stats[] = new Array(8);
  stackSelected: boolean[] = new Array(this.secuence.length);

  createStatArrays() {
    for (let i = 0; i < this.stacks.length; i++) {
      let stack = new StackCount();
      let stat = new Stats();

      stack.stack = 0;
      stack.stackPercent = "";
      this.stacks[i] = stack;

      stat.success = 0;
      stat.errors = 0;
      stat.lapses = [];
      stat.successPercent = "";
      stat.successPercentPrint = "";
      stat.avgTime = 0;
      this.stackScore[i] = stat;
    }

    for (let i = 0; i < this.stackSelected.length; i++) {
      this.stackSelected[i] = false;
    }
  }

  scrolled: boolean = false;
  addedword = new Array(8);
  outword = new Array(8);
  answerWords;
  availableCountry = new Array();
  array_students = new Array();
}
