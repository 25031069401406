import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile} from 'ngx-image-cropper';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-images-importer',
  templateUrl: './images-importer.component.html',
  styleUrls: ['./images-importer.component.css']
})
export class ImagesImporterComponent implements OnInit  {

  coursesCatTree = new Array();
  categoriesCatTree = new Array();
  courseName = '';
  subcategory;
  imgSrc = '';

  imagePath;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  aspectRatio = 4 / 3;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  cropImage;
  showSubcats: boolean = false;

  successfullySave: boolean = false;
  message = '¡GUARDADO!';

  constructor( private admin: AdminService) { }


  ngOnInit() {
    this.buildCatTree();
  }


  buildCatTree(){
    this.admin.getCatTreeInfo().subscribe((data: any) => {
      this.coursesCatTree = data
    });
  }


  getCatTreeCategories(course, subcat){
    if (this.categoriesCatTree != subcat){
      this.showSubcats = true;
    } else{
      this.showSubcats = !this.showSubcats ;
    }
    this.categoriesCatTree = subcat;
    this.courseName = course.cat_name;
    this.subcategory = undefined;
  }
  videoURL
    selectSubcat(subcat){
      this.imgSrc = '';
      this.imgSrc = 'https://pecblobs.blob.core.windows.net/funnel-images/image_subcat_' + subcat.subcat_id + '.png';
      this.videoURL = subcat.video_url;
      this.subcategory = subcat;
      if (subcat.has_image == true){
      }
    }

    //Image Cropper

    fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
    }

    newImage(){
      this.croppedImage  = '';
      this.fileChangeEvent('');
      this.cropImage.emit(this.croppedImage);
    }

    file;
    imagename: File;
    image: File;
    imageselect : boolean;
    imageUrl = "";
    resizedBase64: any = '';
    loaded: boolean = false;
    imageCropped(event: ImageCroppedEvent) {
      if (this.imgSrc != ''){
        this.imgSrc  = '';
      }
      this.croppedImage = event.base64;
      let imageInfo = this.dataURLtoFile(this.croppedImage,'image.png');
      this.loaded = true;
      if ( this.croppedImage.length > 1000000){
        this.resizeImg(imageInfo, event.width, event.height);
      } else {
        this.loaded = true;
      }
    }

    dataURLtoFile(dataurl, filename) {

      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
    }


    onFilesSelected(event: any) {
      const files: FileList = event.target.files;
      if (files.length > 0) {
        console.log('Archivos seleccionados:', files);

        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]);
        }


        this.admin.addVideo(formData).subscribe((data: any) => {
          console.log(data)}

        );
      }
    }


    resizeImg(imageInfo, x, y){
      x= x*0.9;
      y= y*0.9;
      this.compressImage( this.croppedImage, x, y).then(compressed => {
        this.croppedImage  = compressed;
        // console.log(this.resizedBase64);
        imageInfo = this.dataURLtoFile( this.croppedImage ,'image.png');
        if ( this.croppedImage.length > 1000000){
          this.resizeImg(imageInfo, x, y);
        } else {
          this.loaded = true;
        }
      })

    }

    compressImage(src, newX, newY) {
      return new Promise((res, rej) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          const elem = document.createElement('canvas');
          elem.width = newX;
          elem.height = newY;
          const ctx = elem.getContext('2d');
          ctx.drawImage(img, 0, 0, newX, newY);
          const data = ctx.canvas.toDataURL();
          res(data);
        }
        img.onerror = error => rej(error);
      })
    }



    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }

    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }


    cropperReady(sourceImageDimensions: Dimensions) {
      // console.log('Cropper ready', sourceImageDimensions);
  }

    loadImageFailed() {
        // console.log('Load failed');
    }

    // imageCropped(event) {
    //   this.croppedImage = event.base64;
    //   console.log(this.croppedImage);
    // }


    // fileChangeEvent(file) {
    //   var reader = new FileReader();
    //   this.imagePath = file;
    //   reader.readAsDataURL(file[0]);
    //   reader.onload = (_event) => {
    //     this.imgURL = reader.result;
    //   }
    // }




  /////Image Cropper

  saveImage(){
    this.admin.setDegreeImage(this.subcategory.subcat_id, this.croppedImage).subscribe((data: any) => {
      this.successfullySave = true;
      this.subcategory.has_image = true;
      this.loaded = false;
      setTimeout(() => {
        this.successfullySave = false;
      }, 4500);
    });
  }


}
