import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { AuthService } from 'src/app/services/auth.service';
import * as CryptoJS from 'crypto-js';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {


  @ViewChild('modalLogin', {}) modalLogin: ElementRef;
  logIn: UntypedFormGroup;
  formValid = false;
  url: string;

  constructor(private http: HttpClient, private auth: AuthService, private router: Router, public admin: AdminService) {
    this.logIn = new UntypedFormGroup({
      "email": new UntypedFormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      "password": new UntypedFormControl('', [Validators.required, Validators.minLength(8)])
    })
  }

  ngOnInit(): void {
    this.auth.workingArea = 'landing';
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['/group-admin']);
      this.auth.workingArea = 'group-admin';
    } 
  }

  refresh(): any {
    this.url = this.router.url;
    this.router.navigate(['/landing']);
  }

  postSub;
  LogIn() {

    this.postSub = this.http.post(`${environment.apiUrl}/api/auth/loginAdmin`, { "email": this.logIn.value.email, "password": this.logIn.value.password })
      .subscribe(data => {
        this.formValid = false;
        this.auth.setSession(data);
        // setTimeout(() => {
          // this.auth.workingArea = 'group-admin';
          this.router.navigate(["/group-admin"]);
        // }, 100);
        // this.modalLogin.nativeElement.click();
        // setTimeout(this.refresh(), 50);

      },
        err => {
          this.formValid = true;
          this.logIn.controls['email'].setValue('');
          this.logIn.controls['password'].setValue('');
          this.logIn.controls['email'].markAsPristine();
          this.logIn.controls['password'].markAsPristine();
        });

    // this.listLab.websocketsServe().subscribe(data=>{
    //   console.log('Websockets:serve hecho');
    //   console.log(data);
    // });
  }
  ngOnDestroy(): void {
    // this.postSub.unsubscribe();
  }


}
