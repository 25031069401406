
<div class="position-relative h-100 w-100">
    <div *ngIf="successfullySave == true">
        <div class="saveMessage"> {{message}} </div>
    </div>




    <div class="h-100 d-flex w-100" style="padding: 1.2%;" *ngIf="coursesCatTree != undefined">
        <div class="h-100" style="flex-direction: column; display: flex; position: relative;overflow-y: scroll; width: 30%; padding: 1.2%;" >
            <div  *ngFor="let course of coursesCatTree;let j = index"  class="w-100 " style="display: flex; flex-direction: column;"  >
                <div style=" margin: 0;" class="course d-flex w-100" [ngClass]="{'courseActive':  courseName == course.cat_name, 'hasContent': course.has_content}"  (click)="getCatTreeCategories(course, course.subcats)">
                    <a >{{ course.cat_name }}</a>
                    <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;"></i>
                </div>
                <div class="h-100 d-flex" style="width: 100%;  " *ngIf="categoriesCatTree.length > 0 && courseName == course.cat_name && showSubcats">
                    <div style="width: 100%; flex-direction: column;  display: flex; padding: 1.5% 6%;">
                        <div *ngFor="let subcat of categoriesCatTree;let k = index"  class="categories d-flex" (click)="selectSubcat(subcat)">
                            <a [ngClass]="{'hasImage': subcat.has_image == true, 'subcategorySelected': subcategory == subcat}" >{{ subcat.subcat_name }}</a>
                            <!-- <i class="fa-solid fa-angle-right fas my-auto" style="margin-left: auto;" *ngIf="subcategory == subcat " ></i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 100%; width: 3%; padding-left: 21px;">
          <div style="position: relative; border: 1px solid #A8143E; padding: 3px; border-radius: 5px; cursor: pointer;">
            <img style="height: 21px;" src="../../../../../assets/img/add_video.svg">
            <input type="file" (change)="onFilesSelected($event)" style="position: absolute; top: 0; left: 0; opacity: 0; width: 100%; height: 100%; cursor: pointer;" multiple>
          </div>
        </div>

        <div class="h-100" style="width: 59%;">
            <div class="d-flex flex-column" style="padding: 3%;" *ngIf="subcategory != undefined">
                <div style="color: gray; font-weight: 500; margin: auto;">
                    {{courseName | uppercase}}
                    <i class="fa-solid fa-angle-right fas my-auto"  ></i>
                    {{subcategory.subcat_name | uppercase}}
                </div>

                <div  class="d-flex h-100 flex-column" style="position: relative; margin: 3% auto; width: 90%;">
                  <video style="padding-bottom: 30px;" [src]="videoURL" controls></video>
                  <div style="flex-direction: column; width: 81%; margin: auto;" class="d-flex h-100">
                        <div style="height: 30%; display: flex; width: 100%;">
                            <input type="file" style="border: 1px solid #c7c7c7; width: 84%;" [appTooltip]="402" (change)="fileChangeEvent($event)">
                            <button style="width: 15%; justify-content: center; height: 60%; margin: auto" [appTooltip]="403" (click)="newImage()"> Reset </button>
                        </div>
                        <div style="height: 60%; flex-flow: wrap; justify-content: center; align-content: center;" class="d-flex">
                            <button (click)="rotateLeft()" [appTooltip]="405">Rotate left</button>
                            <button (click)="rotateRight()" [appTooltip]="406">Rotate right</button>
                            <button (click)="flipHorizontal()" [appTooltip]="407">Flip horizontal</button>
                            <button (click)="flipVertical()" [appTooltip]="408">Flip vertical</button>
                            <button (click)="toggleContainWithinAspectRatio()" [appTooltip]="409">{{containWithinAspectRatio? 'Fill Aspect Ratio':'Aspect Ratio'}}</button>
                            <button (click)="resetImage()" [appTooltip]="410">Reset image</button>
                            <!-- <input [(ngModel)]="rotation" placeholder="Rotation" type="number" style="height: 24%; width: 15%;  margin: 0.3%;  border: 1px solid #c7c7c7;" (keyup)="updateRotation()">  -->
                            <button (click)="zoomOut()" [appTooltip]="411">Zoom -</button>
                            <button (click)="zoomIn()" [appTooltip]="412">Zoom +</button>
                            <!-- <input [(ngModel)]="aspectRatio" placeholder="Aspect ratio" type="number" style="height: 24%; width: 15%;  margin: 0.3%;  border: 1px solid #c7c7c7;">  -->
                        </div>
                    </div>
                    <div style="height: 100%; margin: 3% 0;" >
                        <image-cropper [appTooltip]="414"  [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatio" [onlyScaleDown]="false" [roundCropper]="false" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'"
                        format="png" [canvasRotation]="canvasRotation" [transform]="transform"   (imageCropped)="imageCropped($event)"   (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"  (loadImageFailed)="loadImageFailed()"></image-cropper>
                    </div>
                    <div class="d-flex" style="justify-content: end;" *ngIf="croppedImage != '' && loaded == true" >
                        <button class="saveButton my-auto text-center" (click)="saveImage()">  Save</button>
                    </div>
                </div>
                <div *ngIf="subcategory.has_image == true && imgSrc != ''" class="d-flex h-100 flex-column" style="position: relative; margin: 3% auto; width: 50%;">
                    <img [src]="imgSrc" style="width: 90%; margin: auto; border: 1px solid #e7e7e7;">
                </div>
            </div>
        </div>
    </div>
</div>
