import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';
import { AdminService } from 'src/app/services/admin.service';


@Component({
  selector: 'app-no-cluster-modal',
  templateUrl: './no-cluster-modal.component.html',
  styleUrls: ['./no-cluster-modal.component.css']
})
export class NoClusterModalComponent implements OnInit{

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, public admin: AdminService ) { }

  searchTerm: string = '';
  ngOnInit() {
    console.log("DATA",this.data)
  }



  getNoClusterSubcats() {
    this.admin.getNoClusterSubcats().subscribe((data: any) => {
      console.log("DATA",data)
      this.data = data;
    });
  }



  get filteredWords() {
    if (!this.searchTerm) {
      return this.data[0];
    }
    return this.data[0].filter((word: any) =>
      word.word.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
}

