<div class="h-100 w-100 position-relative ">
    <div style="display: flex;">
        <div style="flex: 20%; margin-top: 5%;">
            <div *ngFor="let issue of issues; let i = index" style="color: #A8143E; margin-left: 10%;"
                (click)="getIssue(i)">
                <span> {{ issue.type }} </span>
                <span *ngIf="issue.origin != 'Stax'">
                    {{ issue.origin }}
                </span>
                <span *ngIf="issue.origin == 'Stax'">
                    <span *ngIf="issue.stax_type == 'WL'">
                        Word {{ issue.origin }}
                    </span>
                    <span *ngIf="issue.stax_type != 'WL'">
                        {{ issue.stax_type | titlecase }} {{ issue.origin }}
                    </span>
                </span>
            </div>
        </div>
        <div style="flex: 80%;" style="color: grey;">
            <div class="py-2 px-3 row no-gutters d-flex"
                style="background-color: #A8143E; width: 90%; height: 6%; margin-top: 2%; justify-content: flex-end;">
                <div *ngIf="showMessage" class="saveMessage"> {{message}} </div>

                <div class="saveButton my-auto text-center" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 3%;" (click)="update();" >
                    <span> Update </span>
                </div>
                <div class="saveButton my-auto text-center" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer; margin-right: 3%;"(click)="cleanEditor();">
                    <span> Clear</span>
                </div>
            </div>
            <div style="width: 90%;">
                <div *ngIf="currentIssue == undefined || currentIssue.origin != 'Stax'">
                    <ckeditor #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)"
                        [(ngModel)]="EditorText">
                    </ckeditor>
                </div>
                <div *ngIf="currentIssue != undefined && currentIssue.origin == 'Stax'">
                    <div *ngFor="let form of staxForms; let i = index">
                        <form [formGroup]="form">
                          <label for="word">Word:</label>
                          <input type="text" formControlName="word" id="word">
                          
                          <label *ngIf="translation_id != null" for="translation">Translation:</label>
                          <input *ngIf="translation_id != null" type="text" formControlName="translation" id="translation">

                          <label *ngIf="definition_id != null"  for="definition">Definition:</label>
                          <input *ngIf="definition_id != null" type="text" formControlName="definition" id="definition">

                          <!-- El ejemplo va ligado a la definición -->
                          <label *ngIf="definition_id != null" for="example">Example:</label>   
                          <input *ngIf="definition_id != null" type="text" formControlName="example" id="example">
                        </form>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</div>