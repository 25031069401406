<div class="h-100" style="padding: 1% 0%; background-color: #203341; position: absolute; left: 19%; display: flex; transition-duration: 600ms; transition-timing-function: ease-in-out" [appTooltip]="670" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%', 'width': admin.sidebarShow ? '85%' : '96%'}">
    <div style="width: 50%; flex-direction: column; margin-right: 1%;" class="h-100 d-flex" [appTooltip]="671">
        <div style="background-color: white; margin-right: 1%; padding: 2.1%; height: 63%; margin: 0 0% 2% 0%;" [appTooltip]="672">
            <div  class="w-100 d-flex" style="height: 6%;" [appTooltip]="673">
                <div class="title h-100" [appTooltip]="674">
                    New group:
                </div>
                <div style="background-color: white; padding: 0 3%; width: 72%;" class="d-flex" *ngIf="admin.edit_group == false" [appTooltip]="675">
                    <div class="d-flex" [appTooltip]="676" [ngClass]="{'w-100':  (newGroupForm.value.branch.name != undefined || newGroupForm.value.organization != '' || selectedGroupOptions.course.length != 0 )}" *ngIf="(newGroupForm.value.branch.name != undefined || newGroupForm.value.organization != '' || selectedGroupOptions.course.length != 0 )" >
                        <div style="width: 20%;" [appTooltip]="677" class="optionsCandidates filterSelection text-truncate" *ngIf="newGroupForm.value.organization != ''">
                            {{newGroupForm.value.organization.name}}
                        </div>
                        <div style="width: 20%;" [appTooltip]="678" class="optionsCandidates filterSelection text-truncate" *ngIf="newGroupForm.value.branch.name != undefined">
                            {{newGroupForm.value.branch.name}}
                        </div>
                        <div style="width: 20%;" [appTooltip]="679" class="optionsCandidates filterSelection text-truncate" *ngIf="selectedGroupOptions.course.length != 0">
                            {{newGroupForm.value[selectedGroupOptions.course].name}}
                        </div>
                    </div>
                </div>
                <button *ngIf="admin.edit_group == false && groupDone != undefined && group_id == undefined" class="newGroupButton" type="submit" [appTooltip]="680" (click)="createGroup(); groupDone = undefined">
                    Create
                    <div *ngIf="groupDone == true && (usersToGroup == 0 || usersToGroup == undefined)">
                        <div class="groupDone" > Se ha creado el grupo correctamente </div>
                        <div  class="alert-usersGroup">  Añade candidatos al grupo  </div>
                    </div>
                </button>
                <div *ngIf="groupDone == undefined" class="spinner-border">  </div>
                <div class="newGroupButton" (click)="createGroup();  groupDone = undefined" *ngIf="admin.edit_group == true && groupDone != undefined" [appTooltip]="681">
                    Save changes
                    <div *ngIf="groupDone == true"  class="groupDone"> Se han actualizado los campos</div>
                </div>
            </div>
            
            <div class="newGroupArea" *ngIf="groupOptions != undefined" [formGroup]="newGroupForm" [appTooltip]="682">
                <div class="w-100 d-flex">
                    <div class="d-flex" style="flex-direction: column; width: 45%;height: 48px; margin: 1% 3%;" [appTooltip]="683">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="684">
                            Office
                            <span style="color: rgb(233, 79, 83); font-weight: 600;" [appTooltip]="685">*</span>
                        </div>
                        <div  style="height: 60%;" class="w-100 d-flex" [appTooltip]="686">
                            <select class="filterSelection h-100 w-100" style="border-radius: 6px;"   *ngIf="newOffice == false" formControlName="office" (change)="selectOffice($event)" [appTooltip]="670">
                                <option *ngIf="newGroupForm.controls['office'].value != undefined" style="color: #6e6e6e;" value="" disabled selected hidden> {{newGroupForm.controls['office'].value.name}} </option>
                                <option *ngFor="let option of groupOptions.office" style="color: #6e6e6e;"> 
                                        {{option.name}}
                                </option>
                            </select>
                            <div class="filterSelection h-100" style="border-radius: 6px;" *ngIf="newOffice == true" [appTooltip]="687">
                                <div class="h-100"  style="cursor: pointer; text-align: center;"[appTooltip]="688" >
                                    <input  type="text" style="outline: none; border: none; padding-left: 3%; font-size: 1.5vh; border-radius: 6px;" class="w-100 h-100" placeholder="New Office" formControlName="office">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="flex-direction: column; width: 45%;height: 48px; margin: 1% 3%;" [appTooltip]="689">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="690">
                            Mode
                        </div>
                        <select class="filterSelection" style="height: 60%; border-radius: 6px" *ngIf="groupOptions != undefined && groupOptions.mode != undefined" formControlName="mode" [appTooltip]="691">
                            <option *ngIf="newGroupForm.controls['mode'].value != undefined" style="color: #6e6e6e;" disabled selected hidden> {{newGroupForm.controls['mode'].value}} </option>
                            <option *ngFor="let mode of groupOptions.mode" style="color: #6e6e6e;">
                                {{mode.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="w-100 d-flex">
                    <div class="d-flex" style="flex-direction: column;width: 44%;height: 48px; margin: 1% 3%;" [appTooltip]="692">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="693">
                            Organization
                        </div>
                        <select class="filterSelection w-100" style="height: 60%; border-radius: 6px;" *ngIf="newOrganization == false" formControlName="organization" [appTooltip]="694">
                            <option *ngIf="newGroupForm.controls['organization'].value != undefined"  style="color: #6e6e6e;" disabled selected hidden  [(value)]="newGroupForm.controls['organization'].value.name">  </option>
                            <option *ngFor="let organization of groupOptions.organization" [ngValue]="organization" style="color: #6e6e6e;">
                                {{organization.name}}
                        </option>
                        </select>
                        <div class="filterSelection h-100 w-100" style="height: 60%; border-radius: 6px;" *ngIf="newOrganization == true" [appTooltip]="695">
                            <div class="h-100"  style="cursor: pointer; text-align: center;" >
                                <input  type="text" style="outline: none; border: none; padding-left: 3%; font-size: 1.5vh; border-radius: 6px;" class="w-100 h-100" placeholder="New Organization" formControlName="organization">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex" [appTooltip]="696">
                    <div class="d-flex" style="flex-direction: column; width: 45%;height: 48px; margin: 1% 3%;">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="697">
                            Branch
                            <span style="color: rgb(233, 79, 83); font-weight: 600;" [appTooltip]="685">*</span>
                        </div>
                        <select class="filterSelection" style="height: 60%; border-radius: 6px" formControlName="branch" (change)="changeOption('branch', $event)" [appTooltip]="698">
                            <option *ngIf="newGroupForm.controls['branch'].value != undefined" style="color: #6e6e6e;" value="" disabled selected hidden> {{newGroupForm.controls['branch'].value}} </option>
                            <option *ngFor="let branch of groupOptions.branch" [ngValue]="branch" style="color: #6e6e6e;">
                                {{branch.name}}
                            </option>
                        </select>
                    </div>
                    <div class="d-flex" style="flex-direction: column;width: 45%;height: 48px; margin: 1% 3%;" [appTooltip]="699">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="700">
                            <span *ngIf="selectedGroupOptions.course == 0 || selectedGroupOptions.course == undefined" > Subject/Level </span>
                            <span *ngIf="selectedGroupOptions != undefined && selectedGroupOptions.course != 0">{{selectedGroupOptions.course | titlecase}}</span>
                            <span style="color: rgb(233, 79, 83); font-weight: 600;" [appTooltip]="685">*</span>
                        </div>
                        <select class="filterSelection h-100 w-100" style="height: 60%; border-radius: 6px" formControlName="selectedGroupOptions.course" (change)="changeOption(selectedGroupOptions.course, $event)" [appTooltip]="701">
                            <option *ngIf="newGroupForm.controls[selectedGroupOptions.course] != undefined" style="color: #6e6e6e;" value="" disabled selected hidden> {{newGroupForm.controls[selectedGroupOptions.course]}} </option>
                            <option *ngFor="let course of groupOptions[selectedGroupOptions.course]" [ngValue]="selectedGroupOptions.course" style="color: #6e6e6e;">
                                {{course.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="w-100 d-flex" [appTooltip]="702">
                    <div class="d-flex" style="flex-direction: column; width: 45%;height: 48px; margin: 1% 3%;">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="703">
                            Name
                            <span style="color: rgb(233, 79, 83); font-weight: 600;" [appTooltip]="685">*</span>
                        </div>
                        <div class="filterSelection" style="height: 60%; border-radius: 6px">
                            <div class="h-100"  style="cursor: pointer; text-align: center; padding: 2%;" [appTooltip]="704">
                                <input  type="text" style="outline: none; border: none; padding-left: 3%; font-size: 1.5vh; color: #1C4360;" class="w-100 h-100"  formControlName="name">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex" style="flex-direction: column; width: 45%;height: 48px; margin: 1% 3%;">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="705">
                            Test Date
                        </div>
                        <div class="filterSelection" style="height: 60%; border-radius: 6px" [appTooltip]="706" >
                            <mat-form-field appearance="fill" class="w-100 h-100">
                                <input #dpInput matInput [matDatepicker]="dp" style="padding: 2%" class="text-center h-100" [min]="today" formControlName="date" (dateInput)="chooseTestDate(dpInput, dp);">
                                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                    <mat-datepicker #dp> </mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="w-100 d-flex" [appTooltip]="707">
                    <div class="d-flex" style="flex-direction: column; width: 45%;height: 48px; margin: 1% 3%;">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="708"> 
                            Dates
                        </div>
                        <div  class="filterSelection" style="height: 60%; border-radius: 6px" [appTooltip]="709">
                            <mat-form-field appearance="fill"  class="w-100 h-100">
                                <mat-date-range-input class="text-center h-100"  style="padding: 2%"  [min]="today"  [rangePicker]="rangePicker">
                                    <input matStartDate  placeholder="Start date" [value]="newGroupForm.value.start_date" formControlName="start_date" (dateInput)="selectDaysDuration('start_date', $event);">
                                    <input matEndDate  placeholder="End date" [value]="newGroupForm.value.end_date"  formControlName="end_date" (dateInput)="selectDaysDuration('end_date', $event);">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePicker >
                                </mat-date-range-picker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex" style="flex-direction: column; width: 45%;height: 48px; margin: 1% 3%;" [appTooltip]="710">
                        <div style="height: 30%; font-size: 1.2vh; color: grey;">
                            Duration
                        </div>
                        <div class="filterSelection" style="height: 60%; border-radius: 6px; padding: 2%;" value="newGroupForm.controls['duration'].value" [appTooltip]="711">
                            <div *ngIf="newGroupForm.controls['duration'].value != ''">
                                {{newGroupForm.controls['duration'].value}}  Weeks
                            </div>
                        </div>
                    </div>
                </div>
                <div formArrayName="daysGroup">
                    <div id="item1" class="w-100 d-flex" *ngFor="let formDays of daysGroup.controls; let i = index;">
                        <div [formGroupName]="i"  class="w-100 d-flex">
                            <div class="d-flex" style="flex-direction: column; width: 30%;height: 48px;margin: 1%; margin-left: 3%;" [appTooltip]="712">
                                <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="713">
                                    Day
                                    <span (click)="addNewDay();" class="mx-auto my-auto add" style="font-size: 1.2vh;"[appTooltip]="714"> +  </span>
                                </div>
                                <select class="filterSelection" style="height: 60%; border-radius: 6px" formControlName="days" (change)="createDayEvents('name', $event, i)" [appTooltip]="715">
                                    <option *ngFor="let day of days" [value]="day" style="color: #6e6e6e;">
                                        {{day}}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex" style="flex-direction: column; width: 33%;height: 48px; margin: 1%;" [appTooltip]="716">
                                <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="717">
                                    Hour
                                </div>
                                <div style="height: 60%; border-radius: 6px" class="d-flex w-100">
                                    <select class="filterSelection h-100"  style="border-radius: 6px; width: 50%; padding: 2%" formControlName="startHour"  (change)="createDayEvents('name', '', i)" [appTooltip]="718">
                                        <option *ngFor="let hourStart of startHours" [ngValue]="hourStart" style="color: #6e6e6e;">
                                            {{hourStart}}
                                        </option>
                                    </select>
                                    <select class="filterSelection h-100"  style="border-radius: 6px; width: 50%; padding: 2%" formControlName="endHour"  (change)="createDayEvents('name', '', i)" [appTooltip]="719">
                                        <option *ngFor="let hourEnd of endHours" [ngValue]="hourEnd" style="color: #6e6e6e;">
                                            {{hourEnd}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="d-flex" style="flex-direction: column; width: 33%;height: 48px; margin: 1%;" [appTooltip]="720">
                                <div style="height: 30%; font-size: 1.2vh; color: grey;">
                                    Teacher
                                </div>
                                <select class="filterSelection"  style="height: 60%; border-radius: 6px" formControlName="name" (change)="createDayEvents('name', $event, i)" [appTooltip]="721">
                                    <option *ngFor="let teacher of groupOptions.teacher" [ngValue]="teacher.name" style="color: #6e6e6e;">
                                        {{teacher.name}}
                                    </option>
                                </select>

                            </div>
                            <div class="d-flex" style="flex-direction: column; width: 6%;height: 48px; margin: 1%;"[appTooltip]="722" >
                                <div style="height: 30%; font-size: 1.2vh; color: grey;">
                                    Type
                                </div>
                                <select class="filterSelection"  style="height: 60%; border-radius: 6px" (change)="selectTeacherType($event, i)"  formControlName="type" [appTooltip]="723">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden> </option>
                                    <option value="R" style="color: #6e6e6e;" [appTooltip]="724"> R </option>
                                    <option value="S" style="color: #6e6e6e;" [appTooltip]="725"> S </option>
                                </select>
                            </div>
                            <i class="fas fa-times px-1 my-auto delete" (click)="removeDay(i);" [appTooltip]="726"></i>
                        </div>
                    </div>
                    <div id="item2" class="w-100 d-flex" *ngIf="daysGroup.controls.length == 0" [appTooltip]="727">
                        <div [formGroupName]="i"  class="w-100 d-flex" [appTooltip]="728">
                            <div class="d-flex" style="flex-direction: column; width: 33%;height: 48px; margin: 1%; margin-left: 3%;">
                                <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="713">
                                    Day
                                    <span (click)="addNewDay();" class="mx-auto my-auto add" style="font-size: 1.2vh;" [appTooltip]="714"> +  </span>
                                </div>
                                <select class="filterSelection" style="height: 60%; border-radius: 6px" formControlName="days" [appTooltip]="715">
                                    <option *ngFor="let day of days" [value]="day" style="color: #6e6e6e;">
                                        {{day}}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex" style="flex-direction: column; width: 33%;height: 48px; margin: 1%;" [appTooltip]="716">
                                <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="717">
                                    Hour
                                </div>
                                <div style="height: 60%; border-radius: 6px" class="d-flex w-100">
                                    <select class="filterSelection h-100"  style="border-radius: 6px; width: 45%; padding: 2%" formControlName="startHour" [appTooltip]="718">
                                        <option *ngFor="let hourStart of startHours" [ngValue]="hourStart" style="color: #6e6e6e;">
                                            {{hourStart}}
                                        </option>
                                    </select>
                                    <select class="filterSelection h-100"  style="border-radius: 6px; width: 45%; padding: 2%" formControlName="endHour" [appTooltip]="719">
                                        <option *ngFor="let hourEnd of endHours" [ngValue]="hourEnd" style="color: #6e6e6e;">
                                            {{hourEnd}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="d-flex" style="flex-direction: column; width: 33%;height: 48px; margin: 1%;" [appTooltip]="720" >
                                <div style="height: 30%; font-size: 1.2vh; color: grey;">
                                    Teacher
                                </div>
                                <select class="filterSelection h-100"  style="height: 60%; border-radius: 6px" formControlName="name" (change)="createDayEvents('name', $event, i)" [appTooltip]="721">
                                    <option *ngFor="let teacher of groupOptions.teacher" [ngValue]="teacher.name" style="color: #6e6e6e;">
                                        {{teacher.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="d-flex" style="flex-direction: column; width: 6%;height: 48px; margin: 1%;" [appTooltip]="722">
                                <div style="height: 30%; font-size: 1.2vh; color: grey;">
                                    Type
                                </div>
                                <select class="filterSelection"  style="height: 60%; border-radius: 6px"  (change)="selectTeacherType($event, i)" [appTooltip]="723">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden> </option>
                                    <option value="R" style="color: #6e6e6e;" [appTooltip]="724"> R </option>
                                    <option value="S" style="color: #6e6e6e;" [appTooltip]="725"> S </option>
                                </select>
                            </div>
                            <i class="fas fa-times px-1 my-auto delete" (click)="removeDay(i);" [appTooltip]="726"></i>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
        <div style="height: 36%;background-color: white; padding: 2%;" [appTooltip]="727">
                <div class="w-100 d-flex" [appTooltip]="728">
                     <div  class="title"> Group students</div>
                </div>
                <div class="w-100 h-100" [appTooltip]="729">
                    <div class="w-100 d-flex blackLetterColor">
                        <div style="width: 27%;" class="title2" [appTooltip]="730">
                            Candidate
                          </div>
                          <div  style="width: 27%;" class="title2" [appTooltip]="731" *ngIf="registered != true">
                            Target level - placement
                        </div>
                        <div style="width: 15%;" class="title2" [appTooltip]="732">
                            Test date
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="flex-direction: column; height: 84%; padding: 1.2% 0" [appTooltip]="733">
                        <div class="h-100 w-100 letterColor" style="overflow-y: scroll;">
                            <div class="w-100 d-flex" *ngFor="let candidate of candidatesUsersGroup; let j = index;" (click)="getCandidateProfile(candidate)" [appTooltip]="734" style="cursor: pointer;" [ngClass]="{'selectedProfile': candidateProfile == candidate}">
                                <div style="width: 27%;" class="optionsCandidates text-truncate" [appTooltip]="735">
                                    {{candidate.name | titlecase}} {{candidate.last_name | titlecase}}
                                </div>
                                <div style="width: 27%;" class="optionsCandidates text-truncate" [appTooltip]="736">
                                {{candidate.target_level}}
                                </div>
                                <div style="width: 15%;" class="optionsCandidates" [appTooltip]="737">
                                {{candidate.convocation}}
                                </div>
                                <i class="fas fa-times px-1 my-auto delete" style="margin-left: auto;" [appTooltip]="738" (click)="deleteGroupUsers(candidate); studentsUpdate = undefined"></i>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>

    <div  style="width: 50%;" class="h-100">
        <div style="height: 60%; margin: 0% 2% 0% 1%; padding: 0 0% 3% 0%;" id="learners"  [appTooltip]="739">
            <div class="h-100 w-100 bg-white" style="padding: 2%">
                <div  class="w-100 d-flex"  [appTooltip]="740">
                    <div class="d-flex h-100" style="width: 100%;">
                        <div class="candidatesMenu"  [appTooltip]="741" (click)="setcandidatesUsers()" [ngClass]="{'candidatesMenuSelected': userType == 'candidates'}">
                           Candidates
                        </div>
                        <div class="candidatesMenu"  [appTooltip]="742" (click)="setRegisteredUsers()" [ngClass]="{'candidatesMenuSelected':  userType == 'registered'}">
                           Users
                        </div>
                        <div class="candidatesMenu"  [appTooltip]="743" *ngIf="auth.role == 'admin' || auth.role == 'superadmin'" (click)="setprospectsUsers()" [ngClass]="{'candidatesMenuSelected': userType == 'prospects'}">
                           Prospects
                        </div>
                        <select  class="candidatesMenu  filterSelection d-flex" style="cursor: pointer;" (change)="applyFilterOrganizations($event);"  [appTooltip]="744"> 
                            <option style="color: #6e6e6e; width: 24%; cursor: poiner;" value="" disabled selected hidden> Organization</option>
                            <option *ngFor="let organization of organizationsList; let i = index" [value]="organization.organization_name" style="color: #6e6e6e;" >
                                {{organization.organization_name}}
                            </option>
                        </select>
                        <div class="searchBox d-flex" *ngIf="userType != 'prospects'" (click)="searchingStudent = true" (keyup)="searchCandidates($event.target.value);"  [appTooltip]="745">                      
                            <i *ngIf="searchingStudent == false" class="fa-solid fa-magnifying-glass my-auto"></i>
                            <input type="text" class="w-100" style="outline: none; border: none;"  [appTooltip]="746">
                        </div>
                        <div class="searchBox d-flex" *ngIf="userType == 'prospects'"  (click)="searchingStudent = true"  [appTooltip]="747" (keyup)="searchProspects($event.target.value);">                      
                            <i *ngIf="searchingStudent == false" class="fa-solid fa-magnifying-glass my-auto"></i>
                            <input type="text" class="w-100" style="outline: none; border: none;"  [appTooltip]="748">
                        </div>
                    </div>
                </div>
                <div style="background-color: white; padding: 0 3%;" class="d-flex w-100"  [appTooltip]="749" [ngStyle]="{'margin': applyFiltersCandidates == true && candidatesFilters != undefined ?  '3% 0' : '1% 0'}">
                    <div  *ngIf="applyFiltersCandidates == true && candidatesFilters != undefined" class="w-100 d-flex">
                        <div style="width: 20%;" class="optionsCandidates filterSelection text-truncate"  [appTooltip]="750">
                            {{candidatesFilters.organization}}
                        </div>
                    </div>
                </div>
                <div style="height: 79%;" [ngStyle]="{'height': applyFiltersCandidates == true ? '72%': '79%'}"  [appTooltip]="751">
                   <div class="w-100 d-flex blackLetterColor"  [appTooltip]="752">
                        <div class="add text-center" style="width: 6%;" >

                        </div>
                        <div style="width: 21%;" class="title2"  [appTooltip]="753">
                          Candidate
                          <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;"  [appTooltip]="754" (click)="sortByName(users, 'last_name', 'userOrder');"></i>
                        </div>
                        <div  style="width: 24%;" class="title2" *ngIf="registered != true"  [appTooltip]="755">
                            Target level - placement
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;"  [appTooltip]="756" (click)="sortByName(users, 'target_level', 'levelOrder');"></i>
                        </div>
                        <div *ngIf="candidates != false" class="title2"  [appTooltip]="757">
                          Done
                        </div>
                        <div *ngIf="candidates != false" class="title2" [appTooltip]="758" >
                          Level
                        </div>
                        <div  style="width: 12%;" class="title2"  *ngIf="userType == 'candidates'"  [appTooltip]="759">
                          Score
                        </div>
                        <div  style="width: 15%;" class="title2" *ngIf="userType != 'registered'"  [appTooltip]="760">
                          Test date
                          <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;"  [appTooltip]="761" (click)="sortByDate(users);"></i>
                        </div>
                        <div style="width: 15%;" class="title2" *ngIf="userType == 'prospects'"  [appTooltip]="762">
                          Registered
                        </div>
                        <div  style="width: 15%;" class="title2" *ngIf="userType == 'prospects'"  [appTooltip]="763">
                          Placement
                        </div>
                    </div>
                    <div class="w-100 d-flex h-100" style="flex-direction: column; overflow-y: scroll;"  [appTooltip]="764">
                        <div class="w-100 d-flex letterColor" *ngFor="let user of users; let i = index;" (click)="getCandidateProfile(user); getUserChallenges(user)"  [appTooltip]="765" style="cursor: pointer; color: grey;" [ngClass]="{'selectedProfile': candidateProfile == user, 'coincidenceFilters': user.in_filter == true}">
                            <div class="add text-center"  style="width: 6%;" [ngClass]="{'userSelected': user.selected == true}">
                              <span  *ngIf="userType == 'candidates' || userType == 'registered'" (click)="insertUsersToGroup(user)"  [appTooltip]="766"> + </span> 
                            </div>
                            <div style="width: 21%;" class="optionsCandidates text-truncate"  [appTooltip]="767">
                                {{user.name | titlecase}} {{user.last_name | titlecase}}
                            </div>
                            <div style="width: 24%;" class="optionsCandidates text-truncate"  [appTooltip]="768">
                               {{user.target_level}}
                            </div>
                            <div  [appTooltip]="769" *ngIf="candidates != false" style="border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 6px; cursor: pointer; border-radius: 50%;" [ngStyle]="{'background-color': user.finish == true ? '#f28f3f': '#f9d422' }" >
                            </div>
                            <div  [appTooltip]="770" *ngIf="candidates != false" style="border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 6px; cursor: pointer; border-radius: 50%;" [ngStyle]="{'background-color': user.pass == true ? '#6fa84b': '#e94f53' }" >
                            </div>
                            <div style="width: 12%;"  [appTooltip]="771" class="optionsCandidates text-center"  *ngIf="userType == 'candidates'">
                                {{user.score}}
                            </div>
                            <div style="width: 15%;" class="optionsCandidates" *ngIf="userType != 'registered'"  >
                                <span *ngIf="user.test_date != '-'"  [appTooltip]="772"> {{user.test_date | date}}  </span>
                                <span *ngIf="user.test_date == '-'"  [appTooltip]="773"> {{user.test_date }}  </span>
                            </div>
                            <div  [appTooltip]="774" style="border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 6px; cursor: pointer; border-radius: 50%;" *ngIf="userType == 'prospects'" [ngStyle]="{'background-color': user.registered == true ? '#6fa84b': '#e94f53'}">
                               <!-- {{user.registered}} -->
                            </div>
                            <div  [appTooltip]="775" style="border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 6px; cursor: pointer; border-radius: 50%;" *ngIf="userType == 'prospects'" [ngStyle]="{'background-color': user.placement_requested == true ? '#6fa84b': '#e94f53'}">
                               <!-- {{user.placement_requested}} -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 40%; margin: 0% 2% 0% 1%; background-color: white;" id="student">
            <app-candidate-info *ngIf="showReport == true" [user]="candidateProfile"  [area]="userType" [prospectProfile]="prospectProfile" [prospectChallenges]="prospectChallenges"></app-candidate-info>
        </div>
    </div>
</div>
<app-placement-results></app-placement-results>


