import { Component, OnInit, ViewChild } from '@angular/core';
import { ListsInfoService } from '../../services/lists-info.service';
import { LabInfoService } from '../../services/lab-info.service';
import { waitForAsync } from '@angular/core/testing';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {


  constructor(private labInfo: LabInfoService, private listInfo: ListsInfoService) {
    // this.getCampuses();
    // this.getFaculties();
    // this.getDegrees();
    // this.getSubjects();
    // this.getLevels();
  }

  title = "Title";
  description = "Description";
  labimage = "../../../assets/img/laptop.png";
  price: number = 199;
  listId: number = 1;
  offerprice: number = 19;
  keyLine: string[] = [];
  Objectives: string[] = [];
  Requirements: string[] = [];
  Towho: string[] = [];

  @ViewChild('keyline') keyline;
  @ViewChild('objective') objective;
  @ViewChild('require') require;
  @ViewChild('who') who;

  mySlideOptions = {
    nav: true,
    dots: false,
    margin: 15,
    autoplay: false,
    responsive: {
      "0": { items: 1 },
      "630": { items: 2 },
      "991": { items: 3 },
      "1200": { items: 3 },
      "1500": { items: 4 }
    }
  };

  ngOnInit() {

  }

  setLab() {
    let key_lines: string = '';
    this.keyLine.forEach(keyline => {
      key_lines = key_lines + ',' + keyline
      //key_lines =key_lines,",",keyline
    });

    let objectives: string = '';
    this.Objectives.forEach(objective => {
      // objectives = objectives.concat(objectives, ",", objective)
      objectives = objectives + "," + objective
    });

    let requirements: string = '';
    this.Requirements.forEach(requirement => {
      // requirements = requirements.concat(requirements, ",", requirement)
      requirements = requirements + "," + requirement
    });

    let to_who: string = '';
    this.Towho.forEach(keyline => {
      // to_who = to_who.concat(to_who, ",", keyline)
      to_who = to_who + "," + keyline
    });
    // this.labInfo.saveLab(this.title, this.labimage, this.description, String(this.listId), key_lines, requirements, objectives, to_who, this.price, this.offerprice,this.type).subscribe();
  }

  Campuses: any = [];
  getCampuses() {
    this.listInfo.getCampuses().subscribe(data => {
      this.Campuses = data;
    });
  }

  Faculties: any = [];
  // getFaculties() {
  //   this.listInfo.getFaculties().subscribe(data => {
  //     this.Faculties = data;
  //     this.campus_id = data[0].campus_id;
  //   });
  // }

  Degrees: any = [];
  // getDegrees() {
  //   this.listInfo.getDegrees().subscribe(data => {
  //     this.Degrees = data;
  //     this.faculty_id = data[0].faculty_id;
  //   });
  // }

  Subjects: any = [];
  // getSubjects() {
  //   this.listInfo.getSubjects().subscribe(data => {
  //     this.Subjects = data;
  //     this.degree_id = data[0].degree_id;
  //   });
  // }

  Levels: any = [];
  // getLevels() {
  //   this.listInfo.getLevels().subscribe(data => {
  //     this.Levels = data;
  //   });
  // }

  // campus_id: number;
  // selCampus(id: number) {
  //   this.campus_id = id;
  //   this.getFacultiesByCampus();
  //   this.getLabsByCampus();
  // }
  
  // getFacultiesByCampus() {
  //   this.listInfo.getFacultiesByCampus(this.campus_id).subscribe(data => {
  //     this.Faculties = data;
  //   });
  // }


  // faculty_id: number;
  // selFaculty(id: number) {
  //   this.faculty_id = id;
  //   // console.log(this.Faculties[this.faculty_id - 1].campus_id);
  //   this.campus_id = this.Faculties[this.faculty_id - 1].campus_id;
  //   this.getDegreesByFaculty();
  //   this.getLabsByFaculty();
  // }

  // getDegreesByFaculty() {
  //   this.listInfo.getDegreesByFaculty(this.faculty_id).subscribe(data => {
  //     this.Degrees = data;

  //   });
  // }

  // degree_id: number;
  // selDegree(id: number) {
  //   this.degree_id = id;
  //   this.faculty_id = this.Degrees[this.degree_id - 1].faculty_id;
  //   this.campus_id = this.Faculties[this.faculty_id - 1].campus_id;
  //   this.getSubjectsByDegree();
  //   this.getLabsByDegree();
  // }

  // getSubjectsByDegree() {
  //   this.listInfo.getSubjectsByDegree(this.degree_id).subscribe(data => {
  //     this.Subjects = data;
  //   });
  // }

  // subject_id: number;
  // selSubject(id: number) {
  //   this.subject_id = id;
  //   this.degree_id = this.Subjects[this.subject_id - 1].degree_id;
  //   this.faculty_id = this.Degrees[this.degree_id - 1].faculty_id;
  //   this.campus_id = this.Faculties[this.faculty_id - 1].campus_id;
  //   this.getLabsBySubject();
  // }

  level_id: number;
  selLevel(id: number) {
    this.level_id = id;
  }
  
  setLabs(data) {
    this.labs = data;
    console.log(this.labs);
    this.loading = false;
  }

  labs: any = []
  loading:boolean;
  // getLabsByCampus() {
  //   this.loading = true;
  //   this.listInfo.getLabsByCampus(this.campus_id).subscribe(async data => {
  //     await this.setLabs(data);
  //   })
  // }


  // getLabsByFaculty() {
  //   this.loading = true;
  //   this.listInfo.getLabsByFaculty(this.faculty_id).subscribe(async data => {
  //     await this.setLabs(data);
  //   })
  // }

  // getLabsByDegree() {
  //   this.loading = true;
  //   this.listInfo.getLabsByDegree(this.degree_id).subscribe(async data => {
  //     await this.setLabs(data);
  //   })
  // }

  // getLabsBySubject() {
  //   this.loading = true;
  //   this.listInfo.getLabsBySubject(this.subject_id).subscribe(async data => {
  //     await this.setLabs(data);
  //   })
  // }

  indice:number;
  edit=false;
  editLab(index){
    if(this.edit==false){
      this.edit=true;
      this.indice=index;
    }
    else{
      this.edit=false;
    }
  }

  changePreview = 1;
  preview() {
    if (this.changePreview === 1) {
      this.changePreview = 2;
    }
    else if (this.changePreview === 2) {
      this.changePreview = 3;
    }
    else {
      this.changePreview = 1;
    }
  }

  setTitle(titulo: string) {
    this.title = titulo;
  }

  setDescription(descripcion: any) {
    this.description = descripcion.html;
  }

  setPrice(precio: number) {
    this.price = precio;
  }

  setOffer(oferta: number) {
    this.offerprice = oferta;
  }

  addKeyline(key: string) {
    if (key !== ' ') {
      this.keyLine.push(key);
      this.keyline.nativeElement.value = '';
    }
    return
  }

  addObjectives(obj: any) {
    if (obj !== ' ') {
      this.Objectives.push(obj);
      this.objective.nativeElement.value = '';
    }
    return
  }

  addRequirements(req: any) {
    if (req !== ' ') {
      this.Requirements.push(req);
      this.require.nativeElement.value = '';
    }
  }

  addTowho(towho: any) {
    if (towho !== ' ') {
      this.Towho.push(towho);
      this.who.nativeElement.value = '';
    }
  }

  saveLab() {
    if (window.confirm("¿Seguro que quieres guardar?")) {
      this.edit=false;
      // logica de guardado
      this.setLab();
    }
  }

  cancelConfirm() {
    if (window.confirm("¿No quieres guardar?")) {
      this.edit=false;
      // logica de cancel
    }
  }

}
