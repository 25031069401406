import { Component, ViewEncapsulation} from '@angular/core';
import { Router } from '@angular/router';
import { LabInfoService } from '../../services/lab-info.service';
import { ListsInfoService } from '../../services/lists-info.service';
import { CreatorService } from '../../services/creator.service';
import { stringify } from 'querystring';


@Component({
  selector: 'app-list-creator',
  templateUrl: './list-creator.component.html',
  styleUrls: ['./list-creator.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ListCreatorComponent {

  list = true;
  list_name: string[] = [];

  constructor(private labInfo: LabInfoService, private listInfo: ListsInfoService, private _router: Router, private creator: CreatorService) { 
    console.log("constructor");
    
    this.getCampuses();
    console.log("constructor2");
    this.getFaculties();
    this.getDegrees();
    this.getSubjects();
    this.getLevels();

    this.getSessions();
  }

  Lists = this.creator.Lists;
  
  sublist: string[] = ['ESSENTIAL', 'KEY', 'ULTIMATE'];
  //Faculty Tree Load
  Campuses: any = [];
  getCampuses() {
    this.listInfo.getCampuses().subscribe(data => {
      this.Campuses = data ;
      console.log(this.Campuses);
    });
  }
  
  Faculties: any = [];
  getFaculties() { this.listInfo.getFaculties().subscribe(data => this.Faculties = data );
  }

  Degrees: any = [];
  getDegrees() {
    this.listInfo.getDegrees().subscribe(data => this.Degrees = data );
  }

  Subjects: any = [];
  getSubjects() {
    this.listInfo.getSubjects().subscribe(data => this.Subjects = data );
  }

  Levels: any = [];
  getLevels() {
    this.listInfo.getLevels().subscribe( data => this.Levels = data );
  }

  Sessions:any=[];
  getSessions(){
    this.listInfo.getSessions().subscribe(data => this.Sessions = data );
  }

  getFacultiesByCampus(id:string) {
    this.listInfo.getFacultiesByCampus(Number(id)).subscribe(data => this.Faculties = data );
  }

  getDegreesByFaculty(id:string) {
    this.listInfo.getDegreesByFaculty(Number(id)).subscribe(data => this.Degrees = data[0] );
  }

  getSubjectsByDegree(id:string) {
    this.listInfo.getSubjectsByDegree(Number(id)).subscribe(data => this.Subjects = data );
  }

  //Faculty Tree Selection
  nameCampus = 'Campus';
  campus_id: string;
  campus_name: string = "";
  selCampus(id: string,origin:boolean) {
    if(origin){   //viene del html
      this.nameCat = "Categoría";
      this.nameSubCat = "Subcategoría";
      this.nameDis = "Disciplina";
      this.faculty_name = " ";
      this.degree_name = " ";
      this.disc_name = " ";
      }

    this.campus_id = id;

    let index = this.Campuses.map( n => n = n.id );
    this.campus_name = this.Campuses[index.indexOf(Number(this.campus_id))].name;

    if (this.campus_name == 'Certificates') {
      this.nameCampus = this.campus_name;
      this.campus_name = "";
      this.list_name.push("Essential vocabulary" + " " + "for" + " " + this.campus_name + " " + this.degree_name);
      this.list_name.push("Key vocabulary" + " " + "for" + " " + this.campus_name + " " + this.degree_name);
      this.list_name.push("Ultimate vocabulary" + " " + "for" + " " + this.campus_name + " " + this.degree_name);
    }
    else {
      this.campus_name = "PEC";
      this.nameCampus = this.campus_name;
      this.list_name.push("Essential vocabulary" + " " + "for" + " " + this.campus_name + " " + this.disc_name);
      this.list_name.push("Key vocabulary" + " " + "for" + " " + this.campus_name + " " + this.disc_name);
      this.list_name.push("Ultimate vocabulary" + " " + "for" + " " + this.campus_name + " " + this.disc_name);
    }

    this.getFacultiesByCampus(this.campus_id);
    
    this.campusRojo = false;    
  }

  nameCat = 'Categoria';
  faculty_name: string;
  faculty_id: string;
  selFaculty(id: string,origin:boolean) {

    if(origin){//viene del html
      this.nameSubCat = "Subcategoría";
      this.nameDis = "Disciplina";
      this.degree_name = " ";
      this.disc_name = " ";
    }
    
    this.faculty_id = id;

    let index = this.Faculties.map( n => n = n.id );    
    // this.campus_id = this.Faculties[index.indexOf(Number(this.faculty_id))].campus_id; 

    this.faculty_name = this.Faculties[index.indexOf(Number(this.faculty_id))].name;
    this.nameCat = this.faculty_name;

    this.getDegreesByFaculty(this.faculty_id);
    // this.selCampus(this.campus_id,false);
    this.categoriaRojo = false;
    this.campusRojo = false; 
  }

  nameSubCat = 'Subcategoria';
  degree_name: string;
  degree_id: string;
  selDegree(id: string,origin:boolean) {
    if(origin){
      this.nameDis = "Disciplina";
      this.disc_name = " ";
    }
    this.degree_id = id

    let index = this.Degrees.map( n => n = n.id );
    // this.faculty_id = this.Degrees[index.indexOf(Number(this.degree_id))].faculty_id; 
    this.degree_name = this.Degrees[index.indexOf(Number(this.degree_id))].name;
    this.nameSubCat = this.degree_name;

    this.getSubjectsByDegree(this.degree_id);
    // this.selFaculty(this.faculty_id,false);
    this.subcategoriaRojo = false;
    this.categoriaRojo = false;
    this.campusRojo = false;
  }

  campusName:string;
  nameDis = 'Disciplina';
  subject_id: string = null;
  disc_name: string = " ";  
  selSubject(id: string) {  
    this.list_name = [];
    this.subject_id = id;

    let index = this.Subjects.map( n => n = n.id );    
    // this.degree_id = this.Subjects[index.indexOf(this.subject_id)].degree_id; 

    this.disc_name = this.Subjects[index.indexOf(this.subject_id)].name;
    this.nameDis = this.disc_name;
  
    // this.selDegree(this.degree_id,false);
    this.list_name.push("Essential vocabulary" + " " + "for" + " " + this.campus_name + " " + this.disc_name);
    this.list_name.push("Key vocabulary" + " " + "for" + " " + this.campus_name + " " + this.disc_name);
    this.list_name.push("Ultimate vocabulary" + " " + "for" + " " + this.campus_name + " " + this.disc_name);
  }

  //Add to Faculty Tree
  campusCh = true;
  newCampus() { this.campusCh = ! this.campusCh; }

  faculty = true;
  newCat() { this.faculty = ! this.faculty; }

  degree = true;
  newSubcat() { this.degree = ! this.degree; }

  subject = true;
  newSubject() { this.subject = ! this.subject; }

  level = true;
  newLevel() { this.level = ! this.level; }

  nounListId = [];
  onChangeNoun(id,i) { this.nounListId[i] = id; }

  adjetiveListId = [];
  onChangeAdj(id,i) { this.adjetiveListId[i] = id; }

  verbListId = [];
  onChangeVerb(id,i) { this.verbListId[i] = id; }

  adverbListId = [];
  onChangeAdv(id,i) { this.adverbListId[i] = id; }

  conjListId = [];
  onChangeConj(id,i) { this.conjListId[i] = id; }

  prepListId = [];
  onChangePrep(id,i) { this.prepListId[i] = id; }

  pronListId = [];
  onChangePron(id,i) { this.pronListId[i] = id; }

  setCampus(name: string) {
    this.listInfo.setCampus(name).subscribe();
  }

  campusRojo: boolean;
  setFaculty(name: string, campus_id: string) {
    if (campus_id == null) {
      //poner campus en rojo
      this.campusRojo = true;
    } else {
      this.listInfo.setFaculty(name,campus_id).subscribe();
      this.campusRojo = false;
    }
  }

  categoriaRojo: boolean;
  setDegree(name: string, faculty_id: string) {
    if (faculty_id == null) {
      //poner categoria en rojo(y hacia atras) 
      this.categoriaRojo = true;
      this.campusRojo = true;
    } else {
      this.listInfo.setDegree(name,faculty_id).subscribe();
      this.categoriaRojo = false;
    }
  }

  subcategoriaRojo: boolean;
  setSubject(name: string, degree_id: string) {
    console.log(name, degree_id);
    if (degree_id == null) {
      //poner subcategoria en rojo(y hacia atras) 
      this.subcategoriaRojo = true;
      this.categoriaRojo = true;
      this.campusRojo = true;
    }else{
      this.listInfo.setSubject(name,degree_id).subscribe();
      this.subcategoriaRojo = false;
    }    
  }

  //List Name and Description edit
  descripcion: string[] = ['','',''];
  listDescription(name: string, index:number) {
    this.descripcion[index] = name;
  }
  
  index = 0;
  change() {
    if (this.index === 0) {
      this.index = 1;
    }
    else if (this.index === 1) {
      this.index = 2;
    }
    else {
      this.index = 0;
    }
  }

  //Sublists organization
  nounList:any = [[]];
  advList:any = [[]];
  verbList:any = [[]];
  adjList:any = [[]];
  conjList:any = [[]];
  prepList:any = [[]];
  pronpList:any = [[]];
  sublists:any = [[]];

  sessionLists;  
  regex: any[] = [/ESSENTIAL/i, /KEY/i, /ULTIMATE/i];
  selSession(list) {
    let array = list.split(',');
    
    let name = array.pop();
    let l = '';
    array.forEach(element => {
      l = l +element+',';
    });
    l = l+' ,';
    let lists = array;
    console.log(l);
    console.log(name);
    

    this.listInfo.getSessionsLists(l).subscribe(data =>{ 

      console.log(data);
      this.sublists = data;
      this.nounList = [[],[],[]];
      this.adjList = [[],[],[]];
      this.verbList = [[],[],[]];
      this.advList = [[],[],[]];
      this.conjList = [[],[],[]];
      this.prepList = [[],[],[]];
      this.pronpList = [[],[],[]];
      
       
      this.sublists.forEach( listName => {
        if (String(listName.listName).search(' [Nn]ouns')!= -1 ) {
          for (let i = 0; i < this.sublist.length; i++) {
            if (String(listName.listName).search(this.regex[i]) != -1) {
              this.nounList[i].push({'listName':listName.listName,'key':listName.key});
            }            
          }          
        }

        if (String(listName.listName).search('[Aa]djectives')!= -1 ) {
          for (let i = 0; i < this.sublist.length; i++) {
            if (String(listName.listName).search(this.regex[i])!= -1) {
              this.adjList[i].push({'listName':listName.listName,'key':listName.key});
            }            
          }          
        }

        if (String(listName.listName).search(' [Vv]erbs')!= -1 ) {
          for (let i = 0; i < this.sublist.length; i++) {
            if (String(listName.listName).search(this.regex[i])!= -1) {
              this.verbList[i].push({'listName':listName.listName,'key':listName.key});
            }            
          }          
        }

        if (String(listName.listName).search('[Aa]dverbs')!= -1 ) {
          for (let i = 0; i < this.sublist.length; i++) {
            if (String(listName.listName).search(this.regex[i])!= -1) {
              this.advList[i].push({'listName':listName.listName,'key':listName.key});
            }            
          }          
        }

        if (String(listName.listName).search('[Cc]onjunctions')!= -1 ) {
          for (let i = 0; i < this.sublist.length; i++) {
            if (String(listName.listName).search(this.regex[i])!= -1) {
              this.conjList[i].push({'listName':listName.listName,'key':listName.key});
            }            
          }          
        }

        if (String(listName.listName).search('[Pp]repositions')!= -1 ) {
          for (let i = 0; i < this.sublist.length; i++) {
            if (String(listName.listName).search(this.regex[i])!= -1) {
              this.prepList[i].push({'listName':listName.listName,'key':listName.key});
            }            
          }          
        }

        if (String(listName.listName).search('[Pp]ronouns')!= -1 ) {
          for (let i = 0; i < this.sublist.length; i++) {
            if (String(listName.listName).search(this.regex[i])!= -1) {
              this.pronpList[i].push({'listName':listName.listName,'key':listName.key});
            }            
          }          
        }
      });   

      

      for (let index = 0; index < this.sublist.length; index++) {
        this.nounListId.push(this.nounList[index][0].key);
        this.adjetiveListId.push( this.adjList[index][0].key);
        this.verbListId.push( this.verbList[index][0].key);

        if(this.advList[index] != ""){ 
            this.adverbListId.push(this.advList[index][0].key);
        }else{
          this.adverbListId.push("*");
        }
        if(this.conjList[index] != ""){
          this.conjListId.push(this.conjList[index][0].key);
        }else{
          this.conjListId.push("*");
        }
        if(this.prepList[index] != ""){
          this.prepListId.push(this.prepList[index][0].key);
        }else{
          this.prepListId.push("*");
        }
        if(this.pronpList[index] != ""){
          this.pronListId.push(this.pronpList[index][0].key);
        }else{
          this.pronListId.push("*");
        }    
      }
    });   

    this.creator.session = name;
  }
  
  //List Save
  name: string;
  listsKeys: string[] = ["","",""];
  loading = false;
  setList() {
    console.log(this.nounListId);
    console.log(this.adjetiveListId);
    console.log(this.verbListId);
    console.log(this.adverbListId);
    console.log(this.conjListId);
    console.log(this.prepListId);
    console.log(this.pronListId);
    for (let index = 0; index < this.sublist.length; index++) {
      this.listsKeys[index] = this.nounListId[index];
      if (this.adjetiveListId[index] != null){
        this.listsKeys[index] = this.listsKeys[index] + ',' + this.adjetiveListId[index];
      }
      if (this.verbListId[index] != null){
        this.listsKeys[index] = this.listsKeys[index] + ',' + this.verbListId[index];
      }   
      if (this.adverbListId[index] != null){
        this.listsKeys[index] = this.listsKeys[index] + ',' + this.adverbListId[index];
      }
      if (this.conjListId[index] != null) {
        this.listsKeys[index] = this.listsKeys[index] + ',' + this.conjListId[index];
      }
      if (this.prepListId[index] != null) {
        this.listsKeys[index] = this.listsKeys[index] + ',' + this.prepListId[index];
      }     
      if (this.pronListId[index] != null) {
        this.listsKeys[index] = this.listsKeys[index] + ',' + this.pronListId[index];
      }
      this.name = this.list_name[index];
      
      this.listInfo.setList(this.name, this.descripcion[index], this.campus_id, this.faculty_id, this.degree_id, this.subject_id, this.listsKeys[index], String(index+12)).subscribe(data => {
        this.getLists(data);
        this.loading = true;
      });
      this.loading = false;
    }
    this.creator.list_name = this.list_name;
  }

  setListEmpty() {
    if(this.list_name[0].indexOf('Essential')!=-1){
      this.listInfo.setListEmpty(JSON.stringify(this.list_name), '', this.campus_id, this.faculty_id, this.degree_id, this.subject_id, '', String(12)).subscribe(data => {
        this.getLists(data);
        this.loading = true;
      });
    }else if(this.list_name[0].indexOf('Access')!=-1){
      this.listInfo.setListEmpty(JSON.stringify(this.list_name), '', this.campus_id, this.faculty_id, this.degree_id, this.subject_id, '', String(35)).subscribe(data => {
        this.getLists(data);
        this.loading = true;
      });
    }else if(this.list_name[0].indexOf('Junior')!=-1){
      this.listInfo.setListEmpty(JSON.stringify(this.list_name), '', this.campus_id, this.faculty_id, this.degree_id, this.subject_id, '', String(38)).subscribe(data => {
        this.getLists(data);
        this.loading = true;
      });
    }
          
      

      
      this.loading = false;
    
    this.creator.list_name = this.list_name;
  }

  getLists(id: any) {
    this.labInfo.getList(id).subscribe(data => {
      if (data[0].name.search(this.regex[0]) != -1) this.creator.Lists[0] = data[0];  
      if (data[0].name.search(this.regex[1]) != -1) this.creator.Lists[1] = data[0];
      if (data[0].name.search(this.regex[2]) != -1) this.creator.Lists[2] = data[0];
      console.log("Lists", this.creator.Lists);
    });
  }

  load:boolean;
  async saveList() {
    this.load = false;
    if (window.confirm("¿Seguro que quieres guardar?")) {
      for (let index = 0; index < this.list_name.length; index++) {
        localStorage.setItem("list_name"+index, this.list_name[index]);
      }
      
      if (this.list == true) {
        this.list = false;
      }
      await this.setList();
      this.load = true;
    }
  }


  async saveListEmpty() {
    this.load = false;
    if (window.confirm("¿Seguro que quieres guardar la lista vacía?")) {
      for (let index = 0; index < this.list_name.length; index++) {
        localStorage.setItem("list_name"+index, this.list_name[index]);
      }
      
      if (this.list == true) {
        this.list = false;
      }
      await this.setListEmpty();
      this.load = true;
    }
  }
}
