import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Dimensions, ImageCroppedEvent, ImageTransform, base64ToFile} from 'ngx-image-cropper';
import { DatePipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { LmsService } from 'src/app/services/lms.service';
import * as $ from 'jquery';
declare var $: any;

class newGroupSet {
  organization_name = '';
  office_name = '';
  office_id: number = null;
}

class testSet {
  course_name = '';
  exam_id = '';
  date = '';
  startHour = '';
  startEvent = '';
  edit: boolean = true;
  version = '';
  campus_id;
  test_takers;
}

export class userCandidates {
  id: number = null;
  exam_user_id: string = '';
  name: string = '';
  last_name: string = '';
  target_level = '';
  final_level = '';
  convocation = '';
  target_start_date = '';
  email: string = '';
  picture: string = '';
  phone: number = 0;
  score = '';
  selected: boolean = false;
  registered;
  placement_requested;
  in_filter: boolean = false;
  finish: boolean = false;
}

class organizationOptions {
  office;
  office_name = '';
  placements = new Array();
  office_id: number = null;
  pecplacements;
  certplacements;
  organization_id;
  organization_name;
  organization_img;
  tests;
  areas;
}

@Component({
  selector: 'app-new-organization',
  templateUrl: './new-organization.component.html',
  styleUrls: ['./new-organization.component.css']
})
export class NewOrganizationComponent implements OnInit {

  organizationOptions;
  selectedOrganizationOptions;
  groupDone: boolean = false;

  
  organization_id;
  organizations;
  organizationsList = new Array();
  clientAdmins;
  candidates: boolean = true;
  newOffice: boolean = false;
  applyFiltersCandidates: boolean = false;

  candidatesUsers = new Array;
  
  selectedOrganization;
  selectedOptions;
  editGroup;
  newOrganization: boolean = false;
  registered: boolean = false;
  prospectSend: boolean = false;


  candidatesUsersGroup = new Array();
  newOrganizationForm: UntypedFormGroup;
  newTest: UntypedFormGroup;
  placementResults;
  today;
  candidateProfile;
  placementExams = new Array();
  testsArray = new Array();
  imgURL;
  
  imagePath;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  aspectRatio = 5 / 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  cropImage;

  companies;
  openDropDown: boolean = false;
  survey;
  closedSurveys: boolean = false;
  selectedSurveyFolder;
  surveysFiles;
  showPromptNumber;
  showPrompt;

  fileType = 'image';
  startHours = ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];


  constructor(private router: Router, public lms: LmsService, private auth: AuthService, public admin: AdminService, public datePipe: DatePipe, private sanitizer: DomSanitizer, ) {

    this.newOrganizationForm = new UntypedFormGroup({
      organization: new UntypedFormControl('', [Validators.required]),
      organization_id: new UntypedFormControl(''),
      company_name: new UntypedFormControl(''),
      company_id: new UntypedFormControl(''),
      office_id: new UntypedFormControl(''),
      office: new UntypedFormControl('', [Validators.required]),
      pecplacement: new UntypedFormControl(''),
      certplacement: new UntypedFormControl(''),
      course_id: new UntypedFormControl(''),
      subject: new UntypedFormControl(''),
      name: new UntypedFormControl(''),
      area: new UntypedFormControl(''),
    });

  }
  
  
  ngOnInit(): void {
    this.auth.workingArea = 'new organization';
    this.getOrganizations();
    this.setOrganizationOptions();
    this.today = new Date();
  }


  getOrganizations(){
    this.admin.getOrganizations().subscribe((data: any) => {
      this.organizationsList = data;
    });
  }


  setOrganizationOptions(index?) {
    this.selectedOrganizationOptions = new newGroupSet();
    if (index != undefined){
      this.selectedOrganization = this.organizationsList[index];
      this.organization_id = this.organizationsList[index].organization_id;
    }

    this.admin.getOrganizationOptionsBack(this.organization_id).subscribe((data: any) => {

      let organizationOptionsAux = new organizationOptions;
      organizationOptionsAux.pecplacements = data.pec_exams;
      organizationOptionsAux.certplacements = data.cert_exams;
      organizationOptionsAux.areas = data.areas;
      this.testsArray = new Array();
      
      if (this.selectedOrganization == undefined){
        organizationOptionsAux.office = data.office;
        this.testsArray[0] = new testSet();
      } else{
        organizationOptionsAux.office = data.office;
        if (data.organization_info.organization_office != null){
          this.newOrganizationForm.controls['office'].setValue(data.organization_info.organization_office.name);
          this.newOrganizationForm.controls['office_id'].setValue(data.organization_info.organization_office.id);
        }
        if (this.selectedOrganization.company_id != undefined){
          this.newOrganizationForm.controls['company_name'].setValue(this.selectedOrganization.company_name);
          this.newOrganizationForm.controls['company_id'].setValue(this.selectedOrganization.company_id);
        }
        if (this.selectedOrganization.area != undefined){
          this.newOrganizationForm.controls['area'].setValue(this.selectedOrganization.area);
        }
        this.newOrganizationForm.controls['organization'].setValue(this.selectedOrganization.organization_name);
        this.newOrganizationForm.controls['organization_id'].setValue(this.selectedOrganization.organization_id);
        if (this.selectedOrganization.image_url != undefined){
          organizationOptionsAux.organization_img = this.selectedOrganization.image_url;
        }
        organizationOptionsAux.organization_id = this.selectedOrganization.organization_id;
        organizationOptionsAux.organization_name = this.selectedOrganization.organization_name;
        this.admin.getInHouseTests('', '', this.organization_id).subscribe((data: any) => {
          if (data.length != 0){
            for (let i = 0; i < data.length; i++){
              let testArrayAux = new testSet()
              testArrayAux.course_name = data[i].course_name;
              testArrayAux.version = data[i].version;
              testArrayAux.exam_id = data[i].exam_id;
              testArrayAux.date = data[i].date;   
              if (data[i].hour != undefined){
                let hour = data[i].hour.split("", 5);
                testArrayAux.startHour = hour.join('')
              }
              // testArrayAux.startHour = data[i].hour;
              testArrayAux.campus_id = data[i].campus_id;
              if (data[i].campus_id == 1){
                this.placementExams[i] = this.organizationOptions.certplacements;
                this.placementVersions[i] = new Array();
              } else{
                this.placementExams[i] = this.organizationOptions.pecplacements;
                this.placementVersions[i] = new Array();
              }
              
              this.placementExams[i].forEach( test => {
                 test.exams.forEach( exam => {
                  if (exam.exam_id == testArrayAux.exam_id){
                    testArrayAux.version = exam.version;
                  }
                 })   
              })
              testArrayAux.test_takers = data[i].test_takers;
              testArrayAux.edit = false;
              this.testsArray.push(JSON.parse(JSON.stringify(testArrayAux)));
            }
          } else{
            this.testsArray[0] = new testSet();
          }
        });
        this.surveysFiles = Object.keys(this.selectedOrganization.files);
    }
      this.organizationOptions = organizationOptionsAux;
    });
  }

  


  createNewOrganization(){

    this.organization_id = undefined;
    this.selectedOrganization = undefined;
    this.newOrganizationForm.reset();
    this.getOrganizations();
    this.setOrganizationOptions();
  }

  searchCompanies(search_value){
    if (search_value.length > 2){
      this.admin.getCompanies(search_value).subscribe((data: any) => {
        this.companies = data;
        this.openDropDown = true;
      });
    }
  }

  selectCompany(index){
    this.newOrganizationForm.value.company_name = this.companies[index].company_name;
    this.newOrganizationForm.value.company_id =  this.companies[index].company_id;
    this.openDropDown = false;
  }

  
  changeOption(filterOption, option) {
    let index = option.target.selectedIndex;
    // this.newOrganizationForm.controls[filterOption].setValue(this.organizationOptions[filterOption][index]);
    
    switch (filterOption) {
      case 'office':
        this.organizations = this.organizationOptions['office'][index].organizations;
        break;
        
        case 'organization':
          if (this.organizations.length > 0) {
            for (let i = 0; i < this.organizations.length; i++) {
              const organization = this.organizations[i];
              if (organization.name == option.target.value) {
                if (organization.clientAdmins) {
                this.clientAdmins = organization.clientAdmins;
              } else {
                this.clientAdmins = undefined;
              }
            }
          }
        } else {
          this.clientAdmins = undefined;
        }
        break;
        
        default:
          break;
        }
      }
      
  ////New Placements Tests asociados a la organización


  newInHouseTest(){
    this.testsArray.map( test => test.edit = false);
    this.testsArray[this.testsArray.length] = new testSet();
    this.testsArray[this.testsArray.length-1].edit = true;

  }
  
  chooseCampusExams(event, index){
    if (event.target.value == 1){
      this.placementExams[index] = this.organizationOptions.certplacements;
      this.testsArray[index].campus_id = 1;
    } else{
      this.placementExams[index] = this.organizationOptions.pecplacements;
      this.testsArray[index].campus_id = event.target.value;
    }
  }


  editTest(test){
    test.edit = true
  }

  placementVersions = new Array();
  selectPlacement(index, event){
    // let placement =  this.placementExams[index].find( plc => plc.course_name == event.target.value)
    let placement = this.placementExams[index][event.target.selectedIndex-1]
    // this.testsArray[index].exam_id = placement.exam_id;
    this.placementVersions.push(placement.exams);
  }

  selectPlacementVersion(index, event){
    let placementVersion =  this.placementVersions[index].find( plc => plc.version == event.target.value)
    this.testsArray[index].version = placementVersion.version;
    this.testsArray[index].exam_id = placementVersion.exam_id;
  }

  chooseTestDate(input, dp, index) {
    dp.close();
    // this.testsArray[index].date = this.datePipe.transform(input.value, 'dd/MM/yyyy');
    // this.testsArray[index].date = input.value;
    this.testsArray[index].date = new Date(input.value);

  }

  selectTestHour(index, event){
    this.testsArray[index].startHour = event.target.value;
    // let hour = parseInt(event.target.value) + 2;
    // let date = new Date(hour);
    // date.toLocaleTimeString();
  }


  saveTest(test){
    test.edit = false;
    this.updateOrganization();
  }

  removeTest(index){
    this.testsArray.splice(index, 1);
  }

  ///////

  editGroupInfo(infoGroup) {
    this.editGroup[infoGroup] = true;
  }

  createOrganization() {
    if (this.newOrganizationForm.value.office != "") {
      this.newOrganizationForm.value.office_id = null;
    }

    this.admin.createOrganization(this.newOrganizationForm.value.organization, this.newOrganizationForm.value.office, this.newOrganizationForm.value.office_id, this.newOrganizationForm.value.company_id, this.newOrganizationForm.value.company_name, this.croppedImage, this.newOrganizationForm.value.area).subscribe((data: any) => {
      this.groupDone = true;
      setTimeout(() => {
        this.groupDone = false;
      }, 4500);
    });
  }

  updateOrganization() {
    // this.admin.updateOrganization(this.newOrganizationForm.value.organization_id, this.testsArray, this.newOrganizationForm.value.office_id, this.newOrganizationForm.value.office, this.newOrganizationForm.value.company_id, this.newOrganizationForm.value.company_name, this.croppedImage, this.newOrganizationForm.value.area).subscribe((data: any) => {
    //   this.groupDone = true;
    //   setTimeout(() => {
    //     this.groupDone = false;
    //   }, 4500);
    // });
  }

  //Image Cropper 

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  newImage(){
    this.croppedImage  = '';
    this.fileChangeEvent('');
    this.cropImage.emit(this.croppedImage);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.cropImage.emit(this.croppedImage);
    console.log(event, base64ToFile(event.base64));

  }

  imageLoaded() {
      this.showCropper = true;
      console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
      // console.log('Load failed');
  }

  rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }

  flipHorizontal() {
      this.transform = {
          ...this.transform,
          flipH: !this.transform.flipH
      };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.canvasRotation = 0;
      this.transform = {};
  }

  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
      this.transform = {
          ...this.transform,
          rotate: this.rotation
      };
  }

  // imageCropped(event) {
  //   this.croppedImage = event.base64;
  //   console.log(this.croppedImage);
  // }
  

  // fileChangeEvent(file) {
  //   var reader = new FileReader();
  //   this.imagePath = file;
  //   reader.readAsDataURL(file[0]); 
  //   reader.onload = (_event) => { 
  //     this.imgURL = reader.result; 
  //   }
  // }




/////Image Cropper


  sendToAnalysis(){
    this.admin.sendToAnalysis(this.selectedOrganization.organization_id, 'organization').subscribe((data: any) => {
      this.router.navigate(['/surveys']);
      // this.prospectSend = true;
      // setTimeout(() => {
      //   this.prospectSend = false;
      // }, 4500);
    });
  }


  openClosedSurveys(){
    this.surveysFiles = Object.keys(this.selectedOrganization.files);
   }


  openSurveyModal(survey){
    this.survey = survey;
    this.getSurveyArray()
    $("#surveys").modal('show');
    return;
  }

  getSurveyArray(){
    if (this.survey.prompt.length != 0){
      this.survey.prompts = JSON.parse(this.survey.prompt);
    }
    if ( this.survey.questions != undefined){
      this.survey.bubbles = this.survey.questions.split('\n');
    }
  }

  closeModal(){
    this.survey = undefined;
    $("#surveys").modal('hide');
    return;
  }


}
