import { Component, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { SessionDataService } from '../../services/session-data.service';
import { Router } from '@angular/router';
import { environment } from "../../../environments/environment";



@Component({
  selector: 'app-loginform',
  templateUrl: './loginform.component.html',
  styles: []
})
export class LoginformComponent {
  @ViewChild('modalLogin') modalLogin: ElementRef;
  logIn: UntypedFormGroup;
  formValid = false;

  constructor(private http: HttpClient, private auth: AuthService, private router: Router, private sessData: SessionDataService) {

    this.logIn = new UntypedFormGroup({
      "email": new UntypedFormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      "password": new UntypedFormControl('', [Validators.required, Validators.minLength(8)])
    })
  }

  url: string;
  refresh(): any {
    this.url = this.router.url;
    this.router.navigate(['/groups']);
    // if (this.url == '/') {
    //   window.location.reload();
    // }
  }
  postSub;
  LogIn() {
    this.postSub = this.http.post(`${environment.apiUrl}/api/auth/loginAdmin`, { "email": this.logIn.value.email, "password": this.logIn.value.password })
      .subscribe(data => {
        this.formValid = false;
        this.auth.setSession(data);
        this.modalLogin.nativeElement.click();
        setTimeout(this.refresh(), 50);
        // this.sessData.getUserLabs();
      },
        err => {
          this.formValid = true;
          this.logIn.controls['email'].setValue('');
          this.logIn.controls['password'].setValue('');
          this.logIn.controls['email'].markAsPristine();
          this.logIn.controls['password'].markAsPristine();
        });

  }

  ngOnDestroy(): void {
    this.postSub.unsubscribe();
  }
}
