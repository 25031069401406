// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://192.168.0.25:8000',
  // apiUrl: 'http://127.0.0.1:8000',
  apiUrl: 'https://api.altealabs.com',
  // apiUrl: 'https://altealabsback-prepodiccion1.azurewebsites.net',
  // apiUrl: 'https://altealabsback.azurewebsites.net/',
  wsHost: 'publisherapivm.altealabs.com', //websockets


  colaboratorRole: 5,

  // Blob Storage
  accountName: "pecblobs",
  containerName: "organizationimages",
  key: "JYfRhdyXhirRGPyxnI3NHGAYUY8SVZWJOw4lsrs73qQJ2mMl4SFfhuiCOQrJVXTYKLFjmKO2rJi1Wf769KXSCw==",

  jsonPassphrase: 'qC)97@6wu_611,Z'
};

export const roles = {
  student: 1,
  admin: 2,
  teacher: 3,
  tester: 4,
  colaborador: 5,
  dean: 6,
  audioImporter: 7,
  superadmin: 8,
  publisherDean: 9,
  trainee: 10,
  clientAdmin: 13,
  audioVisual: 17
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
