<div>
    <!-- <button class="btn btn-danger" (click)="globalPreview()">
        <i class="fas fa-exchange-alt"></i>
    </button> -->

    <div style="height: 450px;" class="container">
        <!-- Card Preview -->
        <div style="height: 100%;" class="row border pt-2 bg-white" *ngIf="changePreview == 0">
            <div class="card-header row m-0 col-12 px-3 pt-1 pb-2 justify-content-between">
                <h5 class="m-0 col" *ngIf="changeGlobalPreview==0">Essential Card preview</h5>
                <h5 class="m-0 col" *ngIf="changeGlobalPreview==1">Key Card preview</h5>
                <h5 class="m-0 col" *ngIf="changeGlobalPreview==2">Ultimate Card preview</h5>
                <div class="col text-right">
                    <button class="btn btn-danger" (click)="globalPreview()">
                        <i class="fas fa-exchange-alt"></i>
                    </button>
                </div>
            </div>
            <div style="height: calc(100% - 57px);" class="card-preview px-0 row mx-0 col-12 justify-content-between">
                <div style="height: 100%;" class="col-4 border-right py-3 px-3">
                    <div class="card px-0 mx-auto">
                        <img *ngIf="changeGlobalPreview == 0" src="{{ labEss.controls['image'].value }}" class="card-img-top" alt="lab.img" width="100%" />
                        <img *ngIf="changeGlobalPreview == 1" src="{{ labKey.controls['image'].value }}" class="card-img-top" alt="lab.img" width="100%" />
                        <img *ngIf="changeGlobalPreview == 2" src="{{ labUlt.controls['image'].value }}" class="card-img-top" alt="lab.img" width="100%" />
                        <div class="card-body">
                            <h5 class="card-title text-truncate" *ngIf="!Lists.length <= 0">
                                {{ Lists[changeGlobalPreview].name }}</h5>
                            <h5 class="card-title text-truncate" *ngIf="Lists.length <= 0">Loading...</h5>
                            <p *ngIf="changeGlobalPreview == 0" class="card-text text-truncate" [innerHTML]="labEss.controls['description'].value"></p>
                            <p *ngIf="changeGlobalPreview == 1" class="card-text text-truncate" [innerHTML]="labKey.controls['description'].value"></p>
                            <p *ngIf="changeGlobalPreview == 2" class="card-text text-truncate" [innerHTML]="labUlt.controls['description'].value"></p>
                            <div class="row">
                                <div class="stars col-12">
                                    <form>
                                        <p class="clasificacion">
                                            <input id="radio12" type="radio" name="estrellas" value="5" />
                                            <label for="radio12">★</label>
                                            <input id="radio22" type="radio" name="estrellas" value="4" />
                                            <label for="radio22">★</label>
                                            <input id="radio32" type="radio" name="estrellas" value="3" />
                                            <label for="radio32">★</label>
                                            <input id="radio42" type="radio" name="estrellas" value="2" />
                                            <label for="radio42">★</label>
                                            <input id="radio52" type="radio" name="estrellas" value="1" />
                                            <label for="radio52">★</label>
                                        </p>
                                    </form>
                                </div>
                                <div class="text-right col-12">
                                    <p *ngIf="changeGlobalPreview == 0" class="line-through d-inline mr-2">{{ labEss.controls['price'].value }}€</p>
                                    <p *ngIf="changeGlobalPreview == 1" class="line-through d-inline mr-2">{{ labKey.controls['price'].value }}€</p>
                                    <p *ngIf="changeGlobalPreview == 2" class="line-through d-inline mr-2">{{ labUlt.controls['price'].value }}€</p>
                                    <p *ngIf="changeGlobalPreview == 0" class="d-inline" style="font-size:18px;">
                                        {{labEss.controls['offer_price'].value}}€
                                    </p>
                                    <p *ngIf="changeGlobalPreview == 1" class="d-inline" style="font-size:18px;">
                                        {{labKey.controls['offer_price'].value}}€
                                    </p>
                                    <p *ngIf="changeGlobalPreview == 2" class="d-inline" style="font-size:18px;">
                                        {{labUlt.controls['offer_price'].value}}€
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 100%;" class="col-4  border-right text-center py-3 px-3">
                    <div>
                        <h5 class="m-0 mb-3" *ngIf="changeGlobalPreview==0">Essential Lab Image</h5>
                        <h5 class="m-0 mb-3" *ngIf="changeGlobalPreview==1">Key Lab Image</h5>
                        <h5 class="m-0 mb-3" *ngIf="changeGlobalPreview==2">Ultimate Lab Image</h5>
                        <img *ngIf="changeGlobalPreview==0 && labEss.controls['image'].value != ''" [src]="labEss.controls['image'].value" width="100%" />
                        <img *ngIf="changeGlobalPreview==1 && labKey.controls['image'].value != ''" [src]="labKey.controls['image'].value" width="100%" />
                        <img *ngIf="changeGlobalPreview==2 && labUlt.controls['image'].value != ''" [src]="labUlt.controls['image'].value" width="100%" />
                        <div class="custom-file mt-3">
                            <input id="file" class="custom-file-input" type="file" (change)="fileChangeEvent($event,changeGlobalPreview)" formControlName="image" accept=".png, .pdf, .jpg, .jpeg" />
                            <label for="file" class="custom-file-label text-left">Choose file</label>
                        </div>
                    </div>
                </div>
                <div style="height: 100%;" class="col-4 cropper px-0 text-center">
                    <image-cropper style="height: calc(100% - 61px);" [imageChangedEvent]="imageChangedEvent[changeGlobalPreview]" [maintainAspectRatio]="true" [aspectRatio]="16 / 9" format="png jpg" (imageCropped)="imageCropped($event,changeGlobalPreview)">
                    </image-cropper>
                    <hr class="border-white mt-0" />
                    <div class="text-right px-3 py-2">
                        <button (click)="changeImage(changeGlobalPreview)" [disabled]="!croppedImage[changeGlobalPreview]" type="button" class="btn btn-danger">
                            Crop
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Flyer Preview -->
        <div class="row border pt-2 bg-white" *ngIf="changePreview == 1">
            <div class="card-header row m-0 col-12 px-3 pt-1 pb-2">
                <h5 class="m-0 col" *ngIf="changeGlobalPreview==0">Essential Flyer preview</h5>
                <h5 class="m-0 col" *ngIf="changeGlobalPreview==1">Key Flyer preview</h5>
                <h5 class="m-0 col" *ngIf="changeGlobalPreview==2">Ultimate Flyer preview</h5>
                <div class="col text-right">
                    <button class="btn btn-danger" (click)="preview()">
                        <i class="fas fa-exchange-alt"></i>
                    </button>
                </div>
            </div>
            <div class="row container bg-dark justify-content-between pl-5 mx-0">
                <div class="col-7 p-5 ml-5">
                    <div class="bg-danger p-2 my-2">{{ Lists[changeGlobalPreview] }}</div>
                    <div class="text-light p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let key of keyLine[changeGlobalPreview]">{{ key }}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-4 p-5">
                    <div class="col-9 bg-light position-absolute align-self-end border">
                        <img *ngIf="changeGlobalPreview == 0" src="{{ labEss.controls['image'].value }}" alt="lab.img" class="my-3" width="100%" />
                        <img *ngIf="changeGlobalPreview == 1" src="{{ labKey.controls['image'].value }}" alt="lab.img" class="my-3" width="100%" />
                        <img *ngIf="changeGlobalPreview == 2" src="{{ labUlt.controls['image'].value }}" alt="lab.img" class="my-3" width="100%" />
                        <div class="level text-center my-3">{{sublist[changeGlobalPreview]}}</div>
                        <button class="btn btn-md btn-danger d-block mx-auto my-3" disabled style="width:80%;">
                            Buy
                        </button>
                        <button class="btn btn-md btn-outline-danger d-block mx-auto my-3" disabled style="width:80%;">
                            Cart
                        </button>
                        <div class="row m-0">
                            <ul class="col-12 text-center" style="list-style: none;">
                                <li>lab[changeGlobalPreview].words</li>
                                <li>lab[changeGlobalPreview].NAVAC</li>
                                <li *ngIf="changeGlobalPreview == 0" class="line-through">{{ labEss.controls['price'].value }}€</li>
                                <li *ngIf="changeGlobalPreview == 1" class="line-through">{{ labKey.controls['price'].value }}€</li>
                                <li *ngIf="changeGlobalPreview == 2" class="line-through">{{ labUlt.controls['price'].value }}€</li>
                                <li *ngIf="changeGlobalPreview == 0" style="font-size:18px;">{{ labEss.controls['offer_price'].value }}€</li>
                                <li *ngIf="changeGlobalPreview == 1" style="font-size:18px;">{{ labKey.controls['offer_price'].value }}€</li>
                                <li *ngIf="changeGlobalPreview == 2" style="font-size:18px;">{{ labUlt.controls['offer_price'].value }}€</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row container m-0 pl-5">
                <div class="col-7 p-5 ml-5">
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==0">Essential To whom</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==1">Key To whom</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==2">Ultimate To whom</p>
                    <div class="bg-gray border p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let who of Towho[changeGlobalPreview]">{{ who }}</li>
                        </ul>
                    </div>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==0">Essential Objetives</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==1">Key Objetives</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==2">Ultimate Objetives</p>
                    <div class="bg-gray border p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let obj of Objectives[changeGlobalPreview]">{{ obj }}</li>
                        </ul>
                    </div>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==0">Essential Description</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==1">Key Description</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==2">Ultimate Description</p>
                    <div class="bg-gray border p-2 mt-2 mb-4" *ngIf="changeGlobalPreview==0" [innerHTML]="labEss.controls['description'].value"></div>
                    <div class="bg-gray border p-2 mt-2 mb-4" *ngIf="changeGlobalPreview==1" [innerHTML]="labKey.controls['description'].value"></div>
                    <div class="bg-gray border p-2 mt-2 mb-4" *ngIf="changeGlobalPreview==2" [innerHTML]="labUlt.controls['description'].value"></div>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==0">Essential Requisites</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==1">Key Requisites</p>
                    <p class="px-2" style="font-size:24px;" *ngIf="changeGlobalPreview==2">Ultimate Requisites</p>
                    <div class="bg-gray border p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let reqs of Requirements[changeGlobalPreview]">{{ reqs }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Lab Preview -->
        <div class="row border pt-2 bg-white" *ngIf="changePreview == 2">
            <div class="card-header row m-0 col-12 px-3 pt-1 pb-2">
                <h5 class="m-0 col">Lab landing preview</h5>
                <div class="col text-right">
                    <button class="btn btn-danger" (click)="preview()">
                        <i class="fas fa-exchange-alt"></i>
                    </button>
                </div>
            </div>
            <div class="row container m-0 justify-content-between">
                <div class="row container m-0 p-5 ">
                    <div class="col-5">
                        <img *ngIf="changeGlobalPreview == 0" src="{{ labEss.controls['image'].value }}" alt="lab image" width="100%" />
                        <img *ngIf="changeGlobalPreview == 1" src="{{ labKey.controls['image'].value }}" alt="lab image" width="100%" />
                        <img *ngIf="changeGlobalPreview == 2" src="{{ labUlt.controls['image'].value }}" alt="lab image" width="100%" />
                    </div>
                    <div class="col-7">
                        <div class="bg-gray border p-2 my-3">{{ Lists[changeGlobalPreview].name }}</div>
                        <ul class=" my-3">
                            <li *ngFor="let key of keyLine[changeGlobalPreview]">{{ key }}</li>
                        </ul>
                        <button class="btn btn-danger d-block w-80 mx-auto my-3" disabled>
                            Enter lab
                        </button>
                        <div>lab.rating</div>
                    </div>
                    <div class="col-12">
                        <div class="mt-5 row">
                            <ul class="col-6" style="list-style: none;">
                                <li class="text-center list-group-item" style="font-weight:900;">
                                    Objectives
                                </li>
                                <li class="list-group-item text-center" *ngFor="let obj of Objectives[changeGlobalPreview]">
                                    {{ obj }}
                                </li>
                            </ul>
                            <ul class="col-6" style="list-style: none;">
                                <li class="text-center list-group-item" style="font-weight:900;">
                                    To whom
                                </li>
                                <li class="list-group-item text-center" *ngFor="let who of Towho[changeGlobalPreview]">
                                    {{ who }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 bg-gray border mt-5" style="max-width: 100%; min-width: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row border pt-2 bg-white">
            <div class="card-header col-12 px-3 pt-1 pb-2">
                <h5 class="m-0">Lab editor</h5>
            </div>
            <div class="col-12 pt-4 row mx-0 ">
                <form class="w-100" *ngIf="changeGlobalPreview == 0" [formGroup]="labEss">
                    <div class="row w-100 no-gutters">
                        <div class="col-6 d-flex mx-0">
                            <div class="form-group mb-2 col-6 pl-0">
                                <h6>Lab Title</h6>
                                <input type="text" name="title" id="title" formControlName="labtitle" class="form-control" placeholder="Title" aria-describedby="helpId" placeholder="Loading..." readonly/>
                            </div>
                            <div class="col-6">
                                <h6 class="pl-2">Selected List</h6>
                                <div class="form-group pl-2 col-12 pr-0 mb-0" (clickOutside)="onClickedOutside()">
                                    <input (click)="focused = true" class="form-control" type="text" formControlName="list" (keyup)="searchList(0)" (keyup.escape)="focused = false">
                                </div>
                                <div *ngIf="labEss.controls['list'].value!='' && focused" class="bg-gray position-absolute listName ml-3">
                                    <p style="cursor: pointer;" class="mb-0 optionsLab p-2" *ngFor="let list of listsName" (click)="setInput(list.name); selectList(list.id); focused = false">
                                        {{list.name}}</p>
                                </div>
                            </div>
                            <!-- <div class="form-group d-flex justify-content-between col-6">
                                    <div class="my-auto" style="height:32px">
                                        <i *ngIf="Lists.length <= 0"
                                            class="fas text-white fa-spin fa-sync-alt bg-primary rounded-circle p-2"></i>
                                        <i *ngIf="!Lists.length <= 0"
                                            class="fas fa-check bg-success text-white rounded-circle p-2"></i>
                                    </div>
                                    <p *ngIf="Lists.length <= 0" class="form-control col-11 mb-0" readonly>
                                        Loading list...
                                    </p>
                                </div> -->
                        </div>


                        <div class="col-6 d-flex justify-content-between mx-0">
                            <div class="form-group col-4">
                                <h6>Price</h6>
                                <input type="number" name="title" id="title" class="form-control" formControlName="price" placeholder="Price" aria-describedby="helpId" [ngClass]="{ 'border-danger': !labEss.controls['price'].pristine && labEss.controls['price'].value == '' }" />
                            </div>
                            <div class="form-group col-4">
                                <h6>Offer price</h6>
                                <input type="number" name="title" id="title" class="form-control" formControlName="offer_price" placeholder="Offer Price" aria-describedby="helpId" [ngClass]="{ 'border-danger': !labEss.controls['offer_price'].pristine && labEss.controls['offer_price'].value == '' }"
                                />
                            </div>
                            <div class="form-group col-4">
                                <div  style="cursor: pointer; background-color: white;" (click)="stackBool = !stackBool"><h6>Stacks
                                </h6><i class="fas fa-caret-down"></i></div>
                                <div *ngIf="stackBool" style="z-index: 10000;" class="dropdown-menu d-block">
                                    <div *ngFor="let stack of stacks; let i=index" class="custom-control ml-1 my-1 custom-checkbox">
                                        <input [checked]="stacksSelectedEssential[i]" (click)="selectStack(i,0)" class="custom-control-input" type="checkbox" id="{{stack}}">
                                        <label class="custom-control-label my-auto" for="{{stack}}">{{stack}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row w-100 no-gutters">
                        <div class="col-6">
                            <quill-editor #editor0 formControlName="description" [placeholder]="placeholder" [style]="{ height: '200px' }">
                            </quill-editor>
                        </div>
                        <div class="col-6">
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Key Lines</label>
                                    <input type="text" (keydown.enter)="prevent($event);addKeyline(0)" class="form-control" formControlName="inputkeylines" placeholder="" [ngClass]="{ 'border-danger': !labEss.controls['inputkeylines'].pristine && labEss.controls['inputkeylines'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let key of keyLine[changeGlobalPreview]">{{ key }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labEss.controls['inputkeylines'].value == '' }">
                                    <button [disabled]="labEss.controls['inputkeylines'].value == ''" class="btn btn-default" (click)="addKeyline(0)">
                                            <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                        </button>
                                </div>
                            </div>
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Objectives</label>
                                    <input type="text" (keydown.enter)="prevent($event);addObjectives(0)" class="form-control" formControlName="inputobjectives" placeholder="" #objective [ngClass]="{ 'border-danger': !labEss.controls['inputobjectives'].pristine && labEss.controls['inputobjectives'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let obj of Objectives[changeGlobalPreview]">{{ obj }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labEss.controls['inputobjectives'].value == '' }">
                                    <button [disabled]="labEss.controls['inputobjectives'].value == ''" class="btn btn-default" (click)="addObjectives(0)">
                                            <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                        </button>
                                </div>
                            </div>
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Requirements</label>
                                    <input type="text" (keydown.enter)="prevent($event);addRequirements(0)" class="form-control" formControlName="inputrequirements" placeholder="" [ngClass]="{ 'border-danger': !labEss.controls['inputrequirements'].pristine && labEss.controls['inputrequirements'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let reqs of Requirements[changeGlobalPreview]">{{ reqs }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labEss.controls['inputrequirements'].value == '' }">
                                    <button [disabled]="labEss.controls['inputrequirements'].value == ''" class="btn btn-default" (click)="addRequirements(0)">
                                            <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                        </button>
                                </div>
                            </div>
                            <!-- <div class="row m-0 align-items-center">
                                    <div class="form-group col-11">
                                        <label for="">To who</label>
                                        <input type="text" class="form-control" name="" id="" aria-describedby="helpId"
                                            placeholder="" #who (keyup.enter)="addTowho(who.value,changeGlobalPreview)"
                                            [ngClass]="{ 'border-danger': Towho[0].length < 1 }" />
                                        <span class="badge badge-pill badge-accent mr-2"
                                            *ngFor="let who of Towho[changeGlobalPreview]">{{ who }}</span>
                                    </div>
                                    <div class="mt-6 col-1" [ngClass]="{ a01: !who.value }">
                                        <button [disabled]="!who.value" class="btn btn-default"
                                            (click)="addTowho(who.value,changeGlobalPreview)">
                                            <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                        </button>
                                    </div>
                                </div> -->
                        </div>
                    </div>

                </form>
                <form class="w-100" *ngIf="changeGlobalPreview == 1" [formGroup]="labKey">
                    <div class="row w-100 no-gutters">
                        <div class="col-6 d-flex mx-0">
                            <div class="form-group mb-2 col-6 pl-0">
                                <h6>Lab Title</h6>
                                <input type="text" name="title" id="title" formControlName="labtitle" class="form-control" placeholder="Title" aria-describedby="helpId" placeholder="Loading..." readonly />
                            </div>
                            <div class="col-6">
                                <h6 class="pl-2">Selected List</h6>
                                <div class="form-group pl-2 col-12 pr-0 mb-0" (clickOutside)="onClickedOutside()">
                                    <input (click)="focused = true" class="form-control" type="text" formControlName="list" (keyup)="searchList(1)" (keyup.escape)="focused = false">
                                </div>
                                <div *ngIf="labKey.controls['list'].value!='' && focused" class="bg-gray position-absolute listName ml-3">
                                    <p style="cursor: pointer;" class="mb-0 optionsLab p-2" *ngFor="let list of listsName" (click)="setInput(list.name); selectList(list.id); focused = false">
                                        {{list.name}}</p>
                                </div>
                            </div>
                            <!-- <div class="form-group d-flex justify-content-between col-6">
                            <div class="my-auto" style="height:32px">
                                <i *ngIf="Lists.length <= 0"
                                    class="fas text-white fa-spin fa-sync-alt bg-primary rounded-circle p-2"></i>
                                <i *ngIf="!Lists.length <= 0"
                                    class="fas fa-check bg-success text-white rounded-circle p-2"></i>
                            </div>
                            <p *ngIf="Lists.length <= 0" class="form-control col-11 mb-0" readonly>
                                Loading list...
                            </p>
                        </div> -->
                        </div>
                        <div class="col-6 d-flex justify-content-between mx-0">
                            <div class="form-group col-4">
                                <h6>Price</h6>
                                <input type="number" name="title" id="title" class="form-control" formControlName="price" placeholder="Price" aria-describedby="helpId" [ngClass]="{ 'border-danger': !labKey.controls['price'].pristine && labKey.controls['price'].value == '' }" />
                            </div>
                            <div class="form-group col-4">
                                <h6>Offer price</h6>
                                <input type="number" name="title" id="title" class="form-control" formControlName="offer_price" placeholder="Offer Price" aria-describedby="helpId" [ngClass]="{ 'border-danger': !labKey.controls['offer_price'].pristine && labKey.controls['offer_price'].value == '' }"
                                />
                            </div>
                            <div class="form-group col-4">
                                    <div  style="cursor: pointer; background-color: white;" (click)="stackBool = !stackBool"><h6>Stacks
                                    </h6><i class="fas fa-caret-down"></i></div>
                                    <div *ngIf="stackBool" style="z-index: 10000;" class="dropdown-menu d-block">
                                        <div *ngFor="let stack of stacks; let i=index" class="custom-control ml-1 my-1 custom-checkbox">
                                            <input [checked]="stacksSelectedKey[i]" (click)="selectStack(i,1)" class="custom-control-input" type="checkbox" id="{{stack}}">
                                            <label class="custom-control-label my-auto" for="{{stack}}">{{stack}}</label>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 no-gutters">
                        <div class="col-6">
                            <quill-editor #editor1 formControlName="description" [placeholder]="placeholder" [style]="{ height: '200px' }">
                            </quill-editor>
                        </div>
                        <div class="col-6">
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Key Lines</label>
                                    <input type="text" (keydown.enter)="prevent($event); addKeyline(1)" class="form-control" formControlName="inputkeylines" placeholder="" [ngClass]="{ 'border-danger': !labKey.controls['inputkeylines'].pristine && labKey.controls['inputkeylines'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let key of keyLine[changeGlobalPreview]">{{ key }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labKey.controls['inputkeylines'].value == '' }">
                                    <button [disabled]="labKey.controls['inputkeylines'].value == ''" class="btn btn-default" (click)="addKeyline(1)">
                                    <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                </button>
                                </div>
                            </div>
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Objectives</label>
                                    <input type="text" (keydown.enter)="prevent($event); addObjectives(1)" class="form-control" formControlName="inputobjectives" placeholder="" #objective [ngClass]="{ 'border-danger': !labKey.controls['inputobjectives'].pristine && labKey.controls['inputobjectives'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let obj of Objectives[changeGlobalPreview]">{{ obj }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labKey.controls['inputobjectives'].value == '' }">
                                    <button [disabled]="labKey.controls['inputobjectives'].value == ''" class="btn btn-default" (click)="addObjectives(1)">
                                    <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                </button>
                                </div>
                            </div>
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Requirements</label>
                                    <input type="text" (keydown.enter)="prevent($event); addRequirements(1)" class="form-control" formControlName="inputrequirements" placeholder="" [ngClass]="{ 'border-danger': !labKey.controls['inputrequirements'].pristine && labKey.controls['inputrequirements'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let reqs of Requirements[changeGlobalPreview]">{{ reqs }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labKey.controls['inputrequirements'].value == '' }">
                                    <button [disabled]="labKey.controls['inputrequirements'].value == ''" class="btn btn-default" (click)="addRequirements(1)">
                                    <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                </button>
                                </div>
                            </div>
                            <!-- <div class="row m-0 align-items-center">
                            <div class="form-group col-11">
                                <label for="">To who</label>
                                <input type="text" class="form-control" name="" id="" aria-describedby="helpId"
                                    placeholder="" #who (keyup.enter)="addTowho(who.value,changeGlobalPreview)"
                                    [ngClass]="{ 'border-danger': Towho[0].length < 1 }" />
                                <span class="badge badge-pill badge-accent mr-2"
                                    *ngFor="let who of Towho[changeGlobalPreview]">{{ who }}</span>
                            </div>
                            <div class="mt-6 col-1" [ngClass]="{ a01: !who.value }">
                                <button [disabled]="!who.value" class="btn btn-default"
                                    (click)="addTowho(who.value,changeGlobalPreview)">
                                    <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                </button>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </form>
                <form class="w-100" *ngIf="changeGlobalPreview == 2" [formGroup]="labUlt">
                    <div class="row w-100 no-gutters">
                        <div class="col-6 d-flex mx-0">
                            <div class="form-group mb-2 col-6 pl-0">
                                <h6>Lab Title</h6>
                                <input type="text" name="title" id="title" formControlName="labtitle" class="form-control" placeholder="Title" aria-describedby="helpId" placeholder="Loading..." readonly />
                            </div>
                            <div class="col-6">
                                <h6 class="pl-2">Selected List</h6>
                                <div class="form-group pl-2 col-12 pr-0 mb-0" (clickOutside)="onClickedOutside()">
                                    <input (click)="focused = true" class="form-control" type="text" formControlName="list" (keyup)="searchList(2)" (keyup.escape)="focused = false">
                                </div>
                                <div *ngIf="labUlt.controls['list'].value!='' && focused" class="bg-gray position-absolute listName ml-3">
                                    <p style="cursor: pointer;" class="mb-0 optionsLab p-2" *ngFor="let list of listsName" (click)="setInput(list.name); selectList(list.id); focused = false">
                                        {{list.name}}</p>
                                </div>
                            </div>
                            <!-- <div class="form-group d-flex justify-content-between col-6">
                                <div class="my-auto" style="height:32px">
                                    <i *ngIf="Lists.length <= 0"
                                        class="fas text-white fa-spin fa-sync-alt bg-primary rounded-circle p-2"></i>
                                    <i *ngIf="!Lists.length <= 0"
                                        class="fas fa-check bg-success text-white rounded-circle p-2"></i>
                                </div>
                                <p *ngIf="Lists.length <= 0" class="form-control col-11 mb-0" readonly>
                                    Loading list...
                                </p>
                            </div> -->
                        </div>
                        <div class="col-6 d-flex justify-content-between mx-0">
                            <div class="form-group col-4">
                                <h6>Price</h6>
                                <input type="number" name="title" id="title" class="form-control" formControlName="price" placeholder="Price" aria-describedby="helpId" [ngClass]="{ 'border-danger': !labUlt.controls['price'].pristine && labUlt.controls['price'].value == '' }" />
                            </div>
                            <div class="form-group col-4">
                                <h6>Offer price</h6>
                                <input type="number" name="title" id="title" class="form-control" formControlName="offer_price" placeholder="Offer Price" aria-describedby="helpId" [ngClass]="{ 'border-danger': !labUlt.controls['offer_price'].pristine && labUlt.controls['offer_price'].value == '' }"
                                />
                            </div>
                            <div class="form-group col-4">
                                    <div  style="cursor: pointer; background-color: white;" (click)="stackBool = !stackBool"><h6>Stacks
                                    </h6><i class="fas fa-caret-down"></i></div>
                                    <div *ngIf="stackBool" style="z-index: 10000;" class="dropdown-menu d-block">
                                        <div *ngFor="let stack of stacks; let i=index" class="custom-control ml-1 my-1 custom-checkbox">
                                            <input [checked]="stacksSelectedUltimate[i]" (click)="selectStack(i,2)" class="custom-control-input" type="checkbox" id="{{stack}}">
                                            <label class="custom-control-label my-auto" for="{{stack}}">{{stack}}</label>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="row w-100 no-gutters">
                        <div class="col-6">
                            <quill-editor #editor2 formControlName="description" [placeholder]="placeholder" [style]="{ height: '200px' }">
                            </quill-editor>
                        </div>
                        <div class="col-6">
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Key Lines</label>
                                    <input type="text" (keydown.enter)="prevent($event); addKeyline(2);" class="form-control" formControlName="inputkeylines" placeholder="" [ngClass]="{ 'border-danger': !labUlt.controls['inputkeylines'].pristine && labUlt.controls['inputkeylines'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let key of keyLine[changeGlobalPreview]">{{ key }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labUlt.controls['inputkeylines'].value == '' }">
                                    <button [disabled]="labUlt.controls['inputkeylines'].value == ''" class="btn btn-default" (click)="addKeyline(2)">
                                        <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Objectives</label>
                                    <input type="text" (keydown.enter)="prevent($event); addObjectives(2);" class="form-control" formControlName="inputobjectives" placeholder="" #objective [ngClass]="{ 'border-danger': !labUlt.controls['inputobjectives'].pristine && labUlt.controls['inputobjectives'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let obj of Objectives[changeGlobalPreview]">{{ obj }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labUlt.controls['inputobjectives'].value == '' }">
                                    <button [disabled]="labUlt.controls['inputobjectives'].value == ''" class="btn btn-default" (click)="addObjectives(2)">
                                        <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">Requirements</label>
                                    <input type="text" (keydown.enter)="prevent($event); addRequirements(2);" class="form-control" formControlName="inputrequirements" placeholder="" [ngClass]="{ 'border-danger': !labUlt.controls['inputrequirements'].pristine && labUlt.controls['inputrequirements'].value == '' }"
                                    />
                                    <span class="badge badge-pill badge-accent mr-2" *ngFor="let reqs of Requirements[changeGlobalPreview]">{{ reqs }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: labUlt.controls['inputrequirements'].value == '' }">
                                    <button [disabled]="labUlt.controls['inputrequirements'].value == ''" class="btn btn-default" (click)="addRequirements(2)">
                                        <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- <div class="row m-0 align-items-center">
                                <div class="form-group col-11">
                                    <label for="">To who</label>
                                    <input type="text" class="form-control" name="" id="" aria-describedby="helpId"
                                        placeholder="" #who (keyup.enter)="addTowho(who.value,changeGlobalPreview)"
                                        [ngClass]="{ 'border-danger': Towho[0].length < 1 }" />
                                    <span class="badge badge-pill badge-accent mr-2"
                                        *ngFor="let who of Towho[changeGlobalPreview]">{{ who }}</span>
                                </div>
                                <div class="mt-6 col-1" [ngClass]="{ a01: !who.value }">
                                    <button [disabled]="!who.value" class="btn btn-default"
                                        (click)="addTowho(who.value,changeGlobalPreview)">
                                        <i class="fas fa-plus-circle text-danger" style="font-size:30px;"></i>
                                    </button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </form>
            </div>
            <hr style="width:100%;" />
            <div class="row w-100 justify-content-end mb-3">
                <button class="btn btn-light mx-2" (click)="cancelConfirm()">
                    Cancel
                </button>
                <!-- Hay que rehacer las validaciones -->
                <button *ngIf="labNum.labNum==3"  class="btn btn-danger mx-2" (click)="saveLab()">
                    Save
                </button>
                <button *ngIf="labNum.labNum==2 && ((labNum.levelArray[0] == 1 && labNum.levelArray[1] == 2) || (labNum.levelArray[1] == 1 && labNum.levelArray[0] == 2))" [disabled]="labEss.invalid || labKey.invalid" class="btn btn-danger mx-2" (click)="saveLab()">
                    Save
                </button>
                <button *ngIf="labNum.labNum==2 && ((labNum.levelArray[0] == 1 && labNum.levelArray[1] == 3) || (labNum.levelArray[1] == 3 && labNum.levelArray[0] == 1))" [disabled]="labEss.invalid || labUlt.invalid" class="btn btn-danger mx-2" (click)="saveLab()">
                    Save
                </button>
                <button *ngIf="labNum.labNum==2 && ((labNum.levelArray[0] == 2 && labNum.levelArray[1] == 3) || (labNum.levelArray[1] == 2 && labNum.levelArray[0] == 3))" [disabled]="labKey.invalid || labUlt.invalid" class="btn btn-danger mx-2" (click)="saveLab()">
                    Save
                </button>
                <button *ngIf="labNum.labNum==1 && labNum.levelArray[0] == 1" class="btn btn-danger mx-2" (click)="saveLab()">
                    Save
                </button>
                <button *ngIf="labNum.labNum==1 && labNum.levelArray[0] == 2" [disabled]="labKey.invalid" class="btn btn-danger mx-2" (click)="saveLab()">
                    Save
                </button>
                <button *ngIf="labNum.labNum==1 && labNum.levelArray[0] == 3" [disabled]="labUlt.invalid" class="btn btn-danger mx-2" (click)="saveLab()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>