import { Component, Input, OnChanges } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Profile, userCandidates } from "../new-group/new-group.component";
import { PerformanceArea, userSet } from "../group-admin/group-admin.component";
import { LmsService } from 'src/app/services/lms.service';
import { AuthService } from 'src/app/services/auth.service';
import { Options } from "@angular-slider/ngx-slider";
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-candidate-info',
  templateUrl: './candidate-info.component.html',
  styleUrls: ['./candidate-info.component.css']
})
export class CandidateInfoComponent implements OnChanges {

  @Input() user;
  @Input() area;
  @Input() plannerType;
  @Input() prospectProfile;
  @Input() prospectChallenges;

  candidateProfile;

  placementResults;
  studentPerformance;
  studentProgress;
  studentGoals;
  infoStudent;
  prospectPage = 'profile';
  showExamsReport = false;
  value: number = 30;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  examsNames;
  examsReports;
  userInfo;
  selectedExamReport = 'performance';
  indexReport = 0;

  loading: boolean = false;
  prospectSend: boolean = false;


  constructor(public admin: AdminService, public lms: LmsService, public auth: AuthService) { }

  ngOnChanges(changes) {
    if (changes.user.currentValue instanceof userSet) {
      if (this.plannerType == 'group') {
        this.getStudentPerformance();
        this.infoStudent = 'goals';
      } else if (this.plannerType == 'course') {
        this.infoStudent = 'activity';
      }
      this.getStudentProgress();
      this.getStudentExamsReports()
      this.getCandidateProfile(changes.user.currentValue);
    } else if (changes.user.currentValue instanceof userCandidates) {
         this.getCandidateProfile(changes.user.currentValue);
    } else if (changes.user.currentValue == undefined){
      this.studentPerformance = new Array();
      this.studentGoals = new Profile();
      this.studentProgress = new Profile();
      this.examsNames = undefined;
      this.examsReports = undefined
      this.userInfo = undefined
      this.getCandidateProfile('');
    }
  }


  getStudentPerformance() {
    if (this.lms.placement == false && this.admin.selectedStudent != undefined) {
      this.loading = true;
      this.admin.getStudentPerformance(this.admin.selectedStudent.user_id, this.admin.selectedStudent.group_id, this.admin.selectedStudent.degree_id, this.admin.selectedStudent.campus_id).subscribe((data: any) => {
        let areasPerformance;
        let area;
        this.studentPerformance = new Array();
        areasPerformance = Object.keys(data);
        let resourcesAux = {
          name: 'Resources',
          types: new Array()
        }
        let skillsAux = {
          name: 'Skills',
          types: new Array()
        }
        for (let index = 0; index < Object.keys(data).length; index++) {
          area = areasPerformance[index];
          let areaAux = new PerformanceArea();
          areaAux.name = area;
          if (area == 'Writing' || area == 'Speaking'){
            areaAux.certified = Number(data[area].certified).toFixed(0);
          } else{
            areaAux.certified = Number(data[area].certified_course).toFixed(0);
          }
          areaAux.done = data[area].done;
          areaAux.assigned = data[area].assigned;
          // areaAux.total = 100 - (data[area].certified + data[area].done + data[area].assigned) + '%';
          areaAux.total = 100 - (data[area].certified_course + data[area].done + data[area].assigned) + '%';
          areaAux.has_review = data[area].has_review;
         
          if (area == 'Vocabulary' || area == 'Grammar Index' || area == 'Use Of English' || area == 'Use Of English' || area == 'Grammar' ||  area == 'Terminology'){
            resourcesAux.types.push(areaAux)
          } else{
            skillsAux.types.push(areaAux)
          }
        }
        this.studentPerformance.push(resourcesAux);
        this.studentPerformance.push(skillsAux);
      });
    }
  }

  setWritingSpeakingPerformance(area) {
    let writing_value;
    let speaking_value

    this.studentPerformance[1].types.forEach( area =>{
      if (area.name == 'Writing'){
          writing_value = area.certified;
        } else if ('Speaking'){
          speaking_value = area.certified;
        }
    });
    this.admin.setWritingSpeakingPerformance(this.admin.selectedStudent.user_id, this.admin.selectedStudent.degree_id, writing_value, speaking_value).subscribe((data: any) => {
 
    });
 
  }




  getStudentProgress() {
    if (this.admin.selectedStudent.group_user_id != undefined) {
      this.admin.getStudentProgress(this.admin.selectedStudent.group_user_id, this.admin.selectedStudent.campus_id).subscribe((data: any) => {
        for(let i = 0; i < data.length; i++){
          if (i == 0){ // Progreso del alumno
            this.studentProgress = new Profile();
            let arrayResources = Object.keys(data[0].resources);
            let arraySkills = Object.keys(data[0].skills);
      
            arrayResources.forEach(resource => {
              let profileAuxResources = {
                name: resource,
                value: data[0].resources[resource].toFixed(0),
                percentage: String(data[0].resources[resource]) + '%',
                restPercentage: String(100 - data[0].resources[resource]) + '%'
              }
              this.studentProgress.resources.push(profileAuxResources);
            });
      
            arraySkills.forEach(skill => {
              let profileAuxResources = {
                name: skill,
                value: data[0].skills[skill].toFixed(0),
                percentage: String(data[0].skills[skill]) + '%',
                restPercentage: String(100 - data[0].skills[skill]) + '%'
              }
              this.studentProgress.skills.push(profileAuxResources);
            });
  
          } else if (i == 1 && Object.keys(data[1]).length > 0){ // Nuevo progreso con encuesta de objetivos, puede venir vacío en cursos TESLA
            this.studentGoals = new Profile();
            let professionalExAux;
            let arrayResources = Object.keys(data[1].resources);
            let arraySkills = Object.keys(data[1].skills);
            if (this.admin.selectedStudent.campus_id != 1){
              professionalExAux = Object.keys(data[1].professional_expertise);
            }
      
            arrayResources.forEach(resource => {
              let profileAuxResources = {
                name: resource,
                startValue: data[1].resources[resource].start,
                targetValue: data[1].resources[resource].target,
                current: data[1].resources[resource].current,
                current_by_course: Number(data[1].resources[resource].current_by_course),
                num_reviews_certified: data[1].resources[resource].num_reviews_certified,
                part: data[1].resources[resource].part,
                // studentProgress:  Number((data[1].resources[resource].num_reviews_certified * ((data[1].resources[resource].target -  data[1].resources[resource].start)*data[1].resources[resource].part)).toFixed(0))
              }
              this.studentGoals.resources.push(profileAuxResources);
            });
  
            arraySkills.forEach(skill => {
              let profileAuxResources = {
                name: skill,
                startValue: data[1].skills[skill].start,
                targetValue: data[1].skills[skill].target,
                current: data[1].skills[skill].current,
                current_by_course: Number(data[1].skills[skill].current_by_course),
                num_reviews_certified: data[1].skills[skill].num_reviews_certified,
                part: data[1].skills[skill].part,
                // studentProgress: Number((data[1].skills[skill].num_reviews_certified * ((data[1].skills[skill].target -  data[1].skills[skill].start) * data[1].skills[skill].part)).toFixed(0))
              }
              this.studentGoals.skills.push(profileAuxResources);
            });
  
            if (this.admin.selectedStudent.campus_id != 1){
              // professionalExAux.forEach(px => {
                let profileAuxResources = {
                  name: 'px',
                  startValue: data[1].professional_expertise.px.start,
                  targetValue: data[1].professional_expertise.px.target,
                  current: data[1].professional_expertise.px.current,
                  current_by_course: Number(data[1].professional_expertise.px.current_by_course),
                  num_reviews_certified: data[1].professional_expertise.px.num_reviews_certified,
                  part: data[1].professional_expertise.px.part,
                  // studentProgress: Number((data[1].professional_expertise.px.num_reviews_certified * ((data[1].professional_expertise.px.target -  data[1].professional_expertise.px.start) * data[1].professional_expertise.px.part)).toFixed(0))
                }
                this.studentGoals.px.push(profileAuxResources);
              // });
            }
  
          }
        }
        this.loading = false;
      });
    } else {
      this.studentPerformance = new Array();
      this.studentGoals = new Profile();
      this.studentProgress = new Profile();
      this.examsNames = undefined;
      this.examsReports = undefined
      this.userInfo = undefined
      this.getCandidateProfile('');
    }
  }


  getStudentExamsReports(){
    this.showExamsReport = false;
    this.admin.getStudentExamsReports(this.admin.selectedStudent.user_id, this.admin.selectedStudent.campus_id).subscribe((data: any) => {
      this.showExamsReport = true;
      this.examsNames = data[0];
      // this.selectedExamReport = this.examsNames[0];
      this.examsReports = data[1].reports
      this.userInfo = data[1].user_info
    });
  }

  getCandidateProfile(user) {
    this.candidateProfile = user;
    this.loading = true;
    if (this.candidateProfile.exam_user_id != undefined){
      this.admin.getCandidateInfo( this.candidateProfile.id, this.candidateProfile.exam_user_id, this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe((data: any) => {
        if (data.average != undefined) {
          this.setPlacementResults(data);
          this.loading = false;
          this.candidateProfile.email = data.email;
        } else{
          this.candidateProfile.email = data.email;
        }
      });
    }
  }

 
  sendToAnalysis(){
    this.admin.sendToAnalysis(this.candidateProfile.id, 'prospect').subscribe((data: any) => {
      this.prospectSend = true;
      setTimeout(() => {
        this.prospectSend = false;
      }, 4500);
    });
  }


  setPlacementResults(data) {
    this.placementResults = new Profile();
    this.placementResults.name = data.name;
    this.placementResults.target_level = data.target_level;
    this.placementResults.user_id = data.user_id;
    this.placementResults.test_date = data.test_date;
    this.placementResults.organization = data.organization;

    let auxActivityProfile = Object.keys(data.activity_profile[0]);

    let profileAuxResources = {
      name: 'Average',
      value: parseFloat(data.average),
      percentage: data.average + '%',
      restPercentage: String(100 - data.average) + '%'
    }


    this.placementResults.average.push(profileAuxResources);
    let arrayResources = Object.keys(data.resource);
    let arraySkills = Object.keys(data.skill);

    arrayResources.forEach((resource, index) => {
      let profileAuxResources = {
        name: resource,
        value: data.resource[resource],
        percentage: String(data.resource[resource]) + '%',
        restPercentage: String(100 - data.resource[resource]) + '%',

      }
      this.placementResults.resources.push(profileAuxResources);
      for (let i = 0; i < auxActivityProfile.length; i++) {
        if (auxActivityProfile[i].indexOf(resource) == 0) {
          if (auxActivityProfile[i].indexOf('start') != -1) {
            this.placementResults.resources[index].start = data.activity_profile[0][auxActivityProfile[i]] + '%';
          } else {
            this.placementResults.resources[index].target = data.activity_profile[0][auxActivityProfile[i]] + '%';
          }
        }
      }
    });

    arraySkills.forEach((skill, index) => {
      let profileAuxResources = {
        name: skill,
        value: data.skill[skill],
        percentage: String(data.skill[skill]) + '%',
        restPercentage: String(100 - data.skill[skill]) + '%'
      }
      this.placementResults.skills.push(profileAuxResources);
      for (let i = 0; i < auxActivityProfile.length; i++) {
        if (auxActivityProfile[i].indexOf(skill) == 0) {
          if (auxActivityProfile[i].indexOf('start') != -1) {
            this.placementResults.skills[index].start = data.activity_profile[0][auxActivityProfile[i]] + '%';
          } else {
            this.placementResults.skills[index].target = data.activity_profile[0][auxActivityProfile[i]] + '%';
          }
        }
      }
    });
  }

  getBoxGrid() {
    if (this.studentPerformance != undefined) {
      return {
        'display': 'grid',
        'grid-template-rows': 'repeat(  ' + this.studentPerformance.length + ' , 1fr)',
        // 'row-gap': '6px',
        'height': '100%'
      }
    }
  }


  showPlacementReport() {
    // this.showExamsReport = true;
    $("#placementReport").modal('show');
    return;
  }
}
