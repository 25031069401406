import { Component, Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class explanationsSet {
  // name = '';
  explanation = '';
  tips = new Array();
  warnings = new Array();
}
@Injectable({
  providedIn: 'root'
})

export class explanationsTypes {
  whatToDo = new Array();
  strategy= new Array();
  value = new Array();
}


@Component({
  selector: 'app-quizzes-importer',
  templateUrl: './quizzes-importer.component.html',
  styleUrls: ['./quizzes-importer.component.css']
})
export class QuizzesImporterComponent implements OnInit {

  statsTab = 'tips';
  explanationName = 'whatToDo';
  explanations = new explanationsTypes();
  boldText: boolean = false;
  explanationTips: boolean = false;
  explanationWarnings: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.getExplanationText();
    
  }


  getExplanationText(){
    let explanationsAux;
    explanationsAux = Object.keys(this.explanations);
    for (let i = 0; i < explanationsAux.length; i++){
      this.explanations[explanationsAux[i]] = new explanationsSet();
      this.explanations[explanationsAux[i]].tips = new Array();
      this.explanations[explanationsAux[i]].tips[0] = ''
      this.explanations[explanationsAux[i]].warnings = new Array();
      this.explanations[explanationsAux[i]].warnings[0] = '';
    }
  }

  setExplanationText(explanation, text, index?){
    if (explanation == 'tips' || explanation == 'warnings' ){
      if ( this.explanations[this.explanationName][explanation][index] == 0){
        this.explanations[this.explanationName][explanation][index] = new Array(); 
        this.explanations[this.explanationName][explanation][index] = text; 
      } else{
        this.explanations[this.explanationName][explanation][index] = text; 
      }
    } else{
      this.explanations[this.explanationName][explanation] = text; 
    }
  }

  addTipsInfo(type){
    if (this.explanations[this.explanationName][type].length < 4){
      let textAux = '';
      this.explanations[this.explanationName][type].push(textAux);;
    }
  }


}
