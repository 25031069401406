<div class="h-100" style="padding: 1% 0%;position: absolute; display: flex; transition-duration: 600ms; transition-timing-function: ease-in-out; color: grey; width: 85%;" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%',  'width': admin.sidebarShow ? '85%' : '96%'}">
    <div style="width: 50%; flex-direction: column; margin-right: 1%;" class="h-100 d-flex">
        <div style="margin: 0 0% 1.5% 0%; height: 41%;" id="groups" class="groupsJ"  [ngStyle]="{'height': plannerType == 'course' ? '100%' : auth.role != 'clientAdmin' ? '41%' : '27%'}">
            <div class="h-100 w-100 bg-white" style="padding: 1.5%;">
                <div class="w-100 d-flex">
                    <div class="title h-100">
                        <span *ngIf="plannerType == 'group'"  [appTooltip]="50">
                            Groups: 
                            <span [appTooltip]="51">
                                {{admin.office_groups}}
                            </span>
                        </span>
                        <span *ngIf="plannerType == 'course'"  [appTooltip]="52">
                            Courses:
                        </span>
                    </div>
                    <select  class="filterSelection d-flex" (change)="filterCourses($event)"  [appTooltip]="53">
                        <option style="color: #6e6e6e;" value="" disabled selected hidden> Courses </option>
                        <option class="option drop_option" value="All" style="color: #6e6e6e;">All</option>
                        <option *ngFor="let course of totalCourses; let i = index"  [value]="course.id" style="color: #6e6e6e;" >
                             {{course.name}}
                        </option>
                    </select>
                    <div class="searchBox d-flex" (click)="searchingGroup = true" (keyup)="searchGroup($event.target.value);"  [appTooltip]="54">                      
                        <i *ngIf="searchingGroup == false" class="fa-solid fa-magnifying-glass my-auto"></i>
                        <input type="text" class="w-100" style="outline: none; border: none;">
                    </div>
                </div>
                <div  style="height: 90%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex"  [appTooltip]="55">
                    <div class="w-100 d-flex">
                        <div style="width: 3%;" class="h-100 title2" *ngIf="auth.role == 'superadmin'">
                        
                        </div>
                        <div style="width: 47%;" class="h-100 title2"  [appTooltip]="56">
                            <span *ngIf="plannerType == 'group'">Group</span>
                            <span *ngIf="plannerType == 'course'">Subcat</span>
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="sortByName('areaGroups', 'group_name', 'groupOrder');"  [appTooltip]="57"></i>
                        </div>
                        <div *ngIf="plannerType == 'group'" style="width: 15%;" class="h-100 title2" [appTooltip]="58">
                            Exam date
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="sortByDate(admin.areaGroups)"  [appTooltip]="59"></i>
                        </div>
                        <div *ngIf="plannerType == 'course'" style="width: 15%;" class="h-100 title2" [appTooltip]="2000">
                            Cat
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;"  [appTooltip]="2001"></i>
                        </div>
                        <div style=" width: 30%;" class="h-100 title2"  [appTooltip]="60">
                            Performance
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" *ngIf="auth.role == 'superadmin'"  (click)="changePerformanceValue();"  [appTooltip]="61"></i>
                        </div>
                    </div>
                    <div class="w-100 d-flex group">
                        <div *ngFor="let group of admin.areaGroups; let i=index" class="h-100 w-100 d-flex" (click)="selectGroup(i);" [ngClass]="{'groupSelected': group.selected == true}"  [appTooltip]="62">
                            <div style="border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 5px; cursor: pointer; border-radius: 50%;" 
                            [ngStyle]="{'background-color': group.active == '1' ? '#6fa84b': '#e94f53' }" (click)="changeStatusGroup(group);" *ngIf="auth.role == 'superadmin' && plannerType == 'group'"  [appTooltip]="63">

                            </div>
                            <div style="width: 47%; cursor: pointer;"  class="groupInfo text-truncate"  [appTooltip]="64">
                                {{group.group_name }}
                            </div>
                            <div style="width: 15%; cursor: pointer;" class="groupInfo"  [appTooltip]="65">
                                {{group.end_date}}
                            </div>
                            <div style="width: 27%; cursor: pointer;" class="groupInfo d-flex"  [appTooltip]="66">
                                <div class="d-flex my-auto" style="background-color: rgb(215 215 215); width: 100%; height: 72%;">
                                    <div  style="min-width: max-content;" class="greenPerformance" [ngStyle]="{'width': group.certified_value}"  [appTooltip]="67"> </div>
                                    <div  style="min-width: max-content;" class="orangePerformance" [ngStyle]="{'width': group.done}"  [appTooltip]="68"></div>
                                    <div  style="min-width: max-content; " class="yellowPerformance" [ngStyle]="{'width': group.assigned}"  [appTooltip]="69"></div>
                                </div>
                            </div>
                            <div style="width: 8%; margin-left: auto" class="d-flex">
                                <div style="font-size: 1.2vh; font-weight: 600; cursor: pointer; margin: 0px 12%" class="edit my-auto" *ngIf="auth.role != 'clientAdmin' && plannerType == 'group'" (click)="admin.goToLMS(group);"  [appTooltip]="70"> LMS </div>
                                <i *ngIf="(auth.role == 'superadmin' || auth.role == 'admin' || auth.role == 'publisherDean' ||  auth.role == 'dean') && plannerType == 'group'" class="fas fa-edit edit my-auto" style="width: 3%; cursor: pointer;" (click)="newGroup(i)"  [appTooltip]="71"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div  *ngIf="auth.role == 'clientAdmin'" style="height: 24%; margin: 0 0% 1.5% 0%;" >
            <div class="h-100 w-100 bg-white" style="padding: 1.2%;">
                <div class="w-100 d-flex">
                    <div class="title h-100" [appTooltip]="72">
                        In House Tests:
                    </div>
                    <div class="buttons d-flex" style="width: 28%; justify-content: end; align-items: center;">
                      <!-- <i class="fa-solid fa-t" style="cursor: pointer;" (click)="admin.switchPlannerView()"></i> -->
                    </div>
                </div>
                <div  style="height: 90%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex">
                    <div class="w-100 d-flex">
                        <div style="width: 55%;" class="h-100 title2"  [appTooltip]="73">
                            Course
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="sortByName('areaGroups', 'group_name', 'groupOrder');"  [appTooltip]="74"></i>
                        </div>
                        <div style="width: 15%;" class="h-100 title2"  [appTooltip]="75">
                            Date
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="sortByDate(admin.areaGroups)"  [appTooltip]="76"></i>
                        </div>
                        <div style="width: 12%;" class="h-100 title2"  [appTooltip]="77">
                            Hour
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="sortByDate(admin.areaGroups)"  [appTooltip]="78"></i>
                        </div>
                        <div style=" width: 14%;" class="h-100 title2" [appTooltip]="79">
                            Test Takers
                        </div>
                    </div>
                    <div class="w-100 d-flex group"  [appTooltip]="80">
                        <div *ngFor="let test of admin.tests; let i=index" class="h-100 w-100 d-flex" (click)="selectTest(i);" [ngClass]="{'groupSelected': test.selected == true}">
                            <div style="width: 55%; cursor: pointer;"  class="groupInfo text-truncate"  [appTooltip]="81">
                                {{test.course_name}}
                            </div>
                            <div style="width: 15%; cursor: pointer;" class="groupInfo"  [appTooltip]="82">
                                {{test.test_date}}
                            </div>
                            <div style="width: 12%; cursor: pointer;" class="groupInfo"  [appTooltip]="83">
                                {{test.test_hour}}
                            </div>
                            <div style="width: 14%; cursor: pointer;" class="groupInfo"  [appTooltip]="84">
                                {{test.test_takers}}
                            </div>
                            <button class="fa-regular fa-copy" style="border: none; background-color: transparent; color: grey; outline: none;" [cdkCopyToClipboard]="copyURL(test)"  [appTooltip]="85"></button>
                            <!-- <button style="border: none; background-color: transparent; color: grey; outline: none; font-size: 1.2vh" [cdkCopyToClipboard]="copyURL(test)"> URL</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="admin.plannerView == true && plannerType == 'group'" style="height: 60%;">
            <div class="h-100 w-100 bg-white" style="padding: 1.5%;">
                <app-planner></app-planner>
            </div>
        </div>
        <div *ngIf="auth.role == 'clientAdmin'" [ngStyle]="{'height': auth.role != 'clientAdmin' ? '58%' : '47%'}">
            <div class="h-100 w-100 bg-white" style="padding: 1.5%;">
                <app-planner></app-planner>
            </div>
        </div>
    </div>
    <div  style="width: 50%;" class="h-100">
        <div id="learners" [ngStyle]="{'height':(testView == false && auth.role == 'clientAdmin') ? '52%' :  auth.role != 'clientAdmin' ? '51%' : '100%', 'padding':(testView == false && auth.role == 'clientAdmin') || auth.role != 'clientAdmin' ? '0 2% 1.5% 1%' : '0 2% 0% 1%'}" [appTooltip]="100">
            <div *ngIf="testView == false" class="h-100 w-100 bg-white" style="padding: 1.5%" [appTooltip]="100">
                <div class="w-100 d-flex"  *ngIf="admin.selectedGroup != undefined" [appTooltip]="101">
                    <div class="title h-100 w-50" [appTooltip]="102">
                     Learners: 
                    <span style="font-size: 1.5vh;" [appTooltip]="103">
                         {{admin.selectedGroup.group_name}}
                    </span>
                    </div>
                    <div *ngIf="plannerType == 'course'" class="containerSearchBox">
                        <div class="searchBoxStudent" (click)="searchingStudent = true" (keyup)="searchUser($event.target.value);" [appTooltip]="2002">                      
                            <i *ngIf="searchingStudent == false" class="fa-solid fa-magnifying-glass my-auto"></i>
                            <input type="text" style="outline: none; border: none;">
                        </div>
                        <div *ngIf="message != undefined" class="message" [appTooltip]="2003">
                            {{ message }}
                        </div>
                    </div>
                    <div *ngIf="plannerType == 'group'" style="width: 40%; text-align: center;" class="h-100 teacherGroup text-truncate" [appTooltip]="104">
                        {{admin.selectedGroup.teachers}}
                    </div>
                    <div style="width: 9%;" class="h-100 user my-auto" *ngIf="auth.role != 'clientAdmin' && plannerType=='group'" [appTooltip]="105">
                        <div style="width: 9%; border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 6px; cursor: pointer; border-radius: 50%;" [ngStyle]="{'background-color': admin.selectedGroup.active == '1' ? '#6fa84b': '#e94f53' }" (click)="changeStatusGroup(admin.selectedGroup);">
                        </div>
                    </div>
                    <div  class="h-100 my-auto" [appTooltip]="106" *ngIf="plannerType=='group'">
                        <img src="https://img.icons8.com/metro/54/B7B7B7/activity-grid-2.png" style="margin: auto; cursor: pointer; transform: scale(0.42);" *ngIf="admin.goalsGroup != undefined" (click)="setStudentsGoals()" >
                    </div>
                </div>
                <div  [ngClass]="{'learnersGroup2': admin.selectedGroup != undefined, 'learnersGroup': admin.selectedGroup == undefined}" [appTooltip]="107">
                    <div class="w-100 d-flex"  *ngIf="lms.placement == false" [appTooltip]="108">
                        <div style="width: 3%"  class="h-100" *ngIf="auth.role == 'superadmin'">

                        </div>
                        <div style="width: 30%;" class="h-100 title2" [appTooltip]="109">
                            User
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="sortByName('areaGroupsUsers', 'name', 'userOrder');" [appTooltip]="110"></i>
                        </div>
                        <div style="width: 40%;" class="h-100 title2" [appTooltip]="111">
                            Group
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="sortByName('areaGroupsUsers', 'group_name', 'groupOrder');" [appTooltip]="112"></i>
                        </div>
                        <div style=" width: 30%;" class="h-100 title2" [appTooltip]="113">
                            Performance
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" *ngIf="auth.role == 'superadmin'" (click)="changePerformanceValue();" [appTooltip]="114"></i>
                        </div>
                    </div>
                    <div class="w-100 d-flex group"  *ngIf="lms.placement == false" [appTooltip]="115">
                        <div *ngFor="let user of admin.areaGroupsUsers; let i=index" (click)="selectStudent(i);"  class="h-100 w-100 d-flex" [ngClass]="{'groupSelected': user.selected == true}" [appTooltip]="116">
                            <div *ngIf="auth.role == 'superadmin'" style="border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 5px; cursor: pointer; border-radius: 50%;"  [appTooltip]="117" [ngStyle]="{'background-color': user.active == '0' ? '#6fa84b': '#e94f53' }" (click)="changeStatusUser(user)">
                            </div>
                            <div style="width: 30%; cursor: pointer;" class="groupInfo text-truncate" [appTooltip]="118">
                                <span [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">
                                    {{user.name | titlecase}} {{user.last_name | titlecase}}
                                </span>
                            </div>
                            <div style="border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 5px; cursor: pointer; border-radius: 50%;" 
                            [ngStyle]="{'background-color': user.soft_deleted == '0' ? '#6fa84b': '#e94f53' }" (click)="changeStatusGroupUser(user);" *ngIf="plannerType == 'course'"  [appTooltip]="2005">

                            </div>
                            <div style="width: 40%; cursor: pointer;" class="groupInfo text-truncate" [appTooltip]="119">
                                {{user.group_name}}
                            </div>
                            <div style="width: 24%; cursor: pointer;" class="groupInfo d-flex" [appTooltip]="120">
                                <div class="d-flex my-auto" style="background-color: rgb(215 215 215); width: 100%; height: 72%;">
                                    <div  style="min-width: max-content;" class="greenPerformance"  [ngStyle]="{'width': user.certified_value}" [appTooltip]="121"></div>
                                    <div  style="min-width: max-content;" class="orangePerformance" [ngStyle]="{'width': user.done}" [appTooltip]="122"></div>
                                    <div  style="min-width: max-content;" class="yellowPerformance" [ngStyle]="{'width': user.assigned}" [appTooltip]="123"></div>
                                </div>
                            </div>
                            <div style="font-size: 1.2vh; font-weight: 600; cursor: pointer;" class="edit my-auto" *ngIf="auth.role != 'clientAdmin' && plannerType == 'group'" (click)="goToLMSUser(user);" [appTooltip]="124"> LMS </div>
                        </div>
                    </div>
                    <div class="w-100 d-flex" *ngIf="lms.placement == true" [appTooltip]="125">
                        <div style="width: 30%;" class="h-100 title2" [appTooltip]="126">
                            User
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="setAlphabetichOrder('areaGroupsUsers', 'user_name');" [appTooltip]="127"></i>
                        </div>
                        <div style="width: 30%;" class="h-100 title2" [appTooltip]="128"> 
                            Target level
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="setAlphabetichOrder('areaGroupsUsers', 'course_name');" [appTooltip]="129"></i>
                        </div>
                        <div style="width: 30%;" class="h-100 title2" [appTooltip]="130">
                            Final level
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" (click)="setAlphabetichOrder('areaGroupsUsers', 'course_name');" [appTooltip]="131"></i>
                        </div>
                        <div style=" width: 20%;" class="h-100 title2" [appTooltip]="132">
                            Results
                        </div>
                    </div>
                    <div class="w-100 d-flex group"  *ngIf="lms.placement == true" [appTooltip]="133">
                        <div *ngFor="let user of admin.areaGroupsUsers; let i=index" class="h-100 w-100 d-flex" (click)="selectStudent(i);" [ngClass]="{'groupSelected': user.selected == true}" >
                            <div style="width: 30%; cursor: pointer;"  class="groupInfo text-truncate" [appTooltip]="135">
                                {{user.name | titlecase}} {{user.last_name | titlecase}}
                            </div>
                            <div style="width: 30%;" class="groupInfo text-truncate" [appTooltip]="136">
                                {{user.target_level}}
                            </div>
                            <div style="width: 30%;" class="groupInfo text-truncate" [appTooltip]="137">
                                {{user.final_level}}
                            </div>
                            <div style="width: 15%;" class="groupInfo" [appTooltip]="138">
                                <div class="d-flex h-100" style="background-color: rgb(215 215 215); width: 100%;">
                                    <div  style="min-width: max-content;" class="greenPerformance"  [ngStyle]="{'width': user.certified_value}" [appTooltip]="139"></div>
                                    <div  style="min-width: max-content;" class="orangePerformance" [ngStyle]="{'width': user.done}" [appTooltip]="140"></div>
                                    <div  style="min-width: max-content;" class="yellowPerformance" [ngStyle]="{'width': user.assigned}" [appTooltip]="141"></div>
                                </div>
                            </div>
                            <div style="width: 5%;" class="groupInfo">
                                <div class="d-flex h-100" style="background-color: rgb(159, 184, 47); width: 100%;" [appTooltip]="142">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="testView == true" class="h-100 w-100 bg-white" style="padding: 1.5%" [appTooltip]="143">
                <div class="w-100 d-flex"> 
                    <div class="title h-100" [appTooltip]="144">
                     Test Takers:
                     </div>
                </div>
                 <div class="w-100 d-flex" style="padding: 0 3%;">
                    <div class="title3 h-100" [appTooltip]="145">
                        {{admin.selectedTest.course_name}}
                     </div>
                </div>
                 <div class="w-100 d-flex" style="padding: 0 3%; margin-top: 1.5%;">
                    <div class="indexButton h-100 disabled" (click)="testTakersIndex = 'positionInfo'" [ngClass]="{'indexButtonSelected': testTakersIndex == 'positionInfo'}" [appTooltip]="146">
                        Position Info
                     </div>
                    <div class="indexButton h-100 disabled"  (click)="testTakersIndex = 'skillTest'" [ngClass]="{'indexButtonSelected': testTakersIndex == 'skillTest'}" [appTooltip]="147">
                        Skill Test
                     </div>
                    <div class="indexButton h-100 "  (click)="testTakersIndex = 'candidates'" [ngClass]="{'indexButtonSelected': testTakersIndex == 'candidates'}" [appTooltip]="148">
                        Candidates
                     </div>
                </div>
                <div class="w-100 d-flex" style="flex-direction: row; margin: 3% 0;padding: 0 3%;" *ngIf="testTakersIndex == 'candidates'" [appTooltip]="149">
                    <div  style="min-width: max-content; background-color:#EDEDED; border-radius: 15px; width: 100%; font-size: 1.5vh; flex-direction: row" class="d-flex">
                        <div class="d-flex my-auto testResults" (click)="testResults = 'all'; changeTestTakersList()" [ngClass]="{'testResultsSelected': testResults == 'all'}" [appTooltip]="150"> All Candidates ({{totalTestTakers.totalStudents}})</div>
                        <div class="d-flex my-auto testResults" (click)="testResults = 'completed'; changeTestTakersList();" [ngClass]="{'testResultsSelected': testResults == 'completed'}" [appTooltip]="151"> Test Completed ({{completedTestTakers.length}}) </div>
                        <div class="d-flex my-auto testResults" (click)="testResults = 'best'; changeTestTakersList()" [ngClass]="{'testResultsSelected': testResults == 'best'}" [appTooltip]="152"> Best Results ({{bestTestTakers.length}})</div>
                    </div>
                </div>
                <div style="height: 81%; overflow-y: scroll; margin: 1.2% 0">
                    <div class="w-100 d-flex group" *ngIf="testTakersIndex == 'candidates'" [appTooltip]="153">
                        <div *ngFor="let user of admin.testTakers; let i=index" (click)="selectTestTakers(i);" class="h-100 w-100 d-flex" style="margin: 1.2% 0; background-color: white;" [ngClass]="{'groupSelected': user.selected == true}">                      
                            <div class="mx-auto d-flex cards" [appTooltip]="154">    
                                <div style="width: 45%; height: 100%; flex-direction: column; padding: 3%;">
                                    <div style="flex-direction: row; height: 57%;  " class="w-100 d-flex" [appTooltip]="155">
                                        <div style="width: 33%; height: 93%; flex-direction: row; background-color: #e3e3e3; ">
                    
                                        </div>
                                        <div class="d-flex" style="width: 81%; flex-direction: column; padding: 3% 6%;" [appTooltip]="156">
                                            <span style="font-size: 1.56vh; font-weight: 600; color: black;" [appTooltip]="157">
                                                {{user.name | titlecase}} {{user.last_name | titlecase}}
                                            </span>
                                            <span style="font-size: 1.2vh; font-weight: 500; color: rgb(130, 130, 130)" [appTooltip]="158">
                                                {{user.email}} 
                                            </span>
                                            <span style="font-size: 1.2vh; font-weight: 500; color: #ff5555" *ngIf="user.finish == false" [appTooltip]="159">
                                                No completado
                                            </span>
                                        </div>
                                    </div>
                                    <div style="flex-direction: column; font-size: 1.2vh; color: rgb(130, 130, 130); padding: 3% 0;" [appTooltip]="160">
                                        <div  class="w-100 d-flex" style="flex-direction: row;">
                                            <div style="width: 30%" [appTooltip]="161">
                                                Total Score: 
                                            </div>
                                            <span style="font-weight: 600; color: black; font-size: 1.29vh;" [appTooltip]="162"> {{user.report.average}}%</span>  
                                        </div>
                                        <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="163">
                                            <div style="width: 30%" [appTooltip]="164">
                                                Taken at:
                                            </div>
                                            <span style="font-weight: 600; color: black; font-size: 1.29vh;" [appTooltip]="165"> {{user.taken_at}}</span>  
                                         
                                        </div>
                                        <!-- <div  class="w-100 d-flex" style="flex-direction: row;">
                                            <div style="width: 30%">
                                                Elapsed Time: 
                                            </div>
                                            <span style="font-weight: 600; color: black; font-size: 1.29vh;"> - min</span>                    
                                        </div>          -->
                                    </div>
                                </div>
                                <div style="width: 54%; height: 100%; flex-direction: column; font-size: 1.2vh; color:rgb(130, 130, 130); padding: 1.2%;" [appTooltip]="166">
                                    <div class="w-100 d-flex h-100" style="flex-direction: column; font-size: 1.2vh; color: rgb(130, 130, 130); justify-content: center">
                                        <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="167">
                                            <div [appTooltip]="168">
                                                General English Resources & Skills
                                            </div>
                                            <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="169">
                                                <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;">
                                                    <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;" [appTooltip]="170">
                                                        <div class="d-flex my-auto" style="min-width: max-content;height: 100%; border-radius: 6px;" [appTooltip]="171" [ngStyle]="{'width': user.report.average + '%', 'background-color': user.report.average < 33 ? '#5DB6FF': (user.report.average > 33 &&  user.report.average  < 66) ? '#0085F2' : '#005DA7'}"> </div>
                                                    </div>
                                                </div>
                                                <div style="width: 9%; margin: 0 3%;" [appTooltip]="172">
                                                  {{user.report.average}}%
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="173">
                                            <div [appTooltip]="174">
                                                Field-Specific English Resources
                                            </div>
                                            <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="175">
                                                <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;"[appTooltip]="176">
                                                    <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;">
                                                        <div class="d-flex my-auto" style="min-width: max-content;height: 100%; border-radius: 6px;" [appTooltip]="177" [ngStyle]="{'width': user.report.resource.Resources + '%', 'background-color': user.report.resource.Resources < 33 ? '#5DB6FF': (user.report.resource.Resources > 33 &&  user.report.resource.Resources  < 66) ? '#0085F2' : '#005DA7'}"> </div>
                                                    </div>
                                                </div>
                                                <div style="width: 9%; margin: 0 3%;" [appTooltip]="178">
                                                   {{user.report.resource.Resources}}%
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-100 d-flex" style="flex-direction: column; padding: 1.2% 0;" [appTooltip]="179">
                                            <div [appTooltip]="180">
                                                Field-Specific English Skills
                                            </div>
                                            <div class="w-100 d-flex" style="flex-direction: row;" [appTooltip]="181">
                                                <div class="d-flex" style="height: 21%; width: 87%; margin: 1.8% 0;" [appTooltip]="182">
                                                    <div  style="min-width: max-content; height: 9px; background-color:#EDEDED; border-radius: 6px; width: 100%;">
                                                        <div class="d-flex my-auto" style="min-width: max-content;height: 100%; border-radius: 6px;" [appTooltip]="183" [ngStyle]="{'width': user.report.skill.Skills + '%', 'background-color': user.report.skill.Skills < 33 ? '#5DB6FF': (user.report.skill.Skills > 33 &&  user.report.skill.Skills  < 66) ? '#0085F2' : '#005DA7'}"> </div>
                                                    </div>
                                                </div>
                                                <div style="width: 9%; margin: 0 3%;" [appTooltip]="184">
                                                    {{user.report.skill.Skills}}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="student" style="margin: 0 2% 0% 1%; background-color: white;" [ngStyle]="{'height': testView == false && auth.role == 'clientAdmin' ? '48%': '49%'}"  *ngIf="(testView == false && auth.role == 'clientAdmin') || auth.role != 'clientAdmin'">
            <app-candidate-info [user]="candidateProfile" [area]="resultArea" [plannerType]="plannerType"></app-candidate-info>
    </div>
</div>
<app-goals *ngIf="admin.goalsGroup != undefined" [groupGoals]="admin.selectedGroup"></app-goals>
<app-tooltip *ngIf="admin.showTooltip && admin.tooltipIndex != -1" class="tooltip_popup" [style.left.px]="admin.tooltipCoord.x" [style.top.px]="admin.tooltipCoord.y" [ngClass]="{'tt': admin.tooltipPos.y == 1}"  [index]="admin.tooltipIndex"></app-tooltip>

