<div class="h-100" style="padding: 1% 0%; background-color: white; position: absolute; left: 19%; display: flex; transition-duration: 600ms; transition-timing-function: ease-in-out; overflow-x: hidden;" [appTooltip]="890" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%', 'width': admin.sidebarShow ? '84%' : '96%'}">
    <div class="h-100 w-100 bg-white">
        <div class="position-relative py-2 px-3 row no-gutters w-100" style="border: none !important; background-color: #A8143E; height: 6%;">
            <div class="px-1">
                <img style="height: 35px;" src="../../../assets/img/logo-white.png" alt="Geo Logo" [appTooltip]="891">
            </div>
            <div style="font-size: 2.7vh; color: white" class="my-auto" [appTooltip]="892">
                Geo Imports
            </div>
            <div class="d-flex" style="margin: 0 3%; width: 80%;">
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" (click)="selectImporter('Grammar', $event)" [appTooltip]="893" [ngClass]="{'buttonImporterSelected': importerSelected == 'Grammar'}">
                  Grammar
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" (click)="selectImporter('Phrases', $event)" [appTooltip]="894" [ngClass]="{'buttonImporterSelected': importerSelected == 'Phrases'}">
                  Phrases
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" (click)="selectImporter('Stax', $event)" [appTooltip]="895" [ngClass]="{'buttonImporterSelected': importerSelected == 'Stax'}">
                  Stax
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean' || auth.role == 'audiovisual'" (click)="selectImporter('Images', $event)" [appTooltip]="896" [ngClass]="{'buttonImporterSelected': importerSelected == 'Images'}">
                  Images
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin'" (click)="selectImporter('Dictionary', $event)" [appTooltip]="897" [ngClass]="{'buttonImporterSelected': importerSelected == 'Dictionary'}">
                  Dictionary
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" (click)="selectImporter('Editorial', $event)" [appTooltip]="898" [ngClass]="{'buttonImporterSelected': importerSelected == 'Editorial'}">
                  Editorial
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin'" (click)="selectImporter('Gapper', $event)" [appTooltip]="899" [ngClass]="{'buttonImporterSelected': importerSelected == 'Gapper'}">
                  Gapper
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin'" (click)="selectImporter('ActivityProfile', $event)" [appTooltip]="900" [ngClass]="{'buttonImporterSelected': importerSelected == 'ActivityProfile'}">
                  Activity Profile
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" (click)="selectImporter('Issues', $event)" [appTooltip]="901" [ngClass]="{'buttonImporterSelected': importerSelected == 'Issues'}">
                  Reported Issues
              </div>
              <div class="my-auto buttonImporter" *ngIf="auth.role == 'superadmin' || auth.role == 'publisherDean' || auth.role == 'dean'" (click)="selectImporter('AudioLab', $event)" [appTooltip]="902" [ngClass]="{'buttonImporterSelected': importerSelected == 'AudioLab'}">
                  Audio Lab
              </div>
          </div>
        </div>
        <div style="height: 94%;">
          <router-outlet></router-outlet>
        </div>
    </div>
</div>
