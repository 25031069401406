import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ListsInfoService {

  constructor(private http: HttpClient) { }

  getCampuses() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getCampuses`);
  }

  getFaculties() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getFaculties`);
  }

  getDegrees() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getDegrees`);
  }

  getSubjects() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getSubjects`);
  }

  getLevels() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getLevels`);
  }

  getFacultiesByCampus(campus: number) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getFacultiesByCampus`, { params: { 'campus_id': String(campus) } });
  }

  getDegreesByFaculty(faculty: number) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getDegreesByFaculty`, { params: { 'faculty_id': String(faculty) } });
  }

  getSubjectsByDegree(degree: number) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getSubjectsByDegree`, { params: { 'degree_id': String(degree) } });
  }

  setCampus(name: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setCampus`, { 'name': name });
  }

  setFaculty(name: string, campus_id: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setFaculty`, { 'name': name, 'campus_id': campus_id });
  }

  setDegree(name: string, faculty_id: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setDegree`, { 'name': name, 'faculty_id': faculty_id });
  }

  setSubject(name: string, degree_id: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setSubject`, { 'name': name, 'degree_id': degree_id });
  }

  setLevel(name: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setLevel`, { 'name': name });
  }

  setList(name: string, description: string, campus_id: string, faculty_id: string, degree_id: string, subject_id: string, key: string, level_id: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setList`, { 'name': name, 'description': description, 'campus_id': campus_id, 'faculty_id': faculty_id, 'degree_id': degree_id, 'subject_id': subject_id, 'key': key, 'level_id': level_id });
  }
  setListEmpty(name: string, description: string, campus_id: string, faculty_id: string, degree_id: string, subject_id: string, key: string, level_id: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setListEmpty`, { 'name': name, 'description': description, 'campus_id': campus_id, 'faculty_id': faculty_id, 'degree_id': degree_id, 'subject_id': subject_id, 'key': key, 'level_id': level_id });
  }

  getSessions() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getSessions`);
  }

  getSessionsLists(lists: string) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getSessionsLists`, { params: { 'lists': lists } });
  }

  searchList(words_search:string) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/searchLabList`, { params: {'words_search':words_search}});
  }

  selectLists(list_id:string) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/selectLists`, { params: {'list_id':list_id}});
  }

}
