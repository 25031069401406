import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LmsService {
  arrayFilters ;
  responsive: boolean = false;
  placement: boolean = false;
  constructor(private http: HttpClient) { }



  getUserGroups(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lms/getUserGroups`, { params: { 'user_id': user_id } });
  }
  
  getCourses(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lms/getCourses`, { params: { 'user_id': user_id } });
  }

  getExamId(group_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lms/getExamId`, { params: { 'course_id': group_id } });
  }

  getTopGroupsToughWords(lab_id: string, user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/progress/getTopGroupsToughWords`, { params: { 'lab_id': lab_id, 'user_id': user_id } });
  }

  getTeacherLabs(group_id: string, user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/progress/getTeacherLabs`, { params: { 'group_id': group_id, 'user_id': user_id } });
  }

  getTeacherWallOfFame(lab_id: string, user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/progress/getTeacherWallOfFame`, { params: { 'lab_id': lab_id, 'user_id': user_id } });
  }

  getProgressGroups30days(lab_id: string, user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/progress/getProgressGroups30days`, { params: { 'lab_id': lab_id, 'user_id': user_id } });
  }

  getProgressGroups30weeks(lab_id: string, user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/progress/getProgressGroups30weeks`, { params: { 'lab_id': lab_id, 'user_id': user_id } });
  }

  getListStudentsGroup(group_id: string) {
    return this.http.get(`${environment.apiUrl}/api/progress/getListStudentsGroup`, { params: { 'group_id': group_id } });
  }

  removeStudentGroup(user_id: string, group_id: string) {
    return this.http.post(`${environment.apiUrl}/api/progress/removeStudentGroup`, { 'user_id': user_id, 'group_id': group_id });
  }

  getStudentDataFromAdmin(admin_user_id: string, user_id: string, lab_id: string) {
    return this.http.get(`${environment.apiUrl}/api/progress/getStudentDataFromAdmin`, { params: { 'admin_user_id': admin_user_id, 'user_id': user_id, 'lab_id': lab_id } });
  }

  getCampuses() {
    return this.http.get(`${environment.apiUrl}/api/lms/getCampuses`);
  }
  getFacultiesByCampus(campus_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getFacultiesByCampus`, { params: { 'campus_id': campus_id } });
  }
  getDegreesByFaculty(faculty_id) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getDegreesByFaculty`, { params: { 'faculty_id': faculty_id } });
  }
  getSubjectsByDegree(degree_id) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getSubjectsByDegree`, { params: { 'degree_id': degree_id } });
  }

  getLabsBySubject(subject_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getLabsBySubject`, { params: { 'subject_id': subject_id } });
  }

  getLabsByDegree(degree_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getLabsByDegree`, { params: { 'degree_id': degree_id } });
  }

  getCoursesBySubject(subject_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getCoursesBySubject`, { params: { 'subject_id': subject_id } });
  }

  getCoursesByArea(degree_id, subject_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getCoursesByArea`, { params: { 'degree_id': degree_id, 'subject_id': subject_id } });
  }

  removeToGroup(type, ids, group_id) {
    return this.http.post(`${environment.apiUrl}/api/lms/removeToGroup`, { 'type': type, 'ids': ids, 'group_id': group_id });
  }

  savePdfGroup(group_id, pdf) {
    return this.http.post(`${environment.apiUrl}/api/lms/savePdfGroup`, { 'pdf': pdf, 'group_id': group_id });
  }

  deleteUserToGroup(group_id, user_id) {
    return this.http.post(`${environment.apiUrl}/api/lms/deleteUserToGroup`, { 'user_id': user_id, 'group_id': group_id });
  }

  deleteTeacherToGroup(group_id, teacher_id) {
    return this.http.post(`${environment.apiUrl}/api/lms/deleteTeacherToGroup`, { 'teacher_id': teacher_id, 'group_id': group_id });
  }

  inviteUsers(group_id, users) {
    users = JSON.stringify(users);
    return this.http.post(`${environment.apiUrl}/api/lms/inviteUsers`, { 'users': users, 'group_id': group_id });
  }

  getCoursesByDegree(degree_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getCoursesByDegree`, { params: { 'degree_id': degree_id } });
  }

  getGroupSubscriptions(group_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getGroupSubscriptions`, { params: { 'group_id': group_id } });
  }

  createUrlToGroupPdf(group_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/createUrlToGroupPdf`, { params: { 'group_id': group_id } });
  }

  getGroupsCandidates(){
    return this.http.get(`${environment.apiUrl}/api/lms/getGroupsCandidates`, {  });
  }

  inviteUsersToGroupSet(users_ids, groupset_id, referral, endDate) {
    users_ids = JSON.stringify(users_ids);
    return this.http.post(`${environment.apiUrl}/api/lms/inviteUsersToGroupSet`, { 'users_ids': users_ids, 'groupset_id': groupset_id, 'referral': referral, 'endDate': endDate });

  }

  insertTeachersToGroup(teachers_ids, group_id) {
    return this.http.post(`${environment.apiUrl}/api/lms/insertTeachersToGroup`, { 'teachers_ids': teachers_ids, 'coursegroup_id': group_id });
  }

  createToken(groupset_id) {
    return this.http.post(`${environment.apiUrl}/api/lms/createToken`, { 'groupset_id': groupset_id });

  }


  searchUsers(name, group_id?) {
    return this.http.get(`${environment.apiUrl}/api/lms/searchUsers`, { params: { 'name': name, 'group_id': group_id } });
  }

  searchTeacher(searchElement) {
    return this.http.get(`${environment.apiUrl}/api/lms/searchTeacher`, { params: { 'searchElement': searchElement } });
  }

  getGroupId(course_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getGroupId`, { params: { 'course_id': course_id } });
  }


  getGroup(group_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getGroup`, { params: { 'group_id': group_id } });
  }

  getCatTree() {
    return this.http.get(`${environment.apiUrl}/api/lms/getCatTree`, { params: {} });
  }

  getDeanCourses() {
    return this.http.get(`${environment.apiUrl}/api/lms/getDeanCourses`, { params: {} });
  }

  getTeacherGroupsByArea(degree_id, subject_id, course_id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getTeacherGroupsByArea`, { params: { 'degree_id': degree_id, 'subject_id': subject_id, 'course_id': course_id } });
  }

  getExercise(type, id) {
    return this.http.get(`${environment.apiUrl}/api/lms/getExercise`, { params: { 'type': type, 'id': id } });
  }

  setUserPayments(course_ids, user_ids) {
    user_ids = JSON.stringify(user_ids);
    course_ids = JSON.stringify(course_ids);
    return this.http.post(`${environment.apiUrl}/api/management/setUserPayments`, { 'course_ids': course_ids, 'user_ids': user_ids });
  }

  inviteUser(userName, userLastName, email, customKey, group_id) {
    return this.http.post(`${environment.apiUrl}/api/lms/inviteUser`, { 'name': userName, 'last_name': userLastName, 'email': email, 'key': customKey, 'group_id': group_id });
  }

  activeGroup(group_id, active) {
    return this.http.post(`${environment.apiUrl}/api/lms/activeGroup`, { 'group_id': group_id, 'active': active });
  }

  getCourseApplicationForms(course_id){
    return this.http.get(`${environment.apiUrl}/api/getApplicationForms`, { params: { 'course_id': course_id} });
  }
}
