import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { SessionDataService } from './session-data.service';
import { environment } from "../../environments/environment";



@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService) { }

  canActivate() {

    if (this.auth.isAuthenticated()) {
      return true;
    } else {
      return false;
    }
  }
}

// @Injectable({
//   providedIn: 'root'
// })
// export class LabGuardService implements CanActivate {

//   constructor(public sessData: SessionDataService, private auth: AuthService) { }

//   canActivate() {
//     // let canAct = new AuthGuardService(this.auth);
//     // canAct.canActivate  //Comprobacion para implementar el auth guard
//     if (!this.sessData.token['id']) {
//       return false;
//     }

//     for (let lab = 0; lab < this.sessData.userLabsInfo.length; lab++) {
//       if (this.sessData.userLabsInfo[lab].id === Number(this.sessData.currentFlyerId)) {
//         return true;
//       }
//     }
//     return true;
//   }
// }

// @Injectable({
//   providedIn: 'root'
// })
// export class Role_colService implements CanActivate {

//   constructor(public sessData: SessionDataService, public auth: AuthService) { }

//   canActivate() {
//     if (!this.auth.userData['id']) {
//       return false;
//     }
//     if (this.auth.userData.role_id === environment.colaboratorRole) {
//       return true;
//     }
//     return false;
//   }
// }