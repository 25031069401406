<!-- List editor -->
<div *ngIf="list">
    <div class="mt-4 container border bg-white p-0">
        <div class="col-12 mt-3">
            <div>
                <div class="d-inline-block col-2">
                    <div class="d-inline ">
                        <div class="d-inline-block">
                            <div class="btn-group">
                                <a class="badge badge-pill badge-light text-center mb-0 campus d-inline" *ngIf="campusCh" style="font-size:16px; cursor: pointer;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{ 'border-danger border': campusRojo }">{{ nameCampus }}</a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let campus of Campuses" (click)="selCampus(campus.id, true)" [ngClass]="{ active: campus_id == campus.id }">{{ campus.name }}</a>
                                </div>
                            </div>

                            <input type="text" class="form-control campus d-inline" *ngIf="!campusCh" placeholder="Campus" #Campus (keyup.enter)="setCampus(Campus.value); newCampus()" />
                        </div>
                        <button class="btn btn-default p-0 ml-2 mr-4" (click)="newCampus()">
              <i class="fas fa-plus-circle text-success"></i>
            </button>
                    </div>
                </div>
                <div class="d-inline-block col-2">
                    <div class="d-inline ">
                        <div class="d-inline-block">
                            <div class="btn-group">
                                <a class="badge badge-pill badge-light text-center mb-0 campus d-inline" *ngIf="faculty" style="font-size:16px; cursor: pointer;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{ 'border-danger border': categoriaRojo }">{{ nameCat }}
                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let faculty of Faculties" (click)="selFaculty(faculty.id, true)" [ngClass]="{ active: faculty_id == faculty.id }">{{ faculty.name }}</a>
                                </div>
                            </div>
                            <input type="text" class="form-control campus d-inline" *ngIf="!faculty" placeholder="Categoria" #newFaculty (keyup.enter)="setFaculty(newFaculty.value, campus_id); newCat()" />
                        </div>
                        <button class="btn btn-default p-0 ml-2 mr-4" (click)="newCat()">
              <i class="fas fa-plus-circle text-success"></i>
            </button>
                    </div>
                </div>
                <div class="d-inline-block col-2">
                    <div class="d-inline ">
                        <div class="d-inline-block">
                            <div class="btn-group">
                                <a class="badge badge-pill badge-light text-center mb-0 campus d-inline" *ngIf="degree" style="font-size:16px; cursor: pointer;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{ 'border-danger border': subcategoriaRojo }">
                  {{ nameSubCat }}</a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let degree of Degrees" (click)="selDegree(degree.id, true)" [ngClass]="{ active: degree_id == degree.id }">{{ degree.name }}</a>
                                </div>
                            </div>
                            <input type="text" class="form-control campus d-inline" *ngIf="!degree" placeholder="Subcategoría" #newDegree (keyup.enter)="
                  setDegree(newDegree.value, faculty_id); newSubcat()
                " />
                        </div>
                        <button class="btn btn-default p-0 ml-2 mr-4" (click)="newSubcat()">
              <i class="fas fa-plus-circle text-success"></i>
            </button>
                    </div>
                </div>
                <div class="d-inline-block col-2">
                    <div class="d-inline ">
                        <div class="d-inline-block">
                            <div class="btn-group">
                                <a class="badge badge-pill badge-light text-center mb-0 campus d-inline" *ngIf="subject" style="font-size:16px; cursor: pointer;" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [ngClass]="{ 'border-danger border': disciplinaRojo }">
                  {{ nameDis }}
                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let subject of Subjects" (click)="selSubject(subject.id)" [ngClass]="{ active: subject_id == subject.id }">{{ subject.name }}</a>
                                </div>
                            </div>
                            <input type="text" class="form-control campus d-inline" *ngIf="!subject" placeholder="Disciplina" #newSubject (keyup.enter)="
                  setSubject(newSubject.value, degree_id); newSubject()
                " />
                        </div>
                        <button class="btn btn-default p-0 ml-2 mr-4" (click)="newSubject()">
              <i class="fas fa-plus-circle text-success"></i>
            </button>
                    </div>
                </div>
                <button class="btn btn-danger offset-3" (click)="change()">
          <i class="fas fa-exchange-alt"></i>
        </button>

                <div class="form-group justify-content-between row mx-0 mb-2">
                    <div class="col-5">
                        <label class="mb-0" for="" *ngIf="index==0">Name ESSENTIAL</label>
                        <label class="mb-0" for="" *ngIf="index==1">Name KEY</label>
                        <label class="mb-0" for="" *ngIf="index==2">Name ULTIMATE</label>
                        <input type="text" class="form-control alteaform" #listName name="" id="" aria-describedby="helpId" placeholder="{{ list_name[index] }}" value="{{ list_name[index] }}" (keyup)="list_name[index] = listName.value" />

                        <label class="mt-0 mb-0" for="">Session</label>
                        <select class="form-control alteaform container mx-0" name="" id="" (change)="selSession($event.target.value)">
              <option disabled selected hidden>Select Option</option>
              <option [ngClass]="{ selected: nounListId == list.id }" *ngFor="let list of Sessions"
                value='{{list.lists}},{{list.name}}'>
                {{ list.name }}</option>
            </select>
                    </div>
                    <div class="form-group mx-3 col justify-content-between mb-0">
                        <label class="mb-0 col-11 px-0" for="" *ngIf="index==0">Description ESSENTIAL</label>
                        <label class="mb-0 col-11 px-0" for="" *ngIf="index==1">Description KEY</label>
                        <label class="mb-0 col-11 px-0" for="" *ngIf="index==2">Description ULTIMATE</label>
                        <textarea class="form-control" name="" id="" rows="3" style="resize:none; height: 96px;" #description (keyup)="listDescription(description.value, index)" [ngClass]="{ 'border-danger': !descripcion }" *ngIf="index==0">{{descripcion[0]}}</textarea>
                        <textarea class="form-control" name="" id="" rows="3" style="resize:none; height: 96px;" #description (keyup)="listDescription(description.value, index)" [ngClass]="{ 'border-danger': !descripcion }" *ngIf="index==1">{{descripcion[1]}}</textarea>
                        <textarea class="form-control" name="" id="" rows="3" style="resize:none; height: 96px;" #description (keyup)="listDescription(description.value, index)" [ngClass]="{ 'border-danger': !descripcion }" *ngIf="index==2">{{descripcion[2]}}</textarea>
                    </div>

                </div>
                <div class="row">
                    <div class="col-1 ml-1">
                        <label class="ml-2" for="">Sublists</label>
                    </div>

                    <div *ngFor="let type of sublist" class="text-center col">
                        <label>{{ type }}</label>
                    </div>
                </div>

                <div class="row ">
                    <div class="col-1">
                        <div class="navac-pointer px-2 con">
                            <p class="text-center ml-3 mt-0 mb-2 navac text-white">N</p>
                        </div>
                        <div class="navac-pointer px-2">
                            <p class="text-center ml-3 my-2 navac text-white">A</p>
                        </div>
                        <div class="navac-pointer px-2">
                            <p class="text-center ml-3 my-2 navac text-white">V</p>
                        </div>
                        <div class="navac-pointer px-2">
                            <p class="text-center ml-3 my-2 navac text-white">A</p>
                        </div>
                        <div class="navac-pointer px-2">
                            <p class="text-center ml-3 my-2 navac text-white">C</p>
                        </div>
                        <div class="navac-pointer px-2">
                            <p class="text-center ml-3 my-2 navac text-white">P</p>
                        </div>
                        <div class="navac-pointer px-2">
                            <p class="text-center ml-3 my-2 navac text-white">P</p>
                        </div>
                    </div>
                    <div *ngFor="let type of sublist; let i=index" class="form-group mx-4 col">
                        <div class="row">
                            <select class="form-control alteaform container mb-2 navac-selector mr-3" name="" id="" (change)="onChangeNoun($event.target.value,i)">

                <option [ngClass]="{ selected: nounListId[i] == list.id }" *ngFor="let list of nounList[i]"
                  value="{{ list.key }}">
                  {{ list.listName }}</option>
              </select>
                        </div>
                        <div class="row">
                            <select class="form-control alteaform container mb-2 navac-selector mr-3" name="" id="" (change)="onChangeAdj($event.target.value,i)">

                <option [ngClass]="{ selected: adjetive == list.id }" *ngFor="let list of adjList[i]"
                  value="{{ list.key }}">
                  {{ list.listName }}</option>
              </select>
                        </div>
                        <div class="row ">
                            <select class="form-control alteaform container mb-2 navac-selector mr-3" name="" id="" (change)="onChangeVerb($event.target.value,i)">

                <option [ngClass]="{ selected: verbListId == list.id }" *ngFor="let list of verbList[i]"
                  value="{{ list.key }}">
                  {{ list.listName }}</option>
              </select>
                        </div>
                        <div class="row ">
                            <select class="form-control alteaform container mb-2 navac-selector mr-3" name="" id="" (change)="onChangeAdv($event.target.value,i)">

                <option [ngClass]="{ selected: adverbListId == list.id }" *ngFor="let list of advList[i]"
                  value="{{ list.key }}">
                  {{ list.listName }}</option>
              </select>
                        </div>
                        <div class="row ">
                            <select class="form-control alteaform container mb-2 navac-selector mr-3" name="" id="" (change)="onChangeConj($event.target.value,i)">

                <option [ngClass]="{ selected: conjListId == list.id }" *ngFor="let list of conjList[i]"
                  value="{{ list.key }}">
                  {{ list.listName }}</option>
              </select>
                        </div>
                        <div class="row ">
                            <select class="form-control alteaform container mb-2 navac-selector mr-3" name="" id="" (change)="onChangePrep($event.target.value,i)">

                <option [ngClass]="{ selected: prepListId == list.id }" *ngFor="let list of prepList[i]"
                  value="{{ list.key }}">
                  {{ list.listName }}</option>
              </select>
                        </div>
                        <div class="row ">
                            <select class="form-control alteaform container mb-0 navac-selector mr-3" name="" id="" (change)="onChangePrep($event.target.value,i)">

                <option [ngClass]="{ selected: pronpList == list.id }" *ngFor="let list of pronpList[i]"
                  value="{{ list.key }}">
                  {{ list.listName }}</option>
              </select>
                        </div>
                    </div>
                </div>
                <hr style="width:100%;" />
                <div class="row w-100 mb-3 justify-content-end">
                    <button class="btn btn-light mx-2" (click)="cancelConfirm()">
            Cancel
          </button>
                    <a [routerLink]="[ '/labcreator' ]">
                        <button class="btn btn-danger mx-3" *ngIf="descripcion[0] &&  descripcion[1] && descripcion[2] &&  campus_id != NULL && faculty_id != NULL && degree_id != NULL && nounListId != NULL && adjetiveListId != NULL && adverbListId != NULL && verbListId != NULL"
                            [routerLink]="[ '/labcreator' ]" (click)="saveList()">
              Save
            </button>
                        <button class="btn btn-danger mx-3" *ngIf="campus_id != NULL && faculty_id != NULL && degree_id != NULL" [routerLink]="[ '/labcreator' ]" (click)="saveListEmpty()">
              Save Empty
            </button>
                    </a>
                    {{nounListId}}
                </div>
            </div>
        </div>
    </div>
</div>