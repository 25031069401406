import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import Editor from "src/assets/js/ckeditor.js";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reported-issues',
  templateUrl: './reported-issues.component.html',
  styleUrls: ['./reported-issues.component.css']
})
export class ReportedIssuesComponent implements OnInit {
  @ViewChild("myEditor") myEditor: any;
  public Editor = Editor;
  public EditorText: string = " ";
  public Config = {
    placeholder: "Type the content here!",
    removePlugins: ['Title']
  };

  issues;
  writingTypes;
  currentIssue;
  currentIssueMessages; // unir currentIssue y currentIssueMessges en un objeto
  showMessage: boolean = false;
  message: string = '';
  staxForms: FormGroup[] = [];


  constructor(public admin: AdminService, public fb: FormBuilder) { }

  ngOnInit() {

    this.admin.getReportedIssues().subscribe((data) => {
      this.issues = data['issues'];
      this.writingTypes = data['writing_types'];
    });

  }

  staxInfo = new Array();
  getIssue(index) {
    this.cleanEditor()
    this.currentIssue = this.issues[index];

    switch (this.currentIssue.origin) {
      case 'Stax':
        this.admin.getReportedStaxItems(this.currentIssue.lab_id).subscribe((data: Array<any>) => {

          let staxInfo = data;
          staxInfo.forEach(element => {

            const form = this.fb.group({ // no mostrar los valores que tengan id a null
              id: [element.labword_id],
              word: [element.word],
              definition_id: [element.definition_id],
              definition: [element.definition],
              translation_id: [element.translation_id],
              translation: [element.translation],
              example: [element.example]
            });

            this.staxForms.push(form);
          });

        });
        break;
      case 'General':
        this.admin.getGeneralIssues().subscribe((data: Array<any>) => {
          let issues = data;
          let content = new Array();
          issues.forEach(element => {
            content.push(element.message);
          });

          this.EditorText = content.join('<br><br>');
        });
        break;
      case 'Wise Reader':
        this.admin.getReadingCard(this.currentIssue.exercise_id).subscribe((data) => {
          let readingContentArray = data['reading_info'];
          let readingPart, textAux, subpartAux, readingContent = new Array();

          for (let part in readingContentArray) {

            if (part != 'readingcard_id') {
              readingPart = readingContentArray[part];

              if (readingPart != null) {

                switch (part) {
                  case 'title':
                  case 'article':
                  case 'essay':
                  case 'letter':
                  case 'report':

                    readingPart = this.matchTags(readingPart);
                    textAux = part + ': ' + '<span style="background-color: #abffab;">' + readingPart + '</span>';

                    break;

                  case 'questions':
                  case 'proposed solution statements':
                    subpartAux = part + ': ';
                    readingPart.forEach(types => {
                      if (types.question == null || types.correct_answer == null) {
                        if (types.question == null) {
                          subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + '(' + types.correct_answer + ')' + '</span>';
                        } else if (types.correct_answer == null) {
                          subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + types.question + '</span>';
                        }
                      } else {
                        subpartAux = subpartAux + '<br><span style="background-color: #abffab;">' + types.question + '(' + types.correct_answer + ')' + '</span>';
                      }
                    });

                    textAux = subpartAux;
                    break;

                  case 'multiple choice':
                    subpartAux = part + ': ';
                    readingPart.forEach(types => {
                      if (types.question == null || types.correct_answer == null || types.options == null) {
                        let optionsString = '';
                        if (types.options != null) {
                          let optionsArray = new Array()
                          types.options.forEach(option => {
                            let optionAux = '("' + option + '")';
                            optionsArray.push(optionAux)
                          })

                          optionsString = optionsArray.join();
                        }

                        if (types.question == null) {
                          subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + optionsString + '</span>';
                        } else if (types.correct_answer == null) {
                          subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + 'QQQ' + types.question + optionsString + '</span>';
                        } else if (types.options == null) {
                          subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + 'QQQ' + types.question + '(' + types.correct_answer + '%%%' + ')' + '</span>';

                        }
                      } else {

                        let optionsArray = new Array()
                        types.options.forEach(option => {
                          let optionAux = '("' + option + '")';
                          optionsArray.push(optionAux)
                        })

                        let optionsString = optionsArray.join()
                        subpartAux = subpartAux + '<br><span style="background-color: #abffab;">' + 'QQQ' + types.question + optionsString + '</span>';
                      }
                    });
                    textAux = subpartAux;
                    break;

                  case 'idiomatic expressions':
                  case 'technical terms':
                  case 'transition expressions':
                  case 'time references':

                    subpartAux = part + ': ';
                    readingPart.forEach(types => {
                      if (types.term == null || types.def == null) {
                        if (types.term == null) {
                          subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + ': ' + types.def + '</span>';
                        } else if (types.def == null) {
                          subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + types.term + '</span>';
                        }
                      } else {
                        if (!types.found) {
                          subpartAux += '<br><span style="background-color: #E05D5D; color:white">' + types.term + '</span>' + ': ' + '<span>' + types.def + '</span>'
                        } else {
                          subpartAux = subpartAux + '<br><span style="background-color: #abffab;">' + types.term + ': ' + types.def + '</span>';
                        }
                      }
                    });

                    textAux = subpartAux;
                    break;

                  case 'action verbs':
                  case 'self-descriptive adjectives':
                    textAux = part + ':';
                    readingPart.forEach(types => {
                      if (!types.found) {
                        textAux += '<span style="background-color: #E05D5D; color:white">' + types.word + '</span>,';
                      } else {
                        textAux += '<span>' + types.word + '</span>,';
                      }
                    });
                    break;

                  case 'custom':
                    readingPart = this.matchTags(readingPart);
                    textAux = part + ':' + readingPart + ', ';

                    break;

                  default:
                    readingPart = this.matchTags(readingPart);
                    textAux = part + ': ' + '<span style="background-color: #abffab;">' + readingPart + '</span>';
                    break;
                }

              } else {
                textAux = part + ': ' + '<span style="background-color: #ff7b7be3">' + readingPart + '</span>';
              }

              readingContent.push(textAux);
            }
          }

          this.EditorText = readingContent.join('<br><br>');
        });
        break;
    }

  }

  update() {
    if (this.currentIssue != undefined) {
      switch (this.currentIssue.origin) {
        case 'Stax':
          let fixedItems = new Array();
          this.staxForms.forEach(element => {
            fixedItems.push(element.value);
          });
          this.admin.updateReportedStaxItems(fixedItems).subscribe((data: string) => {
            this.message = data;
            this.showMessage = true;
          });
          break;
        case 'Wise Reader':
          this.admin.checkReadingImport(this.EditorText, this.writingTypes, 'professional').subscribe(data => {
            let contentPartsBack = data[1];

            this.admin.udpateReadingCard(this.currentIssue.exercise_id, contentPartsBack).subscribe((data: string) => {
              // mensaje
              this.message = data;
              this.showMessage = true;
            });
          });
          break;
      }
    }
  }

  matchTags(text) {
    //Eliminar las <p> y poner en su lugar un <br> para que haga el salto de línea
    let regex1 = /<\/p>/g;
    let regex2 = /<p\b[^>]*>/g;

    text = text.replace(regex1, '');
    text = text.replace(regex2, '<br><br>');

    return text;
  }

  onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      );
  }

  cleanEditor() {
    this.EditorText = '';
    this.currentIssue = undefined;
    this.showMessage = false;
    this.staxForms = []
  }
}
