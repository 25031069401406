import { Injectable } from '@angular/core';
var CryptoJS = require("crypto-js");

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }




  CryptoJSAesDecrypt(passphrase,encrypted_json_string){

    var obj_json = JSON.parse(encrypted_json_string);

    var encrypted = obj_json.ciphertext;
    var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
    var iv = CryptoJS.enc.Hex.parse(obj_json.iv);   

    var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});


    var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

    return decrypted.toString(CryptoJS.enc.Utf8);
}
}
