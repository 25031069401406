<div class="h-100 w-100">
  <div *ngIf="filtersGrammarIndex != undefined" class="position-relative py-2 px-3 row no-gutters d-flex" style="border: none !important; background-color: #F2F2F2;  height: 9%;" [appTooltip]="900">
    <div *ngIf="successfullySave == true">
        <div class="saveMessage"> {{message}} </div>
    </div>
    <div class="d-flex" style="width: 85%;" [appTooltip]="901">
        <div class="mx-2 my-auto row no-gutters" style="width: 9%; font-size: 1.5vh; height: 75%;">
            <select class="inputEdit my-auto mx-auto w-100" style="color: grey; cursor: pointer; text-align: inherit!important;" [appTooltip]="902" [(ngModel)]="branch">
                <option style="color: #6e6e6e;" value="" disabled selected hidden> Ámbito </option>
                <option  *ngFor="let branch of filtersGrammarIndex; let i = index" [value]="branch.campus_name" [ngStyle]="{'color': branch.length != 0 ? '#A8143E' : '#d5d5d5', 'font-weight': branch.length != 0 ? '600' : '500' }" >
                    {{branch.campus_name | titlecase}}
                </option>
            </select>
        </div>
        <div class="mx-2 my-auto row no-gutters" style="width: 9%; font-size: 1.5vh; height: 75%;">
            <select class="inputEdit my-auto mx-auto w-100" style="color: grey; cursor: pointer; text-align: inherit!important;" [appTooltip]="903" [(ngModel)]="level" (change)="selectLevel(level); openLevel = true">
                <option style="color: #6e6e6e;" value="" disabled selected hidden> Nivel </option>
                <option *ngFor="let level of filtersGrammarIndex[0].levels; let j = index" [value]="j" style="font-weight: 600" [ngStyle]="{'color': level.length != 0 ? '#A8143E' : '#d5d5d5'}" >
                    {{j + 1}}
                </option>
                <option style="color: #6e6e6e;" value="All"> All </option>
            </select>
        </div>         
        <div class="mx-2 my-auto row no-gutters" style="font-size: 1.5vh; height: 75%;">
            <select class="inputEdit my-auto mx-auto w-100" style="color: grey; cursor: pointer; text-align: inherit!important;" [appTooltip]="904" [(ngModel)]="fields" *ngIf="level.length != 0" (change)="openArea = true">
                <option style="color: #6e6e6e;" value="" disabled selected hidden> Campo </option>
                <option *ngFor="let topic of filtersGrammarIndex[0].levels[level]; let k = index" [value]="k" style="font-weight: 600; color: grey">
                        {{topic.name}}
                </option>
            </select>
        </div>
        <div *ngIf="!addingSubarea" class="mx-2 my-auto row no-gutters" style="font-size: 1.5vh; height: 75%;">
            <select class="inputEdit my-auto mx-auto w-100" style="color: grey; cursor: pointer; text-align: inherit!important;" [appTooltip]="905" [(ngModel)]="topicType" *ngIf="fields.length != 0" (dblclick)="addingSubarea = true; editSubarea = true" (change)="newSubArea($event); openTypeArea = true">
                <option style="color: #6e6e6e;" value="" disabled selected hidden> Tema </option>
                <option *ngFor="let type of filtersGrammarIndex[0].levels[level][fields].types; let j = index" [ngValue]="type" style="font-weight: 600; color: grey" >
                    {{type.name}}
                </option>
                <option style="color: #6e6e6e;" value="New" > + New </option>
            </select>
        </div>
        <div *ngIf="addingSubarea" class="mx-2 my-auto row no-gutters"  (clickOutside)="addingSubarea = false; topicType = []">
            <div class="my-auto">
                <input  *ngIf="editSubarea == false"  type="text" name="" id="" [appTooltip]="906" (keyup.enter)="saveSubArea($event.target.value); addingSubarea = false ">
                <input  *ngIf="editSubarea == true" type="text" value="{{topicType.name}}" [appTooltip]="907"  (keyup.enter)="editGrammarIndexSubarea($event.target.value, topicType); addingSubarea = false ">
            </div>
        </div>
        <div class="d-flex my-auto" style="font-size: 1.5vh; height: 75%; flex-direction: row;" cdkDropList (cdkDropListDropped)="drop($event)" [appTooltip]="908">
            <div *ngFor="let filter of newFiltersGrammarIndex; let i = index" class="d-flex my-auto mx-2" style="height: 33px; position: relative;" cdkDrag >
                <!-- <div style="position: absolute; top: -15%; right: -3%; color: grey; font-size: 0.9vh; cursor: pointer;" >
                    <i class="fas fa-times px-1 my-auto delete" (click)="removeFilter(filter, i);"></i>
                </div> -->
                <select *ngIf="filter.newArea != undefined && filter.newArea == false" required class="inputEdit my-auto mx-auto w-100 h-100" [appTooltip]="909" style="color: grey; cursor: pointer; text-align: inherit!important;" (dblclick)="filter.newArea = true; editOptions = true" 
                [(ngModel)]="arrayNewFilters[i]" (change)="newSubArea($event, i, filter); editOptions = false; openFilter = true" >
                    <option style="color: #6e6e6e;"  value="" disabled selected hidden>{{filter.filter_name}}</option>
                    <option *ngFor="let option of filter.options; let j = index" [ngValue]="option" style="font-weight: 600; color: grey" class="d-flex">
                        {{option.option_name}}
                    </option>
                    <option style="color: #6e6e6e;" value="New" > + New </option>
                    <option style="color: #6e6e6e;" value="Remove"> Remove </option>
                </select>
                <div *ngIf="filter.newArea != undefined && filter.newArea == true" class="mx-2 my-auto row no-gutters" (clickOutside)="filter.newArea = false; arrayNewFilters[i] = []; editOptions = false">
                    <div class="my-auto">
                        <input *ngIf="editOptions == true && arrayNewFilters[i] == 0" [appTooltip]="910" type="text" value="{{filter.filter_name}}"  name="" id="" (keyup.enter)="editFilterName($event.target.value, filter); filter.newArea = false;">
                        <input *ngIf="editOptions == false" type="text" name="" id="" [appTooltip]="911" (keyup.enter)="addNewFilterOptions($event.target.value, filter); filter.newArea = false">
                        <input *ngIf="editOptions == true && arrayNewFilters[i] != 0" [appTooltip]="912" type="text" value="{{arrayNewFilters[i].option_name}}"  name="" id="" (keyup.enter)="editFiltersOptions($event.target.value, arrayNewFilters[i]); filter.newArea = false; editOptions = false">
                    </div>
                </div>
            </div>
        </div>

        <div class="mx-2 my-auto row no-gutters" style="font-size: 1.5vh; height: 75%;">
            <div *ngIf="newFilter == false" class="add my-auto" (click)="newFilter = true;" [appTooltip]="913">
                +
            </div>
        <div *ngIf="newFilter == true" class="mx-2 my-auto row no-gutters" [appTooltip]="914">
            <div class="my-auto">
                <input type="text" name="" id="" (keyup.enter)="addNewFilter($event.target.value, filter)">
            </div>
        </div>
        </div>
        <div *ngIf="topicType['id'] != undefined" class="mx-2 my-auto  row no-gutters" style="width: auto; font-size: 1.5vh; height: 75%;">
            <i class="fa-solid fa-filter my-auto" style="cursor:pointer" [appTooltip]="915" (click)="getGrammarIndexExplanation()"></i>
        </div>
    </div>
    <div class="d-flex" style="width: 15%; margin-left: auto;">
        <div class="mx-2 my-auto row no-gutters inputEdit" style="width: 51%; font-size: 1.5vh; height: 60%; position: relative;">
            <div class="d-flex my-auto mx-auto w-100 my-auto" style="color: grey; cursor: pointer;" [appTooltip]="916" (click)="showTotalFilters = !showTotalFilters"  (clickOutside)="showTotalFilters = false">    
                    Filters
                <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" ></i>
            </div>
            <div style="height: max-content; flex-direction: column; " class="d-flex dropDown" *ngIf="showTotalFilters == true" [appTooltip]="917">
                <div  *ngFor="let filter of filtersImporters; let i = index" class="w-100 dropOptions" style="padding: 0 6%; cursor: pointer; color: grey; position: relative;" 
                (mouseenter)="showFilterOptions = filter" (mouseleave)="showFilterOptions = undefined" [appTooltip]="918" (click)="includeFilter(filter);" > 
                    {{filter.filter_name}}
                    <div  class="d-flex dropDown dropDownOptions" *ngIf="showFilterOptions != undefined && filter.filter_id == showFilterOptions.filter_id">
                        <div *ngFor="let option of showFilterOptions.options; let j = index" class="bg-white" style="color: grey;">
                            {{option.option_name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>         
        <div class="saveButton my-auto text-center"  style="margin: 0 1.5%;" [appTooltip]="919" (click)="cleanFilters();">
        Clear
        </div>
        <div class="saveButton my-auto text-center" (click)="setGrammarIndexExplanation();">
            <span *ngIf="updateExplanation == false" [appTooltip]="920"> Save</span>
            <span *ngIf="updateExplanation == true" [appTooltip]="921"> Update</span>
        </div>
    </div>
  </div>
  <div style="width: 96%; margin: auto; height: 90%; color: #6e6e6e" class="d-flex">
    <div style="width: 39%; ; margin-top: 3%; padding: 1.2%; font-size: 1.5vh; overflow-y: scroll;" [ngStyle]="{'width': admin.sidebarShow ? '39%': '15%'}">
        <div class="h-100 w-100 d-flex" style="flex-direction: column;">
            <div style="height: 6%;">
                <div class="searchBox d-flex" (keyup)="searchGrammarArea($event.target.value);" [appTooltip]="922">                      
                    <i class="fa-solid fa-magnifying-glass my-auto"></i>
                    <input type="text" class="w-100" style="outline: none; border: none;" [appTooltip]="923">
                    <div style="height: max-content; flex-direction: column" class="d-flex dropDownSearch" *ngIf="dropDownSearch == true" (clickOutside)="dropDownSearch = false" [appTooltip]="924">
                        <div  *ngFor="let level of filtersGrammarIndex[0].levels[level[0]]; let i = index" style="cursor: pointer; color: grey;" (click)="selectLevel(j)" [appTooltip]="925">
                            <div *ngFor="let type of level.types; let j = index" class="dropDownOptions" style="padding: 0 6%;"  [ngClass]="{'hasContent': type.filters.length != 0}">
                                <div *ngIf="type.found == true" (click)="selectFilterSearch(i, type);" [appTooltip]="926">
                                    {{type.name}}
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
            <div *ngIf="branch.length != 0" [appTooltip]="927">
                <div style="font-size: 1.8vh; font-weight: 500; margin: 3% 0;">
                    {{filtersGrammarIndex[0].campus_name}} 
                </div>
                <div *ngIf="level.length != 0" [ngClass]="{'areaSelected pink-border': level.length != 0}" class="d-flex" style="margin-left: 3%; cursor: pointer; font-size: 1.8vh;">
                    <span  style="margin-left: 6%;">
                        {{filtersGrammarIndex[0].levels[level].name}}
                    </span>
                    <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" [appTooltip]="928" (click)="openLevel = !openLevel"></i>
                </div>
                <div *ngIf="level.length != 0 && openLevel == true"  style="margin: 1.5% 0% 1.5% 3%;">
                    <div *ngFor="let area of filtersGrammarIndex[0].levels[level]; let j = index" [ngClass]="{'disabled': area.types.length == 0}">
                        <div style="margin:1.5% 0% 1.5% 6% ; cursor: pointer;" (click)="fields = j;" [appTooltip]="929">
                            <div [ngClass]="{'areaSelected pink-border': fields == j && fields.length != 0}" class="d-flex" >
                                <span  style="margin-left: 6%;" (click)="openArea = true" [appTooltip]="930">
                                    {{area.name}}
                                </span>
                                <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" (click)="openArea = !openArea" [appTooltip]="931"></i>
                            </div>
                            <div  *ngIf="fields.length != 0 && fields == j && openArea"> 
                                <div  *ngFor="let type of filtersGrammarIndex[0].levels[level][fields].types; let k = index" class="d-flex" style="margin: 1.5% 0% 1.5% 6% ; flex-direction: column;"> 
                                    <div  style="margin-left: 6%; ;" class="d-flex topic" [ngClass]="{'areaSelected': topicType == type, 'hasContent': type.filters.length != 0, 'searchTopic':selectedId == type.id && arrayNewFilters.length == 0}" (click)="topicType = type; cleanArrayNewFilters();"> 
                                        <div style="width: 93%;" (click)="topicType = type; getGrammarIndexExplanation()" [appTooltip]="932">
                                            {{type.name}}
                                        </div>
                                        <i *ngIf="type.filters != 0 && type.filters.filter_id != null" class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" (click)="openFilter(filtersGrammarIndex[0].levels[level][fields], type)" [appTooltip]="933"></i>
                                    </div>
                                    <div *ngIf="type.openTopic == true && topicType == type">
                                        <div *ngFor="let option of type.filters.options; let l = index" style="margin-left: 12%; color: grey; font-weight: 400;">
                                            <div style="margin: 1.5% 0;"  class="d-flex hasContent" [ngClass]="{'searchTopic': selectedId == option.explanation_id, 'hasContent': option.has_content == true}" [appTooltip]="934" (click)="cleanArrayNewFilters(); selectFilterOptions(type.filters.filter_id, option);">
                                                <div  style="width: 93%;" (click)="selectFilterOptions(type.filters.filter_id, option); getGrammarIndexExplanation(option)" [appTooltip]="935">
                                                    {{option.option_name}}
                                                </div>
                                                <i *ngIf="option.filters != 0" class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;" (click)="openFilterOptions(l)" [appTooltip]="936"></i>
                                            </div>
                                            <div *ngIf="option.filters != 0 && openOptions"  style="margin-left: 6%;">
                                                <div *ngFor="let filterOption of option.filters">
                                                    <div *ngFor="let optionVis of filterOption.options" [ngClass]="{'areaSelected': arrayNewFilters != 0 && (optionVis.option_id == (arrayNewFilters[0].option_id || arrayNewFilters[1].option_id)), 'searchTopic': selectedId == optionVis.option_id && arrayNewFilters.length != 0}" class="hasContent" (click)="selectFilterOptions( filterOption.filter_id, optionVis); getGrammarIndexExplanation()" >
                                                        {{optionVis.option_name}}            
                                                    </div>
                                                </div>                                              
                                            </div>
                                        </div>
                                    </div>                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="h-100" style="padding: 1.5%; width: 81%;">
        <div class="position-relative py-2 px-3 row no-gutters" style="border: none !important; background-color: #A8143E;">
            <div class="px-1 d-flex w-100" style="color: white;" >
                <img style="height: 35px;" src="../../../assets/img/logo-white.png" alt="Geo Logo" (click)="viewText = !viewText" [appTooltip]="937"> 
                <div class="w-100 d-flex my-auto">
                    <span *ngIf="branch.length != 0" style="margin: 0 1.5%;" [appTooltip]="938"> {{filtersGrammarIndex[0].campus_name}}  </span>
                    <span *ngIf="level.length != 0"  style="margin: 0 1.5%;" [appTooltip]="939"> {{filtersGrammarIndex[0].levels[level].name}} </span>
                    <span *ngIf="fields.length != 0"  style="margin: 0 1.5%;" [appTooltip]="940"> {{filtersGrammarIndex[0].levels[level][fields].name}} </span>
                    <span *ngIf="topicType.length != 0"  style="margin: 0 1.5%;" [appTooltip]="941">{{topicType.name}}</span>
                    <span *ngIf="arrayNewFilters[0] != undefined"  style="margin: 0 1.5%;" [appTooltip]="942">{{arrayNewFilters[0].option_name}}</span>
                    <span *ngIf="arrayNewFilters[1] != undefined"  style="margin: 0 1.5%;" [appTooltip]="943">{{arrayNewFilters[1].option_name}}</span>
                </div>
                <i class="fe-icon-trash-2 my-auto" style="font-weight: 400; color: white; cursor: pointer; margin: 0 1.5%;"  [ngClass]="{'disabled': explanation.length == 0}" [appTooltip]="944" (click)="closeContent()"></i>
                <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer;" (click)="cleanTextEditor();" [appTooltip]="945">
                    Clear
                </div>
            </div>                
        </div>
        <div *ngIf="viewText == true" style="width: 74%; height: 72%; position: absolute;z-index: 9999; border-radius: 6px; box-shadow: 3px 1px 6px 3px #e3e3e3; overflow-y: scroll;" class="bg-white" [appTooltip]="946">
            <div style="padding: 3%;"  [innerHTML]="textEdition | safeDef">  </div>
        </div>
        <ckeditor #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)" (change)="onChange($event)" [(ngModel)]="EditorText" [appTooltip]="947"></ckeditor>
    </div>
  </div>
</div>
<div *ngIf="warningMessage == true" class="messagePanel" >
    <div class="d-flex w-100 textMessage" style="height: 63%;">
        <p style="margin-bottom: 0;"> ¿Estás seguro de que desea eliminar el filtro?</p>
    </div>
    <div class="d-flex w-100">
        <div class="deleteConfirmation" (click)="removeFilter()">
            Sí, eliminar filtro
        </div>
        <div class="deleteConfirmation" (click)="closeWarningMessage();">
            No, mantener el filtro
        </div>
    </div>
</div>