<div class="modal fade" id="register" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <form class="row px-5" [formGroup]="register" (ngSubmit)="Register()">
        <div class="modal-header col-12 ">
          <h4 class="modal-title">Register to ALTEA Labs</h4>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body row">
          <div class="col-6">
            <div class="form-group"
              [ngClass]="{'text-danger': !register.controls['name'].pristine && !register.controls['name'].valid }">
              <label for="name">Name</label>
              <input id="name" class="form-control" type="text" placeholder="Name" formControlName="name"
                [ngClass]="{'border border-danger': !register.controls['name'].pristine && !register.controls['name'].valid }">
              <div *ngIf="register.controls['name'].errors?.required && !register.controls['name'].pristine"
                class="form-control-feedback text-danger">
                Este campo es requerido
              </div>
              <div *ngIf="register.controls['name'].errors?.minlength && !register.controls['name'].pristine"
                class="form-control-feedback text-danger">
                Al menos 2 caracteres
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="lastname">Last Name</label>
              <input id="lastname" class="form-control" type="text" placeholder="Last Name" formControlName="lastname">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group"
              [ngClass]="{'text-danger': !register.controls['password'].pristine && !register.controls['password'].valid }">
              <label for="password" class="px-1">Password</label>
              <input id="password" class="form-control" type="password" placeholder="Password"
                formControlName="password"
                [ngClass]="{'border border-danger': !register.controls['password'].pristine && !register.controls['password'].valid }">

              <div *ngIf="register.controls['password'].errors?.required && !register.controls['password'].pristine"
                class="form-control-feedback text-danger">
                Este campo es requerido
              </div>
              <div *ngIf="register.controls['password'].errors?.minlength && !register.controls['password'].pristine"
                class="form-control-feedback text-danger">
                Al menos 8 caracteres
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group"
              [ngClass]="{'text-danger': !register.controls['password_confirmation'].pristine && !register.controls['password_confirmation'].valid }">
              <label for="password_confirmation">Confirm Password</label>
              <input id="password_confirmation" class="form-control" type="password" placeholder="Confirm Password"
                formControlName="password_confirmation"
                [ngClass]="{'border border-danger': !register.controls['password_confirmation'].pristine && !register.controls['password_confirmation'].valid }">

              <div
                *ngIf="register.controls['password_confirmation'].errors?.required && !register.controls['password_confirmation'].pristine"
                class="form-control-feedback text-danger">
                Este campo es requerido
              </div>
              <div
                *ngIf="!register.controls['password_confirmation'].valid && !register.controls['password_confirmation'].pristine"
                class="form-control-feedback text-danger">
                Las contraseñas no coinciden
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group"
              [ngClass]="{'text-danger': !register.controls['email'].pristine && !register.controls['email'].valid }">
              <label for="email">Email</label>
              <input id="email" class="form-control" type="email" placeholder="Email" formControlName="email"
                [ngClass]="{'border border-danger': !register.controls['email'].pristine && !register.controls['email'].valid }">
              <div *ngIf="register.controls['email'].errors?.required && !register.controls['email'].pristine"
                class="form-control-feedback text-danger">
                Este campo es requerido
              </div>
              <div *ngIf="!register.controls['email'].valid && !register.controls['email'].pristine"
                class="form-control-feedback text-danger">
                No tiene formato de email válido
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="birth">Birth</label>
              <input id="birth" class="form-control" type="date" placeholder="" formControlName="birth">
            </div>
          </div>
          <div class="modal-footer col-12 px-0">
            <p class="regMod mb-0">Ya tienes cuenta?</p><a class="aMod1" data-toggle="modal" data-dismiss="modal" data-target="#login">INICIA SESION</a>
          <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Close</button>
          <button [disabled]="!register.valid" class="btn btn-altea btn-sm" type="submit">Register</button>
        </div>

        </div>
      </form>
    </div>
  </div>
</div>