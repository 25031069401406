import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit {


  @Input() index: number = -1;

  tooltip = {
    text: ''
  };

  constructor(private admin: AdminService) { }

  ngOnInit(): void {
    this.tooltip.text = this.getTooltipText(this.index);
  }


  getTooltipText(index) {
    var text: string = '';
    text = this.admin.tooltipsText[index]

    return text;
  }

}
