<div class="h-100"  style="padding: 1% 0%;position: absolute; display: flex; transition-duration: 600ms; transition-timing-function: ease-in-out; color: grey; width: 85%;" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%',  'width': admin.sidebarShow ? '85%' : '96%'}">
    <div class="w-100 h-100 d-flex">
        <div class="container" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
            <h3>Drop or paste your files here</h3>
       
            <div class="files-list">
                <label for="fileDropRef">Browse for file</label>
            </div>
            <!-- <div class="searchBox d-flex" (keyup)="searchGroup($event.target.value);">                      
                <i class="fa-solid fa-magnifying-glass my-auto"></i>
                <input type="text" class="w-100" style="outline: none; border: none;">
            </div> -->
            <div class="single-file" *ngFor="let file of files; let i = index">
                <div class="name" (click)="openFile(i)">
                    {{ file.file_name | titlecase }}
                </div>
                <i class="fe-icon-trash-2 my-auto" style="font-weight: 400; color: white; cursor: pointer; margin: 0 1.5%;" (click)="deleteFile(i)"></i>
            </div>
        </div>
        <div *ngIf="viewPDF" class="h-100 w-100 selectable">
            <ngx-extended-pdf-viewer 
                [(src)]="currentFile"
                [textLayer]="true"
                [showHandToolButton]="true"
                [handTool]="false"
                zoom="0%"
                [showZoomButtons]="false"
                (textLayerRendered)=handleTextLayerRender($event);
                >
            </ngx-extended-pdf-viewer>
        </div>
    </div>
</div>