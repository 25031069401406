import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { PusherService } from './services/pusher.service';
import * as jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = 'Admin';

  constructor(private auth: AuthService, private pusher: PusherService, ) { }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.auth.isAuthenticated()) {
      this.auth.userData = jwt_decode(localStorage.getItem('access_token'));
      if (localStorage.getItem('access_token')) {
        this.auth.userData.role_id = Number(this.auth.userData['role_id']);
        //console.log('dentro listas');
      }
    }
  }
}
