import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as jwt_decode from 'jwt-decode';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: "root"
})
export class AuthService {
  userData;
  constructor(public router: Router, private http: HttpClient) {
    var token = localStorage.getItem("access_token");
    if (token) this.userData = jwt_decode(localStorage.getItem("access_token"));
    if (this.userData) this.userData.role_id = Number(this.userData.role_id);
  }

  workingArea;

  public setSession(authResult): void {
    // Set the time that the access token will expire at
    this.userData = jwt_decode(authResult[0].original.access_token);
    this.userData.role_id = Number(this.userData.role_id);

    const expiresAt = this.userData.exp;

    localStorage.setItem("access_token", authResult[0].original.access_token);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem("email", authResult[2]);
  }

  role;
  public checkRole() {
    switch (this.userData.role_id) {
      case 1:
        this.role = 'student';
        break;
      case 2:
        this.role = 'admin';
        break;
      case 3:
        this.role = 'teacher';
        break;
      case 8:
        this.role = 'superadmin';
        break;
      case 6:
        this.role = 'dean';
        break;
      case 9:
        this.role = 'publisherDean';
        break;
      case 13:
        this.role = 'clientAdmin';
        break;
      case 17:
        this.role = 'audiovisual';
        break;
    }
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("user_id");
    this.router.navigate(["/landing"]);
    this.userData = undefined;
  }


  getUsers(role_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }),
      params: new HttpParams({
        fromObject: { 'role_id': role_id }
      })
    };
    return this.http.get(`${environment.apiUrl}/api/listadmin/getUsers`, httpOptions);
  }


  getUserInfo(user_id) {
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
      }),
      params: new HttpParams({
        fromObject: { 'user_id': user_id }
      })
    };
    return this.http.get(`${environment.apiUrl}/api/listadmin/getUsersInfo`, httpOptions);
  }

  getProspectsUsers() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getProspectsUsers`, {});
  }


  changeUserStatus(user_id) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/updateUsersStatus`, { 'id': user_id });
  }


  deleteUser(user_id) {
    return this.http.post(`${environment.apiUrl}/api/user/deleteUser`, { 'user_id': user_id });
  }

  url: string;
  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    var dateInMs = new Date().getTime().toString();
    let is_auth = dateInMs.substring(0, dateInMs.length - 3) < expiresAt;

    if (this.userData != undefined && this.userData.id != null) {
      if (this.workingArea != undefined && this.workingArea == 'landing') {
        this.checkRole();
        this.workingArea = 'group-admin';
        this.router.navigate(["/group-admin"]);
      }
      if (!is_auth) this.logout();
      return is_auth;
    // } else if (this.userData == undefined){
    //   this.router.navigate(["/landing"]);
    } else {
      if (this.workingArea != undefined && this.workingArea != 'landing') {
        this.logout();
      }
      return false;
    }
  }


  public expTime(): number {
    // Check whether the current time is past the
    // access token's expiry time and return the remaining session time
    const expiresAt: number = (JSON.parse(localStorage.getItem("expires_at"))) ? JSON.parse(localStorage.getItem("expires_at")) : 0;
    if (this.isAuthenticated) {
      return expiresAt - new Date().getTime();
    }

    return null;
  }

  sendRecovery(email) {
    return this.http.post(`${environment.apiUrl}/api/resetpswMail`, { 'email': email });
  }

}
