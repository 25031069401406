<div style="height: calc(100% - 60px);" class="w-100">
    <div class="row px-5 no-gutters w-100 justify-content-start">
        <div class="px-0 text-center active p-2">
            <p class="option mb-0" style="cursor: pointer;" [ngClass]="{'workingPlace': !new_user}" (click)="setEdit()">Staff</p>
        </div>
        <div class="px-0 ml-3 text-center active p-2">
            <p class="option mb-0" style="cursor: pointer;" [ngClass]="{'workingPlace': new_user == true}" (click)="setNewUser();">New User</p>
        </div>
    </div>
    <div class="row no-gutters justify-content-start pt-4 pl-5 w-100" *ngIf="!new_user">
        <div class="col-4 px-0">
            <div class="w-100 h-100 px-0">
                <div class="w-100 h-100">
                    <div class="row w-100 pb-5 no-gutters">
                        <!-- <div style="height: 100%;" class="col-3 d-flex">
                            <img class="imgAvatar" [src]="sessData.imageAux">
                        </div> -->
                        <div *ngIf="!students" style="min-width: 160px;" class="position-relative">
                            <div class="dropdown w-100 posboton">
                                <button class="btn row no-gutters d-flex justify-content-between w-100 defCont px-2 dd px-0" (click)="open_users = !open_users"><span>{{selected_type_user.user | uppercase}}
                                        </span><i class="fas my-auto fa-caret-down"></i></button>
                                <div (clickOutside)="open_users = false;" [exclude]="'.posboton'" *ngIf="open_users && type_users.length > 0" style="z-index: 10000; width: max-content;" class="py-0 dropdown-menu d-block">
                                    <div *ngFor="let type_user of type_users; let i=index">
                                        <P [ngClass]="{'selectedArea': selected_type_user.user == type_user.user}" style="cursor: pointer;" (click)="getUserInfo(type_user.role_id); selected_type_user = type_user; open_users = false" class="optionArea px-2 py-2 mb-0">{{type_user.user | uppercase}}</P>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters col ml-3 pr-0 position-relative">
                            <div style="border-bottom: 1px solid black !important;" class="row bg-white no-gutters w-100">
                                <input (clickOutside)="rebootSearch();" class="col input__form apForm py-1 px-0 my-auto inputEdit" type="text" placeholder="TEAM MEMBER" (click)="setVisible()" (keyup)="typeAheadUser($event.target.value)">
                                <i *ngIf="!loading" class="fas pl-2 my-auto fa-search"></i>
                                <span *ngIf="loading" class="spinner-border my-auto mx-auto" style="width: 1rem; height: 1rem;" role="status"></span>
                            </div>
                            <div style="top: 100%; z-index: 10; min-width: 100%; width: max-content;" class="bg-white position-absolute" *ngIf="array_users.length > 0">
                                <div style="max-height: 66vh; overflow-y: auto;" class="w-100">
                                    <div style="cursor: pointer;" class="justify-content-between w-100 optForm row no-gutters px-1" *ngFor="let user of array_users">
                                        <div (click)="setUser(user)" class="d-flex">
                                            <span class="pr-1 text-capitalize">{{user.name}}</span>
                                            <span class="pr-1 text-capitalize" *ngIf="user.last_name != ''">{{user.last_name}}</span>
                                            <span class="text-capitalize" *ngIf="user.second_last_name != ''">{{user.second_last_name}}</span>
                                        </div>
                                        <i *ngIf="rolesAux.superadmin == _auth.checkRole()" (click)="archiveUser(user)" style="cursor: default" class="fas pl-2 my-auto fa-trash-alt"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div style="flex-direction: column;" class="col d-flex justify-content-start">
                            <h5 class="text-lg mt-3">{{formulario.controls['name'].value}} {{formulario.controls['last_name'].value}}</h5>
                        </div> -->
                    </div>
                    <form *ngIf="info != null" [formGroup]="formulario">
                        <div style="height: 96px;" class="row mx-0 no-gutters justify-content-between">
                            <div class="col-4 h-100 px-0">
                                <div style="height: 100%;" class="form-group mb-0" [ngClass]="{ 'text-danger': formulario.controls['name'].invalid && !formulario.controls['name'].pristine }">
                                    <label class="px-0 form__title" for="first-name">First Name</label>
                                    <input id="first-name" class="form-control inputEdit input__form apForm py-1 px-0" type="text" placeholder="First Name" formControlName="name" [ngClass]="{ 'border border-danger': formulario.controls['name'].invalid && !formulario.controls['name'].pristine }"
                                    />
                                    <div style="font-size: 100%;" *ngIf="formulario.controls['name'].errors?.required && !formulario.controls['name'].pristine" class="form-control-feedback">
                                        A Name is required
                                    </div>
                                    <div style="font-size: 100%;" *ngIf="formulario.controls['name'].errors?.minlength && !formulario.controls['name'].pristine" class="form-control-feedback">
                                        Minimum 2 characters
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 h-100 px-3">
                                <div style="height: 100%;" class="form-group mb-0" [ngClass]="{ 'text-danger': formulario.controls['last_name'].invalid && !formulario.controls['last_name'].pristine}">
                                    <label class="form__title px-0" for="last-name">Last/Middle Name</label>
                                    <input id="last-name" class="py-1 inputEdit px-0 input__form apForm form-control" type="text" placeholder="Last/Middle Name" formControlName="last_name" [ngClass]="{ 'border border-danger': formulario.controls['last_name'].invalid && !formulario.controls['last_name'].pristine }"
                                    />
                                    <div style="font-size: 100%;" *ngIf="formulario.controls['last_name'].invalid && !formulario.controls['last_name'].pristine" class="form-control-feedback">
                                        Last/Middle name is required
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 h-100 px-0">
                                <div style="height: 100%;" class="form-group mb-0" [ngClass]="{ 'text-danger': formulario.controls['second_last_name'].invalid && !formulario.controls['second_last_name'].pristine}">
                                    <label class="form__title px-0" for="last-name">Second Last Name</label>
                                    <input id="last-name" class="inputEdit py-1 px-0 input__form apForm form-control" type="text" placeholder="Second Last Name" formControlName="second_last_name" [ngClass]="{ 'border border-danger': formulario.controls['second_last_name'].invalid && !formulario.controls['second_last_name'].pristine }"
                                    />
                                    <div style="font-size: 100%;" *ngIf="formulario.controls['second_last_name'].invalid && !formulario.controls['second_last_name'].pristine" class="form-control-feedback">
                                        Second last name is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 96px;" class="row mx-0 no-gutters justify-content-between">
                            <div>
                                <label class="form__title px-0">User Type</label>
                                <div style="min-width: 160px;" class="position-relative bg-white">
                                    <div class="dropdown w-100 posboton">
                                        <div style="height: 39px !important;" class="btn row no-gutters d-flex justify-content-between w-100 defCont px-2 dd px-0" (click)="open_users_reg = !open_users_reg"><span style="font-size: 120%;" class="h-100">{{selected_type_user_reg.user | uppercase}}
                                                        </span><i class="fas my-auto fa-caret-down"></i></div>
                                        <div (clickOutside)="open_users_reg = false;" [exclude]="'.posboton'" *ngIf="open_users_reg && type_users.length > 0" style="z-index: 10000; width: max-content;" class="py-0 dropdown-menu d-block">
                                            <div *ngFor="let type_user of type_users | slice:1; let i=index">
                                                <P [ngClass]="{'selectedArea': selected_type_user_reg.user == type_user.user}" style="cursor: pointer;" (click)="setTypeUser(type_user)" class="optionArea px-2 py-2 mb-0">{{type_user.user | uppercase}}</P>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pl-3 h-100 pr-0 col">
                                <div class="w-100 h-100 px-0">
                                    <div style="height: 100%;" class="form-group mb-0" [ngClass]="{ 'text-danger': formulario.controls['email'].invalid && !formulario.controls['email'].pristine }">
                                        <label class="form__title px-0" for="email">Email Adress</label>
                                        <input id="email" class="inputEdit form-control input__form apForm py-1 px-0" type="text" placeholder="Email Adress" formControlName="email" [ngClass]="{ 'border border-danger': formulario.controls['email'].invalid  && !formulario.controls['email'].pristine}"
                                        />
                                        <div style="font-size: 100%;" *ngIf="formulario.controls['email'].errors?.required && !formulario.controls['email'].pristine" class="form-control-feedback">
                                            An email address is required
                                        </div>
                                        <div style="font-size: 100%;" *ngIf="formulario.controls['email'].errors?.pattern && !formulario.controls['email'].pristine" class="form-control-feedback">
                                            The email you are trying to use is not valid
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 96px;" class="row mx-0 no-gutters justify-content-between">
                            <div style="height: max-content;" class="w-100 row no-gutters px-0">
                                <label class="form__title w-100 px-0" for="date-of-birth">Phone number</label>
                                <div class="col-6 pl-0 pr-2">
                                    <div class="w-100 position-relative">
                                        <input class="inputEdit form-control w-100 py-1 px-0 input__form apForm" placeholder="Country" formControlName="country" [ngClass]="{'border border-danger': !formulario.controls['country'].pristine && !formulario.controls['country'].valid }" (keyup)="getCountry($event, 'edit')"
                                            type="text">
                                        <div style="min-width: 100%; top: 100%; z-index: 10; width: max-content; max-height: 33vh; overflow-y: auto;" class="position-absolute" *ngIf="selectedCountry.length > 0">
                                            <option style="cursor: pointer;" class="w-100 bg-white optForm text-truncate px-1 py-2" *ngFor="let country of selectedCountry" (click)="setCountry(country, 'edit')">{{country.name}}</option>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 pl-2 pr-0">
                                    <div class="w-100 row no-gutters position-relative">
                                        <span class="my-auto" style="height: max-content;" *ngIf="prefix_edit != 0">+ {{prefix_edit}}</span>
                                        <input class="inputEdit col inputEdit form-control py-1 pl-2 input__form apForm" placeholder="Phone number" formControlName="phone" [ngClass]="{'border border-danger': !formulario.controls['phone'].pristine && !formulario.controls['phone'].valid }" type="tel">
                                    </div>
                                    <div *ngIf="!formulario.controls['phone'].valid && !formulario.controls['phone'].pristine" class="form-control-feedback w-100 text-danger">That is an invalid number</div>
                                </div>
                            </div>
                            <div *ngIf="!formulario.controls['country'].valid && !formulario.controls['country'].pristine" class="form-control-feedback w-100 text-danger">Please select one option</div>
                        </div>
                        <div style="height: 96px;" class="row mx-0 no-gutters justify-content-between">
                            <div class="w-100 col-6 pr-2 pl-0 h-100">
                                <div style="height: 100%;" class="form-group mb-0">
                                    <label class="form__title px-0" for="date-of-birth">Date Of Birth <span class="text-uppercase">(yyyy-mm-dd)</span></label>
                                    <input id="date-of-birth" placeholder="YYYY-MM-DD" class="form-control input__form apForm py-1 inputEdit px-0" type="text" formControlName="date_of_birth" />
                                </div>
                            </div>
                            <div class="w-100 col-6 pl-2 pr-0 h-100">
                                <div class="w-100 h-100 px-0 passContainer">
                                    <div class="input-group h-100 form-group mb-0">
                                        <label class="form__title px-0" for="password_new">Change password</label>
                                        <div class="input-group row no-gutters w-100 form-group">
                                            <input *ngIf="mostrar1 == false" (click)="noIgual()" class="form-control input__form apForm col pl-2 py-1 inputEdit pr-0" type="password" autocomplete="new-password" placeholder="New Password" formControlName="password_new" />
                                            <input *ngIf="mostrar1 == true" (click)="noIgual()" id="password" class="col input__form apForm pl-2 py-1 inputEdit pr-0 form-control" type="text" autocomplete="new-password" placeholder="New Password" formControlName="password_new" />
                                            <div class="input-group-prepend" (click)="mostrar1 = !mostrar1"><span class="input-group-text"><i
                                              class="fe-icon-eye eyefill"></i></span></div>
                                        </div>
                                        <div style="font-size: 100%;" *ngIf="!formulario.controls['password_new'].valid && !formulario.controls['password_new'].pristine" class="form-control-feedback text-danger">
                                            The password is not valid<span *ngIf="not_valid == true">(combine numbers and letters)</span>
                                        </div>
                                        <div style="font-size: 100%;" *ngIf="not_valid == true" class="form-control-feedback text-danger">
                                            (Combine numbers and letters)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters w-100 justify-content-between">
                            <button (click)="archiveUser()" type="button" class="btn-stack my-auto px-2 btn">
                                Archive</button>
                            <button (click)="changeEdit()" type="button" style="border-radius: 0 !important;" class="btn btn-sub px-2 text-white">
                                Save Changes</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- <div *ngIf="alertMess" class="iziToast-wrapper iziToast-wrapper-topCenter">
                <div class="iziToast iziToast-success mx-auto mt-5">
                    <div class="iziToast-body">
                        <strong class="iziToast-tit">Profile</strong>
                        <p>Your changes have been saved</p>
                    </div>
                </div>
            </div>
            <div *ngIf="alertMessPass" class="iziToast-wrapper iziToast-wrapper-topCenter">
                <div class="iziToast iziToast-success mx-auto mt-5">
                    <div class="iziToast-body">
                        <strong class="iziToast-tit">Profile</strong>
                        <p>Your password has been change</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="row no-gutters justify-content-start pt-4 pl-5 w-100" *ngIf="new_user == true">
        <div class="col-4 px-0" role="document">
            <div style="background-color: #f2f2f2;" class="w-100">
                <form style="flex-direction: column;" class="row no-gutters h-100 justify-content-between" [formGroup]="register" (ngSubmit)="Register()">
                    <div style="flex-direction: column;" class="px-4 py-2 bg-white w-100">
                        <h4 style="letter-spacing: inherit;" class="row no-gutters w-100">
                            <div style="font-weight: 100; font-size: 140%;" class="w-100 text-center">
                                <div class="d-inline">
                                    <span style="font-weight: 600" class="text-dark">ALTEA</span>
                                    <span style="font-size: inherit;" class="labs-title">Labs</span>
                                </div>
                            </div>
                            <div style="font-weight: 600; color: #2f5597;" class="w-100 text-center">
                                Registration Form
                            </div>
                        </h4>
                    </div>
                    <div class="row w-100 no-gutters pt-4 pb-0 px-4">
                        <!-- <div class="col-12 px-0">
                            <div class="input-group">
                                <div>
                                    Invitation Key
                                </div>
                                <div class="row no-gutters w-100">
                                    <input id="code_confirmation" class="bg-white form-control" type="text" placeholder="Confirm code" formControlName="key">
                                </div>
                                <div *ngIf="!codeMail" class="form-control-feedback text-danger">
                                    The key or email is invalid
                                </div>
                            </div>
                        </div> -->
                        <div style="height: 90px;" class="col-12 px-0">
                            <div class="w-100" [ngClass]="{'text-danger': (register.controls['first_name'].errors?.required && !register.controls['first_name'].pristine) || (register.controls['last_name'].errors?.required && !register.controls['last_name'].pristine) || (register.controls['second_last_name'].errors?.required && !register.controls['second_last_name'].pristine) }">
                                <div class="input-group">
                                    <div class="form__title">
                                        User Name
                                    </div>
                                    <div class="row no-gutters w-100">
                                        <div class="col-4 px-0">
                                            <input class="form-control inputEdit w-100 py-1 pl-2 pr-0 input__form apForm" type="text" placeholder="First name" formControlName="first_name" [ngClass]="{'border border-danger': !register.controls['first_name'].pristine && !register.controls['first_name'].valid }">
                                        </div>
                                        <div class="col-4 px-3">
                                            <input class="form-control inputEdit w-100 py-1 pl-2 pr-0 input__form apForm" type="text" placeholder="Last/Middle name" formControlName="last_name" [ngClass]="{'border border-danger': !register.controls['last_name'].pristine && !register.controls['last_name'].valid }">
                                        </div>
                                        <div class="col-4 px-0">
                                            <input class="form-control inputEdit w-100 py-1 pl-2 pr-0 input__form apForm" type="text" placeholder="Second last name" formControlName="second_last_name" [ngClass]="{'border border-danger': !register.controls['second_last_name'].pristine && !register.controls['second_last_name'].valid }">
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="(register.controls['first_name'].errors?.required && !register.controls['first_name'].pristine) || (register.controls['last_name'].errors?.required && !register.controls['last_name'].pristine) || (register.controls['second_last_name'].errors?.required && !register.controls['second_last_name'].pristine)"
                                    class="form-control-feedback text-danger">
                                    This fields are required
                                </div>
                            </div>
                        </div>
                        <div style="height: 90px;" class="col-12 px-0">
                            <div class="row no-gutters w-100">
                                <div style="width: max-content;" class="pl-0 pr-2">
                                    <div class="form__title">
                                        User type
                                    </div>
                                    <div style="min-width: 160px; height: 39px;" class="position-relative bg-white">
                                        <div class="dropdown w-100 posboton">
                                            <div style="height: 39px !important;" class="btn row no-gutters d-flex justify-content-between w-100 defCont px-2 dd px-0" (click)="open_users = !open_users"><span>{{selected_type_user_reg.user | uppercase}}
                                                            </span><i class="fas my-auto fa-caret-down"></i></div>
                                            <div (clickOutside)="open_users = false;" [exclude]="'.posboton'" *ngIf="open_users && type_users.length > 0" style="z-index: 10000; width: max-content;" class="py-0 dropdown-menu d-block">
                                                <div *ngFor="let type_user of type_users | slice:1; let i=index">
                                                    <P [ngClass]="{'selectedArea': selected_type_user_reg.user == type_user.user}" style="cursor: pointer;" (click)="setTypeUser(type_user, 'reg')" class="optionArea px-2 py-2 mb-0">{{type_user.user | uppercase}}</P>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col pl-2 pr-0">
                                    <div class="form__title">
                                        User e-mail
                                    </div>
                                    <div class="w-100" [ngClass]="{'text-danger': !register.controls['email'].pristine && !register.controls['email'].valid }">
                                        <input id="email" class="input__form inputEdit apForm py-1 pl-2 pr-0 form-control" type="email" placeholder="Email" formControlName="email" [ngClass]="{'border border-danger': !register.controls['email'].pristine && !register.controls['email'].valid }">
                                        <div *ngIf="register.controls['email'].errors?.required && !register.controls['email'].pristine" class="form-control-feedback text-danger">
                                            This field is required
                                        </div>
                                        <div *ngIf="!register.controls['email'].valid && !register.controls['email'].pristine" class="form-control-feedback text-danger">
                                            Invalid email address
                                        </div>
                                        <div *ngIf="!email" class="form-control-feedback text-danger">
                                            Email already registered
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 90px;" class="col-12 px-0">
                            <div [ngClass]="{'text-danger': !register.controls['phone'].pristine && !register.controls['phone'].valid }">
                                <div class="form__title">
                                    User mobile number
                                </div>
                                <div class="input-group w-100">
                                    <div class="row no-gutters input-group w-100">
                                        <div class="col-6 pl-0 pr-2">
                                            <div class="w-100 position-relative">
                                                <input class="form-control inputEdit w-100 py-1 pl-2 pr-0 input__form apForm" placeholder="Country" formControlName="country" [ngClass]="{'border border-danger': !register.controls['country'].pristine && !register.controls['country'].valid }" (keyup)="getCountry($event)"
                                                    type="text">
                                                <div style="min-width: 100%; top: 100%; z-index: 10; width: max-content; max-height: 33vh; overflow-y: auto;" class="position-absolute" *ngIf="selectedCountry.length > 0">
                                                    <option style="cursor: pointer;" class="w-100 bg-white optForm text-truncate px-1 py-2" *ngFor="let country of selectedCountry" (click)="setCountry(country)">{{country.name}}</option>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pl-2 pr-0">
                                            <div class="w-100 pl-2 bg-white row no-gutters">
                                                <span class="my-auto" style="height: max-content;" *ngIf="prefix != 0">+ {{prefix}}</span>
                                                <input class="col inputEdit py-1 pl-2 pr-0 input__form apForm" placeholder="Phone number" formControlName="phone" [ngClass]="{'border border-danger': !register.controls['phone'].pristine && !register.controls['phone'].valid }" type="tel">
                                            </div>
                                            <div *ngIf="!register.controls['phone'].valid && !register.controls['phone'].pristine" class="form-control-feedback w-100 text-danger">That is an invalid number</div>
                                        </div>
                                        <div *ngIf="!register.controls['country'].valid && !register.controls['country'].pristine" class="form-control-feedback w-100 text-danger">Please select one option</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 90px;" class="col-12 px-0">
                            <div class="row no-gutters input-group w-100">
                                <div class="form__title">
                                    Date Of Birth <span class="text-uppercase">(yyyy-mm-dd)</span>
                                </div>
                                <div class="row no-gutters w-100">
                                    <div class="w-100">
                                        <div class="w-100">
                                            <input id="date_of_birth" class="inputEdit form-control py-1 pl-2 pr-0 input__form apForm" type="text" placeholder="YYYY-MM-DD" formControlName="date_of_birth">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 90px;" class="col-12 px-0">
                            <div class="row no-gutters input-group w-100">
                                <div class="form__title">
                                    Password
                                </div>
                                <div class="row no-gutters w-100">
                                    <div class="col px-0" [ngClass]="{'text-danger': !register.controls['password'].pristine && !register.controls['password'].valid }">
                                        <input *ngIf="mostrar_reg == true" (keyup)="noIgual('register')" id="password" class="inputEdit form-control py-1 pl-2 pr-0 input__form apForm w-100" type="text" placeholder="Password" formControlName="password" [ngClass]="{'border border-danger': !register.controls['password'].pristine && !register.controls['password'].valid }">
                                        <input *ngIf="!mostrar_reg" (keyup)="noIgual('register')" id="password" class="inputEdit form-control py-1 pl-2 pr-0 input__form apForm w-100" type="password" placeholder="Password" formControlName="password" [ngClass]="{'border border-danger': !register.controls['password'].pristine && !register.controls['password'].valid }">
                                    </div>
                                    <div class="px-3 d-flex" (click)="mostrar_reg = !mostrar_reg">
                                        <span class="my-auto">
                                        <i class="fe-icon-eye eyefill"></i>
                                    </span>
                                    </div>
                                </div>
                                <div *ngIf="!register.controls['password'].valid || not_valid_reg == true" class="w-100 d-flex">
                                    <div *ngIf="!register.controls['password'].valid && !register.controls['password'].pristine" class="form-control-feedback pr-2 text-danger">
                                        This field is required
                                    </div>
                                    <div *ngIf="(!register.controls['password'].valid || not_valid_reg == true) && !register.controls['password'].pristine" class="form-control-feedback text-danger">
                                        (8 characters, combine numbers and letters)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 90px;" class="col-12 px-0">
                            <div class="row no-gutters input-group w-100">
                                <div class="form__title">
                                    Confirm Password
                                </div>
                                <div class="row no-gutters w-100">
                                    <div class="w-100">
                                        <div class="w-100" [ngClass]="{'text-danger': !register.controls['password_confirmation'].pristine && !register.controls['password_confirmation'].valid }">
                                            <div class="row no-gutters w-100">
                                                <div class="col px-0">
                                                    <input *ngIf="mostrar_conf == true" (keyup)="noIgualReg()" id="password" class="inputEdit form-control py-1 pl-2 pr-0 input__form apForm w-100" type="text" placeholder="Confirm Password" formControlName="password_confirmation" [ngClass]="{'border border-danger': !register.controls['password_confirmation'].pristine && !register.controls['password_confirmation'].valid }">
                                                    <input *ngIf="!mostrar_conf" (keyup)="noIgualReg()" id="password_confirmation" class="inputEdit form-control py-1 pl-2 pr-0 input__form apForm w-100" type="password" placeholder="Confirm Password" formControlName="password_confirmation" [ngClass]="{'border border-danger': !register.controls['password_confirmation'].pristine && !register.controls['password_confirmation'].valid }">
                                                </div>
                                                <div class="px-3 d-flex" (click)="mostrar_conf = !mostrar_conf">
                                                    <span class="my-auto">
                                                    <i class="fe-icon-eye eyefill"></i>
                                                </span>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div *ngIf="register.controls['password_confirmation'].errors?.required && !register.controls['password_confirmation'].pristine" class="form-control-feedback pr-2 text-danger">
                                                    This field is required
                                                </div>
                                                <div *ngIf="not_valid_match == true" class="form-control-feedback text-danger">
                                                    Passwords do not match
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: max-content;" class="w-100 justify-content-end row no-gutters py-2 px-4">
                        <button [ngClass]="{'disabled': !register.valid || not_valid_reg || not_valid_match}" [disabled]="!register.valid || not_valid_reg || not_valid_match" class="btn btn-sub px-2 text-white" style="width: max-content;" type="submit">Register</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- <a style="display: none" data-target="#passwordCheck" #passChange data-toggle="modal"></a> -->
<!-- <div class="modal fade" id="avatarChange" tabindex="-1" role="dialog">
    <div class="modal-dialog  modal-dialog-centered " role="document">
        <div class="modal-content">
            <form class="w-100" [formGroup]="formularioModalPic">
                <div class="modal-header">
                    <div class=" inputavatar d-flex justify-content-between">
                        <div class=" pr-5 input-group">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" (change)="fileChangeEvent($event)" formControlName="picture" accept="image/*">
                                <label class="custom-file-label">Choose file</label>
                            </div>
                        </div>
                        <i (click)="preImg=false" data-dismiss="modal" class=" fas avatarclose fa-times"></i>
                    </div>
                </div>
                <div class="modal-body">
                    <image-cropper *ngIf="!preImg" class="mb-1 imageCroppper" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1" [resizeToWidth]="128" format="png jpg" (imageCropped)="imageCropped($event)"></image-cropper>
                    <img *ngIf="preImg" class="previewCrop mb-3" lazyload={{croppedImage}} />
                    <div class="modal-footer pb-0 px-0">
                        <button (click)="cargarPreImg()" [disabled]="!croppedImage" type="button" class="btn loadImg btn-success" *ngIf="croppedImage && !preImg">
    Crop
    </button>
                        <button (click)="cargarPreImg()" type="button" class=" btn btn-success" *ngIf="preImg">
    Re-crop
    </button>
                        <button (click)="changeImage(); preImg = false" type="button" class="btn btn-success" data-dismiss="modal" *ngIf="preImg">
    Load image
    </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->
<div class="modal fade" id="passwordCheck" tabindex="-1" role="dialog">
    <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
        <div class="modal-content row no-gutters">
            <div class="modal-header d-flex justify-content-between px-3 py-3 col-12" style="height: 80px;">
                <h3>Change Password</h3>
                <i (click)="cleanInputs()" #passModal data-dismiss="modal" style="cursor: pointer; font-size: 25px; font-weight: bolder;" class="fas fa-times"></i>
            </div>
            <div class="modal-body row">
                <form class="w-100" [formGroup]="formularioModalPass">
                    <div class="col-7 mx-auto px-0 profileBlock" *ngIf="passwordOk ">
                        <label for="password">New password</label>
                        <div class="input-group form-group">
                            <input *ngIf="!mostrar1" class="form-control py-1 pl-2 pr-0 input__form apForm" type="password" autocomplete="new-password" placeholder="New Password" formControlName="password" />
                            <input *ngIf="mostrar1" id="password" class="form-control py-1 pl-2 pr-0 input__form apForm" type="text" autocomplete="new-password" placeholder="New Password" formControlName="password" />
                            <div class="input-group-prepend" (click)="mostrar1 = !mostrar1"><span class="input-group-text"><i
                          class="fe-icon-eye eyefill"></i></span></div>
                        </div>
                    </div>
                    <div class="col-7 mx-auto px-0 profileBlock" *ngIf="passwordOk ">
                        <label for="confirm-password">Confirm password</label>
                        <div class="input-group form-group mb-0">
                            <input *ngIf="!mostrar2" (keydown.enter)="prevent($event); changePassword()" id="confirm-password" class="form-control py-1 pl-2 pr-0 input__form apForm" type="password" autocomplete="new-password" placeholder="Confirm Password" formControlName="password2"
                                [ngClass]="{ 'border border-danger': formularioModalPass.controls['password2'].invalid }" />
                            <input *ngIf="mostrar2" (keyup)="noIgual()" (keydown.enter)="prevent($event); changePassword()" id="confirm-password" class="form-control py-1 pl-2 pr-0 input__form apForm" type="text" autocomplete="new-password" placeholder="Confirm Password" formControlName="password2"
                                [ngClass]="{ 'border border-danger': formularioModalPass.controls['password2'].invalid }" />
                            <div class="input-group-prepend" (click)="mostrar2 = !mostrar2">
                                <span class="input-group-text"><i class="fe-icon-eye eyefill"></i></span>
                            </div>
                        </div>
                        <div *ngIf="notSame" class="form-control-feedback text-danger">
                            Passwords don't match
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer col-12 pl-0 pr-3">
                <a (click)="changePassword()" data-dismiss="modal" class="btn btn-style-6 btn-md btn-altea d-block my-auto text-white">Change password</a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="registerOK" class="iziToast-wrapper iziToast-wrapper-topCenter">
    <div class="iziToast iziToast-success mx-auto mt-5 maxwidth">
        <div class="iziToast-body pl">
            <!-- <i class="iziToast-icon fe-icon-check-circle"></i> -->
            <strong class="iziToast-tit">Registration</strong>
            <p>Successful!</p>
            <!-- <p>A confirmation email has been sent to the provided email adress</p> -->
        </div>
    </div>
</div>
<div *ngIf="alertMess" class="iziToast-wrapper iziToast-wrapper-topCenter">
    <div class="iziToast iziToast-success mx-auto mt-5 maxwidth">
        <div class="iziToast-body pl">
            <!-- <i class="iziToast-icon fe-icon-check-circle"></i> -->
            <strong class="iziToast-tit">Update</strong>
            <p>Successful!</p>
            <!-- <p>A confirmation email has been sent to the provided email adress</p> -->
        </div>
    </div>
</div>
<div *ngIf="archive_mess" class="iziToast-wrapper iziToast-wrapper-topCenter">
    <div class="iziToast iziToast-success mx-auto mt-5 maxwidth">
        <div class="iziToast-body pl">
            <!-- <i class="iziToast-icon fe-icon-check-circle"></i> -->
            <strong class="iziToast-tit">Archive</strong>
            <p>Successful!</p>
            <!-- <p>A confirmation email has been sent to the provided email adress</p> -->
        </div>
    </div>
</div>
<div *ngIf="registerBAD" class="fade-in fade-out iziToast-wrapper iziToast-wrapper-topCenter">
    <div class="iziToast iziToast-danger mx-auto mt-5 maxwidth">
        <div class="iziToast-body pl">
            <!-- <i class="iziToast-icon fe-icon-check-circle"></i> -->
            <strong class="iziToast-tit">Registration</strong>
            <p>There has been a issue with your registration</p>
        </div>
    </div>
</div>