import { Component, OnInit, Input } from '@angular/core';
// import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LabInfoService } from 'src/app/services/lab-info.service';

@Component({
  selector: 'app-imgcropper',
  templateUrl: './imgcropper.component.html',
  styleUrls: ['./imgcropper.component.css']
})
export class ImgcropperComponent implements OnInit {

  @Input() type;
  constructor(public labInfo: LabInfoService) { }
  ngOnInit() {
  }
  imageChangedEvent: any = '';
  imageChangedEvent2: any = '';
  disableButtonImg = false;
  countCarousel = 0;
  indexCalc(index) {
    if (this.countCarousel != 0 && this.labInfo.degree.images.length > 1) {
      if (this.labInfo.degree.images[index + 1] == undefined) {
        this.disableButtonImg = true;
      } else {
        this.disableButtonImg = false;
      }
    }
    this.countCarousel = 1;
  }
  imageSelected;
  fileChangeEvent(event: any): void {
    console.log(event);
    if (!event) {
      this.labInfo.croppedImage = null
      return;
    }
    if (event.target != undefined) {
      this.imageChangedEvent = event;
      this.imageSelected = null;
      console.log(this.imageChangedEvent);
      return;
    } else {
      this.imageSelected = event;
      this.imageChangedEvent = undefined;
    }
  }
  imageSelected2;
  fileChangeEvent2(event: any): void {
    console.log(event);
    if (!event) {
      this.labInfo.croppedImage2 = null
      return;
    }
    if (event.target != undefined) {
      this.imageChangedEvent2 = event;
      this.imageSelected2 = null;
      console.log(this.imageChangedEvent2);
      return;
    } else {
      this.imageSelected2 = event;
      this.imageChangedEvent2 = undefined;
    }
  }
  imageCropped(event) {
    this.labInfo.croppedImage = event.base64;
  }
  cargarPreImg() {
    this.labInfo.preImg = !this.labInfo.preImg;
  }
  imageCropped2(event) {
    this.labInfo.croppedImage2 = event.base64;
  }
  cargarPreImg2() {
    this.labInfo.preImg2 = !this.labInfo.preImg2;
  }


  setImage() {
    if (this.labInfo.imageSet.type == 'degree') {
      this.labInfo.setDegreeImage(this.labInfo.imageSet.idProduct, this.labInfo.croppedImage2).subscribe(data => {
        for (let index = 0; index < this.labInfo.CertList.degrees.length; index++) {
          if (this.labInfo.CertList.degrees[index].area.id == this.labInfo.imageSet.idProduct) {
            this.labInfo.CertList.degrees[index].area.picture = this.labInfo.croppedImage2;
          }
        }
        for (let index = 0; index < this.labInfo.PECList.degrees.length; index++) {
          if (this.labInfo.PECList.degrees[index].area.id == this.labInfo.imageSet.idProduct) {
            this.labInfo.PECList.degrees[index].area.picture = this.labInfo.croppedImage2;
          }
        }
        this.labInfo.alertMess = true;
        setTimeout(() => {
          this.labInfo.alertMess = false;
        }, 4000);
      });
    } else if (this.labInfo.imageSet.type == 'subject') {
      this.labInfo.setSubjectImage(this.labInfo.imageSet.idProduct, this.labInfo.croppedImage2).subscribe(data => {
        for (let index = 0; index < this.labInfo.CertList.subjects.length; index++) {
          if (this.labInfo.CertList.subjects[index].area.id == this.labInfo.imageSet.idProduct) {
            this.labInfo.CertList.subjects[index].area.picture = this.labInfo.croppedImage2;
          }
        }
        for (let index = 0; index < this.labInfo.PECList.subjects.length; index++) {
          if (this.labInfo.PECList.subjects[index].area.id == this.labInfo.imageSet.idProduct) {
            this.labInfo.PECList.subjects[index].area.picture = this.labInfo.croppedImage2;
          }
        }
        this.labInfo.alertMess = true;
        setTimeout(() => {
          this.labInfo.alertMess = false;
        }, 4000);
      });
    } else if (this.labInfo.imageSet.type == 'faculty') {
      this.labInfo.setFacultyImage(this.labInfo.imageSet.idProduct, this.labInfo.croppedImage2).subscribe(data => {
        for (let index = 0; index < this.labInfo.CertList.faculties.length; index++) {
          if (this.labInfo.CertList.faculties[index].area.id == this.labInfo.imageSet.idProduct) {
            this.labInfo.CertList.faculties[index].area.picture = this.labInfo.croppedImage2;
          }
        }
        for (let index = 0; index < this.labInfo.PECList.faculties.length; index++) {
          if (this.labInfo.PECList.faculties[index].area.id == this.labInfo.imageSet.idProduct) {
            this.labInfo.PECList.faculties[index].area.picture = this.labInfo.croppedImage2;
          }
        }
        this.labInfo.alertMess = true;
        setTimeout(() => {
          this.labInfo.alertMess = false;
        }, 4000);
      });
    }
  }
}
