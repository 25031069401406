import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import * as $ from 'jquery';
declare var $: any;


export class progressInfo {
  name: string = '';
  indexName;
  startValue;
  studentStartValue: string = '';
  studentProgress: number = 0;
  targetValue;
  studentTargetValue;
  studentGap;
  teacherGap;
}

@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.css']
})
export class GoalsComponent implements OnChanges {

  @Input() groupGoals;
  
  studentGoalsInfo;
  studentGoals;
  teacherGoals;
  studentActivityProfile = new Array();
  teacherActivityProfile = new Array();
  group_id;


  constructor(public admin: AdminService) { }

  ngOnChanges(changes){
    if (changes.groupGoals.currentValue.group_id != this.group_id){
      this.getGroupUsersActivityProfile();  
    }
  }

  getGroupUsersActivityProfile(){
    this.group_id = this.admin.goalsGroup.group_id;
    this.admin.getGroupUsersActivityProfile(this.admin.goalsGroup.group_id, this.admin.goalsGroup.campus_id, this.admin.goalsGroup.degree_id ).subscribe((data: any) => {
      this.admin.groupGoals = data;
      this.setStudentsGoals();
    });

  }

  setStudentsGoals(){

  this.admin.groupGoals.forEach( infoGoal =>{
    infoGoal.forEach( userGoal =>{
      this.admin.areaGroupsUsers.forEach( student =>{
        if (student.user_id == userGoal.user_id && userGoal.user_id != 1176){
          userGoal.name = student.name + ' ' + student.last_name;
        }
      });
    });
  });

  this.studentActivityProfile = new Array();  
  this.teacherActivityProfile = new Array();

  for (let index = 0; index < 2; index++){
    for (let i = 0; i <  this.admin.groupGoals[index].length; i++){
      if (this.admin.groupGoals[index][i].user_id != 1176){

      let teacherActivityProfileAux = new Array()
      let studentActivityProfileAux = new Array()
      let goalsInfo =  this.admin.groupGoals[index][i];
      let studentInfoAux =  Object.entries(goalsInfo).map( ([name, value]) => ({name, value}));;

        let resourcesAux = {
          name: 'Resources',
          types: new Array()
        }
        let skillsAux = {
          name: 'Skills',
          types: new Array()
        }

        let professionalAux = {
          name: 'Professional expertise',
          types: new Array()
        }


      for (let i = 0; i < studentInfoAux.length; i++){
        if (studentInfoAux[i].name.indexOf('start') != -1){
          if (studentInfoAux[i].name.indexOf('discourse_markers') != -1 || studentInfoAux[i].name.indexOf('grammar') != -1 || studentInfoAux[i].name.indexOf('vocabulary') != -1 || studentInfoAux[i].name.indexOf('terminology') != -1){
            let resourcesInfo = new progressInfo();
            if (studentInfoAux[i].name.indexOf('discourse_markers') != -1){
              resourcesInfo.name = 'Case U&S';
              resourcesInfo.startValue = Number(studentInfoAux[i].value);
              resourcesInfo.studentStartValue = this.studentActivityProfile[studentInfoAux[i].name];
            } else{
              resourcesInfo.name = studentInfoAux[i].name.split('_start')[0];
              resourcesInfo.startValue = Number(studentInfoAux[i].value);
              resourcesInfo.studentStartValue = this.studentActivityProfile[studentInfoAux[i].name];
            }
            resourcesAux.types.push(JSON.parse(JSON.stringify(resourcesInfo)));
          } else if (studentInfoAux[i].name.indexOf('reading') != -1 || studentInfoAux[i].name.indexOf('listening') != -1 || studentInfoAux[i].name.indexOf('speaking') != -1 || studentInfoAux[i].name.indexOf('writing') != -1){
            let skillsInfo = new progressInfo();
            skillsInfo.name = studentInfoAux[i].name.split('_start')[0];
            skillsInfo.startValue = Number(studentInfoAux[i].value);
            skillsInfo.studentStartValue = this.studentActivityProfile[studentInfoAux[i].name];
            skillsAux.types.push(JSON.parse(JSON.stringify(skillsInfo)));
          } else if (this.admin.selectedGroup.campus_id != 1 && index == 1 && studentInfoAux[i].name.indexOf('professional_expertise') != -1){
            let professionalExpertiseInfo = new progressInfo();
            professionalExpertiseInfo.name = 'Px';
            professionalExpertiseInfo.startValue = Number(studentInfoAux[i].value);
            professionalExpertiseInfo.studentStartValue = this.studentActivityProfile[studentInfoAux[i].name];
            professionalAux.types.push(JSON.parse(JSON.stringify(professionalExpertiseInfo)));
          }
        } else{
          if (studentInfoAux[i].name.indexOf('discourse_markers') != -1 || studentInfoAux[i].name.indexOf('grammar') != -1 || studentInfoAux[i].name.indexOf('vocabulary') != -1 || studentInfoAux[i].name.indexOf('terminology') != -1){
            resourcesAux.types.forEach( type =>{
              if (type.name == 'Case U&S' && studentInfoAux[i].name.indexOf('discourse_markers') != -1 && studentInfoAux[i].name.split('_target')[0]){    
                  type.targetValue = Number(studentInfoAux[i].value);
                  type.studentTargetValue = this.studentActivityProfile[studentInfoAux[i].name];
                  // type.num_reviews_certified = this.studentProgress.resources['case u&s'].num_reviews_certified;
                  // type.part = this.studentProgress.resources['case u&s'].part;
                  // type.studentProgress = Number((this.studentProgress.resources['case u&s'].num_reviews_certified * ((type.targetValue -  type.startValue)*this.studentProgress.resources['case u&s'].part)).toFixed(0))
                  if (index == 0){
                    type.studentGap = type.targetValue - type.startValue;
                  } else if (index == 1){
                    type.teacherGap = type.targetValue - type.startValue;
                  }
             } else{
              if (type.name == studentInfoAux[i].name.split('_target')[0]){
                  type.targetValue = Number(studentInfoAux[i].value);
                  type.studentTargetValue = this.studentActivityProfile[studentInfoAux[i].name];
                  // type.num_reviews_certified = this.studentProgress.resources[type.name].num_reviews_certified;
                  // type.part = this.studentProgress.resources[type.name].part;
                  // type.studentProgress = Number((this.studentProgress.resources[type.name].num_reviews_certified * ((type.targetValue -  type.startValue)*this.studentProgress.resources[type.name].part)).toFixed(0))
                  if (index == 0){
                    type.studentGap = type.targetValue - type.startValue;
                  } else if (index == 1){
                    type.teacherGap = type.targetValue - type.startValue;
                  }
                }
              }
            })
          } else if (studentInfoAux[i].name.indexOf('reading') != -1 || studentInfoAux[i].name.indexOf('listening') != -1 || studentInfoAux[i].name.indexOf('speaking') != -1 || studentInfoAux[i].name.indexOf('writing') != -1){
            skillsAux.types.forEach( type => {
              if (type.name == studentInfoAux[i].name.split('_target')[0]){
                type.targetValue =  Number(studentInfoAux[i].value);
                type.studentTargetValue = this.studentActivityProfile[studentInfoAux[i].name];
                // type.num_reviews_certified = this.studentProgress.skills[type.name].num_reviews_certified;
                // type.part = this.studentProgress.skills[type.name].part;
                // type.studentProgress = Number((this.studentProgress.skills[type.name].num_reviews_certified * ((type.targetValue -  type.startValue)*this.studentProgress.skills[type.name].part)).toFixed(0))
                if (index == 0){
                  type.studentGap = type.targetValue - type.startValue;
                } else if (index == 1){
                  type.teacherGap = type.targetValue - type.startValue;
                }
              }
            });
          } else if (this.admin.selectedGroup.campus_id != 1 && index == 1 && studentInfoAux[i].name.indexOf('professional_expertise') != -1){
            professionalAux.types.forEach( type => {
              if (type.name == 'Px'){
                type.targetValue =  Number(studentInfoAux[i].value);
                // type.num_reviews_certified = this.studentProgress.professional_expertise.px.num_reviews_certified;
                // type.part = this.studentProgress.professional_expertise.px.part;
                // type.studentProgress = Number((this.studentProgress.professional_expertise.px.num_reviews_certified * ((type.targetValue -  type.startValue)*this.studentProgress.professional_expertise.px.part)).toFixed(0))
                if (index == 0){
                  type.studentGap = type.targetValue - type.startValue;
                } else if (index == 1){
                  type.teacherGap = type.targetValue - type.startValue;
                }
              }
            });
          }
        }
      }
      if (index == 0){
        studentActivityProfileAux.push(resourcesAux);
        studentActivityProfileAux.push(skillsAux);
        studentActivityProfileAux['name'] = goalsInfo.name;
        studentActivityProfileAux['user_id'] = goalsInfo.user_id;
        this.studentActivityProfile.push(studentActivityProfileAux)
      } else{     
        teacherActivityProfileAux.push(resourcesAux);
        teacherActivityProfileAux.push(skillsAux);
        teacherActivityProfileAux['name'] = goalsInfo.name;
        teacherActivityProfileAux['user_id'] = goalsInfo.user_id;
        this.teacherActivityProfile.push(teacherActivityProfileAux)
      }

      }
    }
  }  
  //  })
  }



  studentGoalsView(user_id){
  
   this.studentGoals = this.studentActivityProfile.find( student => student.user_id == user_id);
   this.teacherGoals = this.teacherActivityProfile.find( student => student.user_id == user_id);

  }





  closeModal(){
    $("#studentsGoals").modal('hide');
    return;
  }



}
