import { Component, ViewChild, ElementRef, Input, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { AuthService } from 'src/app/services/auth.service';
import { AdminService } from 'src/app/services/admin.service';
import { CryptoService } from 'src/app/services/crypto-js.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css'],
})
export class FileUploaderComponent implements OnInit {
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef; 
  @Input() fileType: string;

  viewPDF: boolean = false;
  currentFile;
  files: Array<any> = new Array();
  search_word;
  // fileType;
  // private alreadyRendered: Array<HTMLSpanElement> = [];

  constructor(private cryptoService: CryptoService, public auth: AuthService, public admin: AdminService, private http: HttpClient) {
  }

  ngOnInit(): void {
    if (this.fileType != 'image') {
      this.auth.workingArea = 'uploader';
      this.getFiles();
    }
  }

  getFiles() {
      this.http.get(`${environment.apiUrl}/api/uploader/getFiles`).subscribe((data: any) => {
        data.forEach(element => {
          this.files.push(element);
        });
        this.files.sort();
      });

  }
  /**
   * on file drop handler
   */
  onFileDropped(event) {
    // this.prepareFilesList(event);
    // this.uploadFiles();
    let formData: FormData = new FormData();
    for (const item of event) {
      formData.append('files_content[]', item);
    }
    formData.append('origin', 'admin');
    this.http.post(`${environment.apiUrl}/api/uploader/uploadFiles`, formData ).subscribe((data: any) => {
      data.forEach(element => {
        this.files.push(element);
      });
      this.files.sort(); 
    }); 
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  openFile(index) {
    this.viewPDF = true;
    let file_id = this.files[index].file_id; 
    this.http.get(`${environment.apiUrl}/api/uploader/getFileInfo`, { params: { 'file_id': file_id } }).subscribe((data: any) => {  
      data = this.cryptoService.CryptoJSAesDecrypt('12345678A*', data);
      this.currentFile = new URL('https://pecblobs.blob.core.windows.net/professionalpdfs/pdf_' + file_id + '.pdf' + '?' + data);
    }); 
  }

  closeFile() {
    this.viewPDF = false;
    this.currentFile = null;
  }

  /**
   * 
   * @param index (File index)
   */
  deleteFile(index: number) {
    let file_id = this.files[index].file_id; 

    this.http.post(`${environment.apiUrl}/api/uploader/deleteFile`,  { 'file_id': file_id }).subscribe((data: any) => {  
    }); 
    this.files.splice(index, 1);
    this.viewPDF = false;
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<File>) {
    for (const item of files) {
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
  }

  private _showTextLayer = false;

  public get showTextLayer(): boolean {
    return this._showTextLayer;
  }

  public set showTextLayer(layer: boolean) {
    this._showTextLayer = layer;
    const divs = document.getElementsByClassName('textLayer');
    for (let i = 0; i < divs.length; i++) {
      const div = divs.item(i);
      if (layer) {
        div.classList.add('show-text-layer');
      } else {
        div.classList.remove('show-text-layer');
      }
    }
  }

  public highlightWords(event): void {
    // event.source.textDivs.forEach((span) => {
    //   this.alreadyRendered.push(span);
    // });

    if (this.showTextLayer) {
      event.source.textDivs.forEach((span) => {
        span.classList.add('box');
      });
    }
  }
  

  handleTextLayerRender(event: any) {
    const textLayer = event.source.textLayer;
    const words = textLayer.textDivs;
    words.forEach((word) => {
      word.addEventListener('mouseup', () => {
        const highlightedText = window.getSelection().toString();
        if (highlightedText != '') {
          word.classList.add('box');

          this.search_word = highlightedText;
          console.log('Highlighted text:', highlightedText);
        }
      });
    });
  }
  

}