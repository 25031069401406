<div class="h-100" style="padding: 1% 0%; background-color: #203341; position: absolute; left: 19%; display: flex; transition-duration: 600ms; transition-timing-function: ease-in-out"  [appTooltip]="860" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%', 'width': admin.sidebarShow ? '85%' : '96%'}">
    <div style="width: 50%; flex-direction: column; margin-right: 1%;" class="h-100 d-flex">
        <div style="height: 30%"  [appTooltip]="861">
            <div style="padding-bottom: 2%;" class="w-100 h-100">
                <div class="h-100 w-100 bg-white" style="padding: 2%;">
                    <div class="w-100 d-flex">
                        <div class="title h-100"  [appTooltip]="862">
                            Certificates
                        </div>
                    </div>
                    <div  style="height: 81%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex" >
                        <div class="w-100 h-100" style="overflow-y: scroll;" *ngIf="admin.campusTests != undefined">
                            <div *ngFor="let group of admin.campusTests['testsCertificates']; let i = index" class="w-100 d-flex letterColor" style="cursor: pointer;" (click)="testSelected = group;" [ngClass]="{'groupSelected': group == testSelected}" >
                                <div class="groupInfo" style="width: 12%;"  [appTooltip]="865">
                                    {{group.name}}  
                                </div>
                                <div style="width: 75%;" class="d-flex">
                                    <div class="d-flex groupInfo" style="flex-direction: row; padding: 0;" *ngFor="let exam of group.exams">
                                        <div class="d-flex mx-2" *ngFor="let version of exam.versions">
                                            <span [ngStyle]="{'color': version.exercises != 0 ? '#1C4360' : 'rgb(181, 181, 181)', 'font-weight': version.exercises != 0 ? '550' : '500'}" style="width: max-content;"  [appTooltip]="866">
                                                {{exam.name}} {{version.name}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div  style="width: 6%; margin-left: auto" class="d-flex">
                                    <div style="font-size: 1.2vh; font-weight: 600; cursor: pointer; margin: 0px 12%" class="edit fa-cog fas my-auto"  [appTooltip]="867" (click)="goToLMS(group);"> </div>
                                </div>
                            </div>
                        </div>  
                    </div> 
                </div>
            </div>
        </div>  
        <div style="height: 70%;" class="bg-white">
            <div class="h-100 w-100" style="padding: 2%;">
                <div class="w-100 d-flex"  [appTooltip]="868">
                    <div class="title h-100">
                        PEC
                    </div>
                </div>
                <div  style="height: 81%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex letterColor">
                    <div  class="w-100 h-100" style="overflow-y: scroll;" *ngIf="admin.campusTests != undefined">
                        <div *ngFor="let group of admin.campusTests['testsPEC']; let i = index" class="w-100 d-flex" style="cursor: pointer;" (click)="testSelected = group;" [ngClass]="{'groupSelected': group == testSelected}">
                            <div class="groupInfo" style="width: 30%;"  [appTooltip]="865">
                                {{group.name}}  
                            </div>
                            <div class="d-flex groupInfo" style="flex-direction: row; width: 15%;" *ngFor="let exam of group.exams">
                                <div class="d-flex mx-2" *ngFor="let version of exam.versions">
                                    <span [ngStyle]="{'color': version.exercises != 0 ? '#1C4360' : 'rgb(181, 181, 181)', 'font-weight': version.exercises != 0 ? '550' : '500'}" style="width: max-content;" class="mx-2"  [appTooltip]="866">
                                        {{exam.name | titlecase}} {{version.name}}
                                    </span>
                                </div>
                            </div>
                            <div  style="width: 6%; margin-left: auto" class="d-flex">
                                <div style="font-size: 1.2vh; font-weight: 600; cursor: pointer; margin: 0px 12%" class="edit fa-cog fas my-auto" (click)="goToLMS(group);" [appTooltip]="867"></div>
                            </div>
                        </div>
                    </div>  
                </div> 
            </div>
        </div>    
    </div>
    <div  style="width: 48%;" class="h-100 bg-white" >
        <div *ngIf="testSelected != undefined" style="padding: 1.8%; height: 100%;"  [appTooltip]="868">     
            <div class="h-100 w-100 d-flex" style="overflow-y: scroll; flex-direction: column;">
                <div class="w-100 blackLetterColor" style="padding: 1.2% 0; " *ngFor="let exam of testSelected.exams">
                    <div class="w-100 d-flex title" [appTooltip]="869"> 
                       {{exam.name | titlecase}}
                    </div>
                    <div  style="margin-left: 3%;" *ngFor="let version of exam.versions" [appTooltip]="870">
                        <div class="w-100 d-flex title" [appTooltip]="871"> 
                           Versión : {{version.name}}
                          </div>
                        <div class="title2" style="font-weight: 600;" [appTooltip]="872">
                            URL : <span style="font-weight: 500;"> {{version.exam_id}} </span>
                        </div>
                        <div class="w-100 d-flex" *ngIf="version.exercises != 0">
                            <div  style="width: 50%; flex-direction: column;">
                                <div class="title2" style="font-weight: 600;" [appTooltip]="873">
                                    Part 1
                                </div>    
                                <div *ngFor="let exercise of version.exercises; let i = index" class="w-100 d-flex" style="flex-direction: column;">
                                    <div *ngIf="exercise.part == '0'" class="groupInfo" [appTooltip]="874">
                                        {{exercise.exercise_name}} - {{exercise.pod_name}}
                                    </div>
                                </div>
                            </div>
                            <div  style="width: 50%; flex-direction: column;">
                                <div class="title2" style="font-weight: 600;" [appTooltip]="875">
                                    Part 2
                                </div>    
                                <div *ngFor="let exercise of version.exercises; let i = index" class="w-100 d-flex" style="flex-direction: column;">
                                    <div *ngIf="exercise.part == '1'" class="groupInfo" [appTooltip]="876">
                                        {{exercise.exercise_name}} - {{exercise.pod_name}}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>   
            </div>
        </div>
    </div>
</div>


