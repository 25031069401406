import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { stringify } from '@angular/compiler/src/util';
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class degree {
  campus_id;
  faculties;
  degrees;
  subjects;
  labs;
}
@Injectable({
  providedIn: 'root'
})
export class pictureSet {
  idProduct;
  type;
}
@Injectable({
  providedIn: 'root'
})
export class LabInfoService {

  constructor(private http: HttpClient) { }
  imagenFondo;
  degree;
  croppedImage: any = '';
  croppedImage2: any = '';
  imagen = '../../../assets/img/space.jpg';
  preImg = false;
  preImg2 = false;
  imageSet = new pictureSet();
  alertMess = false;
  alertMess2 = false;
  product;
  PECList = new degree();
  CertList = new degree();

  changeImage() {
    this.imagen = this.croppedImage;
    setTimeout(() => {
      this.preImg = false;
    }, 50);

  }
  saveLab(name: string, image: string, description: string, list_id: string, key_lines: string, requirements: string, objectives: string, type, price: number, offer_price: number,stack_pos:string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/setLab`, { 'name': name, 'picture': image, 'description': description, 'list_id': list_id, 'key_lines': key_lines, 'requirements': requirements, 'objectives': objectives, 'type': type, 'price': String(price), 'offer_price': String(offer_price) , 'stack_pos': stack_pos});
  }
  getLists() {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getLists`);
  }
  getList(id: any) {
    return this.http.get(`${environment.apiUrl}/api/listadmin/getList`, { params: { 'id': id } });
  }
  getInitCourse(degree_id) {
    return this.http.get(`${environment.apiUrl}/api/courses/getInitCourse`, { params: { 'degree_id': degree_id } });
  }
  getLabsBySubject(subject_id) {
    return this.http.get(`${environment.apiUrl}/api/courses/getLabsBySubject`, { params: { 'subject_id': subject_id } });
  }

  saveCourse(nameDegree, subject_id, picture, texts_intros, course_id,labs_ids,fussionCourse) {
    labs_ids = JSON.stringify(labs_ids);
    texts_intros = JSON.stringify(texts_intros);
    return this.http.post(`${environment.apiUrl}/api/courses/saveCourse`, { 'name': nameDegree, 'subject_id': subject_id, 'picture': picture, 'texts_intros': texts_intros, 'degree_id': course_id,'labs_ids': labs_ids, 'fussionCourse': fussionCourse });
  }

  getContentWithoutImages() {
    return this.http.get(`${environment.apiUrl}/api/images/getContentWithoutImages`, { params: {} });
  }
  setFacultyImage(faculty_id, picture) {
    return this.http.post(`${environment.apiUrl}/api/images/setFacultyImage`, { 'faculty_id': faculty_id, 'picture': picture });
  }
  setDegreeImage(degree_id, picture) {
    return this.http.post(`${environment.apiUrl}/api/images/setDegreeImage`, { 'degree_id': degree_id, 'picture': picture });
  }
  setSubjectImage(subject_id, picture) {
    return this.http.post(`${environment.apiUrl}/api/images/setSubjectImage`, { 'subject_id': subject_id, 'picture': picture });

  }
  setSubjectColor(subject_id, color) {
    return this.http.post(`${environment.apiUrl}/api/images/setSubjectColor`, { 'subject_id': subject_id, 'color': color });

  }
  setFacultyColor(faculty_id, color) {
    return this.http.post(`${environment.apiUrl}/api/images/setFacultyColor`, { 'faculty_id': faculty_id, 'color': color });

  }
  setDegreeColor(degree_id, color) {
    return this.http.post(`${environment.apiUrl}/api/images/setDegreeColor`, { 'degree_id': degree_id, 'color': color });

  }
}
