import { Component, OnInit, Injectable, HostListener, ChangeDetectorRef } from "@angular/core";
import { InviteService } from '../../services/invite.service';
import { LmsService } from '../../services/lms.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';
import { AuthService } from 'src/app/services/auth.service';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { environment, roles } from 'src/environments/environment';
import { UserDataService } from "src/app/services/user-data.service";

@Injectable({
  providedIn: 'root'
})
export class group {
  teachers: Array<user> = new Array();
  users: Array<user> = new Array();
  application_users: Array<ap_user> = new Array();
  description: string = '';
  name: string = '';
  start: string = '';
  end: string = '';
  start_month: string = 'start';
  end_month: string = 'end';
  start_year: string = '';
  end_year: string = '';
  active: boolean = false;
  // archived: boolean = false;
  id: number = null;
  course_id: number = null;
  max_users: number = 0;
  showInfo: boolean = false;
  addedUsersGroup: Array<user> = new Array();
  addedTeachersGroup: Array<user> = new Array();
  days: Array<day> = new Array();
  pdf: string = '';
  invitation_url: string = '';
  filtered: boolean = true;
}
@Injectable({
  providedIn: 'root'
})
export class day {
  day: string = '';
  startHour: string = '';
  endHour: string = '';

}
@Injectable({
  providedIn: 'root'
})
export class user {
  id: number = null;
  name: string = '';
  last_name: string = '';
  email: string = '';
}

class Course {
  id: number;
  degree_id: number;
  subject_id: number;
  name: string = '';
}

class groupSet {
  active: boolean = false;
  group_id: number = null;
  group_name: string = '';
  numStudents: number = 0;
  course_id: number = null;
  trial: boolean = false;
  degree_id: number = null;
  subject_id: number = null;
  course_name: string = '';
  course_num_groups: number = 0;
  degree_name: string = '';
  subject_name: string = '';
  user_name: string = '';
  user_last_name: string = '';
  second_last_name: string = '';
  user_id: number = 0;
}

@Injectable({
  providedIn: 'root'
})
export class ap_user {
  id: number = null;
  name: string = '';
  last_name: string = '';
  email: string = '';
}

@Injectable({
  providedIn: 'root'
})
export class areaSet {
  open: boolean = false;
  id: number = null;
  name: string = '';
  hasCourse: boolean = false;
  num_courses: number = 0;
  subAreas = new Array();
}


@Component({
  selector: 'app-groups-component',
  templateUrl: './groups-component.component.html',
  styleUrls: ['./groups-component.component.css']
})
export class GroupsComponentComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize() {
    // //console.logwindow.innerWidth);
    // //console.logwindow.outerWidth);
    if (window.innerWidth <= 900) {
      this.lms.responsive = true;
    } else {
      this.lms.responsive = false;
    }
    this.cdf.detectChanges();
  }

  rolesAux;
  dateRange: UntypedFormGroup;
  emailInvite: UntypedFormControl;
  groupInfo = new UntypedFormGroup({});
  constructor(private cdf: ChangeDetectorRef, public auth: AuthService, public lms: LmsService, public invite: InviteService, private userData: UserDataService) {
    this.rolesAux = roles;
    this.emailInvite = new UntypedFormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]);
    this.dateRange = new UntypedFormGroup({
      "start": new UntypedFormControl('', [Validators.required]),
      "end": new UntypedFormControl('', [Validators.required]),
      "days": new UntypedFormControl(''),
      "hourStart": new UntypedFormControl('18', [Validators.required]),
      "hourEnd": new UntypedFormControl('20', [Validators.required]),
      "minStart": new UntypedFormControl('00', [Validators.required]),
      "minEnd": new UntypedFormControl('00', [Validators.required])
    });
  }

  days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  areaCourses = new Array();
  areaGroupsUsers = new Array();
  ngOnInit() {
    this.auth.workingArea = 'groups';
    this.refreshCourseModal();
    this.group_file = false;
    this.lms.getCatTree().subscribe((data: any) => {
      //console.log(data);
      for (let index = 0; index < data.campuses.length; index++) {
        let areaAux = new areaSet();
        areaAux.id = data.campuses[index].id;
        areaAux.name = data.campuses[index].name;
        for (let index2 = 0; index2 < data.campuses[index].faculties.length; index2++) {
          let facAux = new areaSet();
          facAux.id = data.campuses[index].faculties[index2].id;
          facAux.name = data.campuses[index].faculties[index2].name;
          for (let index3 = 0; index3 < data.campuses[index].faculties[index2].degrees.length; index3++) {
            let degAux = new areaSet();
            degAux.id = data.campuses[index].faculties[index2].degrees[index3].id;
            degAux.name = data.campuses[index].faculties[index2].degrees[index3].name;
            degAux.num_courses = data.campuses[index].faculties[index2].degrees[index3].num_courses;
            for (let index4 = 0; index4 < data.campuses[index].faculties[index2].degrees[index3].subjects.length; index4++) {
              let subAux = new areaSet();
              subAux.id = data.campuses[index].faculties[index2].degrees[index3].subjects[index4].id;
              subAux.name = data.campuses[index].faculties[index2].degrees[index3].subjects[index4].name;
              subAux.num_courses = data.campuses[index].faculties[index2].degrees[index3].subjects[index4].num_courses;
              if (subAux.num_courses > 0) {
                degAux.hasCourse = true;
              }
              degAux.subAreas.push(subAux);
            }
            if (degAux.hasCourse || degAux.num_courses > 0) {
              facAux.hasCourse = true;
            }
            facAux.subAreas.push(degAux);
          }
          if (facAux.hasCourse) {
            areaAux.hasCourse = true;
          }
          areaAux.subAreas.push(facAux);
        }
        for (let indexAux = 0; indexAux < areaAux.subAreas.length; indexAux++) {
          if (areaAux.subAreas[indexAux].hasCourse) {
            areaAux.hasCourse = true;
            break;
          }
        }
        this.campuses.push(areaAux);
      }
      this.campusesCourse = JSON.parse(JSON.stringify(this.campuses));
      //console.log(this.campuses);
    });


    // this.lms.getGroups(String(this.auth.user_id)).subscribe((data: any) => {
    //   this.areaGroups = new Array();
    //   this.groupName = 'Groups';
    //   for (let index = 0; index < data.length; index++) {
    //     let groupAux = new groupSet();
    //     groupAux.group_id = data[index].id;
    //     groupAux.course_name = data[index].course_name;
    //     groupAux.course_id = data[index].course_id;
    //     groupAux.group_name = data[index].name;
    //     groupAux.numStudents = data[index].numStudents;
    //     groupAux.degree_id = data[index].degree_id;
    //     groupAux.subject_id = data[index].subject_id;
    //     groupAux.active = true;

    //     if (data[index].trial == '1') {
    //       groupAux.trial = true;
    //     } else {
    //       groupAux.trial = false;
    //     }
    //     this.areaGroups.push(groupAux);
    //   }
    //   this.areaGroups = this.areaGroups.sort((g1, g2) => {
    //     if (g1.group_name.indexOf(' Placement Test') != -1) {
    //       return 1;
    //     } else {
    //       return -1;
    //     }
    //   });

    //   this.course_show = false;
    //   this.loadingGroup = false;
    // });

    this.lms.getUserGroups(String(this.auth.userData.user_id)).subscribe((data: any) => {
      this.areaGroupsUsers = new Array();
      this.groupName = 'Users';
      for (let index = 0; index < data.length; index++) {
        let groupAux = new groupSet();
        groupAux.course_id = data[index].course_id;
        groupAux.course_name = data[index].course_name;
        groupAux.group_id = data[index].group_id;
        groupAux.group_name = data[index].group_name;
        groupAux.active = true;
        groupAux.user_id = data[index].id;
        groupAux.user_name = data[index].name;
        groupAux.user_last_name = data[index].last_name;
        if (data[index].trial == '1') {
          groupAux.trial = true;
        } else {
          groupAux.trial = false;
        }
        this.areaGroupsUsers.push(groupAux);
      }

      this.areaGroupsUsers = this.areaGroupsUsers.sort((g1, g2) => {
        if (g1.group_name.indexOf(' Placement Test') != -1) {
          return 1;
        } else {
          return -1;
        }
      });
      this.course_show = false;
      this.loadingGroup = false;
    });

    this.lms.getCourses(String(this.auth.userData.user_id)).subscribe((data: any) => {
      this.areaCourses = new Array();
      this.groupName = 'Courses';
      for (let index = 0; index < data.length; index++) {
        let groupAux = new Course();
        groupAux.id = data[index].id;
        groupAux.name = data[index].name;
        groupAux.degree_id = data[index].degree_id;
        groupAux.subject_id = data[index].subject_id;

        this.areaCourses.push(groupAux);
      }

      this.course_show = false;
      this.loadingGroup = false;
    });

    if (window.innerWidth <= 900) {
      this.lms.responsive = true;
    } else {
      this.lms.responsive = false;
    }
  }

  invitedName = '';
  invitedLastName = '';
  invitedKey = '';
  autoKey = true;
  succesInvitation = false;
  invite_area = {
    area_id: null,
    area_name: 'DEGREE - SUBJECT',
    group_id: null,
    group_name: 'Curso'
  }
  dropdown_course_invite: boolean = false;
  arrayGroups = new Array();
  inviteUser(email) {
    if (!this.emailInvite.valid || this.invitedName == '') {
      return;
    }
    this.lms.inviteUser(this.invitedName, this.invitedLastName, this.emailInvite.value, this.invitedKey, this.invite_area.group_id).subscribe((data: any) => {
      this.invitedName = '';
      this.invitedLastName = '';
      this.invitedKey = '';
      this.emailInvite.markAsPristine();
      this.emailInvite.markAsUntouched();
      this.emailInvite.setValue('');
      this.autoKey = true;
      this.succesInvitation = true;
      this.invite_area.area_id = null;
      this.invite_area.area_name = '';
      this.invite_area.group_id = null;
      this.invite_area.group_name = '';
      setTimeout(() => {
        this.succesInvitation = false;
      }, 3000);
    });
  }

  setAuto() {
    this.autoKey = !this.autoKey;
    this.invitedKey = '';
  }

  setInvitationArea(area) {
    this.invite_area.area_name = area.name;
    this.invite_area.area_id = area.id;
    this.arrayAreas = new Array();
  }

  setInvitationGroup(group) {
    this.invite_area.group_id = group.name;
    this.invite_area.group_id = group.id;
  }

  arrayAreas = new Array();
  areaNameModalSearch: string = "Degree - Subject";
  courses_search = new Array();
  courses = new Array();
  courseNameSearch = 'Curso';
  dropdown_course_search: boolean = false;
  groups = new Array();
  refreshCourseModal() {
    this.arrayAreas = new Array();
    this.areaNameModalSearch = 'Degree - Subject';
    this.courses = new Array();
    this.courseNameSearch = 'Curso';
    this.dropdown_course_search = false;
    this.groups = new Array();
    this.groupsOn = true;
    this.setNewGroup();
  }

  groupsOn: boolean = false;
  setShowCourse(how) {
    this.arrayAreas = new Array();
    this.groupsOn = true;
    if (how == 'true') {
      this.setNewGroup();
    } else if (how == 'false') {
      this.group_file = false;
    }
  }

  back() {
    this.setNewGroup();
    this.group_file = false;
  }

  selected_group: group;
  editGroupName: boolean = false;
  group_file: boolean = false;
  setNewGroup() {
    let newGroup = new group();
    let year = new Date();
    let year_set = year.getFullYear();
    newGroup.start_year = String(year_set);
    newGroup.end_year = String(year_set);
    this.selected_group = newGroup;
    this.teacherSearch = new Array();
    this.userSearch = new Array();
    this.editGroupName = true;
    this.areaNameModal = 'Curso';
    this.courseName = 'Curso';
    this.course_id = '';
    this.courses = new Array();
    this.group_file = true;
  }


  userSearch = new Array();
  addUsers = false;
  searchUsersNew(search_value, group_id?) {
    if (search_value.length > 1) {
      this.lms.searchUsers(search_value, group_id).subscribe((data: any) => {
        this.userSearch = new Array();
        data.forEach(element => {
          this.userSearch.push(element);
        });
      });
    } else {
      this.userSearch = new Array();
    }
  }

  user_added: boolean = false;
  selectUserNew(user) {
    let found = false;
    for (let index = 0; index < this.selected_group.users.length; index++) {
      if (this.selected_group.users[index].id == user.id) {
        found = true;
        break;
      }
    }
    if (!found) {
      if (this.selected_group.id == null) {
        this.selected_group.users.push(user);
      } else {
        // this.lms.insertUsersToCourseGroup([user.id], this.selected_group.id).subscribe((data: any) => {
        //   this.selected_group.users.push(user);
        //   this.user_added = true;
        //   setTimeout(() => {
        //     this.user_added = false;
        //   }, 1500);
        // });
      }
      this.userSearch = new Array();
    }
  }

  teacherSearch = new Array();
  addTeachers = false;
  searchTeachersNew(search_value) {
    if (search_value.length > 1) {
      this.lms.searchTeacher(search_value).subscribe((data: any) => {
        this.teacherSearch = new Array();
        data.forEach(element => {
          this.teacherSearch.push(element);
        });
        //console.log(data);
      });
    } else {
      this.teacherSearch = new Array();
    }
  }

  teacher_added: boolean = false;
  selectTeacherNew(teacher) {
    let found = false;
    for (let index = 0; index < this.selected_group.teachers.length; index++) {
      if (this.selected_group.teachers[index].id == teacher.id) {
        found = true;
        break;
      }
    }
    if (!found) {
      if (this.selected_group.id == null) {
        this.selected_group.teachers.push(teacher);
      } else {
        this.lms.insertTeachersToGroup([teacher.id], this.selected_group.id).subscribe((data: any) => {
          this.teacher_added = true;
          setTimeout(() => {
            this.teacher_added = false;
          }, 1500);
          this.selected_group.teachers.push(teacher);
        });
      }
      this.teacherSearch = new Array();
    }
  }


  group_set;
  groupSetName = 'Nombre del conjunto de grupos a crear'
  groupset_id;
  group_id;
  groupCreated = false;
  creating_group = false;
  group_mess = '';
  group_alert = false;
  id_group: number = null;
  group_finish: string = '';
  createGroup() {
    console.log(this.creating_group)
    this.group_mess = '\n' + 'Faltan los siguientes atributos para generar el grupo:' + '\n';
    if (this.creating_group
      || this.selected_group.name.toLowerCase().indexOf(' trial ') == - 1 && (this.selected_group.name == ''
        || this.selected_group.teachers.length == 0
        || this.dateRange.controls['start'].value == '' && this.selected_group == undefined
        || this.selected_group != undefined && this.selected_group.end_month == 'end' && this.selected_group.end_year == ''
        || this.dateRange.controls['end'].value == '' && this.selected_group == undefined
        || this.selected_group != undefined && this.selected_group.start_month == 'start' && this.selected_group.start_year == ''
        || this.selected_group.days.length == 0)) {
      console.log(this.creating_group);

      if (this.selected_group.name == '') {
        this.group_mess = this.group_mess + '-Asignarle nombre al grupo' + '\n';
        this.group_mess = this.group_mess.substring(0, this.group_mess.length - 1);
        this.group_alert = true;
      }
      if (this.selected_group.teachers.length == 0) {
        this.group_mess = this.group_mess + '-Asignarle al menos un profesor' + '\n';
        this.group_mess = this.group_mess.substring(0, this.group_mess.length - 1);
        this.group_alert = true;
      }
      if (this.dateRange.controls['start'].value == '') {
        this.group_mess = this.group_mess + '-Asignarle una fecha de inicio' + '\n';
        this.group_mess = this.group_mess.substring(0, this.group_mess.length - 1);
        this.group_alert = true;
      }
      if (this.dateRange.controls['end'].value == '') {
        this.group_mess = this.group_mess + '-Asignarle una fecha de final' + '\n';
        this.group_mess = this.group_mess.substring(0, this.group_mess.length - 1);
        this.group_alert = true;
      }
      if (this.selected_group.days.length == 0) {
        this.group_mess = this.group_mess + '-Asignarle los dias de la semana en los que se dará el curso' + '\n';
        this.group_mess = this.group_mess.substring(0, this.group_mess.length - 1);
        this.group_alert = true;
      }

      return;
    }
    let teacher_ids = new Array();
    for (let index = 0; index < this.selected_group.teachers.length; index++) {
      teacher_ids.push(this.selected_group.teachers[index].id);
    }
    let user_ids = new Array();
    for (let index = 0; index < this.selected_group.users.length; index++) {
      user_ids.push(this.selected_group.users[index].id);
    }
    let nameGroupNew = this.selected_group.name;
    let arrayDays = new Array();
    let arrayStartHour = new Array();
    let arrayEndHour = new Array();
    for (let index = 0; index < this.selected_group.days.length; index++) {
      arrayDays.push(this.selected_group.days[index].day);
      arrayStartHour.push(this.selected_group.days[index].startHour);
      arrayEndHour.push(this.selected_group.days[index].endHour);
    }
    let startDate = null;
    let endDate = null;
    if (this.selected_group.name.toLowerCase().indexOf(' trial ') == - 1) {
      if (this.dateRange.controls['start'].value != '' && this.dateRange.controls['end'].value != '') {
        startDate = String(this.dateRange.controls['start'].value.toArray()[2]) + '/' + String(this.dateRange.controls['start'].value.toArray()[1] + 1) + '/' + String(this.dateRange.controls['start'].value.toArray()[0]);
        endDate = String(this.dateRange.controls['end'].value.toArray()[2]) + '/' + String(this.dateRange.controls['end'].value.toArray()[1] + 1) + '/' + String(this.dateRange.controls['end'].value.toArray()[0]);
      } else {
        if (this.selected_group != undefined && this.selected_group.id != null) {
          startDate = this.selected_group.start;
          endDate = this.selected_group.end;
        }
      }
    }
    this.creating_group = true;
    // this.lms.createGroupBack(teacher_ids, nameGroupNew, this.selected_group.description, startDate, endDate, this.selected_group.course_id, this.selected_group.id, this.selected_group.max_users, arrayStartHour, arrayEndHour, arrayDays).subscribe((data: any) => {
    //   this.id_group = data.id;
    //   if (user_ids.length > 0 && this.selected_group.id == null) {
    //     this.group_finish = 'creado';
    //     this.setNewGroupId();
    //     this.lms.insertUsersToCourseGroup(user_ids, this.selected_group.id).subscribe(data => {
    //       this.groupCreated = true;
    //     });
    //   } else if (user_ids.length == 0 && this.selected_group.id == null || this.selected_group.id != null) {
    //     if (this.selected_group.id != null) {
    //       this.group_finish = 'actualizado';
    //     } else {
    //       this.group_finish = 'creado';
    //       this.setNewGroupId();
    //     }
    //     this.groupCreated = true;
    //   }
    //   this.creating_group = false;
    //   this.createInvitationGroupUrl(this.selected_group);
    //   this.selected_group.name = nameGroupNew;
    //   if (this.groups.filter(group => group.id == this.selected_group.id).length == 0) {
    //     this.groups.push(JSON.parse(JSON.stringify(this.selected_group)));
    //   }
    // });
  }

  setNewGroupId() {
    this.selected_group.id = this.id_group;
  }

  addTeacherForGroup(group, teacher) {
    let found = false;
    for (let index = 0; index < group.addedTeachersGroup.length; index++) {
      if (group.addedTeachersGroup[index].id == teacher.id) {
        found = true;
        break;
      }
    }
    if (!found) {
      group.addedTeachersGroup.push(teacher);
    }
    this.teacherSearch = new Array();
  }

  addUserForGroup(group, student) {
    let found = false;
    for (let index = 0; index < group.addedUsersGroup.length; index++) {
      if (group.addedUsersGroup[index].id == student.id) {
        found = true;
        break;
      }
    }
    if (!found) {
      group.addedUsersGroup.push(student);
    }
    this.userSearch = new Array();
  }

  arrayUsers = [];
  quitUserFromGroup(student) {
    for (let index = 0; index < this.arrayUsers.length; index++) {
      if (student.id == this.arrayUsers[index].id) {
        this.arrayUsers.splice(index, 1);
        break;
      }
    }
  }

  resetValueFile(event) {
    event.target.value = '';
  }

  success_pdf = {
    active: false
  }
  sending_pdf = {
    active: false
  }
  loadGroupPDF(event, group) {
    //Read File
    var selectedFile = event.target.files;
    //Check File is not Empty
    if (selectedFile.length > 0) {
      // Select the very first file from list
      var fileToLoad = selectedFile[0];
      // FileReader function for read the file.
      var fileReader = new FileReader();
      var base64_pdf;
      // Onload of file read the file content
      fileReader.readAsDataURL(fileToLoad);
      var save_func = this.lms;
      let success_pdf = this.success_pdf;
      let sending_pdf = this.sending_pdf;
      fileReader.onload = function (fileLoadedEvent) {
        base64_pdf = fileLoadedEvent.target.result;
        // Print data in console
        //console.log(base64_pdf);
        sending_pdf.active = true;
        save_func.savePdfGroup(group.id, base64_pdf).subscribe((data: any) => {
          group.pdf = base64_pdf;
          sending_pdf.active = false;
          success_pdf.active = true;
          setTimeout(() => {
            success_pdf.active = false;
          }, 1500);
        }, err => {
          sending_pdf.active = false;
        });
      };
    }
  }

  containerWidth = '';
  desplegado: boolean = false;
  setPanel() {
    this.desplegado = true;
    if (this.containerWidth == '') {
      setTimeout(() => {
        this.containerWidth = String(document.getElementById('containerGroups').getBoundingClientRect().width / 2) + 'px';
      }, 100);
    }
  }

  donwLoadPDF(group) {
    //console.log(group.pdf);
    if (group.pdf != null && group.pdf != '') {
      const linkSource = group.pdf;
      const downloadLink = document.createElement("a");
      const fileName = 'Información ' + group.name + ".pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    }
  }

  success_url: boolean = false;
  createInvitationGroupUrl(group) {
    let url = "https://new.altealabs.com/#/" + 'admisiones/' + String(group.id);
    group.invitation_url = url;
    // this.lms.createUrlToGroupPdf(group.id).subscribe((data: any) => {
    //   group.invitation_url = data;
    //   this.success_url = true;
    //   setTimeout(() => {
    //     this.success_url = false;
    //   }, 2500);
    // });
  }

  success_copy: boolean = false;
  copyGroupUrl(group) {
    this.createInvitationGroupUrl(group);
    // navigator.clipboard.writeText(group.invitation_url);
    this.success_copy = true;
    setTimeout(() => {
      this.success_copy = false;
    }, 1000);
  }

  show_area_config: boolean = false;
  show_group_course_config: boolean = false;
  show_group_name_config: boolean = false;
  show_group_date_config: boolean = false;
  show_group_teachers_config: boolean = false;
  show_group_students_config: boolean = false;
  show_group_info_config: boolean = false;
  show_student_file: boolean = false;
  setModalGroup(where, event) {
    if (event.view.getSelection().toString().length === 0) {
      this.show_area_config = false;
      this.show_group_date_config = false;
      this.show_group_students_config = false;
      this.show_group_info_config = false;
      if (where == 'area') {
        this.show_area_config = true;
      } else if (where == 'date') {
        this.show_group_date_config = true;

      } else if (where == 'students') {
        this.show_group_students_config = true;

      } else if (where == 'info') {
        this.show_group_info_config = true;

      }
    }
  }

  student_file: user;
  showStudentFile(user_set) {
    this.show_area_config = false;
    this.show_group_date_config = false;
    this.show_group_students_config = false;
    this.show_group_info_config = false;
    this.student_file = user_set;
    this.show_student_file = true;
  }

  teacher_delete: boolean = false;
  cancelAddTeacher(teacher_id) {
    if (this.selected_group.id == null) {
      for (let index = 0; index < this.selected_group.teachers.length; index++) {
        if (this.selected_group.teachers[index].id == teacher_id) {
          this.selected_group.teachers.splice(index, 1);
          break;
        }
      }
    } else {
      if (this.selected_group.teachers.length == 1) {
        return;
      }
      this.lms.deleteTeacherToGroup(this.selected_group.id, teacher_id).subscribe((data: any) => {
        for (let index = 0; index < this.selected_group.teachers.length; index++) {
          if (this.selected_group.teachers[index].id == teacher_id) {
            this.selected_group.teachers.splice(index, 1);
            break;
          }
        }
        this.teacher_delete = true;
        setTimeout(() => {
          this.teacher_delete = false;
        }, 1500);
      });
    }
  }

  user_delete: boolean = false;
  cancelAddUser(user_id) {
    if (this.selected_group.id == null) {
      for (let index = 0; index < this.selected_group.users.length; index++) {
        if (this.selected_group.users[index].id == user_id) {
          this.selected_group.users.splice(index, 1);
          break;
        }
      }
    } else {
      this.lms.deleteUserToGroup(this.selected_group.id, user_id).subscribe((data: any) => {
        for (let index = 0; index < this.selected_group.users.length; index++) {
          if (this.selected_group.users[index].id == user_id) {
            this.selected_group.users.splice(index, 1);
            break;
          }
        }
        this.user_delete = true;
        setTimeout(() => {
          this.user_delete = false;
        }, 1500);
      });
    }
  }

  cancelDay(indexDay) {
    this.selected_group.days.splice(indexDay);
  }

  confirmDay() {
    if (this.dateRange.controls['days'].value == '' || this.dateRange.controls['hourStart'].value == '' || this.dateRange.controls['hourEnd'].value == '') {
      return;
    }
    //console.log(this.dateRange.controls['days'].value);
    //console.log(this.dateRange.controls['hourStart'].value);
    //console.log(this.dateRange.controls['minStart'].value);
    //console.log(this.dateRange.controls['hourEnd'].value);
    //console.log(this.dateRange.controls['minEnd'].value);
    let day_aux = new day();
    day_aux.day = this.dateRange.controls['days'].value;
    day_aux.startHour = this.dateRange.controls['hourStart'].value + ':' + this.dateRange.controls['minStart'].value;
    day_aux.endHour = this.dateRange.controls['hourEnd'].value + ':' + this.dateRange.controls['minEnd'].value;
    this.selected_group.days.push(day_aux);
    this.dateRange.controls['days'].markAsPristine();
    this.dateRange.controls['days'].setValue('');
    this.dateRange.controls['hourStart'].setValue('18');
    this.dateRange.controls['hourEnd'].setValue('20');
    this.dateRange.controls['minStart'].setValue('00');
    this.dateRange.controls['minEnd'].setValue('00');
  }

  campuses = new Array();
  campusesCourse;
  area_search: boolean = true;
  typeAheadTree(searchValue, full = false) {
    searchValue = searchValue.toLowerCase();
    this.arrayAreas = new Array();
    if (searchValue.length > 2) {
      // let degree_id = null;
      // let subject_id = null;
      // let indexGroup = 0;
      // if (this.campuses.length > 0) {
      //     for (let index = 0; index < this.campuses.length; index++) {
      //       if (this.campuses[index].name.toLowerCase().indexOf(searchValue) != -1) {
      //         let area = {
      //           name: this.campuses[index].name,
      //           nameSearch: '',
      //           campus_name: this.campuses[index].name,
      //           campus_id: this.campuses[index].id,
      //           faculty_id: null,
      //           faculty_name: null,
      //           degree_id: null,
      //           degree_name: null,
      //           subject_id: null,
      //           subject_name: null
      //         }
      //         this.arrayAreas.push(area);
      //       }
      //       for (let index2 = 0; index2 < this.campuses[index].subAreas.length; index2++) {
      //         if (this.campuses[index].subAreas[index2].name.toLowerCase().indexOf(searchValue) != -1) {
      //           let area = {
      //             name: this.campuses[index].subAreas[index2].name,
      //             nameSearch: '',
      //             campus_name: this.campuses[index].name,
      //             campus_id: this.campuses[index].id,
      //             faculty_id: this.campuses[index].subAreas[index2].id,
      //             faculty_name: this.campuses[index].subAreas[index2].name,
      //             degree_id: null,
      //             degree_name: null,
      //             subject_id: null,
      //             subject_name: null
      //           }
      //           this.arrayAreas.push(area);
      //         }
      //         for (let index3 = 0; index3 < this.campuses[index].subAreas[index2].subAreas.length; index3++) {
      //           if (this.campuses[index].subAreas[index2].subAreas[index3].name.toLowerCase().indexOf(searchValue) != -1) {
      //             let area = {
      //               name: this.campuses[index].subAreas[index2].subAreas[index3].name,
      //               nameSearch: this.campuses[index].subAreas[index2].name,
      //               campus_name: this.campuses[index].name,
      //               campus_id: this.campuses[index].id,
      //               faculty_id: this.campuses[index].subAreas[index2].id,
      //               faculty_name: this.campuses[index].subAreas[index2].name,
      //               degree_id: this.campuses[index].subAreas[index2].subAreas[index3].id,
      //               degree_name: this.campuses[index].subAreas[index2].subAreas[index3].name,
      //               subAreas: this.campuses[index].subAreas[index2].subAreas[index3].subAreas,
      //               subject_id: null,
      //               subject_name: null,
      //               hasCourse: this.campuses[index].subAreas[index2].subAreas[index3].num_courses > 0 ? true : false
      //             }
      //             this.arrayAreas.push(area);
      //           }
      //           for (let index4 = 0; index4 < this.campuses[index].subAreas[index2].subAreas[index3].subAreas.length; index4++) {
      //             if (this.campuses[index].subAreas[index2].subAreas[index3].subAreas[index4].name.toLowerCase().indexOf(searchValue) != -1) {
      //               let area = {
      //                 name: this.campuses[index].subAreas[index2].subAreas[index3].subAreas[index4].name,
      //                 nameSearch: this.campuses[index].subAreas[index2].subAreas[index3].name,
      //                 campus_name: this.campuses[index].name,
      //                 campus_id: this.campuses[index].id,
      //                 faculty_id: this.campuses[index].subAreas[index2].id,
      //                 faculty_name: this.campuses[index].subAreas[index2].name,
      //                 degree_id: this.campuses[index].subAreas[index2].subAreas[index3].id,
      //                 degree_name: this.campuses[index].subAreas[index2].subAreas[index3].name,
      //                 subject_id: this.campuses[index].subAreas[index2].subAreas[index3].subAreas[index4].id,
      //                 subject_name: this.campuses[index].subAreas[index2].subAreas[index3].subAreas[index4].name,
      //                 hasCourse: this.campuses[index].subAreas[index2].subAreas[index3].subAreas[index4].num_courses > 0 ? true : false
      //               }
      //               this.arrayAreas.push(area);
      //             }
      //           }
      //         }
      //       }
      //     }
      //   } else {
      //     this.arrayAreas = new Array();
      //   }
      // }
      for (let index = 0; index < this.areaCourses.length; index++) {
        if (this.areaCourses[index].name.toLowerCase().indexOf(searchValue) != -1) {
          let area = {
            id: this.areaCourses[index].id,
            name: this.areaCourses[index].name,
            degree_id: this.areaCourses[index].degree_id,
            subject_id: this.areaCourses[index].subject_id,
            isCourse: true,
            isUser: false,
            isGroup: false,
          }
          this.arrayAreas.push(area);
        }
      }

      if (full) {
        for (let index = 0; index < this.areaGroups.length; index++) {
          if (this.areaGroups[index].group_name.toLowerCase().indexOf(searchValue) != -1) {
            let area = {
              name: this.areaGroups[index].group_name,
              course_name: this.areaGroups[index].course_name,
              nameSearch: this.areaGroups[index].name,
              group_id: this.areaGroups[index].group_id,
              course_id: this.areaGroups[index].course_id,
              degree_id: this.areaGroups[index].degree_id,
              degree_name: this.areaGroups[index].degree_name,
              subject_id: this.areaGroups[index].subject_id,
              subject_name: this.areaGroups[index].subject_name,
              isCourse: false,
              isUser: false,
              isGroup: true,
            }
            this.arrayAreas.push(area);
          }
        }

        for (let index = 0; index < this.areaGroupsUsers.length; index++) {
          let name_index = this.areaGroupsUsers[index].user_name != undefined ? this.areaGroupsUsers[index].user_name.toLowerCase().indexOf(searchValue) : -1;
          let last_name_index = this.areaGroupsUsers[index].user_last_name != undefined ? this.areaGroupsUsers[index].user_last_name.toLowerCase().indexOf(searchValue) : -1;
          let second_last_name = this.areaGroupsUsers[index].second_last_name != undefined ? this.areaGroupsUsers[index].second_last_name.toLowerCase().indexOf(searchValue) : -1;
          if (name_index != -1 || last_name_index != -1 || second_last_name != -1) {
            let area = {
              name: this.areaGroupsUsers[index].user_name + ' ' + this.areaGroupsUsers[index].user_last_name,
              nameSearch: this.areaGroupsUsers[index].group_name,
              course_name: this.areaGroupsUsers[index].course_name,
              group_id: this.areaGroupsUsers[index].group_id,
              course_id: this.areaGroupsUsers[index].course_id,
              user_id: this.areaGroupsUsers[index].user_id,
              user_name: this.areaGroupsUsers[index].user_name,
              user_last_name: this.areaGroupsUsers[index].user_last_name,
              isCourse: false,
              isUser: true,
              isGroup: false
            }
            this.arrayAreas.push(area);
          }
        }
      }
    }
  }

  dropdown_areaModal: boolean = false;
  campusName: string = 'Campus';
  facultyName: string = 'Facultad';
  degreeName: string = 'Grado';
  subjectName: string = 'Subject';
  courseName: string = 'Curso';
  course_show: boolean = false;
  course_name: string = 'Cursos';
  labName: string = 'Lab';
  subjects;
  setDegree(degree, where?) {
    //console.log('dentro de setdegree');
    this.dropdown_areaModal = false;
    this.cleanTree('modal');
    this.degree_id = degree.id;
    this.degreeName = degree.name;
    this.subjects = degree.subAreas;
    this.subject_id = '';
    this.getOptions(where);
    // this.lms.getSubjectsByDegree(degree.id).subscribe((data: any) => {
    //   this.degreeName = degree.name;
    //   this.subjects = data['subjects'];
    //   this.getOptions();
    // });
  }

  dropdown_subject: boolean = false;
  setSubject(degree, subject, where?) {
    //console.log('dentro de setSubject');
    this.dropdown_areaModal = false;
    this.degree_id = degree.id;
    this.subject_id = subject.id;
    this.subjectName = subject.name;
    this.dropdown_subject = false;
    this.getOptions(where);
    this.cleanTree('modal');
  }

  loadingGroup: boolean = false;
  selectedDegreeId;
  selectedSubjectId;
  groupName: string = "Groups";
  areaName: string = "Degree - Subject";
  areaNameModal: string = "Curso";
  search_landing: boolean = false;
  areaGroups = new Array();
  areaGroupStudents = new Array();
  studentName: string = "Students";
  groupSelected;
  labsBool: boolean = false;
  subject_id: string = '';
  degree_id: string = '';
  labs;
  labsSelected;
  loading_courses: boolean = false;
  getOptions(where?) {
    if (this.labsBool) {
      if (this.subject_id == '') {
        this.lms.getLabsByDegree(this.degree_id).subscribe(data => {
          this.labs = data;
          this.labsSelected = new Array(this.labs.length);
          this.labsSelected.forEach(lab => {
            lab = false;
          });
        });
      } else {
        this.lms.getLabsBySubject(this.subject_id).subscribe(data => {
          this.labs = data;
          this.labsSelected = new Array(this.labs.length);
          this.labsSelected.forEach(lab => {
            lab = false;
          });
        });
      }
    } else {
      let idSubject;
      if (this.subject_id == '') {
        idSubject = 'null';
      } else {
        idSubject = this.subject_id;
      }
      if (this.degree_id != undefined) {
        this.loading_courses = true;
        this.lms.getCoursesByArea(this.degree_id, idSubject).subscribe((data: any) => {
          this.loading_courses = false;
          //console.log(where);
          if (where == 'file') {
            this.courses = new Array();
          } else {
            this.courses_search = new Array();
          }
          for (let index = 0; index < data.length; index++) {
            let courseAux = {
              id: data[index].id,
              name: data[index].name + ' ' + '(' + data[index].created_at.split(' ')[0] + ')',
              num_groups: Number(data[index].num_groups)
            }
            if (where == 'file') {
              this.courses.push(courseAux);
            } else {
              this.courses_search.push(courseAux);
            }
          }
        }, err => {
          this.loading_courses = false;
        });

      }
    }
  }

  cleanTree(modal?) {
    let tree;
    if (this.campusesCourse == undefined && this.campuses.length == 0) {
      return;
    }
    if (modal != undefined) {
      tree = this.campusesCourse;
    } else {
      tree = this.campuses;
    }
    for (let index = 0; index < tree.length; index++) {
      tree[index].open = false;
      for (let index2 = 0; index2 < tree[index].subAreas.length; index2++) {
        tree[index].subAreas[index2].open = false;
        for (let index3 = 0; index3 < tree[index].subAreas[index2].subAreas.length; index3++) {
          tree[index].subAreas[index2].subAreas[index3].open = false;
          for (let index4 = 0; index4 < tree[index].subAreas[index2].subAreas[index3].subAreas.length; index4++) {
            tree[index].subAreas[index2].subAreas[index3].subAreas[index4].open = false;
          }
        }
      }
    }
  }

  setInputName(groupName) {
    return groupName;
  }

  inputSearch() {
    this.area_search = true;
    setTimeout(() => {
      document.getElementById('area_search').focus();
    }, 50);
  }

  setAreaGroup(area, where?) {
    let area_deg = {
      id: '',
      name: '',
      subAreas: new Array()
    }
    let area_subj = {
      id: '',
      name: '',
    }
    area_deg.id = area.degree_id;
    area_deg.name = area.degree_name;
    area_deg.subAreas = area.subAreas;
    if (where == undefined) {
      this.areaNameModalSearch = area_deg.name;
      this.courseNameSearch = 'Curso';
    } else {
      this.areaNameModal = area_deg.name;
      this.courseName = 'Curso';
    }
    this.area_search = false;
    if (area.subject_id == null) {
      this.setDegree(area_deg, where);
    } else {
      area_subj.id = area.subject_id;
      area_subj.name = area.subject_name;
      this.setSubject(area_deg, area_subj, where);
    }

    this.arrayAreas = new Array();

    // if (area.course_id != undefined) {
    this.selectCourseSearch(area);
    // (<HTMLInputElement>document.getElementById('setAreaGroup')).value = '';
    // }
  }

  setDate(event) {
    //console.log(event);
    // //console.log(event.target.ngControl.name);
    // //console.log(event.value._i.month);
    if (event.value != null) {
      if (event.target.ngControl.name == 'start') {
        this.selected_group.start_year = String(event.value._i.year);
      } else if (event.target.ngControl.name == 'end') {
        this.selected_group.end_year = String(event.value._i.year);
      }
      if (event.value._i.month == 0) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'January';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'January';
        }
      } else if (event.value._i.month == 1) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'February';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'February';
        }
      } else if (event.value._i.month == 2) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'March';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'March';
        }
      } else if (event.value._i.month == 3) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'April';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'April';
        }
      } else if (event.value._i.month == 4) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'May';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'May';
        }
      } else if (event.value._i.month == 5) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'June';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'June';
        }
      } else if (event.value._i.month == 6) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'July';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'July';
        }
      } else if (event.value._i.month == 7) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'August';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'August';
        }
      } else if (event.value._i.month == 8) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'September';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'September';
        }
      } else if (event.value._i.month == 9) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'October';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'October';
        }
      } else if (event.value._i.month == 10) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'November';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'November';
        }
      } else if (event.value._i.month == 11) {
        if (event.target.ngControl.name == 'start') {
          this.selected_group.start_month = 'Dicember';
        } else if (event.target.ngControl.name == 'end') {
          this.selected_group.end_month = 'Dicember';
        }
      }
    } else {
      let year = new Date();
      let year_set = year.getFullYear();
      if (event.target.ngControl.name == 'start') {
        this.selected_group.start_month = 'start';
        this.selected_group.start_year = String(year_set);
      } else if (event.target.ngControl.name == 'end') {
        this.selected_group.end_month = 'end';
        this.selected_group.end_year = String(year_set);
      }
    }
  }

  viewGroupFile(group_set) {
    this.selected_group = group_set;
    //console.log(this.selected_group);
    this.group_file = true;
    //console.log(this.groups);
    for (let index = 0; index < this.groups.length; index++) {
      if (this.groups[index].id == this.selected_group.id) {
        this.course_id = String(this.selected_group.course_id);
        this.courseName = this.courseNameSearch;
        this.areaNameModal = this.groups[index].name;
        this.courses = JSON.parse(JSON.stringify(this.courses_search));
        break;
      }
    }
    // this.lms.getGroupSubscriptions(group_set.id).subscribe((data: any) => {

    // });
  }

  
  placementTest;
  course_id_search: string = '';
  potential_users = [];
  selectCourseSearch(course) {
    if (!course.isCourse) {
      this.course_id_search = course.course_id;
      this.dropdown_course_search = false;
      this.lms.getGroup(course.group_id).subscribe((data: any) => {
        this.groups = new Array();
        let courseActiveAux = new Array();
        let courseDeactiveAux = new Array();
        for (let index = 0; index < data.length; index++) {
          let control = new UntypedFormControl((data[index].description));
          let groupAux = new group();
          groupAux.teachers = new Array();
          groupAux.users = new Array();
          groupAux.application_users = new Array();
          groupAux.description = '';
          groupAux.name = data[index].name;
          groupAux.pdf = data[index].pdf;
          // groupAux.invitation_url = data[index].url;
          if (data[index].endDate != null) {
            groupAux.end = data[index].endDate;
            let end_split = data[index].endDate.split('/');
            if (end_split[1] == '1') {
              groupAux.end_month = 'January';
            } else if (end_split[1] == '2') {
              groupAux.end_month = 'February';
            } else if (end_split[1] == '3') {
              groupAux.end_month = 'March';
            } else if (end_split[1] == '4') {
              groupAux.end_month = 'April';
            } else if (end_split[1] == '5') {
              groupAux.end_month = 'May';
            } else if (end_split[1] == '6') {
              groupAux.end_month = 'June';
            } else if (end_split[1] == '7') {
              groupAux.end_month = 'July';
            } else if (end_split[1] == '8') {
              groupAux.end_month = 'August';
            } else if (end_split[1] == '9') {
              groupAux.end_month = 'September';
            } else if (end_split[1] == '10') {
              groupAux.end_month = 'October';
            } else if (end_split[1] == '11') {
              groupAux.end_month = 'November';
            } else if (end_split[1] == '12') {
              groupAux.end_month = 'Dicember';
            }
            groupAux.end_year = end_split[2];
          }

          if (data[index].startDate != null) {
            groupAux.start = data[index].startDate;
            let start_split = data[index].startDate.split('/');
            if (start_split[1] == '1') {
              groupAux.start_month = 'January';
            } else if (start_split[1] == '2') {
              groupAux.start_month = 'February';
            } else if (start_split[1] == '3') {
              groupAux.start_month = 'March';
            } else if (start_split[1] == '4') {
              groupAux.start_month = 'April';
            } else if (start_split[1] == '5') {
              groupAux.start_month = 'May';
            } else if (start_split[1] == '6') {
              groupAux.start_month = 'June';
            } else if (start_split[1] == '7') {
              groupAux.start_month = 'July';
            } else if (start_split[1] == '8') {
              groupAux.start_month = 'August';
            } else if (start_split[1] == '9') {
              groupAux.start_month = 'September';
            } else if (start_split[1] == '10') {
              groupAux.start_month = 'October';
            } else if (start_split[1] == '11') {
              groupAux.start_month = 'November';
            } else if (start_split[1] == '12') {
              groupAux.start_month = 'Dicember';
            }
            groupAux.start_year = start_split[2];
          }

          if (data[index].active == '1') {
            groupAux.active = true;
          }
          let days_group = JSON.parse(data[index].days);
          if (days_group != null) {
            for (let index_d = 0; index_d < days_group.length; index_d++) {
              let day_aux = new day();
              day_aux.day = days_group[index_d];
              try {
                day_aux.startHour = JSON.parse(data[index].startHour)[index_d];
                day_aux.endHour = JSON.parse(data[index].endHour)[index_d];
              } catch (error) {

              }
              groupAux.days.push(day_aux);
            }
          }
          // if (data[index].archived == '1') {
          //   groupAux.archived = true;
          // }
          groupAux.id = data[index].id;
          groupAux.course_id = data[index].course_id;
          groupAux.max_users = 0;
          groupAux.showInfo = false;
          groupAux.addedUsersGroup = new Array();
          groupAux.addedTeachersGroup = new Array();
          this.groupInfo.addControl(groupAux.name, control);
          if (data[index].max_users != null) {
            groupAux.max_users = data[index].max_users;
          }
          if (data[index].description != null) {
            groupAux.description = data[index].description;
          }
          for (let indexTeachers = 0; indexTeachers < data[index].teachers.length; indexTeachers++) {
            let teacher = new user();
            teacher.email = data[index].teachers[indexTeachers].email;
            teacher.id = data[index].teachers[indexTeachers].teacher_id;
            teacher.name = data[index].teachers[indexTeachers].name;
            teacher.last_name = data[index].teachers[indexTeachers].last_name;
            groupAux.teachers.push(teacher);
          }
          for (let indexTeachers = 0; indexTeachers < data[index].users.length; indexTeachers++) {
            let user_aux = new user();
            user_aux.email = data[index].users[indexTeachers].email;
            user_aux.id = data[index].users[indexTeachers].user_id;
            user_aux.name = data[index].users[indexTeachers].name;
            user_aux.last_name = data[index].users[indexTeachers].last_name;
            groupAux.users.push(user_aux);
          }
          this.createInvitationGroupUrl(groupAux);
          // if (!groupAux.archived) {
          //   if (groupAux.active) {
          //     courseActiveAux.push(groupAux);
          //   } else {
          //     courseDeactiveAux.push(groupAux);
          //   }
          //   // this.groups.push(groupAux);
          // } else {
          if (groupAux.active) {
            courseActiveAux.push(groupAux);
          } else {
            courseActiveAux.push(groupAux);
          }
          // this.groupsArchived.push(groupAux);
          // }
        }
        courseActiveAux.forEach(element => {
          this.groups.push(element);
        });
        courseDeactiveAux.forEach(element => {
          this.groups.push(element);
        });
        this.groupSelected = undefined;
        if (this.groups.length == 0) {
          this.group_file = false;
          this.setNewGroup();
        }
        this.courseNameSearch = course.isCourse ? course.name : course.course_name;
      });
      this.lms.getExamId(this.course_id_search).subscribe((data: any) => {
        let examId = data[0].id;
        this.placementTest = "https://georgetownpec.altealabs.com/#/placement/" +  String(examId)
      });
    } else {
      this.course_id_search = course.id;
      this.dropdown_course_search = false;
      this.lms.getGroupId(course.id).subscribe((data: any) => {
        this.groups = new Array();
        let courseActiveAux = new Array();
        let courseDeactiveAux = new Array();
        for (let index = 0; index < data.length; index++) {
          let control = new UntypedFormControl((data[index].description));
          let groupAux = new group();
          groupAux.teachers = new Array();
          groupAux.users = new Array();
          groupAux.application_users = new Array();
          groupAux.description = '';
          groupAux.name = data[index].name;
          groupAux.pdf = data[index].pdf;
          // groupAux.invitation_url = data[index].url;
          if (data[index].endDate != null) {
            groupAux.end = data[index].endDate;
            let end_split = data[index].endDate.split('/');
            if (end_split[1] == '1') {
              groupAux.end_month = 'January';
            } else if (end_split[1] == '2') {
              groupAux.end_month = 'February';
            } else if (end_split[1] == '3') {
              groupAux.end_month = 'March';
            } else if (end_split[1] == '4') {
              groupAux.end_month = 'April';
            } else if (end_split[1] == '5') {
              groupAux.end_month = 'May';
            } else if (end_split[1] == '6') {
              groupAux.end_month = 'June';
            } else if (end_split[1] == '7') {
              groupAux.end_month = 'July';
            } else if (end_split[1] == '8') {
              groupAux.end_month = 'August';
            } else if (end_split[1] == '9') {
              groupAux.end_month = 'September';
            } else if (end_split[1] == '10') {
              groupAux.end_month = 'October';
            } else if (end_split[1] == '11') {
              groupAux.end_month = 'November';
            } else if (end_split[1] == '12') {
              groupAux.end_month = 'Dicember';
            }
            groupAux.end_year = end_split[2];
          }

          if (data[index].startDate != null) {
            groupAux.start = data[index].startDate;
            let start_split = data[index].startDate.split('/');
            if (start_split[1] == '1') {
              groupAux.start_month = 'January';
            } else if (start_split[1] == '2') {
              groupAux.start_month = 'February';
            } else if (start_split[1] == '3') {
              groupAux.start_month = 'March';
            } else if (start_split[1] == '4') {
              groupAux.start_month = 'April';
            } else if (start_split[1] == '5') {
              groupAux.start_month = 'May';
            } else if (start_split[1] == '6') {
              groupAux.start_month = 'June';
            } else if (start_split[1] == '7') {
              groupAux.start_month = 'July';
            } else if (start_split[1] == '8') {
              groupAux.start_month = 'August';
            } else if (start_split[1] == '9') {
              groupAux.start_month = 'September';
            } else if (start_split[1] == '10') {
              groupAux.start_month = 'October';
            } else if (start_split[1] == '11') {
              groupAux.start_month = 'November';
            } else if (start_split[1] == '12') {
              groupAux.start_month = 'Dicember';
            }
            groupAux.start_year = start_split[2];
          }

          if (data[index].active == '1') {
            groupAux.active = true;
          }
          let days_group = JSON.parse(data[index].days);
          if (days_group != null) {
            for (let index_d = 0; index_d < days_group.length; index_d++) {
              let day_aux = new day();
              day_aux.day = days_group[index_d];
              try {
                day_aux.startHour = JSON.parse(data[index].startHour)[index_d];
                day_aux.endHour = JSON.parse(data[index].endHour)[index_d];
              } catch (error) {

              }
              groupAux.days.push(day_aux);
            }
          }
          // if (data[index].archived == '1') {
          //   groupAux.archived = true;
          // }
          groupAux.id = data[index].id;
          groupAux.course_id = data[index].course_id;
          groupAux.max_users = 0;
          groupAux.showInfo = false;
          groupAux.addedUsersGroup = new Array();
          groupAux.addedTeachersGroup = new Array();
          this.groupInfo.addControl(groupAux.name, control);
          if (data[index].max_users != null) {
            groupAux.max_users = data[index].max_users;
          }
          if (data[index].description != null) {
            groupAux.description = data[index].description;
          }
          for (let indexTeachers = 0; indexTeachers < data[index].teachers.length; indexTeachers++) {
            let teacher = new user();
            teacher.email = data[index].teachers[indexTeachers].email;
            teacher.id = data[index].teachers[indexTeachers].teacher_id;
            teacher.name = data[index].teachers[indexTeachers].name;
            teacher.last_name = data[index].teachers[indexTeachers].last_name;
            groupAux.teachers.push(teacher);
          }
          for (let indexTeachers = 0; indexTeachers < data[index].users.length; indexTeachers++) {
            let user_aux = new user();
            user_aux.email = data[index].users[indexTeachers].email;
            user_aux.id = data[index].users[indexTeachers].user_id;
            user_aux.name = data[index].users[indexTeachers].name;
            user_aux.last_name = data[index].users[indexTeachers].last_name;
            groupAux.users.push(user_aux);
          }
          this.createInvitationGroupUrl(groupAux);
          // if (!groupAux.archived) {
          //   if (groupAux.active) {
          //     courseActiveAux.push(groupAux);
          //   } else {
          //     courseDeactiveAux.push(groupAux);
          //   }
          //   // this.groups.push(groupAux);
          // } else {
          if (groupAux.active) {
            courseActiveAux.push(groupAux);
          } else {
            courseActiveAux.push(groupAux);
          }
          // this.groupsArchived.push(groupAux);
          // }
        }
        courseActiveAux.forEach(element => {
          this.groups.push(element);
        });
        courseDeactiveAux.forEach(element => {
          this.groups.push(element);
        });
        this.groupSelected = undefined;
        if (this.groups.length == 0) {
          this.group_file = false;
          this.setNewGroup();
        }
        this.courseNameSearch = course.name;
      });   
      this.lms.getExamId(course.id).subscribe((data: any) => {
        let examId = data[0].id;
        this.placementTest = "https://georgetownpec.altealabs.com/#/placement/" +  String(examId)
      });
    }


    //consulta de application forms
    let course_id = course.isCourse ? course.id : course.course_id;
    this.lms.getCourseApplicationForms(course_id).subscribe((data: any) => {
      data.forEach(user => {
        if (user.inGroup == null) {
          user.showInfo = false;
          this.potential_users.push(user);
        }
      });
    });
  }



  course_id: string = '';
  dropdown_course: boolean = false;
  selectCourse(course) {
    if (course.course_id != undefined) {
      this.course_id = course.course_id;
      this.selected_group.course_id = course.course_id;
    } else {
      this.course_id = course.id;
      this.selected_group.course_id = course.id;

    }
    this.dropdown_course = false;
    this.courseName = course.name;    
    this.areaNameModal = this.courseName;
  }

  setGroupName(group_name) {
    this.selected_group.name = group_name;
  }

  activarGrupo(group) {
    if (group.archived) {
      return;
    }
    let newState = !group.active;
    group.active = newState;
    if (group.id != null) {
      this.lms.activeGroup(group.id, newState).subscribe((data: any) => {
        let groupAux;
        if (group.active) {
          let activeAux = new Array();
          let aux = new Array();
          for (let index = 0; index < this.groups.length; index++) {
            if (this.groups[index].active) {
              activeAux.push(this.groups[index]);
            } else {
              aux.push(this.groups[index]);
            }
          }
          this.groups = new Array();
          if (activeAux.length > 0) {
            activeAux = activeAux.sort((gr1, gr2) => {
              if (Number(gr1.start) > Number(gr2.start)) {
                return -1;
              } else if (Number(gr1.start) < Number(gr2.start)) {
                return 1;
              } else {
                return 0;
              }
            });
            for (let index = 0; index < activeAux.length; index++) {
              this.groups.push(activeAux[index]);
            }
          }
          if (aux.length > 0) {
            aux = aux.sort((gr1, gr2) => {
              if (Number(gr1.start) > Number(gr2.start)) {
                return -1;
              } else if (Number(gr1.start) < Number(gr2.start)) {
                return 1;
              } else {
                return 0;
              }
            });
            for (let index = 0; index < aux.length; index++) {
              this.groups.push(aux[index]);
            }
          }
        } else {
          for (let index = 0; index < this.groups.length; index++) {
            if (group.id == this.groups[index].id) {
              groupAux = this.groups.splice(index, 1);
              break;
            }
          }
          this.groups.push(groupAux[0]);
        }
      });
    }
  }

  filterGroupList() {

  }

  setStudentFile(student) {

  }


  inviteUsersToGroup() {
    let group_id = this.selected_group.id;
    this.lms.inviteUsers(group_id, this.potential_users).subscribe((data: any) => {
      let emails_acceepted = data['email_accepted'];
      for (let index = 0; index < this.potential_users.length; index++) {
        if (emails_acceepted.includes(this.potential_users[index].email)) {
          this.potential_users[index].group_id_invite = group_id;
        }

      }


    });
  }
  inviteUserToGroup(user) {
    let group_id = this.selected_group.id;
    this.lms.inviteUser(user.name, user.last_name, user.email, null, group_id).subscribe((data: any) => {
      user.group_id_invite = group_id;
    });
  }

  openUserInfo(user) {
    if (user.group_id_invite == null && this.selected_group.id != null) {
      user.showInfo = !user.showInfo;
    }
  }
}
