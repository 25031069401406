import { Component, OnInit, Injectable } from '@angular/core';
import { LabInfoService } from 'src/app/services/lab-info.service';


@Injectable({
  providedIn: 'root'
})
export class backgroundSet {
  area;
  colorPicker = false;
}

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.css']
})
export class ImageEditorComponent implements OnInit {

  constructor(public labInfo: LabInfoService) { }

  click = 'click!'
  Certs = true;
  PEC = false;

  ngOnInit() {
    this.labInfo.getContentWithoutImages().subscribe(data => {
      this.labInfo.CertList.campus_id = data[0].campus_id;
      this.labInfo.CertList.faculties = new Array(data[0]['faculties'].length);
      for (let index = 0; index < data[0]['faculties'].length; index++) {
        this.labInfo.CertList.faculties[index] = new backgroundSet();
        this.labInfo.CertList.faculties[index].area = data[0]['faculties'][index];
      }
      this.labInfo.CertList.degrees = new Array(data[0]['degrees'].length);
      for (let index = 0; index < data[0]['degrees'].length; index++) {
        this.labInfo.CertList.degrees[index] = new backgroundSet();
        this.labInfo.CertList.degrees[index].area = data[0]['degrees'][index];
      }
      this.labInfo.CertList.subjects = new Array(data[0]['subjects'].length);
      for (let index = 0; index < data[0]['subjects'].length; index++) {
        this.labInfo.CertList.subjects[index] = new backgroundSet();
        this.labInfo.CertList.subjects[index].area = data[0]['subjects'][index];
      }
      this.labInfo.CertList.labs = data[0]['labs'];

      this.labInfo.PECList.campus_id = data[1].campus_id;
      this.labInfo.PECList.faculties = new Array(data[1]['faculties'].length);
      for (let index = 0; index < data[1]['faculties'].length; index++) {
        this.labInfo.PECList.faculties[index] = new backgroundSet();
        this.labInfo.PECList.faculties[index].area = data[1]['faculties'][index];
      }
      this.labInfo.PECList.degrees = new Array(data[1]['degrees'].length);
      for (let index = 0; index < data[1]['degrees'].length; index++) {
        this.labInfo.PECList.degrees[index] = new backgroundSet();
        this.labInfo.PECList.degrees[index].area = data[1]['degrees'][index];
      }
      this.labInfo.PECList.subjects = new Array(data[1]['subjects'].length);
      for (let index = 0; index < data[1]['subjects'].length; index++) {
        this.labInfo.PECList.subjects[index] = new backgroundSet();
        this.labInfo.PECList.subjects[index].area = data[1]['subjects'][index];
      }
      this.labInfo.PECList.labs = data[1]['labs'];
    });
  }
  campus = 'Certificates'
  changeCampus() {
    this.Certs = !this.Certs;
    this.PEC = !this.PEC;

    if (this.PEC == true) {
      this.campus = 'PEC';
    } else if (this.Certs == true) {
      this.campus = 'Certificates';
    }
  }

  getId(type, id, name) {
    this.labInfo.product = name;
    this.labInfo.imageSet.type = type;
    this.labInfo.imageSet.idProduct = id;
  }

  colorSend;
  setColor(type, name, id, color) {
    this.colorSend = color;
    this.labInfo.product = name;
    if (type == 'faculty') {
      this.labInfo.setFacultyColor(id, color).subscribe(data => {
        for (let index = 0; index < this.labInfo.CertList.faculties.length; index++) {
          if (this.labInfo.CertList.faculties[index].area.id == id) {
            this.labInfo.CertList.faculties[index].area.color = color;
          }
        }
        for (let index = 0; index < this.labInfo.PECList.faculties.length; index++) {
          if (this.labInfo.PECList.faculties[index].area.id == id) {
            this.labInfo.PECList.faculties[index].area.color = color;
          }
        }
        this.labInfo.alertMess2 = true;
        setTimeout(() => {
          this.labInfo.alertMess2 = false;
        }, 4000);
      });
    } else if (type == 'degree') {
      this.labInfo.setDegreeColor(id, color).subscribe(data => {
        for (let index = 0; index < this.labInfo.CertList.degrees.length; index++) {
          if (this.labInfo.CertList.degrees[index].area.id == id) {
            this.labInfo.CertList.degrees[index].area.color = color;
          }
        }
        for (let index = 0; index < this.labInfo.PECList.degrees.length; index++) {
          if (this.labInfo.PECList.degrees[index].area.id == id) {
            this.labInfo.PECList.degrees[index].area.color = color;
          }
        }
        this.labInfo.alertMess2 = true;
        setTimeout(() => {
          this.labInfo.alertMess2 = false;
        }, 4000);
      });
    } else if (type == 'subject') {
      this.labInfo.setSubjectColor(id, color).subscribe(data => {
        for (let index = 0; index < this.labInfo.CertList.subjects.length; index++) {
          if (this.labInfo.CertList.subjects[index].area.id == id) {
            this.labInfo.CertList.subjects[index].area.color = color;
          }
        }
        for (let index = 0; index < this.labInfo.PECList.subjects.length; index++) {
          if (this.labInfo.PECList.subjects[index].area.id == id) {
            this.labInfo.PECList.subjects[index].area.color = color;
          }
        }
        this.labInfo.alertMess2 = true;
        setTimeout(() => {
          this.labInfo.alertMess2 = false;
        }, 4000);
      });
    }
  }

  showColorPicker(area) {

    area.colorPicker = !area.colorPicker;
    console.log(area.colorPicker);
    if (area.colorPicker == false) {
      this.click = 'click!';
    }
  }

}
