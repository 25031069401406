<div class="mainContent">
  <div class="black-divider">
    <!--Search bar-->
    <div style="margin-left: 25px; position: absolute;" class="pt-4 nav-item searchbar">

      <button *ngIf="!filters" class="btn  btn-altea px-3" type="button" (click)="filters = true">
        filtros
      </button>
      <button *ngIf="filters" class="btn  btn-altea px-3" type="button"
        (click)="aplicar = false; filters = false; categoria.value=''; subcategoria.value =''; nameLab.value='';">
        cancelar
      </button>

    </div>
    <div *ngIf="filters"
      style="background-color: #f2f3f5;margin-left: 25px;position: absolute; width: 250px; margin-top: 68px">
      <div class="d-flex">
        <div class="container-fluid px-0">
          <div class="container ml-0">
            <div class="row mt-4">
              <div class="col-12">
                <span>Nivel</span>
                <div><label class="pl-0" title=""><input (click)="setLevel(12)" class="mr-2"
                      type="checkbox"><span><span>Essential</span><span class="text-muted"></span></span></label>
                </div>
                <div><label class="pl-0" title=""><input (click)="setLevel(13)" class="mr-2"
                      type="checkbox"><span><span>Key</span><span class="text-muted">
                      </span></span></label></div>
                <div><label class="pl-0" title=""><input (click)="setLevel(14)" class="mr-2"
                      type="checkbox"><span><span>Ultimate</span><span class="text-muted">
                      </span></span></label></div>
                <div><label class="pl-0" title=""><input (click)="setLevel(0)" class="mr-2"
                      type="checkbox"><span><span>Todos los
                        niveles</span><span class="text-muted">
                      </span></span></label></div>


                <div><span class="mb-4">Categoría</span>
                  <div><input [disabled]="subcategoria.value != ''" #categoria type="text" class="mr-2">
                  </div>
                </div>

                <div class="mt-4"><span class="mb-4">Subcategoría</span>
                  <div><input [disabled]="categoria.value != ''" #subcategoria type="text" class="mr-2">
                  </div>
                </div>


                <div class="mt-4 mb-3"><span class="mb-4">Nombre del lab</span>
                  <div><input #nameLab type="text" class="mr-2">
                  </div>
                </div>

                <button class="btn mb-3 btn-altea px-3" type="button"
                  (click)="searchLab(categoria.value, subcategoria.value, nameLab.value)">
                  Aplicar
                </button>
              </div>
            </div>
          </div>

        </div>
        <div>
          <div class="searchResults" [ngClass]="{'d-block': aplicar}">
            <!--Aqui el resultado de la busqueda-->
            <div>
              <p class="pl-2 searchLabOptions pb-2 pt-2 mb-0" (click)=" loadLab(lab); aplicar = false"
                *ngFor="let lab of labSearch">{{lab.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="center-spacing">
      <div class="flyerHeader row no-gutters px-0">
        <div class="flyerTitle px-0">
          <div class="d-flex justify-content-between title" *ngIf="!editname" (mouseenter)="pencilTitle = true"
            (mouseleave)="pencilTitle = false">
            <p class="pl-0 pr-2 py-0 mr-0 ml-0 mt-0 mb-0" (click)="editname=!editname; pencilTitle = false">
              {{flyerName|uppercase}}</p>
            <div *ngIf="pencilTitle" class="penciltitle"><i (click)="editname = true;"
                class="fas pl-1 pencilTitle fa-edit"></i></div>
          </div>
          <input *ngIf="editname" class="editTitle pl-0 pr-2 py-0 mr-2 ml-0 mt-0 mb-0" value="{{flyerName|uppercase}}"
            (keyup.enter)="editname=!editname; changeName(name.value); pencilTitle = false" #name>
          <!-- Campo editable -->


          <div *ngIf="!editKey" class="d-flex text-light justify-content-between keyline "
            (mouseenter)="pencilkey = true" (mouseleave)="pencilkey = false">
            <p (click)="editKey=!editKey; pencilkey = false" class="">{{keys}}</p>
            <div *ngIf="pencilkey" class="penciltitle"><i (click)="editKey= true"
                class="pl-1 pencilkey fas fa-edit"></i></div>
          </div>
          <input *ngIf="editKey" (keyup.enter)="changeKey(keyEdit.value); editKey=!editKey" value="{{keys}}" #keyEdit
            class="w-100 text-light editkey" type="text">



          <!-- <li class="key list-unstyled"><input (keyup.enter)="addKeys(key.value); key.value=''" #key class="w-100" type="text"></li> -->



          <div class="starsread mb-2 d-flex">
            <i *ngIf="rating == 0" class="fas fa-star star starempty"></i>
            <i *ngIf="rating == 0" class="fas fa-star star starempty"></i>
            <i *ngIf="rating == 0" class="fas fa-star star starempty"></i>
            <i *ngIf="rating == 0" class="fas fa-star star starempty"></i>
            <i *ngIf="rating == 0" class="fas fa-star star starempty"></i>

            <i *ngIf="rating == 1 || rating == 2 || rating == 3 || rating == 4 || rating == 5
             || (2 > rating && rating > 1) || (3 > rating && rating > 2) 
             || (4 > rating && rating > 3)|| (5 > rating && rating > 4)" class="fas star fa-star starfill"></i>

            <i *ngIf="rating == 2 || rating == 3 || rating == 4 || rating == 5 
            || (3 > rating && rating > 2) || (4 > rating && rating > 3)|| (5 > rating && rating > 4)"
              class="fas star fa-star starfill"></i>
            <i *ngIf="2 > rating && rating > 1" class="fas star fa-star-half starfill"></i>
            <i *ngIf="2 > rating && rating > 1" class="fas star fa-star-half starempty halfemptyflyer"></i>
            <i *ngIf="rating == 1" class="fas star fa-star starempty"></i>

            <i *ngIf="rating == 3 || rating == 4 || rating == 5 || (4 > rating && rating > 3)|| (5 > rating && rating > 4)"
              class="fas star fa-star starfill"></i>
            <i *ngIf="3 > rating && rating > 2" class="fas star fa-star-half starfill"></i>
            <i *ngIf="3 > rating && rating > 2" class="fas star fa-star-half starempty halfemptyflyer"></i>
            <i *ngIf=" rating == 1 || rating == 2 || (2 > rating && rating > 1)" class="fas star fa-star starempty"></i>

            <i *ngIf="rating == 4 || rating == 5 || (5 > rating && rating > 4) " class="fas star fa-star starfill"></i>
            <i *ngIf="4 > rating && rating > 3" class="fas star fa-star-half starfill"></i>
            <i *ngIf="4 > rating && rating > 3" class="fas star fa-star-half starempty halfemptyflyer"></i>
            <i *ngIf="rating == 1 ||rating == 2 || rating == 3 || (2 > rating && rating > 1) ||
             (3 > rating && rating > 2)" class="fas star fa-star starempty"></i>

            <i *ngIf="rating == 5" class="fas star fa-star starfill"></i>
            <i *ngIf="5 > rating && rating > 4" class="fas star fa-star-half starfill"></i>
            <i *ngIf="5 > rating && rating > 4" class="fas star fa-star-half starempty halfemptyflyer"></i>
            <i *ngIf="rating == 1 || rating == 2 || rating == 3 || rating == 4
           || (2 > rating && rating > 1) || (3 > rating && rating > 2) ||
            (4 > rating && rating > 3)" class="fas star fa-star starempty"></i>
            <div class="studentCount d-flex">
              <p class="val ml-2 mb-0"> ({{count}} valoraciones)&nbsp;</p>
              <p class="val ml-2 mb-0">{{numStudents}} estudiantes inscritos</p>
            </div>
          </div>
          <div class="d-flex mb-1">
            <p class="created mb-0 font-15">Creado por ALTEA Labs</p>&nbsp;&nbsp;&nbsp;&nbsp;
            <p class="created mb-0 font-15">Última actualizacion {{updated}}</p>
          </div>

          <!-- <div class="d-flex">
            <i class="fas pt-1 coment fa-comment"></i>&nbsp;
            <p class="created font-15">INGLÉS</p>
          </div> -->



        </div>
      </div>
    </div>
  </div>

  <div class="px-0 flyerAll">
    <div *ngIf="!adq" class="mx-auto">
      <div class="mt-3 wishFlyer d-flex" *ngIf="!sessData.inWishlist">
        <i class="mb-2 fa-heart heartflyer text-center heart far" (click)="addToFavourite()"></i>
        <p class="ml-3 wish">Lista de deseos</p>
      </div>
      <div class="mt-3 wishFlyer2 d-flex" *ngIf="sessData.inWishlist">
        <i class=" fa-heart heartflyer text-center heart fas" (click)="deleteFavourite()"></i>&nbsp;
        <p class=" wish"> Incluido en lista de deseados</p>
      </div>
    </div>
    <div class="addedDiv" *ngIf="adq"></div>


    <div *ngIf="!cardFlyer" class="col-4 p-0 align-self-end border flyercard border-rad05">
      <img [src]="flyerimage" alt="lab.img" class="labimage p-1">
      <div data-toggle="modal" data-target="#editImage" (click)="fileChangeEvent()" class="position-absolute editImg"><i
          class="fas faimage fa-edit"></i></div>
      <div class="p-4">
        <div class="d-flex">
          <p *ngIf="!editoffer" class="flyerOfferPrice mb-0" (click)="editoffer=!editoffer">{{flyerOffer}}€</p>
          <input type="number" (keyup.enter)="editoffer=!editoffer; changeOffer(offer.value)" #offer *ngIf="editoffer"
            class="editPrice flyerOfferPrice mb-0 w-25" value="{{flyerOffer}}">&nbsp;&nbsp;
          <p *ngIf="!editprice" class="line-through flyerprice mt-3 mb-0" (click)="editprice=!editprice">{{flyerPrice}}€
          </p>
          <input type="number" (keyup.enter)="editprice=!editprice; changePrice(price.value)" #price *ngIf="editprice"
            class="editPrice line-through mt-3 mb-0 w-25" value="{{flyerPrice}}">
        </div>
        <p class="text-muted discount">{{discount}}% de descuento</p>
        <!--<div class="level text-center my-3">lab.level</div>-->
        <button *ngIf="loading" class="btn btn-style-6 w-100 btn-md d-block mx-auto my-3" disabled>Añadir al
          carrito</button>
        <button *ngIf="!loading && !sessData.addedLab && !isAuth() && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3" data-toggle="modal" data-target="#login"
          data-toggle="modal">Añadir al carrito</button>
        <button *ngIf="!loading && !sessData.addedLab && isAuth() && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3" (click)="addToCart()" data-toggle="modal"
          data-target="#addToCart">Añadir al carrito</button>
        <a *ngIf="!loading && sessData.addedLab && !adq" [routerLink]="[ '/cart' ]"
          class="btn cartBoton btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3">Ir
          al carrito</a>
        <button *ngIf="!loading && !isAuth() && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3 letter2" data-toggle="modal"
          data-target="#login">Cómpralo</button>
        <button *ngIf="!loading && isAuth() && !adq && !sessData.addedLab"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3 letter2" (click)="addToCart()"
          [routerLink]="['/cart/checkout']">Cómpralo</button>
        <button *ngIf="!loading && isAuth() && sessData.addedLab && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3 letter2"
          [routerLink]="['/cart/checkout']">Cómpralo</button>
        <a *ngIf="adq" class="btn btn-md btn-style-6 d-lg-block mx-auto mt-3 d-none"
          [routerLink]="[ '/lab-landing', lab.id, 'lab' ]">Portal Lab</a>

        <div class="row mx-0 mt-3">
          <ul class="col-12 px-0 included">
            <li>Este lab incluye:</li>
            <li><i class="fas fa-book"></i>&nbsp;&nbsp;<strong>Numero de palabras:</strong> {{list.num_words}}</li>
            <li><i class="fas fa-infinity"></i>&nbsp;Acceso de por vida</li>
          </ul>

        </div>
      </div>

      <div class="row no-gutters col-12 px-0 justify-content-around position-absolute saveBtn">
        <hr class="col-12 mb-2">
        <button class="btn border mx-1">Cancel</button>
        <button class="btn btn-altea mx-1" (click)="saveFlyer()">save changes</button>
      </div>
    </div>






    <div *ngIf="cardFlyer" class="col-4 p-0 align-self-end border flyercardFixed">
      <div class="p-4">
        <div class="d-flex">
          <p *ngIf="!editoffer" class="flyerOfferPrice mb-0" (click)="editoffer=!editoffer">{{lab.offer_price}}€</p>
          <input (keyup.enter)="editoffer=!editoffer" *ngIf="editoffer" class="editPrice flyerOfferPrice mb-0 w-25"
            value="{{lab.offer_price}}€">&nbsp;&nbsp;
          <p *ngIf="!editprice" class="line-through mt-3 mb-0" (click)="editprice=!editprice">{{lab.price}}€</p>
          <input (keyup.enter)="editprice=!editprice" *ngIf="editprice" class="editPrice line-through mt-3 mb-0 w-25"
            value="{{lab.price}}€">
        </div>
        <p class="text-muted discount">{{discount}}% de descuento</p>
        <!--<div class="level text-center my-3">lab.level</div>-->
        <button *ngIf="loading" class="btn btn-style-6 w-100 btn-md d-block mx-auto my-3" disabled>Añadir al
          carrito</button>
        <button *ngIf="!loading && !sessData.addedLab && !isAuth() && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3" data-toggle="modal" data-target="#login"
          data-toggle="modal">Añadir al carrito</button>
        <button *ngIf="!loading && !sessData.addedLab && isAuth() && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3" (click)="addToCart()" data-toggle="modal"
          data-target="#addToCart">Añadir al carrito</button>
        <a *ngIf="!loading && sessData.addedLab && !adq" [routerLink]="[ '/cart' ]"
          class="btn cartBoton btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3">Ir
          al carrito</a>
        <button *ngIf="!loading && !isAuth() && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3 letter2" data-toggle="modal"
          data-target="#login">Cómpralo</button>
        <button *ngIf="!loading && isAuth() && !adq && !sessData.addedLab"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3 letter2" (click)="addToCart()"
          [routerLink]="['/cart/checkout']">Cómpralo</button>
        <button *ngIf="!loading && isAuth() && sessData.addedLab && !adq"
          class="btn btn-style-6 w-100 btn-md btn-altea d-block mx-auto my-3 letter2"
          [routerLink]="['/cart/checkout']">Cómpralo</button>
        <a *ngIf="adq" class="btn btn-md btn-style-6 d-lg-block mx-auto mt-3 d-none"
          [routerLink]="[ '/lab-landing', lab.id, 'lab' ]">Portal Lab</a>


        <div class="row mx-0 mt-3">
          <ul class="col-12 px-0 included">
            <li>Este lab incluye:</li>
            <li><i class="fas fa-book"></i>&nbsp;&nbsp;<strong>Numero de palabras:</strong> {{list.num_words}}</li>
            <li><i class="fas fa-infinity"></i>&nbsp;Acceso de por vida</li>
          </ul>

        </div>
      </div>

      <div class="row no-gutters col-12 px-0 justify-content-around position-absolute saveBtn">
        <hr class="col-12 mb-2">
        <button class="btn border mx-1" [routerLink]="[ '/landing' ]">Cancel</button>
        <button class="btn btn-altea mx-1" (click)="saveFlyer()">save changes</button>
      </div>
    </div>

  </div>


  <div class="center-spacing">
    <div class="row px-0 no-gutters objContainer">
      <div class="px-0 pt-3 col-12 mx-0">
        <div class="pb-2 pt-1 px-0 row no-gutters grayCont mx-auto justify-content-between">
          <div class="trusted-cards ml-0 mx-2  uni-card text-center bg-white" *ngFor="let emp of Empresas|slice:0:5">
            <div class="entimg-container">
              <img [src]="emp.image" alt="Card image" class="ent-img mx-auto">
            </div>
            <!-- <div class="uni-cardbody">
                <h4 class="uni-name text-center">{{emp.name}}</h4>
            </div> -->
          </div>
        </div>
        <div class="pb-2 pt-1 px-0 row no-gutters grayCont mx-auto justify-content-between">
          <div class="trusted-cards ml-0 mx-2  uni-card text-center bg-white" *ngFor="let emp of Empresas|slice:5:10">
            <div class="entimg-container">
              <img [src]="emp.image" alt="Card image" class="ent-img mx-auto">
            </div>
            <!-- <div class="uni-cardbody">
                <h4 class="uni-name text-center">{{emp.name}}</h4>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row px-0 no-gutters objContainer">
      <div class="px-0 pt-4 col-12">
        <div class="row">
          <div class="pr-2 col-6">
            <div class="p-2 mt-2 mb-4 row no-gutters grayCont">
              <p class="font24 d-block">Objetivos</p>
              <ul class="pl-0 d-block">
                <li class="row no-gutters mainGray list-unstyled" *ngFor="let obj of objs;let j = index">
                  <i class="fas fa-check col-1 check"></i>
                  <div *ngIf="!editObj[j]" class="col objectives d-flex justify-content-between"
                    (mouseenter)="pencilobjetives[j] = true" (mouseleave)="pencilobjetives[j] = false">
                    <p class="mb-0" (click)="editObj[j]=!editObj[j]">{{obj}}</p>
                    <div *ngIf="pencilobjetives[j]" class="pencilboard"><i
                        (click)="editObj[j]= true; editfieldobj = true; assignIndex(j)"
                        class="pl-1 my-auto pencilobjetives fas fa-edit"></i></div>
                  </div>
                  <div class="d-flex col px-0 mx-0 justify-content-between" *ngIf="editObj[j]">
                    <textarea class="w-100" rows="3"
                      (keyup.enter)="editObj[j]=!editObj[j]; addObjective(objectiveEdit.value, j); pencilobjetives[j]"
                      #objectiveEdit value="{{obj}}">
                      </textarea>
                    <i (click)="deleteobj(j); editObj[j]=false" class="fas p-1 my-auto fa-trash-alt"></i>
                  </div>
                </li>
                <li class="row no-gutters mainGray list-unstyled">
                  <i class="fas fa-check col-1 check"></i>
                  <p class="col"><input (click)="editfieldobj = true; assignIndex(objs.length)" type="text"
                      class="w-100" (keyup.enter)="addObjective(objective.value); objective.value=''" #objective></p>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-6">
            <div class="p-2 mt-2 mb-4 row no-gutters grayCont">
              <p class=" font24">Requisitos</p>
              <ul class="ml-0">
                <li *ngFor="let req of reqs; let g=index">
                  <div *ngIf="!editReq[g]" class="req d-flex justify-content-between" (mouseenter)="pencilreq[g] = true"
                    (mouseleave)="pencilreq[g] = false">
                    <p class="mb-0" (click)="editReq[g]=!editReq[g]">{{req}}</p>
                    <div *ngIf="pencilreq[g]" class="pencilboard"><i
                        (click)="editReq[g]= true; editfieldreq = true; assignIndex(g)"
                        class="pl-1 my-auto pencilreq fas fa-edit"></i></div>
                  </div>
                  <div *ngIf="editReq[g]" class="d-flex mx-0 px-0 col justify-content-between">
                    <textarea type="text" class="w-100" (keyup.enter)="editReq[g]=!editReq[g]; addReqs(reqEdit.value, g)"
                      #reqEdit value="{{req}}"></textarea>
                    <i (click)="deletereq(g); editReq[g]=false" class="fas p-1 my-auto fa-trash-alt"></i>
                  </div>
                </li>
                <li><input type="text" class="w-100" (click)="editfieldreq = true; assignIndex(reqs.length)"
                    (keyup.enter)="addReqs(req.value); req.value=''" #req></li>
              </ul>
            </div>

            <!-- <p class="font24">Para quien</p>
            <ul class="pl-0">
              <li class="row no-gutters mainGray list-unstyled" *ngFor="let who of towho; let i=index;">
                <i class="fas fa-check col-1 check"></i>
                <div *ngIf="!editWho[i]" class="col towho d-flex justify-content-between"
                  (mouseenter)="penciltowho[i] = true" (mouseleave)="penciltowho[i] = false">
                  <p class="mb-0" (click)="editWho[i]=!editWho[i]">{{who}}</p>
                  <div *ngIf="penciltowho[i]" class="pencilboard"><i
                      (click)="editWho[i]= true; editfieldwho = true; assignIndex(i)"
                      class="pl-1 my-auto pencilobjetives fas fa-edit"></i></div>
                </div>
                <div *ngIf="editWho[i]" class="d-flex col mx0 px-0 justify-content-between">
                  <input type="text" class="w-100" (keyup.enter)="editWho[i]=!editWho[i]; addTowho(toWhoEdit.value, i)"
                    #toWhoEdit value="{{who}}">
                  <i (click)="deletetowho(i); editWho[i]=false" class="fas p-1 my-auto fa-trash-alt"></i>
                </div>
              </li>
              <li class="row no-gutters mainGray list-unstyled">
                <i class="fas fa-check col-1 check"></i>
                <p class="col"><input type="text" class="w-100" (click)="editfieldwho = true; assignIndex(towho.length)"
                    (keyup.enter)="addTowho(toWho.value); toWho.value=''" #toWho></p>
              </li>
            </ul> -->
          </div>
        </div>



        <p class=" font24">Descripción</p>
        <div *ngIf="!editdesc" class="d-flex mt-2 mb-4  desc" (mouseenter)="pencildesc = true"
          (mouseleave)="pencildesc = false">
          <div (click)="editdesc=!editdesc" class="col px-0" [innerHTML]="desc">

          </div>
          <div *ngIf="pencildesc" class="pencilboard"><i (click)="editdesc = true; editfieldesc = true"
              class="pl-1 my-auto pencilreq fas fa-edit"></i></div>
        </div>
        <div *ngIf="editdesc" class="d-flex mx-0 px-0 justify-content-between">
          <quill-editor ngDefaultControl #editor [(ngModel)]="desc" [placeholder]="placeholder"
            [modules]="editorModules" [style]="{height: '200px'}" (onContentChanged)="changeDesc($event)">
          </quill-editor>
          <!-- <i (click)="desc.value=''" class="fas p-1 my-auto fa-trash-alt"></i> -->
          <i (click)="editdesc=!editdesc;pencildesc = false" class="fas pencildescription p-1 my-auto fa-check"></i>
        </div>
      </div>
      <div class="comentary ">
        <span class="coments">Comentarios de los estudiantes</span>
        <div class="firstReview border mb-3" *ngIf="reviews.length == 0">
          <div class="d-flex">

            <p class="mb-0 sugerenciaComent">
              Se el primero
              en
              dejar uno!!</p>
            <label class="pl-0 mb-0 mr-1 firstStarReview starfill" for="radio12"><i class="fas fa-star"></i></label>

          </div>
          <p class="mb-0 noComent">
            Aun no hay ningun comentario...</p>
          <button type="button" class="btn btn-altea d-block mx-0 btnValorar" data-toggle="modal" data-target="#rating">
            Valorar
          </button>
        </div>

        <div *ngIf="reviews.length > 0">
          <div class="row rateTotal mt-2 mb-2 ml-3">
            <div class="my-auto avgRate col-3 text-center">
              <span class="font72">{{rating | decimalPipe}}</span>
              <div class="starstrans  mx-auto">
                <i *ngIf="rating == 0" class="fas px-1 fa-star star starempty"></i>
                <i *ngIf="rating == 0" class="fas px-1 fa-star star starempty"></i>
                <i *ngIf="rating == 0" class="fas px-1 fa-star star starempty"></i>
                <i *ngIf="rating == 0" class="fas px-1 fa-star star starempty"></i>
                <i *ngIf="rating == 0" class="fas px-1 fa-star star starempty"></i>

                <i *ngIf="rating == 1 || rating == 2 || rating == 3 || rating == 4 || rating == 5
             || (2 > rating && rating > 1) || (3 > rating && rating > 2) 
             || (4 > rating && rating > 3)|| (5 > rating && rating > 4)" class="fas star fa-star starfill"></i>

                <i *ngIf="rating == 2 || rating == 3 || rating == 4 || rating == 5 
            || (3 > rating && rating > 2) || (4 > rating && rating > 3)|| (5 > rating && rating > 4)"
                  class="fas star fa-star starfill"></i>
                <i *ngIf="2 > rating && rating > 1" class="fas star fa-star-half starfill"></i>
                <i *ngIf="2 > rating && rating > 1" class="fas star fa-star-half starempty halfemptyflyeravg"></i>
                <i *ngIf="rating == 1" class="fas star fa-star starempty"></i>

                <i *ngIf="rating == 3 || rating == 4 || rating == 5 || (4 > rating && rating > 3)|| (5 > rating && rating > 4)"
                  class="fas star fa-star starfill"></i>
                <i *ngIf="3 > rating && rating > 2" class="fas star fa-star-half starfill"></i>
                <i *ngIf="3 > rating && rating > 2" class="fas star fa-star-half starempty halfemptyflyeravg"></i>
                <i *ngIf=" rating == 1 || rating == 2 || (2 > rating && rating > 1)"
                  class="fas star fa-star starempty"></i>

                <i *ngIf="rating == 4 || rating == 5 || (5 > rating && rating > 4) "
                  class="fas star fa-star starfill"></i>
                <i *ngIf="4 > rating && rating > 3" class="fas star fa-star-half starfill"></i>
                <i *ngIf="4 > rating && rating > 3" class="fas star fa-star-half starempty halfemptyflyeravg"></i>
                <i *ngIf="rating == 1 ||rating == 2 || rating == 3 || (2 > rating && rating > 1) ||
             (3 > rating && rating > 2)" class="fas star fa-star starempty"></i>

                <i *ngIf="rating == 5" class="fas star fa-star starfill"></i>
                <i *ngIf="5 > rating && rating > 4" class="fas star fa-star-half starfill"></i>
                <i *ngIf="5 > rating && rating > 4" class="fas star fa-star-half starempty halfemptyflyeravg"></i>
                <i *ngIf="rating == 1 || rating == 2 || rating == 3 || rating == 4
           || (2 > rating && rating > 1) || (3 > rating && rating > 2) ||
            (4 > rating && rating > 3)" class="fas star fa-star starempty"></i>
              </div>
              <span class="valoration mx-auto">Valoración del curso</span>
            </div>
            <!-- Progress Warning Animated (Style 3) -->
            <div class="row col-9 flexquerie">
              <div class="col-8 progressBar">
                <div class="progress progress-style-3 progress-animate-fill pt-3 mt-2 mb-4">
                  <div class="progress-bar bg-warning" [ngStyle]="{'width': barPercent[4]}" role="progressbar"
                    aria-valuenow="starsPercent[4]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress progress-style-3 progress-animate-fill pt-3 mb-4">
                  <div class="progress-bar bg-warning" [ngStyle]="{'width': barPercent[3]}" role="progressbar"
                    aria-valuenow="starsPercent[3]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress progressbar3 progress-style-3 progress-animate-fill pt-3 mb-4">
                  <div class="progress-bar bg-warning" [ngStyle]="{'width': barPercent[2]}" role="progressbar"
                    aria-valuenow="starsPercent[2]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress progressbar2 progress-style-3 progress-animate-fill pt-3 mb-4">
                  <div class="progress-bar bg-warning" [ngStyle]="{'width': barPercent[1]}" role="progressbar"
                    aria-valuenow="starsPercent[1]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="progress progressbar1 progress-style-3 progress-animate-fill pt-3 mb-4">
                  <div class="progress-bar bg-warning" [ngStyle]="{'width': barPercent[0]}" role="progressbar"
                    aria-valuenow="starsPercent[0]" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
              <div class="starsright pr-0 col-4">
                <div class="stars1 ml-3 mt-1 d-flex">
                  <p class="clasificacion mb-0">

                    <label class=" clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio12"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio22"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio32"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio42"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio52"><i
                        class="fas fa-star"></i></label>
                  </p>
                  <span class="ml-1">{{starsPercent[4]| decimalPipe}}%</span>
                </div>
                <div class="stars1 ml-3 d-flex">
                  <p class="clasificacion mb-0">



                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio52"><i
                        class="fas fa-star"></i></label>
                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio12"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio22"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio32"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio42"><i
                        class="fas fa-star"></i></label>
                  </p>
                  <span class="ml-1">{{starsPercent[3]| decimalPipe}}%</span>
                </div>
                <div class="stars1 ml-3 stars2 d-flex">
                  <p class="clasificacion mb-0">



                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio42"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio52"><i
                        class="fas fa-star"></i></label>
                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio12"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio22"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio32"><i
                        class="fas fa-star"></i></label>
                  </p>
                  <span class="ml-1">{{starsPercent[2]| decimalPipe}}%</span>
                </div>
                <div class="stars1 ml-3 d-flex">
                  <p class="clasificacion mb-0">



                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio32"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio42"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio52"><i
                        class="fas fa-star"></i></label>
                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio12"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio22"><i
                        class="fas fa-star"></i></label>
                  </p>
                  <span class="ml-1">{{starsPercent[1]| decimalPipe}}%</span>
                </div>
                <div class="stars1 ml-3 d-flex">
                  <p class="clasificacion mb-0">



                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio22"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio32"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio42"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star" for="radio52"><i
                        class="fas fa-star"></i></label>

                    <label class="clasificacionflyer pl-0 mb-0 mr-1 star starfill" for="radio12"><i
                        class="fas fa-star"></i></label>
                  </p>
                  <span class="ml-1">{{starsPercent[0]| decimalPipe}}%</span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between mb-2">
            <p class="mb-3 font20">Reseñas</p>
            <button type="button" class="btn btn-altea d-block mx-0  " data-toggle="modal" data-target="#rating">
              Valorar
            </button>
          </div>
          <div class="comentSection" *ngFor="let review of reviews">
            <div class="row">
              <div class="avatarFlyer col-2">
                <picture><img class="avatar" [src]="review.user.picture" *ngIf="review.user.picture != null" alt="">
                  <i class="fas fa-user" *ngIf="review.user.picture == null"></i>
                </picture>
              </div>
              <div class="col-3">
                <p class="mb-1 text-muted font-15">Hace 7 meses</p>
                <p class="font-15">{{review.user.name}} {{review.user.last_name}}</p>

              </div>
              <div class="col-7">
                <div class="starsread d-flex">
                  <p class="clasificacion mb-0 pb-0">

                    <label class="pl-0 mr-1 star" for="radio52" *ngFor="let item of review.arrayRatingElse"><i
                        class="fas fa-star"></i></label>

                    <label class="pl-0 mr-1 star starfill" for="radio12" *ngFor="let item of review.arrayRating"><i
                        class="fas fa-star"></i></label>



                  </p>
                </div>
                <div>
                  <p>{{review.comment}}
                  </p>
                </div>
                <div class="d-flex">
                  <p class="my-auto text-muted mr-1 mb-0">¿Te ha resultado útil el comentario?</p>
                  <a class="btnopinion text-center cursorpointer">Sí</a>
                  <a class="btnopinion text-center cursorpointer">No</a>

                </div>
              </div>
            </div>
          </div>






        </div>
      </div>
    </div>
  </div>
</div>

<div class="p-2 ml-4 border"
  style="background-color: white !important;position:fixed; bottom:0; width: 600px; height: max-content"
  *ngIf="editname">
  <div style="cursor: pointer" (click)="editname = false; pencilTitle = false" class="d-flex justify-content-between">
    <p style="font-weight: bolder" class="mb-1">Editando título del flyer</p>
    <i class="fas fa-times"></i>
  </div>
  <hr class="mb-2">
  <p style="font-weight: bolder">Ultimas frases que has utilizado:</p>
  <div *ngFor="let name of flyerName">
    <div class="d-flex justify-content-between">
      <p>-{{name}}</p>
      <i (click)="addName(name)" style="cursor:pointer" class="fas pl-1 fa-edit"></i>
    </div>
  </div>
</div>
<div class="p-2 ml-4 border"
  style="background-color: white !important;position:fixed; bottom:0; width: 600px; height: max-content"
  *ngIf="editKey">
  <div style="cursor: pointer" (click)="editKey = false; pencilkey = false" class="d-flex justify-content-between">
    <p style="font-weight: bolder" class="mb-1">Editando key del flyer</p>
    <i class="fas fa-times"></i>
  </div>
  <hr class="mb-2">
  <p style="font-weight: bolder">Ultimas frases que has utilizado:</p>
  <div *ngFor="let key of keys">
    <div class="d-flex justify-content-between">
      <p>-{{key}}</p>
      <i (click)="addKey(key)" style="cursor:pointer" class="fas pl-1 fa-edit"></i>
    </div>
  </div>
</div>
<div class="p-2 ml-4 border"
  style="background-color: white !important;position:fixed; bottom:0; width: 600px; height: max-content"
  *ngIf="editfieldobj">
  <div style="cursor: pointer"
    (click)="editfieldobj = false; editObj[indexEdit] = false; pencilobjetives[indexEdit]=false"
    class="d-flex justify-content-between">
    <p style="font-weight: bolder" class="mb-1">Editando objetivos del flyer</p>
    <i class="fas fa-times"></i>
  </div>
  <hr class="mb-2">
  <p style="font-weight: bolder">Ultimas frases que has utilizado:</p>
  <div *ngFor="let obj of objs">
    <div class="d-flex justify-content-between">
      <p>-{{obj}}</p>
      <i (click)="addObj(obj)" style="cursor:pointer" class="fas pl-1 fa-edit"></i>
    </div>
  </div>
</div>
<div class="p-2 ml-4 border"
  style="background-color: white !important;position:fixed; bottom:0; width: 600px; height: max-content"
  *ngIf="editfieldwho">
  <div style="cursor: pointer" (click)="editfieldwho = false; editWho[indexEdit] = false; penciltowho[indexEdit]=false"
    class="d-flex justify-content-between">
    <p style="font-weight: bolder" class="mb-1">Editando para quien del flyer</p>
    <i class="fas fa-times"></i>
  </div>
  <hr class="mb-2">
  <p style="font-weight: bolder">Ultimas frases que has utilizado:</p>
  <div *ngFor="let who of towho">
    <div class="d-flex justify-content-between">
      <p>-{{who}}</p>
      <i (click)="addWho(who)" style="cursor:pointer" class="fas pl-1 fa-edit"></i>
    </div>
  </div>
</div>
<div class="p-2 ml-4 border"
  style="background-color: white !important;position:fixed; bottom:0; width: 600px; height: max-content"
  *ngIf="editfieldreq">
  <div style="cursor: pointer" (click)="editfieldreq = false; pencilreq[indexEdit]=false; editReq[indexEdit]=false"
    class="d-flex justify-content-between">
    <p style="font-weight: bolder" class="mb-1">Editando requisitos del flyer</p>
    <i class="fas fa-times"></i>
  </div>
  <hr class="mb-2">
  <p style="font-weight: bolder">Ultimas frases que has utilizado:</p>
  <div *ngFor="let req of reqs">
    <div class="d-flex justify-content-between">
      <p>-{{req}}</p>
      <i (click)="addReq(req)" style="cursor:pointer" class="fas pl-1 fa-edit"></i>
    </div>
  </div>
</div>
<div class="p-2 ml-4 border"
  style="background-color: white !important;position:fixed; bottom:0; width: 600px; height: max-content"
  *ngIf="editfieldesc">
  <div style="cursor: pointer" (click)="editfieldesc = false;pencildesc=false; editdesc=false;"
    class="d-flex justify-content-between">
    <p style="font-weight: bolder" class="mb-1">Editando descripcion del flyer</p>
    <i class="fas fa-times"></i>
  </div>
  <hr class="mb-2">
  <p style="font-weight: bolder">Ultimas frases que has utilizado:</p>
  <div *ngFor="let desc of desc">
    <div class="d-flex justify-content-between">
      <p>-{{desc}}</p>
      <i (click)="addDesc(desc)" style="cursor:pointer" class="fas pl-1 fa-edit"></i>
    </div>
  </div>
</div>

<div class="modal fade" id="editImage" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="cropper px-0 text-center">
          <image-cropper *ngIf="!imgcrop" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
            [aspectRatio]="16 / 9" format="png jpg" (imageCropped)="imageCropped($event)">
          </image-cropper>
          <img *ngIf="imgcrop" class="" [src]="croppedImage" />
        </div>
      </div>
      <hr class="border-white mt-0" />
      <div class="modal-body">
        <div class="row">
          <div class="col-6 input-group">
            <div class="custom-file">
              <input type="file" class="custom-file-input" (change)="fileChangeEvent($event)" accept="image/*">
              <label class="custom-file-label">Choose file</label>
            </div>
          </div>
          <button *ngIf="croppedImage && !imgcrop" (click)="changeImage();!imgcrop=!imgcrop" [disabled]="!croppedImage"
            type="button" class="btn btn-danger">
            Crop
          </button>
          <button *ngIf="imgcrop" (click)="!imgcrop=!imgcrop" [disabled]="!croppedImage" type="button"
            class="btn btn-danger">
            Re-crop
          </button>
        </div>
      </div>
      <div class="modal-footer col-12">
        <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Close</button>
        <button class="btn btn-secondary btn-sm" (click)="changeImage()" type="button"
          data-dismiss="modal">Save</button>
      </div>
    </div>
  </div>
</div>