<div class="h-100" style="padding: 1% 0%; width: 81%; background-color: #203341; position: absolute; left: 19%; display: flex;  transition-duration: 600ms; transition-timing-function: ease-in-out"  [appTooltip]="800" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%', 'width': admin.sidebarShow ? '85%' : '96%'}">
    <div style="width: 60%; flex-direction: column; margin-right: 1%;" class="h-100 d-flex"  [appTooltip]="801">
        <div style="height: 100%; overflow: auto;">
            <div class="h-100 w-100 bg-white" style="padding: 2%" [appTooltip]="802">
                <div  class="w-100 d-flex" [appTooltip]="803">
                    <div class="title h-100">
                    User list
                    </div>             
                </div>
                <div class="searchUser d-flex blackLetterColor" [appTooltip]="804">     
                    <span [appTooltip]="803"> Search  </span>            
                    <div class="searchBox" (keyup)="searchUsersNew($event.target.value)" [appTooltip]="805"> 
                        <input type="text" class="w-100" style="outline: none; border: none;" >
                    </div>
                    <span [appTooltip]="803">   Role </span>
                    <div class="searchBox d-flex" >                      
                        <div (keyup)="searchRoleType($event.target.value)" [ngStyle]="{'width': selectedRole == undefined ? '90%': '0'}" [appTooltip]="806"> 
                            <input type="text" class="w-100" style="outline: none; border: none;" (click)="researchRole()" [appTooltip]="807">
                        </div>
                        <div *ngIf="selectedRole != undefined" class="h-100 w-100" style="padding: 2px 8px;">
                            <span (click)="researchRole()" [appTooltip]="808">
                                {{selectedRole}}
                            </span>
                        </div>
                        <div *ngIf="searchUserRole == true"  style="z-index: 1000; color: #3C5176 !important; width: 22%; border: 1px solid #1C4360;  height: 120px; overflow-y: scroll; cursor: pointer;" class="position-absolute bg-white" >
                            <div *ngFor="let role of roles; let i=index" style="padding: 3px;" (click)="selectRole(role);" [appTooltip]="809">
                                {{role}}
                            </div>
                        </div>    
                        <i class="fas fa-caret-down" (click)="searchRole()" [appTooltip]="810" style="width: 10%; margin: auto; cursor: pointer;" (clickOutside)="searchUserRole = false"></i>
                    </div>
                </div>
                <div  style="margin: 1% 0; flex-direction: column; height: 90%;" class="w-100 d-flex" [appTooltip]="811" >
                    <div class="w-100 d-flex blackLetterColor">
                        <div style="width: 25%;" class="h-100 title2" [appTooltip]="812">
                           User name
                        </div>
                        <div style="width: 25%;" class="h-100 title2" [appTooltip]="813">
                           E-mail
                        </div>
                        <div style=" width: 20%;" class="h-100 title2" [appTooltip]="814">
                            Mobile
                        </div>
                        <div style=" width: 20%;" class="h-100 title2" [appTooltip]="815">
                            Group / Role
                        </div>
                        <div style=" width: 10%;" class="h-100 title2 text-center" [appTooltip]="816">
                           Status
                        </div>
                    </div>
                    <div  class="w-100 h-100 group">
                        <div class="w-100 d-flex letterColor" *ngFor="let user of userListSearch; let i = index" (click)="selectUser(user);" [appTooltip]="817" [ngClass]="{'groupSelected':  selectedUserProfile == user}">
                            <div style="width: 25%; cursor: pointer;" class="h-100 user text-truncate" [appTooltip]="818">
                                {{user.name}} {{user.last_name}}
                            </div>
                            <div style="width: 25%; cursor: pointer;" class="h-100 user text-truncate" [appTooltip]="819">
                                {{user.email}}
                            </div>
                            <div style=" width: 20%; cursor: pointer;" class="h-100 user" [appTooltip]="820">
                                {{user.phone}}
                            </div>
                            <div style=" width: 20%; cursor: pointer;" class="h-100 user" [appTooltip]="821">
                                {{user.role_name}}
                            </div>
                            <div style=" width: 10%;" class="h-100 user">
                                <div [appTooltip]="822" style="width: 9%; border: 0.5px solid rgb(241, 241, 241);  font-size: 1.3vh;  margin: auto; padding: 6px; cursor: pointer; border-radius: 50%;" [ngStyle]="{'background-color': user.deleted == 0 ? '#6fa84b': '#e94f53' }" (click)="changeStatusUser(user);">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>
<div style="width: 38%; " class="h-100">
    <div class="h-100 w-100 bg-white letterColor" style="padding: 3%;" [appTooltip]="823">
        <div  class="w-100 d-flex">
            <div class="title h-100" [appTooltip]="824">
                USER PROFILE
            </div>
        </div> 
        <div class="w-100 d-flex">
            <div *ngIf="selectedUserProfile != undefined" class="h-100 w-100 d-flex groupSelected" style="margin: 1% 0" [appTooltip]="825">
                <div style="width: 30%;"  class="groupInfo" [appTooltip]="826">
                    {{selectedUserProfile.name}} {{selectedUserProfile.last_name}}
                </div>
                <div style="width: 50%;" class="groupInfo text-truncate">
                    
                </div>
            </div>
        </div>
        <div class="d-flex" style="flex-direction: column; height:60%; margin: 3% 0;" *ngIf="userProfileInfo != undefined" [appTooltip]="827">
            <div class="d-flex w-100" style="height: 30%;">
                <div style="width: 21%;height: 93%;" [appTooltip]="828" [ngStyle]="{'background-color': userProfileInfo.picture != null ? 'userProfileInfo.picture' : 'rgb(240 240 240)'}">

                </div>
                <div class="d-flex" style="flex-direction: column; width: 78%; margin: 0 6%" [appTooltip]="829">
                    <div class="user text-truncate" [appTooltip]="830">
                        {{userProfileInfo.name}} {{userProfileInfo.last_name}}
                    </div>
                    <div  class="user text-truncate" [appTooltip]="831">
                        {{userProfileInfo.email}}
                    </div>
                    <div  class="user" style="border: 1px solid rgb(241, 241, 241); width: 192px; cursor: pointer; margin: 1% 0; height: 15%;" (click)="changeRole = true" (clickOutside)="changeRole = false" [appTooltip]="832">
                        {{userProfileInfo.role_name}}
                        <div *ngIf="changeRole == true"  style="z-index: 1000; color: #3C5176 !important; width: 12%; border: 1px solid #1C4360;  height: 120px; overflow-y: scroll;" class="position-absolute bg-white">
                            <div *ngFor="let role of roles; let i=index" style="padding: 3px;" (click)="changeUserRole(userProfileInfo, role)"  [appTooltip]="833">
                                {{role}}
                            </div>
                        </div>   
                    </div>
                    <div class="user" style="border: 1px solid rgb(241, 241, 241); width: 192px; cursor: pointer; margin: 1% 0; height: 15%;" (click)="chooseOffice = true" (clickOutside)="chooseOffice = false" [appTooltip]="834">
                    <div *ngIf="userProfileInfo.office_name != undefined" [appTooltip]="835">{{userProfileInfo.office_name}} </div>
                    <div *ngIf="userProfileInfo.office_name == undefined" [appTooltip]="836"> Office </div>
                        <div *ngIf="chooseOffice == true"  style="z-index: 1000; color: #3C5176 !important; width: 12%; border: 1px solid #1C4360;  height: 120px; overflow-y: scroll;" class="position-absolute bg-white" >
                            <div *ngFor="let office of offices; let i=index" style="padding: 3px;" (click)="changeOffice(userProfileInfo, office)" [appTooltip]="837">
                                {{office.name}}
                            </div>
                        </div>   
                    </div>
                    <div class="user" [appTooltip]="838">
                        {{userProfileInfo.phone}}
                    </div> 
                    <div style="width: 21%; text-align: center;" >
                        <div class="newGroupButton"  (click)="changeUserProfile();" [appTooltip]="839">
                            Actualizar 
                        </div> 
                    </div>
                </div>
            </div> 
            <div  class="d-flex w-100" style="flex-direction: column; margin: 3% 0">
                <div  class="w-100 d-flex" [appTooltip]="840"> 
                    <div class="title h-100">
                        LogIn Information
                    </div>          
                </div>
                <div  class="d-flex w-100" style="flex-direction: column;" [appTooltip]="841">
                    <div class="d-flex w-100">
                        <span class="title2" style="color: black">
                            Last login: 
                        </span>
                        <span  class="title2" [appTooltip]="842">
                            {{userLogInfo.last_login}} 
                        </span>
                    </div>
                    <div class="d-flex w-100">
                        <span class="title2" style="color: black">
                            País: 
                        </span>
                        <span class="title2" [appTooltip]="843">
                            {{userLogInfo.country}} 
                        </span>
                    </div>
                    <div class="d-flex w-100">
                        <span class="title2"  style="color: black">
                            Región: 
                        </span>
                        <span class="title2" [appTooltip]="844">
                            {{userLogInfo.region}} 
                        </span>
                    </div>
                    <div class="d-flex w-100">
                        <span class="title2"  style="color: black">
                            Ciudad: 
                        </span>
                        <span class="title2" [appTooltip]="845">
                            {{userLogInfo.city}} 
                        </span>
                    </div>
                    <div class="d-flex w-100">
                        <span class="title2"  style="color: black">
                            Zona horaria: 
                        </span>
                        <span class="title2" [appTooltip]="846">
                            {{userLogInfo.timezone}} 
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>    
    </div> 
</div>


