<div class="modal fade" id="avatarChange" tabindex="-1" role="dialog">
    <div class="modal-dialog  modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-between">
                <div class=" pr-5 input-group">
                    <div style="max-width: 300px" class="custom-file">
                        <input type="file" class="custom-file-input" (change)="fileChangeEvent($event)" accept="image/*">
                        <label class="custom-file-label">Choose file</label>
                    </div>
                </div>
                <i style="cursor: pointer" (click)="labInfo.preImg=false" data-dismiss="modal" class=" fas avatarclose fa-times"></i>
            </div>
            <div style="display: flex; flex-direction: column" class="modal-body">
                <image-cropper *ngIf="!labInfo.preImg" class="mb-1 imageCroppper" [imageBase64]="imageSelected" [imageChangedEvent]="imageChangedEvent" [imageQuality]="100" [maintainAspectRatio]="false" [aspectRatio]="1/1" format="jpeg" (imageCropped)="imageCropped($event)"></image-cropper>
                <img style="height: 100%; width: auto" *ngIf="labInfo.preImg" class="mx-auto mb-3" src={{labInfo.croppedImage}} />
            </div>
            <div style="flex-direction: column" class="modal-footer pb-0 px-0">
                <div style="position: relative" class="mx-auto">
                    <div ng2-carouselamos *ngIf="labInfo.degree != undefined" [width]="1380" [items]="labInfo.degree.images" [$item]="itemTemplate" [$prev]="prevTemplate" [$next]="nextTemplate" (onSelectedItem)="indexCalc($event.index); selectedItem = $event.item; selectedIndex = $event.index"></div>

                    <ng-template #prevTemplate>
                        <div *ngIf="labInfo.degree.images.length > 6 && selectedIndex != 0" class="prevButton"></div>
                    </ng-template>

                    <ng-template #nextTemplate>
                        <div *ngIf="labInfo.degree.images.length > 6 && !disableButton" class="nextButton"></div>
                    </ng-template>

                    <ng-template let-item let-i="index" #itemTemplate>
                        <img style="height: 120px; width: 214px; cursor: pointer" (click)="fileChangeEvent(item.picture)" class="mx-2" [src]="item.picture">
                    </ng-template>
                </div>
                <div class="row w-100 mx-0 pl-0 pr-4 justify-content-end">
                    <button (click)="cargarPreImg()" [disabled]="!labInfo.croppedImage" type="button" class="btn loadImg btn-success" *ngIf="labInfo.croppedImage && !labInfo.preImg"> Crop
        </button>
                    <button (click)="cargarPreImg()" type="button" class="mr-3 loadImg btn btn-success" *ngIf="labInfo.preImg">
        Re-crop
        </button>
                    <button data-dismiss="modal" (click)="labInfo.changeImage();" type="button" class="btn loadImg btn-success" *ngIf="labInfo.preImg">
        Load image
        </button>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="imgChange" tabindex="-1" role="dialog">
    <div class="modal-dialog  modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-between">
                <div class=" pr-5 input-group">
                    <div style="max-width: 300px" class="custom-file">
                        <input type="file" class="custom-file-input" (change)="fileChangeEvent2($event)" accept="image/*">
                        <label class="custom-file-label">Choose file</label>
                    </div>
                </div>
                <i style="cursor: pointer" (click)="labInfo.preImg2=false" data-dismiss="modal" class=" fas avatarclose fa-times"></i>
            </div>
            <div style="display: flex; flex-direction: column" class="modal-body">
                <image-cropper *ngIf="!labInfo.preImg2" class="mb-1 imageCroppper" [imageBase64]="imageSelected2" [imageChangedEvent]="imageChangedEvent2" [imageQuality]="100" [maintainAspectRatio]="false" [aspectRatio]="1/1" format="jpeg" (imageCropped)="imageCropped2($event)"></image-cropper>
                <img style="height: 100%; width: auto" *ngIf="labInfo.preImg2" class="mx-auto mb-3" src={{labInfo.croppedImage2}} />
            </div>
            <div style="flex-direction: column" class="modal-footer pb-0 px-0">
                <!-- <div style="position: relative" class="mx-auto">
                    <div ng2-carouselamos *ngIf="labInfo.degree != undefined" [width]="1380" [items]="labInfo.degree.images" [$item]="itemTemplate" [$prev]="prevTemplate" [$next]="nextTemplate" (onSelectedItem)="indexCalc($event.index); selectedItem = $event.item; selectedIndex = $event.index"></div>

                    <ng-template #prevTemplate>
                        <div *ngIf="labInfo.degree.images.length > 6 && selectedIndex != 0" class="prevButton"></div>
                    </ng-template>

                    <ng-template #nextTemplate>
                        <div *ngIf="labInfo.degree.images.length > 6 && !disableButton" class="nextButton"></div>
                    </ng-template>

                    <ng-template let-item let-i="index" #itemTemplate>
                        <img style="height: 120px; width: 214px; cursor: pointer" (click)="fileChangeEvent2(item.picture)" class="mx-2" [src]="item.picture">
                    </ng-template>
                </div> -->
                <div class="row w-100 mx-0 pl-0 pr-4 justify-content-end">
                    <button (click)="cargarPreImg2()" [disabled]="!labInfo.croppedImage2" type="button" class="btn loadImg btn-success" *ngIf="labInfo.croppedImage2 && !labInfo.preImg2"> Crop
            </button>
                    <button (click)="cargarPreImg2()" type="button" class="mr-3 loadImg btn btn-success" *ngIf="labInfo.preImg2">
            Re-crop
            </button>
                    <button data-dismiss="modal" (click)="setImage();" type="button" class="btn loadImg btn-success" *ngIf="labInfo.preImg2">
            Load image
            </button>
                </div>

            </div>
        </div>
    </div>
</div>