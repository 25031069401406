<div class="navbar navbar-expand-sm navbar-dark-white bg-gradient-primary p-0 ">
    <div class="container page__container px-0">
        <button class="navbar-toggler ml-n16pt" type="button" data-toggle="collapse" data-target="#navbar-submenu2">
      <span class="material-icons">people_outline</span>
    </button>
        <div class="collapse navbar-collapse" id="navbar-submenu2">
            <div class="navbar-collapse__content pb-16pt pb-sm-0">
                <ul class="nav navbar-nav">
                    <li class="nav-item active  ">
                        <a class="nav-link">
                            <div class="btn-group">
                                <a class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Campus
                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let campus of Campuses" (click)="selCampus(campus.id)" [ngClass]="{'active': campus_id==campus.id}">{{campus.name}}</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item active  ">
                        <a class="nav-link">
                            <div class="btn-group">
                                <a class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Categoria
                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let faculty of Faculties" (click)="selFaculty(faculty.id)" [ngClass]="{'active': faculty_id==faculty.id}">{{faculty.name}}</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item active  ">
                        <a class="nav-link">
                            <div class="btn-group">
                                <a class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Subcategoría
                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let degree of Degrees" (click)="selDegree(degree.id)" [ngClass]="{'active': degree_id==degree.id}">{{degree.name}}</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item active  ">
                        <a class="nav-link">
                            <div class="btn-group">
                                <a class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Disciplina
                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let subject of Subjects" (click)="selSubject(subject.id)" [ngClass]="{'active': subject_id==subject.id}">{{subject.name}}</a>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item active  ">
                        <a class="nav-link">
                            <div class="btn-group">
                                <a class="nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Nivel
                </a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" *ngFor="let level of Levels" (click)="selLevel(level.id)" [ngClass]="{'active': level_id==level.id}">{{level.name}}</a>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!edit">
    <owl-carousel [options]="mySlideOptions" [items]="images" [carouselClasses]="['owl-theme', 'sliding', 'container', 'mb-4', 'mt-3', 'px-0']" *ngIf="!loading">
        <div class="card" *ngFor="let card of labs; let i=index">
            <img src="../../../assets/img/laptop.png" class="card-img-top" alt="lab.img">
            <div class="card-body">
                <h5 class="card-title text-truncate">{{card.name}}</h5>
                <p class="card-text text-truncate card-desc" [innerHTML]="card.description" style="height: 48px;"></p>
                <div class="text-right">
                    <p class="line-through d-inline mr-2">{{card.price}} €</p>
                    <p class="d-inline" style="font-size:18px;">{{card.offer_price}} €</p>
                </div>
                <div class="stars">
                    <form>
                        <p class="clasificacion">
                            <input id="radio1" type="radio" name="estrellas" value="5">
                            <label for="radio1">★</label>
                            <input id="radio2" type="radio" name="estrellas" value="4">
                            <label for="radio2">★</label>
                            <input id="radio3" type="radio" name="estrellas" value="3">
                            <label for="radio3">★</label>
                            <input id="radio4" type="radio" name="estrellas" value="2">
                            <label for="radio4">★</label>
                            <input id="radio5" type="radio" name="estrellas" value="1">
                            <label for="radio5">★</label>
                        </p>
                    </form>
                </div>
                <button href="#" class="btn btn-danger" (click)="editLab(i)">Edit lab</button>
            </div>
        </div>
    </owl-carousel>
</div>

<div *ngIf="edit">
    <div class="container my-3">
        <!-- Card Preview -->
        <div class="row border py-2 bg-white" *ngIf="changePreview=='1'">
            <div class="card-header col-12 px-3 pt-1 pb-2">
                <h5 class="m-0 d-inline">Card preview</h5>
                <button class="btn btn-danger mx-5" (click)="preview()"><i class="fas fa-exchange-alt"></i></button>
            </div>
            <div class="card-preview pt-4 px-3 mx-auto">
                <div class="card">
                    <img src="{{labimage}}" class="card-img-top" alt="lab.img">
                    <div class="card-body">
                        <h5 class="card-title text-truncate">{{labs[indice].name}}</h5>
                        <p class="card-text text-truncate" [innerHTML]="labs[indice].description"></p>
                        <div class="row">
                            <div class="stars col-6">
                                <form>
                                    <p class="clasificacion">
                                        <input id="radio12" type="radio" name="estrellas" value="5">
                                        <label for="radio12">★</label>
                                        <input id="radio22" type="radio" name="estrellas" value="4">
                                        <label for="radio22">★</label>
                                        <input id="radio32" type="radio" name="estrellas" value="3">
                                        <label for="radio32">★</label>
                                        <input id="radio42" type="radio" name="estrellas" value="2">
                                        <label for="radio42">★</label>
                                        <input id="radio52" type="radio" name="estrellas" value="1">
                                        <label for="radio52">★</label>
                                    </p>
                                </form>
                            </div>
                            <div class="text-right col-6">
                                <p class="line-through d-inline mr-2">{{labs[indice].price}}€</p>
                                <p class="d-inline" style="font-size:18px;">{{labs[indice].offer_price}}€</p>
                            </div>
                        </div>
                        <a href="#" class="btn btn-primary" (click)="magic()">Go somewhere</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Flyer Preview -->
        <div class="row border pt-2 bg-white" *ngIf="changePreview=='2'">
            <div class="card-header col-12 px-3 pt-1 pb-2">
                <h5 class="m-0 d-inline">Flyer preview</h5>
                <button class="btn btn-danger mx-5" (click)="preview()"><i class="fas fa-exchange-alt"></i></button>
            </div>
            <div class="row container bg-dark justify-content-between pl-5 mx-0">
                <div class="col-7 p-5 ml-5">
                    <div class="bg-danger p-2 my-2">{{ labs[indice].name }}</div>
                    <div class="text-light p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let key of keyLine">{{key}}</li>
                        </ul>
                    </div>
                </div>
                <div class="col-4 p-5">
                    <div class="col-9 bg-light position-absolute align-self-end border">
                        <img src="{{labimage}}" alt="lab.img" class="my-3" width="100%">
                        <div class="stars text-center my-3">lab.rating</div>
                        <div class="level text-center my-3">lab.level</div>
                        <button class="btn btn-md btn-danger d-block mx-auto my-3" disabled style="width:80%;">Buy</button>
                        <button class="btn btn-md btn-outline-danger d-block mx-auto my-3" disabled style="width:80%;">Cart</button>
                        <div class="row m-0">
                            <ul class="col-12 text-center" style="list-style: none;">
                                <li>lab.words</li>
                                <li>lab.NAVAC</li>
                                <li class="line-through">{{labs[indice].price}}€</li>
                                <li style="font-size:18px;">{{labs[indice].offer_price}}€</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row container m-0 pl-5">
                <div class="col-7 p-5 ml-5">
                    <p class="px-2" style="font-size:24px;">To who</p>
                    <div class="bg-gray border p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let who of Towho">{{who}}</li>
                        </ul>
                    </div>
                    <p class="px-2" style="font-size:24px;">Objetives</p>
                    <div class="bg-gray border p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let obj of Objectives">{{obj}}</li>
                        </ul>
                    </div>
                    <p class="px-2" style="font-size:24px;">Description</p>
                    <div class="bg-gray border p-2 mt-2 mb-4" [innerHTML]="labs[indice].description"></div>
                    <p class="px-2" style="font-size:24px;">Requisites</p>
                    <div class="bg-gray border p-2 mt-2 mb-4">
                        <ul>
                            <li *ngFor="let reqs of Requirements">{{reqs}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Lab Preview -->
        <div class="row border pt-2 bg-white" *ngIf="changePreview=='3'">
            <div class="card-header col-12 px-3 pt-1 pb-2">
                <h5 class="m-0 d-inline">Lab landing preview</h5>
                <button class="btn btn-danger mx-5" (click)="preview()"><i class="fas fa-exchange-alt"></i></button>
            </div>
            <div class="row container m-0 justify-content-between">

                <div class="row container m-0 p-5 ">
                    <div class="col-5">
                        <img src="{{labimage}}" alt="lab.img" width="100%">
                    </div>
                    <div class="col-7">
                        <div class="bg-gray border p-2 my-3">{{ labs[indice].name }}</div>
                        <ul class=" my-3">
                            <li *ngFor="let key of keyLine">{{key}}</li>
                        </ul>
                        <button class="btn btn-danger d-block w-80 mx-auto my-3" disabled>Enter lab</button>
                        <div>lab.rating</div>
                    </div>
                    <div class="col-12">
                        <div class="mt-5 row">
                            <ul class="col-6" style="list-style: none;">
                                <li class="text-center list-group-item" style="font-weight:900;">Objectives</li>
                                <li class="list-group-item text-center" *ngFor="let obj of Objectives">{{obj}}</li>
                            </ul>
                            <ul class="col-6" style="list-style: none;">
                                <li class="text-center list-group-item" style="font-weight:900;">To who</li>
                                <li class="list-group-item text-center" *ngFor="let who of Towho">{{who}}</li>
                            </ul>
                        </div>
                        <div class="col-12 bg-gray border mt-5" style="max-width: 100%; min-width: 100%;" [innerHTML]="labs[indice].description">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mb-5">
        <div class="row border pt-2 bg-white">
            <div class="card-header col-12 px-3 pt-1 pb-2">
                <h5 class="m-0">Lab editor</h5>
            </div>
            <div class="col-4 pt-4">
                <img src="{{labimage}}" alt="" width="100%">
                <div class="form-group mt-3">
                    <div class="custom-file">
                        <input type="file" id="file" class="custom-file-input">
                        <label for="file" class="custom-file-label">Choose file</label>
                    </div>
                </div>
            </div>
            <div class="col-8 pt-4">
                <div class="form-group">
                    <input type="text" name="title" id="title" class="form-control" placeholder="Title" aria-describedby="helpId" (keyup)="setTitle(titulo.value)" #titulo placeholder="{{ list_name }}">
                </div>
                <div class="form-group">
                    <select class="form-control col-9 my-2" name="" id="" (change)="onChange($event.target.value)">
            <option [ngClass]="{'selected': listId==list.id}" *ngFor="let list of Lists" value={{list.id}}>
              {{list.name}}</option>
          </select>
                </div>
                <div class="form-group">
                    <input type="number" name="title" id="title" class="form-control" placeholder="Price" aria-describedby="helpId" (keyup)="setPrice(precio.value)" (click)="setPrice(precio.value)" #precio>
                </div>
                <div class="form-group">
                    <input type="number" name="title" id="title" class="form-control" placeholder="Offer Price" aria-describedby="helpId" (keyup)="setOffer(oferta.value)" (click)="setOffer(oferta.value)" #oferta>
                </div>
                <quill-editor ngDefaultControl #editor [(ngModel)]="editorContent" [placeholder]="placeholder" [modules]="editorModules" [style]="{height: '200px'}" (onContentChanged)="setDescription($event)">
                </quill-editor>
            </div>
            <div class="col-12 mt-3">
                <div class="row m-0 align-items-center">
                    <div class="form-group col-11">
                        <label for="">Key Lines</label>
                        <input type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder="" #keyline (keyup.enter)="addKeyline(keyline.value)">
                        <span class="badge badge-pill badge-accent mr-2" *ngFor="let key of keyLine">{{key}}</span>
                    </div>
                    <div class="mt-6 col-1" [ngClass]="{'a01': !keyline.value}">
                        <button [disabled]="!keyline.value" class="btn btn-default" (click)="addKeyline(keyline.value)"><i
                class="fas fa-plus-circle text-accent" style="font-size:30px;"></i></button>
                    </div>
                </div>
                <div class="row m-0 align-items-center">
                    <div class="form-group col-11">
                        <label for="">Objectives</label>
                        <input type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder="" #objective (keyup.enter)="addObjectives(objective.value)">
                        <span class="badge badge-pill badge-accent mr-2" *ngFor="let obj of Objectives">{{obj}}</span>
                    </div>
                    <div class="mt-6 col-1" [ngClass]="{'a01': !objective.value}">
                        <button [disabled]="!objective.value" class="btn btn-default" (click)="addObjectives(objective.value)"><i
                class="fas fa-plus-circle text-accent" style="font-size:30px;"></i></button>
                    </div>
                </div>
                <div class="row m-0 align-items-center">
                    <div class="form-group col-11">
                        <label for="">Requisites</label>
                        <input type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder="" #require (keyup.enter)="addRequirements(require.value)">
                        <span class="badge badge-pill badge-accent mr-2" *ngFor="let reqs of Requirements">{{reqs}}</span>
                    </div>
                    <div class="mt-6 col-1" [ngClass]="{'a01': !require.value}">
                        <button [disabled]="!require.value" class="btn btn-default" (click)="addRequirements(require.value)"><i
                class="fas fa-plus-circle text-accent" style="font-size:30px;"></i></button>
                    </div>
                </div>
                <div class="row m-0 align-items-center">
                    <div class="form-group col-11">
                        <label for="">To who</label>
                        <input type="text" class="form-control" name="" id="" aria-describedby="helpId" placeholder="" #who (keyup.enter)="addTowho(who.value)">
                        <span class="badge badge-pill badge-accent mr-2" *ngFor="let who of Towho">{{who}}</span>
                    </div>
                    <div class="mt-6 col-1" [ngClass]="{'a01': !who.value}">
                        <button [disabled]="!who.value" class="btn btn-default" (click)="addTowho(who.value)"><i
                class="fas fa-plus-circle text-accent" style="font-size:30px;"></i></button>
                    </div>
                </div>
            </div>
            <hr style="width:100%;">
            <div class="row w-100 justify-content-end mb-3">
                <button class="btn btn-light mx-2" (click)="cancelConfirm()">Cancel</button>
                <button class="btn btn-accent mx-2" (click)="saveLab()">Save</button>
            </div>
        </div>
    </div>
</div>