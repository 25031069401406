import { Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";

@Component({
  selector: "app-regform",
  templateUrl: "./regform.component.html",
  styles: []
})
export class RegformComponent {
  register: UntypedFormGroup;

  constructor(private http: HttpClient) {
    this.register = new UntypedFormGroup({
      name: new UntypedFormControl("", [Validators.required, Validators.minLength(2)]),
      lastname: new UntypedFormControl(""),
      password: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8)
      ]),
      password_confirmation: new UntypedFormControl("", []),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")
      ]),
      birth: new UntypedFormControl("")
    });
    this.register.controls["password_confirmation"].setValidators([
      Validators.required,
      this.noIgual.bind(this.register)
    ]);
  }

  Register() {
    try {
      this.http
        .post(
          `http://localhost:8000/api/register`,
          {
            name: this.register.value.name,
            email: this.register.value.email,
            password: this.register.value.password,
            password_confirmation: this.register.value.confirmPassword
          },
          { responseType: "json" }
        )
        .subscribe();
    } catch (error) {
    }
  }

  noIgual(control: UntypedFormControl): any {
    let register: any = this;
    if (control.value !== register.controls["password"].value) {
      return {
        noiguales: true
      };
    }
    return null;
  }
}