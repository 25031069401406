

<div>

<button (click)="checkText()">
Check
</button>


<button *ngIf="saveButton" (click)="saveText()">
Save
</button>






<!-- Conectarlo a futuro con los planes -->
<label for="plan">Plan:</label>
<select id="plan" [(ngModel)]="selectedPlan">
  <option value="academic">Academic</option>
  <option value="professional">Professional</option>
  <option value="management">Management</option>
</select>

<label for="type">Type:</label>
<select id="type" [(ngModel)]="selectedType">
  <option *ngFor="let type of readingTypes" [value]="type.type">{{type.type}}</option>
  <option>Chart</option>
  <option>Expertise</option>
</select>

<label for="ngap">w/l</label>
<select id="ngap" [(ngModel)]="selectedNgap">
  <option value=9>9</option>
  <option value=12>12</option>
  <option value=15>15</option>
</select>

<button (click)="openGapModal()" *ngIf="this.wordsToColor">Ver Gapeo</button>



</div>
<ckeditor #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)" (change)="onChange($event);" [(ngModel)]="EditorText"></ckeditor>
