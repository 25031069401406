import { Injectable, OnChanges, Input, SimpleChanges } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreatorService implements OnChanges {

  constructor() { console.log("Lists servicio", this.Lists) }

  session: string = "";

  Lists: any[3] = [];

  list_name: string[] = [];

  ngOnChanges(changes: SimpleChanges){
    console.log("Changes", changes);
  }

}
