<div class="h-100" style="padding: 1% 0%;position: absolute; display: flex; transition-duration: 600ms; transition-timing-function: ease-in-out; color: grey; width: 85%;" [appTooltip]="350" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%',  'width': admin.sidebarShow ? '85%' : '96%'}">
    <div style="width: 50%; flex-direction: column; margin-right: 1%;" class="h-100 d-flex">
       <div style="height: 50%; margin: 0 0% 1.5% 0%;" [appTooltip]="351">
            <div class="h-100 w-100 bg-white" style="padding: 1.5%;" [appTooltip]="352">
                <div class="w-100 d-flex" style="height: 9%;">
                    <div class="title h-100" [appTooltip]="353">
                        Organizations:
                    </div>
                    <button class="my-auto newGroupButton" [appTooltip]="354" type="submit" style="width: 21%; height: 60%;" *ngIf="newOrganizationForm.value.organization != ''" (click)="createNewOrganization()">
                        New organization
                    </button>
                </div>
                <div  style="height: 90%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex" [appTooltip]="355"> 
                    <div class="w-100 d-flex">
                        <div style="width: 72%;" class="h-100 title2" [appTooltip]="356">
                            Nombre
                        </div>
                        <div style="width: 15%;" class="h-100 title2" [appTooltip]="357">
                            Oficina
                        </div>
                
                    </div>
                    <div class="w-100 d-flex group" [appTooltip]="358">
                        <div *ngFor="let organization of organizationsList; let i=index" class="h-100 w-100 d-flex" [ngClass]="{'groupSelected': selectedOrganization != undefined && organization.organization_id == selectedOrganization.organization_id}" (click)="setOrganizationOptions(i);" [appTooltip]="359">
                            <div style="width: 72%; cursor: pointer;"  class="groupInfo text-truncate" [appTooltip]="360" >
                                {{organization.organization_name}}
                            </div>
                            <div style="width: 15%; cursor: pointer;" class="groupInfo" [appTooltip]="361">
                                {{organization.office_name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
       <div style="height: 50%;" [appTooltip]="362">
            <div class="h-100 w-100 bg-white" style="padding: 1.5%;">
                <div class="w-100 d-flex">
                    <div class="title h-100" [appTooltip]="363">
                        Case Study:
                    </div>
                    <button class="my-auto newGroupButton" type="submit" style="width: 15%; height: 60%;" [appTooltip]="364" (click)="sendToAnalysis()">
                        Add to Surveys
                    </button>
                </div>    
                <div  style="height: 90%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex"  *ngIf="selectedOrganization != undefined" [appTooltip]="365">
                    <div class="d-flex w-100" style="flex-direction: column;">
                        <div class="w-100 d-flex" [appTooltip]="366">
                            <div style="width: 81%;" class="h-100 title2">
                                File
                            </div>
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: column; overflow-y: scroll;" [appTooltip]="367" >
                            <div *ngFor="let folder of surveysFiles; let i=index" style="flex-direction: column;" class="h-100 w-100 d-flex" (click)="selectedSurveyFolder = folder" [appTooltip]="368">
                                <div class="w-100 d-flex" [ngClass]="{'selected-prospect': selectedSurveyFolder != undefined &&  selectedSurveyFolder == folder}"  [appTooltip]="369">
                                    <div style="width: 3%; color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex"  [appTooltip]="370">
                                        <img *ngIf=" selectedOrganization.files[folder].closed == '0'" src="https://img.icons8.com/ios/50/203341/lock--v1.png" style="width: 81%; margin: auto;" [appTooltip]="371">
                                        <img  *ngIf=" selectedOrganization.files[folder].closed == '1'" src="https://img.icons8.com/ios-filled/50/203341/lock.png" style="width: 81%; margin: auto;" [appTooltip]="372">
                                    </div>
                                    <div style="width: 96%; cursor: pointer; display: flex; align-items: center;" class="prospectInfo text-truncate" [appTooltip]="373">  
                                        <span *ngIf="editFolder != folder">   {{folder}}      </span>
                                    </div>
                                    <div class="add mx-auto my-auto"  (click)="createSurvey()" [appTooltip]="374"> + </div>
                                </div>
                                <div class="w-100 d-flex" style="flex-direction: column; overflow-y: scroll;" *ngIf="selectedSurveyFolder != undefined && selectedSurveyFolder == folder" [appTooltip]="375">
                                    <div *ngFor="let surveyAux of selectedOrganization.files[selectedSurveyFolder].surveys; let j=index" class="h-100 w-100 d-flex" [ngClass]="{'selected-prospect': survey != undefined && surveyAux.survey_id == survey.survey_id}">
                                        <div style="width: 96%; cursor: pointer; margin-left: 3%;" class="prospectInfo text-truncate" [appTooltip]="376">
                                            {{surveyAux.survey_name}}
                                        </div>
                                        <div style="color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex" [appTooltip]="377" >
                                            <img src="https://img.icons8.com/windows/32/203341/visible--v1.png" style="width: 72%;" (click)="editText = false; openSurveyModal(surveyAux)" [appTooltip]="378">          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
    <div  style="width: 50%; flex-direction: column; " class="h-100 d-flex">
        <div style="flex-direction: column; padding: 0 2% 0% 1%; height: 69%; margin: 0 0% 1.5% 0%;" class="d-flex w-100" [appTooltip]="379">
            <div style="background-color: white; margin-right: 1%; padding: 2.1%; height: 100%;">
                <div class="w-100 d-flex" style="height: 9%;" [appTooltip]="380">
                    <div class="title h-100">
                        <span *ngIf="selectedOrganization == undefined" [appTooltip]="381">
                            New organization:
                        </span>
                        <span *ngIf="selectedOrganization != undefined" [appTooltip]="382"> 
                            Edit organization: {{selectedOrganization.organization_name}}
                        </span>
                    </div>
                    <button *ngIf="organization_id == undefined && groupDone != undefined" class="newGroupButton" type="submit" [appTooltip]="383"
                        (click)="createOrganization(); groupDone = undefined">
                        Create
                        <div *ngIf="groupDone == true && (usersToGroup == 0 || usersToGroup == undefined)">
                            <div class="groupDone"> La organización se ha creado correctamente </div>
                        </div>
                    </button>
                    <button *ngIf="organization_id != undefined " class="newGroupButton" type="submit" [appTooltip]="384"
                        (click)="updateOrganization()">
                        Update
                    </button>
                    <div *ngIf="groupDone == undefined" class="spinner-border"> </div>
                </div>
    
                <div *ngIf="organizationOptions != undefined" class="newGroupArea" [formGroup]="newOrganizationForm" [appTooltip]="385">
                    <div class="w-100 d-flex" [appTooltip]="350">
                        <div class="d-flex" style="flex-direction: column; width: 48%;height: 48px; margin: 1%;" [appTooltip]="386">
                            <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="387">
                                Office
                            </div>
                            <div style="height: 60%;" class="w-100 d-flex" [appTooltip]="388">
                                <select class="filterSelection h-100" style="border-radius: 6px; width: 93%;" *ngIf="newOffice == false" formControlName="office_id" (change)="changeOption('office', $event)" [appTooltip]="389">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden>  </option>
                                    <option *ngFor="let option of organizationOptions.office" [value]="option.id"   style="color: #6e6e6e;">
                                        {{option.name}}
                                    </option>
                                </select>
                                <div class="filterSelection h-100" style="border-radius: 6px;  width: 93%;" *ngIf="newOffice == true" [appTooltip]="390">
                                    <div class="h-100" style="cursor: pointer; text-align: center;" [appTooltip]="391">
                                        <input type="text"  style="outline: none; border: none; padding-left: 3%; font-size: 1.5vh; border-radius: 6px;" formControlName="office" class="w-100 h-100" placeholder="New Office" formControlName="office">
                                    </div>
                                </div>
                                <div style="width: 4%; font-weight: 600; text-align: center; cursor: pointer; margin: auto;" [appTooltip]="392"
                                    (click)="newOffice = !newOffice;">
                                    +
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" style="flex-direction: column; width: 48%;height: 48px; margin: 1%;" [appTooltip]="393">
                            <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="394">
                                Organization Name
                            </div>
                            <div style="height: 60%;" class="w-100 d-flex" [appTooltip]="395">
                                <select class="filterSelection h-100" style="border-radius: 6px; width: 93%;"     *ngIf="newOrganization == false" formControlName="organization"  (change)="changeOption('organization', $event)" [appTooltip]="396">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden>  </option>
                                    <option *ngFor="let option of organizationsList" [value]="option.organization_name" style="color: #6e6e6e;">
                                        {{option.organization_name}}
                                    </option>
                                </select>
                                <div class="filterSelection h-100" style="border-radius: 6px;  width: 93%; position: relative;" *ngIf="newOrganization == true" [appTooltip]="397">
                                    <div class="h-100" style="cursor: pointer; text-align: center;">
                                        <input type="text" [appTooltip]="398"  style="outline: none; border: none; padding-left: 3%; font-size: 1.5vh; border-radius: 6px; color: grey;"  [(value)]="newOrganizationForm.value.organization"  class="w-100 h-100" placeholder="New Organization" formControlName="organization"  (keyup)="searchCompanies($event.target.value)"  (clickOutside)="openDropDown = false">
                                        <div  *ngIf="companies != undefined && companies != 0 && openDropDown == true" [appTooltip]="350" style="z-index: 1000; font-size: 1.2vh; color: #6e6e6e !important; border: 1px solid grey;  overflow-y: scroll;" class="w-100 position-absolute bg-white">
                                            <div *ngFor="let company of companies; let i = index" class="option drop_option" style="text-align: start; padding: 0 3%;" (click)="selectCompany(i)" [appTooltip]="350">
                                                {{company.company_name}}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div style="width: 4%; font-weight: 600; text-align: center; cursor: pointer; margin: auto;"
                                    (click)="newOrganization = !newOrganization;" [appTooltip]="399">
                                    +
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  class="d-flex" style="margin: 1%; position: relative; height: 45%;" [appTooltip]="400">
                        <!-- <div class="d-flex" style="width: 50%; height: 54px;">
                            <input id="file" style="width: 50%;" class="custom-file-input" #file type="file" (change)="fileChangeEvent(file.files)" accept=".png, .pdf, .jpg, .jpeg"> 
                            <label for="file" class="custom-file-label text-left">Choose file</label>
                        </div>
                        <div  class="d-flex" style="width: 50%; justify-content: center;">
                            <img [src]="imgURL" *ngIf="imgURL" style="width: 63%">
                        </div> -->
                        <div style="width: 50%; flex-direction: column;" class="d-flex h-100" [appTooltip]="401">
                            <div style="height: 36%; display: flex;">
                                <input type="file" style="border: 1px solid #c7c7c7; width: 84%;" [appTooltip]="402" (change)="fileChangeEvent($event)">
                                <button style="width: 15%; justify-content: center; height: 60%; margin: auto" [appTooltip]="403" (click)="newImage()"> Reset </button>    
                            </div>
                            <div style="height: 63%; flex-flow: wrap; justify-content: center; align-content: center;" class="d-flex" [appTooltip]="404">
                                <button (click)="rotateLeft()" [appTooltip]="405">Rotate left</button>
                                <button (click)="rotateRight()" [appTooltip]="406">Rotate right</button>
                                <button (click)="flipHorizontal()" [appTooltip]="407">Flip horizontal</button>
                                <button (click)="flipVertical()" [appTooltip]="408">Flip vertical</button>
                                <button (click)="toggleContainWithinAspectRatio()" [appTooltip]="409">{{containWithinAspectRatio? 'Fill Aspect Ratio':'Aspect Ratio'}}</button>
                                <button (click)="resetImage()" [appTooltip]="410">Reset image</button>
                                <!-- <input [(ngModel)]="rotation" placeholder="Rotation" type="number" style="height: 24%; width: 15%;  margin: 0.3%;  border: 1px solid #c7c7c7;" (keyup)="updateRotation()">  -->
                                <button (click)="zoomOut()" [appTooltip]="411">Zoom -</button> 
                                <button (click)="zoomIn()" [appTooltip]="412">Zoom +</button>
                                <!-- <input [(ngModel)]="aspectRatio" placeholder="Aspect ratio" type="number" style="height: 24%; width: 15%;  margin: 0.3%;  border: 1px solid #c7c7c7;">  -->
                            </div>
                        </div>
                        <div style="height: 100%; width: 50%;" *ngIf="organizationOptions.organization_img == undefined" [appTooltip]="413">
                            <image-cropper [appTooltip]="414"  [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="aspectRatio" [onlyScaleDown]="false" [roundCropper]="false" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" 
                            format="png" [canvasRotation]="canvasRotation" [transform]="transform"   (imageCropped)="imageCropped($event)"   (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"  (loadImageFailed)="loadImageFailed()"></image-cropper>
                        </div>
                        <div style="height: 100%; width: 50%; display: flex;" *ngIf="organizationOptions.organization_img != undefined" >
                            <img [src]="organizationOptions.organization_img" style="width: 90%; margin: auto; border: 1px solid #e7e7e7;" [appTooltip]="415">
                        </div>   
                    </div>
                    <div class="w-100 d-flex" [appTooltip]="416">
                        <div class="d-flex" style="flex-direction: column; width: 48%;height: 48px; margin: 1%;">
                            <div style="height: 30%; font-size: 1.2vh; color: grey;" [appTooltip]="417">
                                Area
                            </div>
                            <div style="height: 60%;" class="w-100 d-flex" [appTooltip]="418">
                                <select class="filterSelection h-100" style="border-radius: 6px; width: 93%;"  formControlName="area" [appTooltip]="419">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden>  </option>
                                    <option *ngFor="let area of organizationOptions.areas" [value]="area.name"   style="color: #6e6e6e;">
                                        {{area.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                      
                    <!-- <div *ngIf="clientAdmins != undefined" class="w-100 d-flex">
                        <div class="d-flex" style="flex-direction: column; width: 48%;height: 48px; margin: 1%;">
                            <div style="height: 30%; font-size: 1.2vh; color: grey;">
                                List of Client Admins:
                            </div>
                            <div style="height: 60%;" class="w-100 d-flex">
                                <select class="filterSelection h-100" style="border-radius: 6px; width: 93%;"  *ngIf="newOrganization == false" formControlName="office"  (change)="changeOption('organization', $event)">
                                    <option *ngFor="let option of clientAdmins" [value]="option.name" [selected]="newOrganizationForm.controls['organization'].value.name" style="color: #6e6e6e;">
                                        {{option.name}}
                                    </option>
                                </select>
                                <div class="filterSelection h-100" style="border-radius: 6px;  width: 93%;"
                                    *ngIf="newOrganization == true">
                                    <div class="h-100" style="cursor: pointer; text-align: center;">
                                        <input type="text"   style="outline: none; border: none; padding-left: 3%; font-size: 1.5vh; border-radius: 6px;" class="w-100 h-100" placeholder="New Organization"   formControlName="organization">
                                    </div>
                                </div>
                                <div style="width: 4%; font-weight: 600; text-align: center; cursor: pointer; margin: auto;"
                                    (click)="newOrganization = !newOrganization;">
                                    +
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
         <div style="flex-direction: column; padding: 0 2% 0% 1%; height: 30%;" class="d-flex w-100" [appTooltip]="420">
            <div class="h-100 w-100 bg-white" style="padding: 1.2%;">
                <div class="w-100 d-flex">
                    <div class="title h-100" [appTooltip]="421">
                        In House Tests:
                    </div>
                    <button class="newGroupButton" [appTooltip]="422" type="submit" (click)="newInHouseTest();">
                        Nuevo exam
                    </button>
                </div>
                <div  style="height: 90%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex" [appTooltip]="423">
                    <div class="w-100 d-flex" [appTooltip]="424">
                        <div style="width: 55%;" class="h-100 title2" [appTooltip]="425">
                            Course
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" [appTooltip]="426" ></i>
                        </div>
                        <div style="width: 15%;" class="h-100 title2" [appTooltip]="427">
                            Date
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" [appTooltip]="428" ></i>
                        </div>
                        <div style="width: 12%;" class="h-100 title2" [appTooltip]="429">
                            Hour
                            <i class="fas fa-caret-down" style="width: 10%;margin: auto; cursor: pointer;" [appTooltip]="430"></i>
                        </div>
                        <div style=" width: 14%;" class="h-100 title2" [appTooltip]="431">
                            Test Takers
                        </div>
                    </div>
                    <div  class="w-100 d-flex" style="flex-direction: column;" [appTooltip]="432">
                        <div *ngFor="let test of testsArray; let i=index" class="h-100 w-100 d-flex"  [ngClass]="{'groupSelected': test.selected == true}">
                            <div *ngIf="test.edit == true" class="d-flex w-100" [appTooltip]="434">
                                <select class="testsBox h-100" (change)="chooseCampusExams($event, i)" [(ngModel)]="test.campus_id" [appTooltip]="435">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden>  </option>
                                    <option value="1" style="color: #6e6e6e;"> Certificates  </option>
                                    <option value="2" style="color: #6e6e6e;"> PEC </option>
                                </select>
                                <select  class="testsBox h-100" style="width: 33%;" (change)="selectPlacement(i, $event)" [(ngModel)]="test.course_name" [appTooltip]="436">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden> </option>
                                    <option *ngFor="let placement of placementExams[i]; let j = index" style="color: #6e6e6e;">
                                        {{placement.course_name}}
                                    </option>
                                </select>
                                <select *ngIf="placementVersions != undefined" class="testsBox h-100" style="width: 9%;" (change)="selectPlacementVersion(i, $event)" [(ngModel)]="test.version" [appTooltip]="436">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden> </option>
                                    <option *ngFor="let version of placementVersions[i]; let j = index" style="color: #6e6e6e;">
                                        {{version.version}}
                                    </option>
                                </select>
                                <div class="testsBox h-100" style="width: 15%; height: 60%" [appTooltip]="437">
                                    <mat-form-field appearance="fill" class="w-100 h-100">
                                        <input #dpInput matInput [matDatepicker]="dp" class="text-center" [min]="today" [value]="test.date" (dateInput)="chooseTestDate(dpInput, dp, i);">
                                        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                            <mat-datepicker #dp> </mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <select class="testsBox h-100"  style="border-radius: 6px; width: 12%;"  (change)="selectTestHour(i, $event)"  [(ngModel)]="test.startHour" [appTooltip]="438">
                                    <option style="color: #6e6e6e;" value="" disabled selected hidden>  </option>
                                    <option *ngFor="let startHour of startHours" [ngValue]="startHour"  style="color: #6e6e6e;">
                                        {{startHour}}
                                    </option>
                                </select>
                                <div class="testsBox h-100" style="width: 6%;" >

                                </div>
                                <div style="width: 6%; margin-left: auto;" class="d-flex">
                                    <button class="submitButton" style="margin: auto;" type="submit" [appTooltip]="439" (click)="saveTest(test)"> Save  </button> 
                                </div>
                                <i class="fas fa-times px-1 my-auto delete" style="font-size: 1.2vh;" [appTooltip]="440" (click)="removeTest(i)"></i>
                            </div>
                            <div  *ngIf="test.edit == false" class="d-flex w-100" [appTooltip]="441">
                                <div style="width: 48%; cursor: pointer;"  class="groupInfo text-truncate"[appTooltip]="442" >
                                    {{test.course_name}}
                                </div>
                                <div style="width: 6%; cursor: pointer;"  class="groupInfo text-truncate"[appTooltip]="442" >
                                    {{test.version}}
                                </div>
                                <div style="width: 15%; cursor: pointer;" class="groupInfo" [appTooltip]="443">
                                    {{test.date}}
                                </div>
                                <div style="width: 12%; cursor: pointer;" class="groupInfo" [appTooltip]="444">
                                    {{test.startHour}}
                                </div>
                                <div style="width: 14%; cursor: pointer;" class="groupInfo" [appTooltip]="445">
                                    {{test.test_takers}}
                                </div>
                                <i class="fas fa-edit edit my-auto" style="width: 3%; cursor: pointer; margin-left: auto;" (click)="editTest(test)" [appTooltip]="446"></i>
                                <i class="fas fa-times px-1 my-auto delete" style="font-size: 1.2vh;" (click)="removeTest(i)" [appTooltip]="447"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>
    <div class="modal fade" id="surveys" tabindex="-1" role="dialog" aria-labelledby="surveys" aria-hidden="true" data-backdrop="static" data-keyboard="false" style="background-color: rgb(32, 51, 65);">
        <div class="modal-dialog"  style="overflow: hidden; height: 90%; max-width: 39%; background-color: white; padding: 1.5%; top: 6%; margin: auto">
            <div class="modal-content h-100" [appTooltip]="448">
                <div class="d-flex" [appTooltip]="449"> 
                    <div  style="width: 6%; margin-right: 3%; align-items: center; display: flex" >
                        <img src="../../../assets/img/blue-logo-2.png" [appTooltip]="450">
                    </div>
                    <div class="h-100" style="width: 90%;font-weight: 600; font-size: 1.5vh; color: #203341; display: flex; justify-content: center; align-items: center; text-transform: uppercase;"  [appTooltip]="451" *ngIf="survey != undefined && survey.survey_name != undefined">
                        {{survey.survey_name}}      
                    </div>
                    <div style="font-weight: 900; font-size: 1.5vh; cursor: pointer;  width: 12%;" class="d-flex" *ngIf="survey != undefined &&  survey.prompts != 0" [appTooltip]="452">
                        <div style="color: #203341; display: flex; align-items: center; margin: 0 9%; font-family: times new roman;" [appTooltip]="453"  *ngFor="let prompt of survey.prompts; let j = index" (mouseenter)="showPromptNumber = j; showPrompt = true" (mouseleave)="showPrompt = false">
                            {{j+1}}
                        </div>
                    </div>
                    <div style="display: flex; align-items: flex-end; margin-left: auto;">
                        <i class="fas fa-times px-1 my-auto" style="cursor: pointer; color: rgb(181, 181, 181); font-size: 1.5vh;" [appTooltip]="454" (click)="closeModal()"></i>         
                    </div>
                </div>
                <div class="d-flex" style="width: 100%; padding-top: 3%; height: 96%; flex-direction: column;" *ngIf="survey != undefined" [appTooltip]="455">
                    <div  *ngIf="showPrompt == true" class="w-100 d-flex" style="justify-content: center;" [appTooltip]="456">
                        <div class="text-center d-flex prompt">  
                            {{survey.prompts[showPromptNumber]}}
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="height: 93%;" [appTooltip]="457">
                        <div class="d-flex w-100 drag-list" >
                            <div *ngFor="let bubble of survey.bubbles; let k = index" class="d-flex w-100 drag-option" >
                                <div class="number" [appTooltip]="458">
                                    {{k+1}}. 
                                </div>
                                <div class="bubble-box" [appTooltip]="459" (mouseenter)="dropNumber = k" (mouseleave)="dropNumber = -1"  [ngStyle]="{'background-color': dropNumber == k ? 'rgb(32, 51, 65)': '', 'color': dropNumber == k ? 'white': ''}">
                                    {{bubble}}
                                </div>
                                <div style="display: flex; align-items: center; margin-left: 0.9%;">
                                    <i class="fas fa-times px-1 my-auto delete" [appTooltip]="460"></i>         
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <!-- <div style="margin-top: 510px; margin-left: 0px;">
        <app-file-uploader [fileType] = 'fileType'></app-file-uploader>
    </div> -->
</div>