import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LandingDataService {

  constructor(private http: HttpClient) { }

  getCatTree() {
    return this.http.get(`${environment.apiUrl}/api/catTree/get`);
  }

  saveFlyer(name: string, keys:string, description: string, objectives, toWhom, requisites, picture, price: number, offer_price: number, lab_id: string) {
    console.log("dentro de saveFlyer");
    return this.http.post(`${environment.apiUrl}/api/flyerEditor/updateFlyer`, { 'keys': keys, 'name': name, 'description': description, 'objectives': objectives, 'toWhom': toWhom, 'requirements': requisites, 'picture': picture, 'price': price, 'offer_price': offer_price, 'lab_id': lab_id });
  }

  getCardByFaculty(faculty_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lab/faculty`, { params: { 'faculty_id': faculty_id, 'index': '0' } });
  }
  getCardByDegree(degree_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lab/degree`, { params: { 'degree_id': degree_id, 'index': '0' } });
  }
  getCardBySubject(subject_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lab/subject`, { params: { 'subject_id': subject_id, 'index': '0' } });
  }

  getFlyer(lab_id: string) {
    return this.http.get(`${environment.apiUrl}/api/lab/get`, { params: { 'lab_id': lab_id } });
  }

  getUnloggedCards(param: string) {
    return this.http.get(`${environment.apiUrl}/api/lab/unlogged`, { params: { 'param': param } });
  }

  // getLabsLanding() {
  //   return this.http.get(`${environment.apiUrl}/api/lab/getLabsLanding`);
  // }
  // getLoggedCards(param: string) {
  //   return this.http.get(`${environment.apiUrl}/api/lab/logged`, { params: { 'param': param } });
  // }
}
