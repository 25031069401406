import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { LmsService } from 'src/app/services/lms.service';
import { UserDataService } from 'src/app/services/user-data.service';
import * as $ from 'jquery';
declare var $: any;

class groupSet {
  active: boolean = false;
  campus_id: string = '';
  group_id: number = null;
  group_name: string = '';
  numStudents: number = 0;
  course_id: number = null;
  trial: boolean = false;
  degree_id: number = null;
  subject_id: number = null;
  course_name: string = '';
  course_num_groups: number = 0;
  degree_name: string = '';
  teachers: string = '';
  start_date: string = '';
  end_date: string = '';
  subject_name: string = '';
  user_name: string = '';
  user_last_name: string = '';
  second_last_name: string = '';
  user_id: number = 0;
  selected: boolean = false;
  assigned = '0%';
  done = '0%';
  certified_value = '0%';
  certified = '0%';
  certified_course = '0%';
  groupOrder: boolean = false;
  testOrder: boolean = false;
  subcat_id: number;
  cat_name: string = '';
}

export class userSet {
  active: boolean = false;
  group_id: number = null;
  group_user_id: number = null;
  group_name: string = '';
  course_id: number = null;
  campus_id: number = null;
  trial: boolean = false;
  degree_id: number = null;
  course_name: string = '';
  name: string = '';
  last_name: string = '';
  second_last_name: string = '';
  target_level;
  final_level;
  user_id: number = 0;
  selected: boolean = false;
  assigned = '0%';
  done = '0%';
  certified_value = '0%';
  certified = '0%';
  certified_course = '0%';
  certified_group = '0%';
  userOrder: boolean = false;
  groupOrder: boolean = false;
  soft_deleted: boolean = false;
  user_email: string;
}
export class PerformanceArea {
  name: string = '';
  assigned = '0%';
  done = '0%';
  certified;
  has_review: boolean = false;
  total = '';
}

class testSet {
  organization_id: number = null;
  exam_id: number = null;
  course_name: string = '';
  test_takers: number = null;
  test_date: string = '';
  test_hour: string = '';
  selected: boolean = false;
}

export class profile {
  name: '';
  target_level: '';
  user_id: number;
  resources = new Array();
  score;
  num_exercise;
  skills = new Array();
}

@Component({
  selector: 'app-group-admin',
  templateUrl: './group-admin.component.html',
  styleUrls: ['./group-admin.component.css']
})
export class GroupAdminComponent implements OnInit {

  areaGroups;
  areaGroupsUsers
  groupName;
  areaUsers;
  selectedGroup;
  selectedStudent;
  studentPerformance;
  studentProgress;
  candidateProfile;
  testResults = 'completed';
  testTakersIndex = 'candidates'
  totalTestTakers;
  completedTestTakers = new Array();
  bestTestTakers;
  plannerType;
  params;

  testView: boolean = false;
  resultArea: string;

  constructor(public auth: AuthService, public lms: LmsService, private router: Router, private activatedRoute: ActivatedRoute, private userData: UserDataService, public admin: AdminService) { }


  subscription: Subscription;

  ngOnInit(): void {
    this.params = this.activatedRoute.params.subscribe(params => {
      this.plannerType = params.type;
    });
    if (this.plannerType == 'group') {
      this.auth.workingArea = this.plannerType == 'group admin';
    } else {
      this.auth.workingArea = 'course admin';
    }
    this.admin.edit_group = false;
    this.getInfoGroups();

    // if (this.auth.userData.role_id == 2 || this.auth.userData.role_id == 8) {
    //   this.admin.plannerView = true;
    // }

    if (this.auth.userData.role_id == 13) {
      this.getInHouseTests();
    }

    this.subscription = this.userData.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'apply_filters') {
        this.getInfoGroups();
      }
      if (res.hasOwnProperty('option') && res.option === 'user_view') {
        this.getInfoGroups();
        if (this.auth.role == 'clientAdmin') {
          this.getInHouseTests();
        }
      }

    });

  }

  totalCourses = new Array();
  getInfoGroups() {
    if (this.plannerType == 'group') {
      this.admin.getGroups(this.lms.arrayFilters, this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe((data: any) => {
        this.loadGroupsInfo(data);
      });
    } else { // course planner (cursos sin profesor)
      this.admin.getCoursesBack().subscribe((data: any) => {
        this.loadGroupsInfo(data);
      });
    }
  }

  loadGroupsInfo(data) {
    this.admin.areaGroups = new Array();
    this.admin.areaUsers = new Array();
    this.admin.selectedGroup = new Array();
    // this.totalCourses = new Array();
    for (let index = 0; index < data.length; index++) {
      if (index == 0) {
        this.groupName = 'Groups';
        for (let index = 0; index < data[0].length; index++) {
          let groupAux = new groupSet();
          groupAux.group_id = data[0][index].id;
          groupAux.campus_id = data[0][index].campus_id;
          groupAux.course_name = data[0][index].course_name;
          groupAux.course_id = data[0][index].course_id;
          groupAux.group_name = data[0][index].name;
          if (data[0][index].teachers != 0) {
            groupAux.teachers = data[0][index].teachers;
          } else {
            groupAux.teachers = '--';
          }
          groupAux.numStudents = data[0][index].numStudents;
          groupAux.degree_id = data[0][index].degree_id;
          groupAux.subject_id = data[0][index].subject_id;
          groupAux.start_date = data[0][index].start_date;
          groupAux.end_date = data[0][index].end_date;
          groupAux.done = data[0][index].done + '%';
          groupAux.assigned = data[0][index].assigned + '%';
          groupAux.certified_value = data[0][index].certified_course + '%';
          groupAux.certified = data[0][index].certified + '%';
          if (data[0][index].certified_course != undefined) {
            groupAux.certified_course = data[0][index].certified_course + '%';
          }
          groupAux.active = data[0][index].active;
          groupAux.groupOrder = false;
          groupAux.testOrder = false;
          if (data[0][index].subcat_id != 'undefined') {
            groupAux.subcat_id = data[0][index].subcat_id;
            groupAux.cat_name = data[0][index].cat_name;
          }

          if (data[0][index].trial == '1') {
            groupAux.trial = true;
          } else {
            groupAux.trial = false;
          }
          this.admin.areaGroups.push(groupAux);


          this.setColor(Number(data[0][index].course_id));
          let courseInfoAux = {
            name: data[0][index].course_name,
            id: data[0][index].course_id,
            color: this.courseColor,
            degree_id: data[0][index].degree_id
          }

          if (this.totalCourses.length == 0) {
            this.totalCourses.push(courseInfoAux);
          } else {
            let course = this.totalCourses.find(course => course.id == data[0][index].course_id);
            if (course == undefined) {
              this.totalCourses.push(courseInfoAux);
            }
          }
        }
      } else if (index == 1) {
        this.groupName = 'Users';
        let userSet;
        for (let index = 0; index < data[1].length; index++) {
          userSet = this.newUserSet(data[1][index]);
          this.admin.areaUsers.push(userSet);
        }
        if (this.admin.areaGroups.length > 0) {
          this.admin.areaGroups[0].selected = true;
          this.admin.selectedGroup = this.admin.areaGroups[0];
        }
        if (this.admin.areaUsers.length > 0) {
          this.admin.areaGroupsUsers = this.admin.areaUsers;
          this.admin.areaGroupsUsers[0].selected = true;
          this.admin.selectedStudent = this.admin.areaGroupsUsers[0];
          this.selectStudent(0);
        } else {
          this.admin.areaGroupsUsers = new Array();
          this.admin.selectedStudent = undefined;
          this.candidateProfile = undefined;
        }
      }
    }
    this.areaGroups = this.admin.areaGroups;
  }

  getInHouseTests() {
    this.admin.getInHouseTests(this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe((data: any) => {
      this.admin.tests = new Array();
      // this.groupName = 'Tests';
      for (let index = 0; index < data.length; index++) {
        let testAux = new testSet();
        testAux.organization_id = data[index].organization_id;
        testAux.exam_id = data[index].exam_id;
        testAux.course_name = data[index].course_name;
        testAux.test_takers = data[index].test_takers;
        testAux.test_date = data[index].date;
        if (data[index].hour != null) {
          testAux.test_hour = data[index].hour.replace(new RegExp(/\:\d+\.\d+/), '');
        }

        this.admin.tests.push(testAux);
      }
    });
  }


  copyURL(test) {
    return test.course_name + ' ' + 'https://new.altealabs.com/#/placement/' + test.exam_id;
  }

  searchingStudent = false;
  searchingGroup = false;
  searchGroup(search_value) {
    this.searchingGroup = true;
    search_value = search_value.toLowerCase();
    let groupsAux = new Array();

    if (search_value.length > 2) {
      this.admin.areaGroups.forEach(group => {
        if (group.group_name != undefined) {
          if (group.group_name.toLowerCase().indexOf(search_value) != -1) {
            groupsAux.push(group);
          }
        }
      });
      this.admin.areaGroups = groupsAux;
    } else {
      this.admin.areaGroups = this.areaGroups;
      if (search_value == '') {
        this.searchingGroup = false;
      }
    }
  }

  searchUser(search_value) {
    if (search_value.length > 2) {
      this.searchingStudent = true;

      // búsqueda front
      const queryTerms = search_value.toLowerCase().split(/\s+/).filter(Boolean);
      this.admin.areaGroupsUsers = this.admin.areaUsers.filter((user) =>
        queryTerms.every(
          (term) =>
            user.name.toLowerCase().includes(term) ||
            user.last_name != undefined && user.last_name.toLowerCase().includes(term)
        )
      );

      // búsqueda back
      this.lms.searchUsers(search_value).subscribe((data: any) => {
        // pasarlos a userset
        let userSet;
        for (let index = 0; index < data.length; index++) {
          userSet = this.newUserSet(data[index]);
          this.admin.areaGroupsUsers.push(userSet);
        }
      });
    } else {
      this.admin.areaGroupsUsers = this.admin.areaUsers;
      if (search_value == '') {
        this.searchingStudent = false;
      }
    }
  }

  // Para quitar los mensajes
  @HostListener('document:click', ['$event'])
  documentClickHandler(event: any) {
    const isClickInside = this.isClickInsideContainer(event, '.message');
    if (isClickInside) {
      this.message = undefined;
    }

  }

  // Comprueba si el check es dentro del container
  private isClickInsideContainer(event: any, containerName): boolean {
    const container = document.querySelector(containerName);

    if (container) {
      return container.contains(event.target);
    }

    return false;
  }

  // Añade el usuario al curso
  message;
  addUserToTESLACourse(user) {
    let selectedGroup = this.admin.areaGroups.find(item => item.selected);
    if (selectedGroup) {
      let currentGroupSubcatId = selectedGroup.subcat_id;

      this.admin.setTESLAUserCourse(user.user_id, currentGroupSubcatId).subscribe((data) => {
        let newUser = data['user'];
        this.message = data['message'];
        if (newUser) {
          let userSet = this.newUserSet(newUser);

          // quitar de areaGroupUsers el que coincida con el id del usuario añadido y no tenga grupo
          const index = this.admin.areaGroupsUsers.findIndex((item) => item.user_id == user.user_id && item.course_id == undefined);
          if (index !== -1) {
            // array con los alumnos del grupo seleccionado o lo que coincida con la búsqueda
            this.admin.areaGroupsUsers.splice(index, 1, userSet);
          }
          // array con los alumnos de todos los grupos
          this.admin.areaUsers.push(userSet);

          this.searchingStudent = false;
        }

      });
    }
  }

  newUserSet(userData) {
    let groupAux = new userSet();
    groupAux.course_id = userData.course_id;
    groupAux.campus_id = userData.campus_id;
    groupAux.degree_id = userData.degree_id;
    groupAux.course_name = userData.course_name;
    groupAux.group_id = userData.group_id;
    groupAux.group_name = userData.group_name;
    groupAux.group_user_id = userData.group_user_id;
    groupAux.target_level = userData.target_level;
    groupAux.final_level = userData.final_level;
    groupAux.user_id = userData.id;
    groupAux.name = userData.name;
    groupAux.last_name = userData.last_name;
    groupAux.active = userData.deleted;
    groupAux.soft_deleted = userData.soft_deleted;
    groupAux.done = userData.done + '%';
    groupAux.assigned = userData.assigned + '%';
    groupAux.certified_value = userData.certified_course + '%';
    groupAux.certified_group = userData.certified_group + '%';
    groupAux.certified = userData.certified + '%';
    if (userData.certified_course != undefined) {
      groupAux.certified_course = userData.certified_course + '%';
    }
    groupAux.groupOrder = false;
    groupAux.userOrder = false;

    return groupAux;
  }

  arrayFilters;
  filterCourses(event) {
    let course_id = event.target.value;
    let arrayFilters = new Array();
    if (course_id == 'All') {
      arrayFilters = [];
    } else {
      this.totalCourses.forEach(group => {
        if (group.id == course_id) {
          let arrayFilter = {
            filter: 'Level',
            name: group.name,
            id: group.degree_id
          }
          arrayFilters.push(arrayFilter);
        }
      })
    }
    this.lms.arrayFilters = arrayFilters;
    this.userData.notifyExercise({ option: 'apply_filters' });

  }


  courseColor;
  setColor(course_id) {
    switch (course_id) {
      case 353:
        this.courseColor = '#6fa84b';
        break;

      case 351:
        this.courseColor = '#e0ebc2';
        break;

      case 352:
        this.courseColor = '#309191';
        break;

      case 354:
        this.courseColor = '#34b5aa';
        break;

      default:
        this.courseColor = '#' + Math.floor(0x1000000 * Math.random()).toString(16);
        break;
    }
  }

  setAlphabetichOrder(type, subType) {

    if (type == 'areaGroups') {
      let alphabetic = new Array;
      let newGroupOrder = new Array;
      this.admin.areaGroups.forEach((group, i) => {
        alphabetic[i] = new Array;
        alphabetic[i].push(group.course_name);
        alphabetic[i].push(group.group_id);
      });

      alphabetic = alphabetic.sort();

      for (let index = 0; index < alphabetic.length;) {
        for (let indexGroup = 0; indexGroup < this.admin.areaGroups.length && newGroupOrder.length < this.admin.areaGroups.length; indexGroup++) {
          if ((this.admin.areaGroups[indexGroup].course_name == alphabetic[index][0]) && (this.admin.areaGroups[indexGroup].group_id == alphabetic[index][1])) {
            newGroupOrder.push(this.admin.areaGroups[indexGroup]);
            indexGroup = 0;
            index++;
          }
        }
      }
      this.admin.areaGroups = newGroupOrder;
    } else if ('areaGroupsUsers') {
      let alphabetic = new Array;
      let newGroupOrder = new Array;
      this.admin.areaGroupsUsers.forEach((group, i) => {
        alphabetic[i] = new Array;
        if (subType == 'user_name') {
          alphabetic[i].push(group.user_name);
          alphabetic[i].push(group.group_id);
        } else if (subType == 'course_name') {
          alphabetic[i].push(group.course_name);
          alphabetic[i].push(group.user_id);
        }
      });
      alphabetic = alphabetic.sort();
      for (let index = 0; index < alphabetic.length;) {
        for (let indexGroup = 0; indexGroup < this.admin.areaGroupsUsers.length && newGroupOrder.length < this.admin.areaGroupsUsers.length; indexGroup++) {
          if (subType == 'course_name') {
            if ((this.admin.areaGroupsUsers[indexGroup].course_name == alphabetic[index][0]) && (this.admin.areaGroupsUsers[indexGroup].user_id == alphabetic[index][1])) {
              newGroupOrder.push(this.admin.areaGroupsUsers[indexGroup]);
              indexGroup = 0;
              index++;
            }
          } else if (subType == 'user_name') {
            if ((this.areaGroupsUsers[indexGroup].user_name == alphabetic[index][0]) && (this.areaGroupsUsers[indexGroup].group_id == alphabetic[index][1])) {
              newGroupOrder.push(this.areaGroupsUsers[indexGroup]);
              indexGroup = 0;
              index++;
            }
          }
        }
      }
      this.admin.areaGroupsUsers = newGroupOrder;
    }
  }


  sortByName(type, subType, order) {
    if (this.admin[type][0][order] == false) {
      this.admin[type] = this.admin[type].sort((a, b) => a[subType].localeCompare(b[subType]));
      this.admin[type][0][order] = true;
    } else {
      this.admin[type] = this.admin[type].sort((a, b) => a[subType].localeCompare(b[subType])).reverse();
      this.admin[type][0][order] = false;
    }
  }


  sortByDate(date) {
    date.forEach(d => {
      if (d.end_date != null) {
        let end_date = d.end_date.split('/');
        let date = new Date(end_date[2], end_date[1] - 1, end_date[0]);
        d.date = date;
      }
    })
    if (date[0].testOrder == false) {
      date.sort((a, b) => a.date - b.date);
      date[0].testOrder = true;
    } else {
      date.sort((a, b) => a.date - b.date).reverse();
      date[0].testOrder = false;
    }
    this.admin.areaGroups = date;
  }



  changePerformanceValue() {
    this.admin.areaGroups.forEach(group => {
      if (group.certified_value == group.certified) {
        group.certified_value = group.certified_course;
      } else {
        group.certified_value = group.certified;
      }
    });

    this.admin.areaGroupsUsers.forEach(group => {
      if (group.certified_value == group.certified) {
        group.certified_value = group.certified_course;
      } else {
        group.certified_value = group.certified;
      }
    });

  }

  goToLMSUser(student) {
    // const url = 'http://lms.altealabs.com/#/groupmanagement?jwt=' + localStorage.getItem('access_token') + '&group_id=' + group.group_id;
    // const url = 'http://localhost:4500/#/groupmanagement?jwt=' + localStorage.getItem('access_token') + '&group_id=' + student.group_id + '&user_id=' + student.user_id + '&placement=' + '0';
    const url = 'https://lms.altealabs.com/#/groupmanagement?jwt=' + localStorage.getItem('access_token') + '&group_id=' + student.group_id + '&user_id=' + student.user_id + '&placement=' + '0';
    window.open(url, '_blank');
  }


  changeStatusGroup(group) {
    let group_id = group.group_id;
    if (group.active == true) {
      group.active = false;
    } else {
      group.active = true;
    }

    this.admin.changeGroupStatus(group_id, group.active).subscribe((data: any) => {
      this.getInfoGroups();
      this.userData.notifyExercise({ option: 'update_planner' });
    });;
  }

  changeStatusUser(user) {

    if (user.active == 1) {
      user.active = 0;
    } else if (user.active == 0) {
      user.active = 1;
    }
    this.auth.changeUserStatus(user.user_id).subscribe((data: any) => { });;
  }

  changeStatusGroupUser(user) {
    if (user.soft_deleted == 1 || user.soft_deleted == undefined) {
      user.soft_deleted = 0;
      this.addUserToTESLACourse(user);
    } else if (user.soft_deleted == 0) {
      user.soft_deleted = 1;
      this.admin.deleteUserToCourse([user.user_id], user.group_id).subscribe();
    }
  }


  selectGroup(index) {
    let group_id;
    let groupUsers;
    this.admin.goalsGroup = undefined;
    this.testView = false;
    if (this.admin.selectedGroup != undefined) {
      this.admin.areaGroups = this.admin.areaGroups.map(group => ({
        ...group,
        selected: false,
      }));

    }
    group_id = this.admin.areaGroups[index].group_id;
    groupUsers = new Array;
    this.admin.areaUsers.forEach(user => {
      if (user.group_id == group_id) {
        groupUsers.push(user);
      }
    });
    if (groupUsers.length != 0) {
      this.admin.areaGroupsUsers = groupUsers;
      this.admin.areaGroupsUsers[0].selected = true;
      this.admin.selectedStudent = this.admin.areaGroupsUsers[0];
    } else {
      this.admin.areaGroupsUsers = groupUsers;
      this.admin.selectedStudent = undefined;
    }
    this.admin.areaGroupsUsers.forEach(group => {
      if (group.certified_value == group.certified_course) {
        group.certified_value = group.certified_group;
      }
    });

    this.admin.areaGroups[index].selected = true;
    this.admin.group_id = this.admin.areaGroups[index].group_id;
    this.admin.selectedGroup = this.admin.areaGroups[index];
    this.admin.goalsGroup = this.admin.selectedGroup;
    // this.admin.goalsGroup.campus_id = this.admin.areaGroups[0].campus_id;
    if (!this.admin.edit_group) {
      this.selectStudent(0);
    }
  }

  selectStudent(index) {
    // this.resultArea = 'report';
    this.resultArea = 'candidate';
    if (this.admin.selectedStudent != undefined) {
      this.admin.areaGroupsUsers.forEach(student => {
        student.selected = false;
      });
    } else {
      this.admin.areaGroupsUsers[0] = true;
    }
    this.admin.areaGroupsUsers[index].selected = true;
    this.admin.selectedStudent = this.admin.areaGroupsUsers[index];
    this.candidateProfile = this.admin.areaGroupsUsers[index];
  }


  setStudentsGoals() {
    this.admin.goalsGroup = this.admin.selectedGroup;
    $("#studentsGoals").modal('show');
    return;
  }


  selectTest(index) {
    let testTakers = new Array();
    this.admin.getTestTakers(this.admin.tests[index].organization_id, this.admin.tests[index].exam_id).subscribe(data => {
      this.testView = true;
      this.admin.tests.forEach(test => {
        test.selected = false;
      });

      // this.admin.areaUsers.forEach( user => {
      //   if (user.group_id == group_id){
      //     testTakers.push(user);
      //   }
      // });

      this.admin.testTakers = data;
      this.admin.testTakers[0].selected = true;
      this.admin.selectedStudent = this.admin.testTakers[0];

      this.admin.tests[index].selected = true;
      this.admin.selectedTest = this.admin.tests[index];
      this.totalTestTakers = JSON.parse(JSON.stringify(this.admin.testTakers));

      let studentsID = new Array();
      for (let i = 0; i < this.totalTestTakers.length; i++) {
        if (!studentsID.includes(this.totalTestTakers[i].id)) {
          studentsID.push(this.totalTestTakers[i].id);
          this.totalTestTakers.totalStudents = studentsID.length;
        }
      }
      this.testTakersListType();
    });
  }

  testTakersListType() {
    this.completedTestTakers = new Array();
    this.bestTestTakers = new Array();
    this.totalTestTakers.forEach(student => {
      if (student.finish == true) {
        this.completedTestTakers.push(student);
      }
    });
    this.admin.testTakers = this.completedTestTakers;
    this.admin.getOrganizationBestResults(this.admin.selectedTest.test_takers, this.admin.selectedTest.exam_id, this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe(data => {
      this.bestTestTakers = data;
    });
  }

  changeTestTakersList() {
    if (this.testResults == 'completed') {
      this.admin.testTakers = this.completedTestTakers;
    } else if (this.testResults == 'best') {
      this.admin.testTakers = this.bestTestTakers;
    } else if (this.testResults == 'all') {
      this.admin.testTakers = this.totalTestTakers;
    }
  }


  selectTestTakers(index) {
    if (index instanceof userSet) {

    }
    if (this.admin.selectedStudent != undefined) {
      this.admin.testTakers.forEach(student => {
        student.selected = false;
      });
    } else {
      this.admin.testTakers[0] = true;
    }

    this.admin.testTakers[index].selected = true;
    this.admin.selectedStudent = this.admin.testTakers[index];

    this.getCandidateProfile(this.admin.testTakers[index]);
  }

  getCandidateProfile(user) {
    this.resultArea = 'candidate';
    this.candidateProfile = user;
  }

  newGroup(index) {
    this.admin.edit_group = true;
    this.selectGroup(index);
    this.router.navigate(['/new-group']);
  }


  beforeunloadHandler(event) {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }

  }

  ngOnDestroy() {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
      document.removeEventListener('click', this.documentClickHandler);
    }
  }


}
