import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import * as $ from 'jquery';
declare var $: any;

export class profile  {
  name: '';
  target_level:'';
  user_id: number;
  resources = new Array();
  score;
  num_exercise;
  skills = new Array();
  average= new Array();
}
  
@Component({
  selector: 'app-placement-report',
  templateUrl: './placement-report.component.html',
  styleUrls: ['./placement-report.component.css']
})
export class PlacementReportComponent implements OnChanges {

  @Input() selectedExamReport;
  @Input() examsReports;
  @Input() studentPerformance;
  @Input() userInfo;
 
  studentReport = new Array();
  indexReport = 0;
  
  constructor(public admin: AdminService) { }

  ngOnChanges(changes): void {

    if (this.examsReports != 0 && this.selectedExamReport != undefined){
      this.setExamsReport()
    }
  }
  

  setExamsReport(){
    this.studentReport = new Array();
    if (this.selectedExamReport == 'performance'){
      let studentReportAux = new profile();
      studentReportAux.average = this.admin.selectedStudent.certified_course.slice(0,-1);

      for (let i = 0; i < this.studentPerformance.length; i++){
        for (let j = 0; j < this.studentPerformance[i].types.length;j++){
          if (i == 0){
            let profileAuxResources = {
              name: this.studentPerformance[i].types[j].name,
              value: this.studentPerformance[i].types[j].certified,
              percentage: this.studentPerformance[i].types[j].certified + '%',
              restPercentage: String(100 -this.studentPerformance[i].types[j].certified) + '%'
            }
            studentReportAux.resources.push(profileAuxResources);
  
          } else if (i==1){
            let profileAuxResources = {
              name: this.studentPerformance[i].types[j].name,
              value: this.studentPerformance[i].types[j].certified,
              percentage: this.studentPerformance[i].types[j].certified + '%',
              restPercentage: String(100 -this.studentPerformance[i].types[j].certified) + '%'
            }
            studentReportAux.skills.push(profileAuxResources);

          }
        }
      }
      
      this.studentReport.push(JSON.parse(JSON.stringify(studentReportAux)));

    } else{
      for (let i = 0; i <  this.examsReports[this.selectedExamReport].length; i++){
      let examResults = this.examsReports[this.selectedExamReport][i].report_activity_profile;   
      let studentReportAux = new profile();
      let arrayResources = Object.keys(examResults.resource);
      let arraySkills = Object.keys(examResults.skill);
      
      studentReportAux.average = this.examsReports[this.selectedExamReport][0].report_activity_profile.average;
  
      arrayResources.forEach(resource => {
        let profileAuxResources = {
          name: resource,
          value: examResults.resource[resource].toFixed(0),
          percentage: String(examResults.resource[resource]) + '%',
          restPercentage: String(100 - examResults.resource[resource]) + '%'
        }
        studentReportAux.resources.push(profileAuxResources);
      });
      
      arraySkills.forEach(skill => {
        let profileAuxResources = {
          name: skill,
          value: examResults.skill[skill].toFixed(0),
          percentage: String(examResults.skill[skill]) + '%',
          restPercentage: String(100 - examResults.skill[skill]) + '%'
        }
        studentReportAux.skills.push(profileAuxResources);
      });
      this.studentReport.push(JSON.parse(JSON.stringify(studentReportAux)));
      }
    }
     
  }


  closeModal(){
    $("#placementReport").modal('hide');
    return;
  }

}
