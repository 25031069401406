import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { LmsService } from 'src/app/services/lms.service';
import * as $ from 'jquery';
declare var $: any;


class newGroupSet {
  teacher_ids = new Array();
  name = '';
  office_name = '';
  office_id: number = null;
  description = '';
  startDate = '';
  endDate = '';
  course_id: number = null;
  course_name = '';
  group_id: number = null;
  startHour = new Array();
  endHour = new Array;
  days = new Array;
  max_users: number = null;
  placement: boolean = false;
  gov = '';
  date = ''; //test_date
  course = new Array();
  daysGroup = new Array();
  startEvent = new Array();
  endEvent = new Array();
  teachers = new Array();
  modes = new Array();
  offices = new Array();
}
class editSet {
  dates: boolean = false;
  date = ''; //test_date
  teachers = new Array();
  office: boolean = false;
  mode: boolean = false;
}

class groupCandidates {
  course = new Array();
  convocation = new Array();
}

export class userCandidates {
  id: number = null;
  exam_user_id: string = '';
  name: string = '';
  last_name: string = '';
  target_level = '';
  final_level = '';
  // test_date = '';
  convocation = '';
  test_date = '';
  target_start_date = '';
  email: string = '';
  picture: string = '';
  phone: number = 0;
  score = '';
  selected: boolean = false;
  registered;
  pass: boolean = false;
  placement_requested;
  in_filter: boolean = false;
  finish: boolean = false;
  nameOrder: boolean = false;
  levelOrder: boolean = false;
  testOrder: boolean = false;
}

class groupOptions {
  organization = new Array();
  branch = new Array();
  dean = new Array();
  level = new Array();
  mode = new Array();
  office = new Array();
  subject = new Array();
  teacher = new Array();
  tutor = new Array();
}

export class Profile {
  name: '';
  target_level: '';
  user_id: number;
  resources = new Array();
  score;
  num_exercise;
  skills = new Array();
  px = new Array();
  average = new Array();
  organization: string = '';
}

@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrls: ['./new-group.component.css']
})
export class NewGroupComponent implements OnInit {

  group_id;
  startDay;
  endDay;
  groupOptions;
  selectedGroupOptions;
  usersToGroup;
  groupDone: boolean = false;
  test_date = new UntypedFormControl();
  selected: Date | null;


  users = new Array;
  candidates: boolean = true;
  prospectsUsers = new Array();
  newOffice: boolean = false;
  newOrganization: boolean = false;
  groupCandidates;
  applyFiltersCandidates: boolean = false;
  userType = 'candidates';
  candidatesUsers = new Array;

  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  startHours = ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];
  endHours = [ '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'];

  indexWeekDay = new Array;
  filterOption;
  selectedOptions;
  editGroup;
  newTeacher: boolean = false;
  studentsUpdate: boolean = false;
  registeredUsers = new Array();
  registered: boolean = false;
  showReport: boolean = false;

  candidatesUsersGroup = new Array();
  newGroupForm: UntypedFormGroup;
  newGroupFormDays: UntypedFormGroup;
  placementResults;
  today;
  candidateProfile: any;
  prospectProfile;
  organizationsList;
  candidatesFilters;
  prospectChallenges = new Array();



  constructor(public lms: LmsService, public auth: AuthService, public admin: AdminService, public datePipe: DatePipe) {

    this.newGroupForm = new UntypedFormGroup({
      office_id: new UntypedFormControl('', [Validators.required]),
      office: new UntypedFormControl('', [Validators.required]),
      mode: new UntypedFormControl('', [Validators.required]),
      organization: new UntypedFormControl('', Validators.required),
      branch: new UntypedFormControl('', Validators.required),
      level: new UntypedFormControl(''),
      course_id: new UntypedFormControl(''),
      subject: new UntypedFormControl(''),
      name: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl(''),
      start_date: new UntypedFormControl(''),
      end_date: new UntypedFormControl(''),
      duration: new UntypedFormControl(''),
      daysGroup: new UntypedFormArray([
      ]),
    });

    this.newGroupFormDays = new UntypedFormGroup({
      days: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      startHour: new UntypedFormControl('', Validators.required),
      startEvent: new UntypedFormControl('', Validators.required),
      endHour: new UntypedFormControl('', Validators.required),
      endEvent: new UntypedFormControl('', Validators.required),
      teacher_id: new UntypedFormControl('', Validators.required),
      type: new UntypedFormControl('', Validators.required),
    })
  }

  get daysGroup() {
    return this.newGroupForm.controls["daysGroup"] as UntypedFormArray;
  }

  ngOnInit(): void {
    this.auth.workingArea = 'new group';
    this.setGroupsOptions();
    this.setUsers();
    this.getOrganizations();
    this.today = new Date();
    if (this.admin.edit_group == false) {
      this.addNewDay();
    }
  }

  setGroupsOptions() {
    this.selectedGroupOptions = new newGroupSet();
    let group_id;
    if (this.admin.edit_group == true) {
      group_id = this.admin.group_id;
    }
    this.admin.getGroupsOptionsBack(group_id).subscribe((data: any) => {
      if (this.admin.edit_group == false) {
        let groupOptionsAux = new groupOptions();

        groupOptionsAux.organization = data.organization;
        groupOptionsAux.branch = data.branch;
        groupOptionsAux.dean = data.dean;
        groupOptionsAux.level = data.level;
        groupOptionsAux.mode = data.mode;
        groupOptionsAux.office = data.office;
        groupOptionsAux.subject = data.subject;
        groupOptionsAux.teacher = data.teacher;
        groupOptionsAux.tutor = data.tutor;

        this.groupOptions = groupOptionsAux;

      } else {

        let groupOptionsAux = new groupOptions();

        groupOptionsAux.mode = data.modes_list;
        groupOptionsAux.office = data.offices_list;
        groupOptionsAux.teacher = data.teachers_list;

        this.groupOptions = groupOptionsAux;


        this.newGroupForm.controls['office'].setValue(data.office[0]);
        this.newGroupForm.controls['office_id'].setValue(data.office[0].id);
        this.newGroupForm.controls['branch'].setValue(data.branch);
        this.newGroupForm.controls['branch'].markAsPristine();
        // this.newGroupForm.controls['branch'].disable();
        if (data.mode != null) {
          this.newGroupForm.controls['mode'].setValue(data.mode);
          // this.newGroupForm.value.mode = data.mode;
        }
        if (data.level != null) {
          this.selectedGroupOptions.course = 'level';
          this.newGroupForm.controls[this.selectedGroupOptions.course] = data.level;
          this.newGroupForm.value.level = data.level;
        } else if (data.subject != undefined) {
          this.selectedGroupOptions.course = 'subject';
          this.newGroupForm.controls[this.selectedGroupOptions.course] = data.subject;
          this.newGroupForm.value.subject = data.subject;
        }

        if (data.organization.length != 0){
          this.newGroupForm.controls['organization'].setValue(data.organization[0])
        }
        groupOptionsAux.organization = data.organizations_list;

        this.newGroupForm.controls['name'].setValue(data.group_name);
        this.newGroupForm.controls['course_id'].setValue(data.course_id);

        if (data.test_date != null){
          let test_date = data.test_date.split('/');
          let date = new Date(test_date[2], test_date[1] - 1, test_date[0]);
          this.newGroupForm.controls['date'].setValue(date);
        }
        if (data.startDate != null){
          let start_date = data.startDate.split('/');
          let startDate = new Date(start_date[2], start_date[1] - 1, start_date[0]);
          this.newGroupForm.controls['start_date'].setValue(startDate);
          // this.newGroupForm.controls['start_date'].setValue(data.startDate);
        }
        if (data.end_date != null){
          let end_date = data.endDate.split('/');
          let endDate = new Date(end_date[2], end_date[1] - 1, end_date[0]);
          this.newGroupForm.controls['end_date'].setValue(endDate);
        }

        if (data.duration != null) {
          this.newGroupForm.controls['duration'].setValue(data.duration);
        }

        if (data.teacher.length != 0){
          for (let i = 0; i < data.teacher.length; i++) {
            for (let j = 0; j < data.teacher[i].days.length; j++) {
              
              let formDays = new UntypedFormGroup({
                days: new UntypedFormControl('', Validators.required),
                name: new UntypedFormControl('', Validators.required),
                startHour: new UntypedFormControl('', Validators.required),
                startEvent: new UntypedFormControl('', Validators.required),
                endHour: new UntypedFormControl('', Validators.required),
                endEvent: new UntypedFormControl('', Validators.required),
                teacher_id: new UntypedFormControl('', Validators.required),
                type: new UntypedFormControl('', Validators.required),
              })
              
              formDays.controls['days'].setValue(data.teacher[i].days[j]);
              formDays.controls['name'].setValue(data.teacher[i].name);
              formDays.controls['startHour'].setValue(data.teacher[i].startHour[j]);
              formDays.controls['startEvent'].setValue(data.teacher[i].startEvent[j]);
              formDays.controls['endHour'].setValue(data.teacher[i].endHour[j]);
              formDays.controls['endEvent'].setValue(data.teacher[i].endEvent[j]);
              formDays.controls['teacher_id'].setValue(data.teacher[i].teacher_id);
              if (data.teacher[i].type != null){
                formDays.controls['type'].setValue(data.teacher[i].type[j]);
              } else{
                formDays.controls['type'].setValue(data.teacher[i].type);
              }
  
              this.daysGroup.push(formDays);
  
            }
          }
        } else{
          this.daysGroup.push(this.newGroupFormDays);
        }


        this.group_id = data.id;
        console.log(this.newGroupForm.value);
      }

    });

  }



  setUsers() {

    let filters = new Array();
    let group_id;

    if (this.admin.edit_group == true) {
      group_id = this.admin.group_id;
    } else {
      group_id = this.group_id;
    }

    if (this.applyFiltersCandidates == true) {
      for (let i = 0; i < Object.keys(this.groupCandidates).length; i++) {
        let filter = this.groupCandidates[Object.keys(this.groupCandidates)[i]];
        if (filter != 0){
          filters.push(filter);
        }
      }
    } else {
      filters = undefined;
    }

    this.admin.getGroupsCandidates(filters, group_id).subscribe((data: any) => {
      this.users = [];
      this.candidatesUsers = [];
      this.candidatesUsersGroup = new Array();
      // this.userType = 'candidates';
      for (let i = 0; i < data[0].length; i++) {
        let userAux = new userCandidates();
        userAux.id = data[0][i].id;
        userAux.name = data[0][i].name;
        userAux.exam_user_id = data[0][i].exam_user_id;
        userAux.last_name = data[0][i].last_name;
        userAux.phone = data[0][i].phone;
        userAux.email = data[0][i].email;
        userAux.target_level = data[0][i].target_level;
        userAux.final_level = data[0][i].final_level;
        userAux.score = data[0][i].score;
        userAux.in_filter = data[0][i].in_filter;
        userAux.pass = data[0][i].pass;
        userAux.finish = data[0][i].finish;
        userAux.nameOrder = false;
        userAux.levelOrder = false;
        this.candidatesUsers.push(userAux);
      }
      this.users = this.candidatesUsers;

      if (data[1] != undefined && data[1].length != 0) {
        for (let i = 0; i < data[1].length; i++) {
          let userAux = new userCandidates();
          userAux.id = data[1][i].id;
          userAux.name = data[1][i].name;
          userAux.last_name = data[1][i].last_name;
          userAux.phone = data[1][i].phone;
          userAux.email = data[1][i].email;
          userAux.target_level = data[1][i].target_level;
          userAux.final_level = data[1][i].final_level;
          userAux.score = data[1][i].score;
          // userAux.selected = true;
          this.candidatesUsersGroup.push(userAux);
        }
      }


    });

  }

  getOrganizations(){
    this.admin.getOrganizations().subscribe((data: any) => {  
      this.organizationsList = data;
     });
  } 

  selectOffice(event) {
    this.newGroupForm.controls['office_id'].setValue(this.groupOptions['office'][event.target.selectedIndex-1].id);
    if (this.groupCandidates.convocation != 0 && this.groupCandidates.course != 0 && this.newGroupForm.value.office != '') {
      this.applyFiltersCandidates = true;
      this.setUsers();
    }
  }

  changeOption(filterOption, option) {
    let index = option.target.selectedIndex - 1;
    this.newGroupForm.controls[filterOption].setValue(this.groupOptions[filterOption][index]);
    this.newGroupForm.controls['course_id'].setValue(this.groupOptions[filterOption][index].course_id);
    if (this.groupCandidates == undefined) {
      this.groupCandidates = new groupCandidates();
    }
    if (filterOption == 'branch') {
      if (this.newGroupForm.value.branch.id == 1) {
        this.selectedGroupOptions.course = 'level';
      } else {
        this.selectedGroupOptions.course = 'subject';
      }
    } 
    if (this.newGroupForm.value[this.selectedGroupOptions.course] != '') {
      let course = {
        filter: filterOption,
        id: this.newGroupForm.value[this.selectedGroupOptions.course].id
      }
      this.groupCandidates.course = course;
    }

    if (this.groupCandidates.convocation != 0 && this.groupCandidates.course != 0 && this.newGroupForm.value.office != '') {
      this.applyFiltersCandidates = true;
      this.setUsers();
    }

  }

  addNewDay() {
    let formDays = new UntypedFormGroup({
      days: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      startHour: new UntypedFormControl('', Validators.required),
      startEvent: new UntypedFormControl('', Validators.required),
      endHour: new UntypedFormControl('', Validators.required),
      endEvent: new UntypedFormControl('', Validators.required),
      teacher_id: new UntypedFormControl('', Validators.required),
      type:  new UntypedFormControl('', Validators.required),
    })

    this.daysGroup.push(formDays);
  }


  removeDay(i) {
    this.daysGroup.removeAt(i)
  }


  addGroupName(groupName) {
    this.selectedGroupOptions.name = groupName;
  }

  showPlacementResults() {
    $("#placementResults").modal('show');
    return;
  }


  chooseTestDate(input, dp) {
    dp.close();
    let event = this.datePipe.transform(input.value, 'dd/MM/yyyy');
    this.newGroupForm.controls['date'].setValue(input.value);
    input.value = event;

    if (this.groupCandidates == undefined) {
      this.groupCandidates = new groupCandidates();
    }
    this.groupCandidates.convocation = {
      filter: 'convocation',
      test_date: this.newGroupForm.value.date,
    }
    if (this.groupCandidates.convocation != 0 && this.groupCandidates.course != 0) {
      this.applyFiltersCandidates = true;
      this.setUsers();
    }

  }

  applyFilterOrganizations(input) {

    if (this.groupCandidates == undefined) {
      this.groupCandidates = new groupCandidates();
    }
    this.candidatesFilters = new Array();
    this.candidatesFilters.organization = input.target.value;
    this.groupCandidates.organization = {
      filter: 'organization',
      name: this.candidatesFilters.organization,
    }
    
    if (this.groupCandidates.organization != 0) {
      this.applyFiltersCandidates = true;
      this.setUsers();
    }

  }


  selectDaysDuration(filterOption, event) {

    // let eventAux = event.targetElement.value;
    if (filterOption == 'start_date') {
      this.startDay = event.targetElement.value;
      event.targetElement.value = this.datePipe.transform(event.targetElement.value, 'dd/MM/yyyy');
    } else {
      this.endDay = event.targetElement.value;
      event.targetElement.value = this.datePipe.transform(event.targetElement.value, 'dd/MM/yyyy');

    }

    if (this.newGroupForm.value.start_date != null && this.newGroupForm.value.end_date != null) {

      let date1 = new Date(this.startDay);
      let date2 = new Date(this.endDay);
      let time = date2.getTime() - date1.getTime();
      let days = time / (1000 * 3600 * 24);
      if (isNaN(days) == false) {
        this.newGroupForm.value.duration = (days / 7).toFixed(0);
        this.newGroupForm.controls['duration'].setValue((days / 7).toFixed(0));
      }

    }

    for (let i = 0; i < this.daysGroup.value.length; i++) {
      if (this.daysGroup.value[i].days != '' && this.daysGroup.value[i].endHour != '' && this.daysGroup.value[i].startHour != '' && this.daysGroup.value[i].name != '' && this.startDay != undefined) {
        this.createEventsPlanner();
      } else if (this.daysGroup.value[i].days != '' && this.daysGroup.value[i].endHour != '' && this.daysGroup.value[i].startHour != '' && this.daysGroup.value[i].name != '' && this.startDay == undefined) {
        this.startDay = new Date(this.newGroupForm.value.start_date);
        this.createEventsPlanner();
      }
    }
  }

  selectTeacher(filterOption, option, index){
    this.daysGroup.value[index].teacher_id = this.groupOptions.teacher[option.target.selectedIndex].teacher_id;
    this.createDayEvents(filterOption, option, index);
  }

  createDayEvents(filterOption, option, index) {

    this.groupOptions.teacher.forEach( (teacher, i) =>{
      if ( teacher.name == this.daysGroup.value[index].name){
        this.daysGroup.value[index].teacher_id = teacher.teacher_id;
      } 
    });
    if (this.daysGroup.value[index].days != '' && this.daysGroup.value[index].endHour != '' && this.daysGroup.value[index].startHour != '' && this.daysGroup.value[index].name != '' && this.startDay != undefined) {
      this.createEventsPlanner();
    } else if (this.daysGroup.value[index].days != '' && this.daysGroup.value[index].endHour != '' && this.daysGroup.value[index].startHour != '' && this.daysGroup.value[index].name != '' && this.startDay == undefined) {
      this.startDay = new Date(this.newGroupForm.value.start_date);
      this.createEventsPlanner();
    }
  }

  createEventsPlanner() {

    let startEventAux;
    let endEventAux
    let startDay = new Date(this.startDay);
    for (let i = 0; i < this.daysGroup.value.length; i++) {
      let weekDay = (this.days.indexOf(this.daysGroup.value[i].days) + 1) - startDay.getDay();
      startEventAux = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate() + weekDay, parseInt(this.daysGroup.value[i].startHour) + 2).toJSON();
      endEventAux = new Date(startDay.getFullYear(), startDay.getMonth(), startDay.getDate() + weekDay, parseInt(this.daysGroup.value[i].endHour) + 2).toJSON();
      this.daysGroup.value[i].startEvent = startEventAux;
      this.daysGroup.value[i].endEvent = endEventAux;
    }

  }


  addNewOffice(newOfficeName) {

    this.selectedGroupOptions.office_name = newOfficeName;
    this.selectedGroupOptions.office_id = '';

  }

  selectTestDate(infoOption) {

    this.newGroupForm.value.date = String(infoOption.value._i.date) + '/' + String(infoOption.value._i.month + 1) + '/' + String(infoOption.value._i.year);
    this.selectedGroupOptions.date = String(infoOption.value._i.date) + '/' + String(infoOption.value._i.month + 1) + '/' + String(infoOption.value._i.year);
    if (this.groupCandidates == undefined) {
      this.groupCandidates = new groupCandidates();
    }
    this.groupCandidates.convocation = {
      filter: 'convocation',
      test_date: this.selectedGroupOptions.date,
    }
    if (this.groupCandidates.convocation != 0 && this.groupCandidates.course != 0) {
      this.applyFiltersCandidates = true;
      this.setUsers();
    }

  }

  selectTeacherType(event, index){
    this.daysGroup.value[index].type = event.target.value;
    this.createDayEvents('', '', index)
  }


  editGroupInfo(infoGroup) {
    this.editGroup[infoGroup] = true;
  }


  deleteDay(index) {

    let daysGroup = this.selectedGroupOptions.daysGroup;
    daysGroup[index] = null;
    this.selectedGroupOptions.daysGroup = new Array();
    for (let i = 0; i < daysGroup.length; i++) {
      if (daysGroup[i] != null) {
        this.selectedGroupOptions.daysGroup.push(daysGroup[i]);
      }
    }
  }


  createGroup() {

    // if (this.admin.edit_group == true){
    if (this.newGroupForm.value.start_date || this.newGroupForm.value.end_date || this.newGroupForm.value.date) {
      this.newGroupForm.value.start_date = this.datePipe.transform(this.newGroupForm.value.start_date, 'dd/MM/yyyy');
      this.newGroupForm.value.end_date = this.datePipe.transform(this.newGroupForm.value.end_date, 'dd/MM/yyyy');
      this.newGroupForm.value.date = this.datePipe.transform(this.newGroupForm.value.date, 'dd/MM/yyyy');
    }
    // }

    this.group_id;
    this.selectedGroupOptions.max_users;
    this.admin.createGroupBack(this.newGroupForm.value.name, this.selectedGroupOptions.description, this.newGroupForm.value.mode, this.newGroupForm.value.organization, this.newGroupForm.value.start_date, this.newGroupForm.value.end_date, this.newGroupForm.value.course_id, this.group_id, this.selectedGroupOptions.max_users, this.newGroupForm.value.date, this.newGroupForm.value.duration, this.newGroupForm.value.daysGroup, this.newGroupForm.value.office_id, this.newGroupForm.value.office).subscribe((data: any) => {  
      this.group_id = data.id;
      if ( data.startDate != undefined){
        let start_date = data.startDate.split('/');
        let startDate = new Date(start_date[2], start_date[1] - 1, start_date[0]);
        this.newGroupForm.controls['start_date'].setValue(startDate);
      }
       
      if ( data.endDate != undefined){
        let end_date = data.endDate.split('/');
        let endDate = new Date(end_date[2], end_date[1] - 1, end_date[0]);
        this.newGroupForm.controls['end_date'].setValue(endDate);
      }

        this.groupDone = true;
      setTimeout(() => {
        this.groupDone = false;
      }, 4500);
    });

  }

 
  setprospectsUsers() {
    this.auth.getProspectsUsers().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        let userAux = new userCandidates();
        userAux.id = data[i].id;
        userAux.name = data[i].name;
        userAux.last_name = data[i].last_name;
        userAux.test_date = data[i].test_date;
        userAux.target_level = data[i].target_level;
        userAux.target_start_date = data[i].target_start_date;
        userAux.registered = data[i].registered;
        userAux.placement_requested = data[i].placement_requested;
        userAux.nameOrder = false;
        userAux.levelOrder = false;
        userAux.testOrder = false;
        this.prospectsUsers.push(userAux);
      }
      this.users = this.prospectsUsers;
      this.candidates = false;
      this.registered = false;
      this.userType = 'prospects';
    });
  }

  setcandidatesUsers() {
    this.users = this.candidatesUsers;
    this.candidates = true;
    this.registered = false;
    this.userType = 'candidates';
  }

  setRegisteredUsers() {
    this.admin.getRegisteredUsers().subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        let userAux = new userCandidates();
        userAux.id = data[i].id;
        userAux.name = data[i].name;
        userAux.last_name = data[i].last_name;
        userAux.email = data[i].email;
        userAux.nameOrder = false;
        this.registeredUsers.push(userAux);
      }
    });

    this.users = this.registeredUsers;
    this.registered = true;
    this.candidates = false;
    this.userType = 'registered';
  }

  candidatesListSearch;
  searchingStudent = false;
  searchCandidates(search_value) {
    this.searchingStudent = true;
    search_value = search_value.toLowerCase();
    let usersList;
      if (this.userType == 'candidates'){
        this.candidatesListSearch = this.candidatesUsers;
        usersList = this.candidatesUsers;
      } else if (this.userType == 'registered'){
        this.candidatesListSearch = this.registeredUsers;
        usersList = this.registeredUsers;
      } 
      let userListSearchAux = new Array();
  
      if (search_value.length > 2) {  
      this.candidatesListSearch.forEach( candidate => {
        if(candidate.name != undefined &&  candidate.last_name != undefined){
          if (candidate.name.toLowerCase().indexOf(search_value) != -1 || candidate.last_name.toLowerCase().indexOf(search_value) != -1){
            userListSearchAux.push(candidate);
          } 
        }
      });
        this.users = userListSearchAux;
      } else{
        this.users = usersList;
        if (search_value == ''){
          this.searchingStudent = false;  
        }
      }
  }

  sortByName(users, subType, order){
    if (users[0][order] == false){
      users =  users.sort((a, b) =>{
        if (a[subType] != undefined && b[subType] != undefined){
         return a[subType].localeCompare(b[subType]);
        }
      });
      users[0][order] = true;
    } else{
      users =  users.sort((a, b) =>{
        if (a[subType] != undefined && b[subType] != undefined){
         return a[subType].localeCompare(b[subType]);
        }
      }).reverse();

      users[0][order] = false;
    }
  }

  sortByDate(users) {
    users.forEach( d =>{
      if (d.test_date != null){
        let test_date = d.test_date.split('-');
        let date = new Date(test_date[2], test_date[1], test_date[0]);
        d.date = date;
      }
    })
    if (users[0].testOrder == false){
      users = users.sort((a, b) => a.date - b.date);
      users[0].testOrder = true;
    } else{
      users =  users.sort((a, b) => a.date - b.date).reverse();
      users[0].testOrder = false;
    }
  }



  searchProspects(search_value){
    this.searchingStudent = true;
    search_value = search_value.toLowerCase();
    let usersList;
    usersList= this.prospectsUsers;
    let userListSearchAux = new Array();
    if (search_value.length > 2) {  
      this.admin.searchProspects(search_value).subscribe((data: any) => {
        for (let i = 0; i < data.length; i++) {
          let userAux = new userCandidates();
          userAux.id = data[i].id;
          userAux.name = data[i].name;
          userAux.last_name = data[i].last_name;
          userAux.test_date = data[i].test_date;
          userAux.target_level = data[i].target_level;
          userAux.target_start_date = data[i].target_start_date;
          userAux.registered = data[i].registered;
          userAux.placement_requested = data[i].placement_requested;
          userListSearchAux.push(userAux);
        }
      });
      this.users = userListSearchAux;
    } else{
      this.users = usersList;
      if (search_value == ''){
        this.searchingStudent = false;  
      }
    }

  }


  insertUser(index, typeUser) {
    if (typeUser[index].selected == undefined || typeUser[index].selected == false) {
      typeUser[index].selected = true;
      if (typeUser != this.candidatesUsersGroup) {
        this.candidatesUsersGroup.push(typeUser[index]);
      }
    } else {
      typeUser[index].selected = false;
      let candidatesUsersGroup = new Array();
      this.candidatesUsersGroup.forEach((user, i) => {
        if (user == typeUser[index]) {
          i++;
        } else {
          candidatesUsersGroup.push(user);
        }
        this.candidatesUsersGroup = candidatesUsersGroup;
      });
    }
  }

  insertUsersToGroup(student) {
    this.usersToGroup = new Array;
    this.usersToGroup.push(student.id);

    if (!this.candidatesUsersGroup.includes(student.id)){
      this.candidatesUsersGroup.push(student);
    }

    if (this.group_id == undefined) {
      this.group_id = this.admin.group_id;
    }

    this.admin.insertUsersToCourseGroup(this.usersToGroup, this.group_id).subscribe((data: any) => {
      this.studentsUpdate = true;
      let usersAux = new Array();
      this.users.forEach(user => {
        if (user != student) {
          usersAux.push(user);
        }
      });
      this.users = usersAux;
      setTimeout(() => {
        this.studentsUpdate = false;
      }, 4500);
      // this.setUsers();
    });
  }


  deleteGroupUsers(deleteStudent) {
    this.usersToGroup = new Array;
    this.candidatesUsersGroup.forEach(candidate => {
      if (candidate == deleteStudent) {
        this.usersToGroup.push(candidate.id);
      }
    });

    if (this.group_id == undefined) {
      this.group_id = this.admin.group_id;
    }

    this.admin.deleteUserToCourse(this.usersToGroup, this.group_id).subscribe((data: any) => {
      this.studentsUpdate = true;
      setTimeout(() => {
        this.studentsUpdate = false;
      }, 4500);
      this.setUsers();
    });
  }

  getCandidateProfile(user) {
    this.showReport = true;
    this.candidateProfile = user;
    if (this.userType == 'prospects'){
      this.admin.getProspectInfo(user.id).subscribe((data: any) => {
        this.prospectProfile = data[0];
      });
    }
  }

  getUserChallenges(user){
    this.prospectChallenges = new Array();
    this.admin.getChallenges(user.id).subscribe((data: any) => {
      data =  data[1]
      if (data.academic.length != 0 && data.professional.length != 0 ){
        let studentChallengeName = 'academic';
        for (let i = 0; i < 2; i++){
          if (i == 0){
            studentChallengeName = 'academic';
          } else if (i == 1){
            studentChallengeName = 'professional';
          }
          let studentChallengesAux = {
            name : studentChallengeName,
            challenges: data[studentChallengeName]     
          }
          this.prospectChallenges.push(JSON.parse(JSON.stringify(studentChallengesAux)));
        }
      }
    });
  }


}
