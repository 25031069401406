import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NavigationDataService {

  constructor(private http: HttpClient) { }



  countLabs;
  labsMejorValorados;
  labs;
  labsDestacados;
  name;
  ratingsLabs;
  ratingsLabsMV;
  numStudentsLabs;
  numStudentsLabsMV
  loadingCarrousel = true;
  countRatingLabs;
  roundRating =[];
  roundRatingElse=[];
  roundRatingLabs=[];
  roundRatingElseLabs=[];
  countRatingLabsMV;
  numLabsMejorValorados = new Array(6);
  pagination = false;

  countLevels:any = [];
  countCampuses:any = [];
  countNumWords:any = [];
  countRating:any = [];
  loadingFiltersCount:boolean = true;

  getCatTreeInfoVar(env: string, id: string) {
    this.loadingCarrousel = true;
    this.countLabs = [];
    this.labs = [];
    this.labsMejorValorados = [];
    this.countLabs = [];
    this.getCatTreeInfo(env, id).subscribe(data => {
      this.labsMejorValorados = data['labsMejorValorados'];;
      this.labs = data['labs'];
      this.labsDestacados = data['labsDestacados'];
      this.name = data['name'];
      if (this.labs.length > 12) {
        this.pagination = true;
  
      } else {
        this.pagination = false;
      }
     
      this.loadingCarrousel = false;
    });

  }


  level = '';
  element: string;
  rating = '0';
  faculty = ''

  
  getSearchLabs(query: string, level: string, rating: string, faculty: string) {
    return this.http.get(`${environment.apiUrl}/api/lab/getSearchLabs`, { params: { 'q': query, 'level': level, 'rating': rating, 'faculty': faculty } });
  }

  getCatTreeInfo(env: string, id: string) {
    return this.http.get(`${environment.apiUrl}/api/catTree/getCatTreeInfo`, { params: { 'env': env, 'id': id } });
  }

  getFilterLabs(ratingFilter:string,numWords:string,env: string, id: string,level:string,faculty:string){
    return this.http.get(`${environment.apiUrl}/api/catTree/getFilterLabs`, { params: { 'ratingFilter': ratingFilter,'numWords':numWords ,'faculty': faculty ,'level':level,'env': env, 'id': id} });
  }

}
