import { Component, OnInit } from '@angular/core';
import { roles } from '../../../../environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import * as $ from 'jquery';
declare var $: any;

export class surveysCreation {
  prompts = new Array();
  prompt = '';
  survey_name = '';
  text = '';
  bubbles = new Array();
  survey_id;
}


@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent  implements OnInit {
 
  rolesAux;
  searchingCompany: boolean = false;
  editText: boolean = true;
  showPrompt: boolean = false;
  showPromptNumber;
  removedProspect: boolean = false;
  savedSurvey: boolean = false;
  surveyUserInfo;
  selectedProspect;
  prospectUsers = new Array();
  companies;
  survey;
  newBubble;
  warningMessage;
  editCompany: boolean = false;
  arrayCompanies = new Array();
  savedCompanies: boolean = false;

  closedSurveys: boolean = false;
  editFolder;
  surveysFiles;
  selectedSurveyFolder;
  // createFolder: boolean = false;
  savedClosedSurvey: boolean = false;
  arraySurveysIds = new Array()
  folderName = '';


  constructor(public auth: AuthService, public admin: AdminService) {
    this.rolesAux = roles;
  }


  ngOnInit() {
    this.auth.workingArea = 'surveys';
    this.getProspectsWithAnalysis();
    this.getCompaniesToReview();
  }


  getProspectsWithAnalysis(){

    this.admin.getProspectsWithAnalysis( ).subscribe((data: any) => {
      this.prospectUsers = data;
    });

  }
  getCompaniesToReview(){

    this.admin.getCompaniesToReview( ).subscribe((data: any) => {
      this.companies = data;
    });

  }

  removeFromAnalysis(prospect_id, i) {
    this.prospectUsers.splice(i, 1);
    this.admin.removeFromAnalysis(prospect_id, 'prospect').subscribe((data: any) => {
      this.removedProspect = true;
      setTimeout(() => {
        this.removedProspect = false;
      }, 4500);

    });
  }

  addCompanies(company?){
    if (company != undefined){
      this.arrayCompanies.push(company);
    } else{
      this.admin.addCompanies(this.arrayCompanies).subscribe((data: any) => {
        this.warningMessage = 'Se han añadido las empresas';
        this.savedCompanies = true;
        setTimeout(() => {
          this.savedCompanies = false;
        }, 4500);
      });
    }
  }


  selectProspect(prospect){
    this.selectedProspect = prospect;
    // this.selectedProspect.surveys.forEach( survey =>{
    //   survey.prompt = JSON.parse(survey.prompt);
    // })
    this.surveysFiles =JSON.parse(JSON.stringify(Object.keys(this.selectedProspect.files)));
    // this.surveysFiles = Object.keys(this.selectedProspect.files).map(key => ({name: key, surveys: this.selectedProspect.files[key]}));


  }

  createFolder(){

    this.folderName = 'Nueva carpeta';
    this.surveysFiles.push(this.folderName);
    this.editFolder =  this.folderName;
    let folder = {
      closed: '0',
      surveys: new Array()
    }
    this.selectedProspect.files[this.folderName] = folder;
    // this.selectedProspect.files[this.folderName].closed = '0';
    // this.selectedProspect.files[this.folderName].surveys = new Array();
    
  }

  changeFolderName(value, i){
    let folderNameAux = Object.keys(this.selectedProspect.files)[i];
    this.selectedProspect.files[value] = this.selectedProspect.files[Object.keys(this.selectedProspect.files)[i]];
    delete   this.selectedProspect.files[folderNameAux];
    this.surveysFiles = Object.keys(this.selectedProspect.files);
    this.editFolder = undefined;

  }

  createSurvey(index?){
    this.survey = new surveysCreation();
    this.editText = true;
    if (index != undefined){
      this.survey.text = this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].questions;
      this.survey.survey_id = this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].survey_id;
      this.survey.survey_name = this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].survey_name;
      if (this.survey.text != undefined){
        this.survey.bubbles = this.survey.text.split('\n');
      } else{
        this.survey.bubbles =this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].bubbles;
      }
      if (this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].prompts == undefined){
        this.survey.prompt = this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].prompt.join('\n');
        this.survey.prompts = this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].prompt;
      } else{
        this.survey.prompt = this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].prompt;
        this.survey.prompts = this.selectedProspect.files[this.selectedSurveyFolder].surveys[index].prompts;
      }
    } 
  }



  createFolderName(value, folder){
    this.folderName = value;
  }

  createSurveyFile(folderName){
    this.admin.closeSurveyFile(folderName, this.selectedProspect.organization_id, this.selectedProspect.prospect_id).subscribe(data => {
      this.savedClosedSurvey = true;
      this.warningMessage = 'Carpeta creada';
      this.editFolder = undefined;
      setTimeout(() => {
        this.savedClosedSurvey = false;
      }, 4500);
    });
  }

  closeSurveyFile(folderName){
    this.admin.closeSurveyFile(folderName, this.selectedProspect.organization_id, this.selectedProspect.prospect_id).subscribe(data => {
      this.savedClosedSurvey = true;
      this.selectedProspect.files[folderName].closed = '1';
      this.warningMessage = 'Carpeta cerrada';
      setTimeout(() => {
        this.savedClosedSurvey = false;
      }, 4500);
    });
  }

  cloneMode = false;
  targetClone;
  cloneSurveys(){
    if (this.targetClone != undefined){
      this.admin.cloneSurveys( this.selectedProspect.prospect_id,  this.selectedProspect.organization_id, this.targetClone.prospect_id, this.targetClone.organization_id, this.targetClone.organization_name).subscribe(data => {
        this.savedClosedSurvey = true;
        if (data[0].length == 0){
          this.warningMessage = data[1];
        } else{
          this.warningMessage = 'Información clonada';
        }
        this.targetClone.files = data[0];
        this.cloneMode = false;
        setTimeout(() => {
          this.savedClosedSurvey = false;
        }, 4500);
      });
    }
  }

  saveSurvey() {
    if (this.survey.survey_id == undefined) {
      this.admin.createSurvey(this.selectedSurveyFolder, this.selectedProspect.prospect_id, this.survey.survey_name, this.survey.prompts, this.survey.text).subscribe(data => {
        this.savedSurvey = true;
        this.warningMessage = data[0];
        this.survey.survey_id = data[1];
        this.selectedProspect.files[this.selectedSurveyFolder].surveys.push(this.survey);
        setTimeout(() => {
          this.savedSurvey = false;
        }, 4500);
      });
    } 
    else {
      this.admin.updateSurveyInfo(this.survey.survey_id, this.survey.survey_name, this.survey.prompts, this.survey.text).subscribe(data => {
        this.warningMessage = 'Se ha actualizado la información';
        this.savedSurvey = true;
        this.selectedProspect.files[this.selectedSurveyFolder].surveys.forEach( survey =>{
          if (survey.survey_id ==  this.survey.survey_id){
            survey.questions = this.survey.text
          }
        }) 
        setTimeout(() => {
          this.savedSurvey = false;
        }, 4500);
      });
    }
  }
  
  editSurvey(type, value) {
    if (type == 'prompt') {
      this.survey.prompt = value;
      this.getPromptBubbles();
    } else if (type == 'title') {
      this.survey.survey_name = value;
    } else if (type == 'text') {
      // if (this.survey.text.split('\n').length < 12 ){
        this.survey.text = value;
        this.getTextBubbles();
      // } 
    } else if (type == 'bubble'){
      // if (this.survey.bubbles.length < 12){
        this.survey.bubbles.push(value);
        this.survey.text = this.survey.bubbles.join('\n');
        this.newBubble = '';
      // }
    }
  }

  openSurveyModal(survey){
    if (this.survey == undefined){
      this.survey = survey;
      this.getSurveyArray()
    }
    $("#surveys").modal('show');
    return;
  }

  getSurveyArray(){
    if (this.survey.prompt.length != 0){
      this.survey.prompts = JSON.parse(this.survey.prompt);
    }
    if ( this.survey.questions != undefined){
      this.survey.bubbles = this.survey.questions.split('\n');
    }
  }

  closeModal(){
    this.survey = undefined;
    $("#surveys").modal('hide');
    return;
  }

  getTextBubbles() {
    if (this.survey.text != null) {
      this.survey.bubbles = this.survey.text.split('\n');
      this.survey.bubbles.forEach((bubble, i) =>{
        if (bubble == ''){
          this.survey.bubbles.splice( i, 1);
        }
      });
    } 
  }

  getPromptBubbles(){
    if (this.survey.prompt != null){
      this.survey.prompts = this.survey.prompt.split('\n');
      this.survey.prompts.forEach((prompt, i) =>{
        if (prompt == ''){
          this.survey.prompts.splice( i, 1);
        }
      });
    }
  }


  deleteBubble(index){
    this.survey.bubbles.splice( index, 1);
    this.survey.text = this.survey.bubbles.join('\n');
  }

  dropNumber = -1;
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.survey.bubbles, event.previousIndex, event.currentIndex);
    this.survey.text = this.survey.bubbles.join('\n');
  }



}
