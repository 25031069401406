import { Component, Injectable, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';


@Injectable({
  providedIn: 'root'
})

export class testConfigArray {
  course_name = '';
  name = '';
  exams = new Array();
  versions = new Array();
  exercises = new Array();
  version = '';
  exam_id = ''
}

@Injectable({
  providedIn: 'root'
})

export class configExams {
  name = '';
  exams = new Array();
  group_id;
  placement;
  degree_id;
  campus_id;
}


export class tests {
  testsCertificates = new Array();
  testsPEC = new Array()
}


@Component({
  selector: 'app-test-config',
  templateUrl: './test-config.component.html',
  styleUrls: ['./test-config.component.css']
})
export class TestConfigComponent implements OnInit {

  placementGroupsCertificates = new Array();
  placementGroupsPEC = new Array();
  testSelected;
  linkPlacementTest;
  constructor(public admin: AdminService, public auth: AuthService) { }

  ngOnInit(): void {
    this.getTestsConfig();
    this.auth.workingArea = 'test config';
  }

  getTestsConfig(){
    this.admin.campusTests = new tests();
    this.admin.getTestsConfig().subscribe((data: any) => {
      for (let i = 0; i < data.length ; i++){
        let exams;
        let versions;
        exams = Object.keys(data[i].exams);
        let configExamsAux = new configExams();
        configExamsAux.name =  data[i].name;
        configExamsAux.group_id =  data[i].group_id;
        configExamsAux.placement =  data[i].placement;
        configExamsAux.degree_id =  data[i].degree_id;
        configExamsAux.campus_id =  data[i].campus_id;

        for (let indexExam = 0; indexExam < exams.length; indexExam++){
          let examsAux = {
            name: exams[indexExam], 
            versions: new Array(),
          }
          configExamsAux.exams.push(examsAux);       
          versions = Object.keys(data[i].exams[exams[indexExam]]);
          for (let indexVersion = 0; indexVersion < versions.length; indexVersion++){
            let version = {
              name: versions[indexVersion],
              exam_id: 'https://new.altealabs.com/#/placement/' +  data[i].exams[exams[indexExam]][versions[indexVersion]].exam_id, 
              exercises:  data[i].exams[exams[indexExam]][versions[indexVersion]].exercises
            }
             configExamsAux.exams[indexExam].versions.push(version);
            }    
          }
        if (data[i].campus_id == 1){
          this.admin.campusTests['testsCertificates'].push(configExamsAux);
        } else{
          this.admin.campusTests['testsPEC'].push(configExamsAux);
        }
      }
    });
  }


  // getURLPlacement(group) {
  //   this.admin.getExamId(group.course_id).subscribe((data: any) => {
  //     this.linkPlacementTest = 'https://new.altealabs.com/#/placement/'  + data[0].id ;
  //   });
  // }


  goToLMS(group) {
    // const url = 'http://lms.altealabs.com/#/groupmanagement?jwt=' + localStorage.getItem('access_token')  + '&group_name=' + group.name + '&group_id=' + group.group_id + '&course_id=' + group.course_id + '&degree_id=' + group.degree_id + '&subject_id=' + group.subject_id + '&placement=' + group.placement + '&campus_id=' + group.campus_id;
    // const url = 'http://localhost:4500/#/groupmanagement?jwt=' + localStorage.getItem('access_token') + '&group_id=' + group.group_id + '&placement=' + group.placement;
    const url = 'https://lms.altealabs.com/#/groupmanagement?jwt=' + localStorage.getItem('access_token') + '&group_id=' + group.group_id + '&placement=' + group.placement;
    window.open(url, '_blank');
  }


}
