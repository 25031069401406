import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

export class profile  {
  name: '';
  target_level:'';
  user_id: number;
  resources = new Array();
  score;
  num_exercise;
  skills = new Array();
  average= new Array();
}
  
@Component({
  selector: 'app-placement-results',
  templateUrl: './placement-results.component.html',
  styleUrls: ['./placement-results.component.css']
})
export class PlacementResultsComponent implements OnInit {

  placementResults = new Array();

  constructor(public admin: AdminService) { }

  ngOnInit(): void {
    this.setPlacementValues();
  }

  setPlacementValues(){


    this.admin.getPlacementReport().subscribe((data: any) => {
      this.setPlacementResults(data);
    });

  }


  setPlacementResults(data){

    for (let i = 0; i < data.length; i++){

      this.placementResults[i] = new profile();
      this.placementResults[i].name = data[i].name;
      this.placementResults[i].score = data[i].accuracy_score;
      this.placementResults[i].num_exercise = data[i].number_exercise;
      this.placementResults[i].target_level = data[i].target_level;
      this.placementResults[i].user_id = data[i].user_id;




      let profileAuxResources = {
        name: 'Average',
        value: parseFloat(data[i].average.toFixed(0)),
        percentage: data[i].average + '%',
        restPercentage: String(100 - data[i].average) + '%'
      }
  

      this.placementResults[i].average.push(profileAuxResources);
      let arrayResources =  Object.keys(data[i].resource);
      let arraySkills =  Object.keys(data[i].skill);
  
      arrayResources.forEach( resource =>{
        let profileAuxResources = {
          name: resource,
          value: data[i].resource[resource].toFixed(0),
          percentage: String(data[i].resource[resource]) + '%',
          restPercentage: String(100 - data[i].resource[resource]) + '%'
        }
        this.placementResults[i].resources.push(profileAuxResources);
      });
      
      arraySkills.forEach( skill =>{
        let profileAuxResources = {
          name: skill,
          value: data[i].skill[skill].toFixed(0),
          percentage: String(data[i].skill[skill]) + '%',
          restPercentage: String(100 - data[i].skill[skill]) + '%'
        }
        this.placementResults[i].skills.push(profileAuxResources);
      });

    }

  }


}
