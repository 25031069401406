<div class="modal fade" id="login" tabindex="-1" role="dialog" #modalLogin>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <form class="row px-5" [formGroup]="logIn" (ngSubmit)="LogIn()">
        <div class="modal-header col-12 ">
          <h4 class="modal-title text-center">Log In to ALTEA Labs</h4>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
              aria-hidden="true">&times;</span></button>
        </div>


        <div class="col-12">
          <!-- <div class="d-sm-flex justify-content-between pb-2"><a class="btn btn-secondary btn-sm btn-block my-2 mr-3"
              href="#"><i class="socicon-facebook"></i>&nbsp;Login</a><a
              class="btn btn-secondary btn-sm btn-block my-2 mr-3" href="#"><i
                class="socicon-twitter"></i>&nbsp;Login</a><a class="btn btn-secondary btn-sm btn-block my-2 mr-3"
              href="#"><i class="socicon-linkedin"></i>&nbsp;Login</a></div> 
          <hr>
          <h3 class="h5 pt-4 pb-2">Or using form below</h3> -->


          <div class="modal-body mx-auto px-0">
            <div *ngIf="formValid" class="form-control-feedback text-danger col-12 px-0">
              El email o el password no son correctos
            </div>
            <div class="col-12 px-0 email">
              <div class="form-group"
                [ngClass]="{'text-danger': !logIn.controls['email'].pristine && !logIn.controls['email'].valid }">
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="fe-icon-mail"></i></span>
                  </div>
                  <input id="loginemail" class="form-control" type="email" placeholder="Email" formControlName="email"
                    [ngClass]="{'border border-danger': !logIn.controls['email'].pristine && !logIn.controls['email'].valid }">
                </div>

                <div *ngIf="logIn.controls['email'].errors?.required && !logIn.controls['email'].pristine"
                  class="form-control-feedback text-danger">
                  Este campo es requerido
                </div>
                <div *ngIf="!logIn.controls['email'].valid && !logIn.controls['email'].pristine"
                  class="form-control-feedback text-danger">
                  No tiene formato de email válido
                </div>
              </div>
            </div>



            <div class="col-12 px-0 password">
              <div class="form-group"
                [ngClass]="{'text-danger': !logIn.controls['password'].pristine && !logIn.controls['password'].valid }">
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="fe-icon-lock"></i></span>
                  </div>
                  <input id="loginpassword" class="form-control" type="password" placeholder="Password"
                    formControlName="password"
                    [ngClass]="{'border border-danger': !logIn.controls['password'].pristine && !logIn.controls['password'].valid }">
                </div>

                <div *ngIf="logIn.controls['password'].errors?.required && !logIn.controls['password'].pristine"
                  class="form-control-feedback text-danger">
                  Este campo es requerido
                </div>
                <div *ngIf="logIn.controls['password'].errors?.minlength && !logIn.controls['password'].pristine"
                  class="form-control-feedback text-danger">
                  Al menos 8 caracteres
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-content-between">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" type="checkbox" checked="" id="remember-me">
              <label class="custom-control-label" for="remember-me">Remember me</label>
            </div><a class="navi-link cursorPointer" data-toggle="modal" data-dismiss="modal" data-target="#forgot">Forgot password?</a>
          </div>
        </div>
        <div class="modal-footer col-12 px-0">
          <p class="regMod mb-0">Aun no tienes cuenta? </p><a class="aMod" data-toggle="modal" data-dismiss="modal"
            data-target="#register">&nbsp;REGISTRATE</a>
          <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Close</button>
          <button [disabled]="!logIn.valid" class="btn btn-altea btn-sm" type="submit">Login</button>
        </div>

      </form>
    </div>
  </div>
</div>