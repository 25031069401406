<div class="modal fade" id="studentsGoals" data-backdrop="static" data-keyboard="false"  tabindex="-1" role="dialog" aria-labelledby="studentsGoals" aria-hidden="true">
    <div class="modal-dialog" role="document"  style="overflow: hidden; height: 93%; max-width: 96%; background-color: white;">
        <div class="modal-content h-100" style="background-color: rgb(243 239 239); padding: 1.2%" >
            <div class="d-flex" [ngStyle]="{'filter': studentGoals != undefined ? 'blur(3px)':''}">
                <div class="my-auto title2" style="width: 100%; text-align: center; margin-left: 6%; font-size: 1.8vh;">
                        COURSE GOALS
                </div>
                <button type="button" class="btn btn-secondary btn-sm" style="margin-left: auto; width: 6%;" data-dismiss="modal" (click)="closeModal()" > 
                    Salir
                </button>
            </div>
            <div *ngIf="teacherActivityProfile != undefined" class="w-100 h-100" style="overflow: auto; display: grid; grid-template-columns: repeat(auto-fit, 18%); grid-template-rows: repeat(auto-fit, 50%); justify-content: center;" [ngStyle]="{'filter': studentGoals != undefined ? 'blur(3px)':''}">
                <div *ngFor="let studentProfile of teacherActivityProfile; let i = index" class="h-100" style="padding: 6%;">
                    <div class="h-100 bg-white">
                        <div class="blue-header" style="height: 9%; cursor: pointer;" (click)="studentGoalsView(studentProfile.user_id)">
                            <div  style="width: fit-content; color: white; font-weight: 500; font-size: 1.5vh;"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}"> {{studentProfile.name | titlecase}} </div>
                        </div>
                        <div style="height: calc(100% - 9%);  flex-direction: column; justify-content: space-around;" class="d-flex">   
                            <div *ngFor="let profile of studentProfile; let j = index" style="width: 100%; flex-direction: column; padding: 3%;" >
                                <span  class="title2"> {{profile.name}}</span>        
                                <div style="width: 100%;" class="d-flex" *ngFor="let type of profile.types; let j = index">
                                    <div  style="padding: 3%; height: 90%; justify-content: space-around;" class="w-100 d-flex titleResults2">
                                        <div class="h-100 my-auto mx-auto" style="width: 30%;">
                                            {{type.name | titlecase}}
                                        </div>
                                        <div style="height: 21%; width: 63%;" class="my-auto">
                                            <div class="w-100 d-flex" style="position: relative; background-color: #E7E6E6; height: 9px;">
                                                <div class="d-flex my-auto" style="min-width: max-content; background-color: #7698D4; height: 9px;position: absolute; z-index: 999;" [ngStyle]="{'width': type.startValue + '%'}"> </div>
                                                <div class="d-flex my-auto" style="min-width: max-content; background-color: rgb(146, 208, 80); height: 9px;position: absolute; z-index: 99;" [ngStyle]="{'width': type.startValue +  type.studentProgress + '%'}"> 
                                                </div>      
                                                <div class="d-flex my-auto" style="min-width: max-content; background-color: #FFCB25; height: 9px;position: absolute; z-index: 9;" [ngStyle]="{'width': type.targetValue + '%'}">
                                                </div>      
                                                <div class="GValue" [ngStyle]="{'left': (type.targetValue - 6) + '%'}">
                                                    G
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="studentGoals != undefined && teacherGoals != undefined" class="d-flex h-100" style="position: absolute; width: 96%;" >
                <div class="w-100 d-flex studentResultsModal  mx-auto" style="justify-content: center;  position: relative; flex-direction: column; background-color: rgb(243 239 239); box-shadow: 7px 0rem 2rem 0rem #d2d1d1;">
                    <div class="blue-header" style="height: 9%;" (click)="studentGoals = undefined; teacherGoals = undefined ">
                        <div  style="width: fit-content; color: white; font-weight: 500; font-size: 1.8vh;" > Student GOALS </div>
                        <div style="color: white; font-weight: 900; font-size: 1.8vh; position: absolute; right: 3%; cursor: pointer;" class="d-flex"  (click)="studentGoals = undefined; teacherGoals = undefined ">
                            x
                        </div>
                    </div>
                    <div class="h-100 w-100 d-flex"  style="justify-content: center">
                        <div class="bg-white my-auto" style="height: 72%; width: 30%; margin: 3%">
                            <div class="blue-header" style="height: 9%;">
                                <div  style="width: fit-content; color: white; font-weight: 500; font-size: 1.5vh;" [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}"> {{studentGoals.name}} </div>
                            </div>
                            <div style="height: calc(100% - 9%);  flex-direction: column; justify-content: space-around;" class="d-flex">   
                                <div *ngFor="let studentProfile of studentGoals; let j = index" style="width: 100%; flex-direction: column; padding: 3%;" >
                                    <span  class="title2"> {{studentProfile.name}}</span>        
                                    <div style="width: 100%;" class="d-flex" *ngFor="let type of studentProfile.types; let j = index">
                                        <div  style="padding: 3%; height: 90%; justify-content: space-around;" class="w-100 d-flex titleResults2">
                                            <div class="h-100 my-auto mx-auto" style="width: 30%;">
                                                {{type.name | titlecase}}
                                            </div>
                                            <div style="height: 21%; width: 63%;" class="my-auto">
                                                <div class="w-100 d-flex" style="position: relative; background-color: #E7E6E6; height: 9px;">
                                                    <div class="d-flex my-auto" style="min-width: max-content; background-color: #7698D4; height: 9px;position: absolute; z-index: 999;" [ngStyle]="{'width': type.startValue + '%'}"> </div>
                                                    <div class="d-flex my-auto" style="min-width: max-content; background-color: rgb(146, 208, 80); height: 9px;position: absolute; z-index: 99;" [ngStyle]="{'width': type.startValue +  type.studentProgress + '%'}"> </div>      
                                                    <div class="d-flex my-auto" style="min-width: max-content; background-color: #FFCB25; height: 9px;position: absolute; z-index: 9;" [ngStyle]="{'width': type.targetValue + '%'}"> </div>      
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-white my-auto" style="height: 72%; width: 30%; margin: 3%">
                            <div class="blue-header" style="height: 9%;">
                                <div  style="width: fit-content; color: white; font-weight: 500; font-size: 1.5vh;"> Georgetown </div>
                            </div>
                            <div style="height: calc(100% - 9%);  flex-direction: column; justify-content: space-around;" class="d-flex">   
                                <div *ngFor="let teacherProfile of teacherGoals; let j = index" style="width: 100%; flex-direction: column; padding: 3%;" >
                                    <span  class="title2"> {{teacherProfile.name}}</span>        
                                    <div style="width: 100%;" class="d-flex" *ngFor="let type of teacherProfile.types; let j = index">
                                        <div  style="padding: 3%; height: 90%; justify-content: space-around;" class="w-100 d-flex titleResults2">
                                            <div class="h-100 my-auto mx-auto" style="width: 30%;">
                                                {{type.name | titlecase}}
                                            </div>
                                            <div style="height: 21%; width: 63%;" class="my-auto">
                                                <div class="w-100 d-flex" style="position: relative; background-color: #E7E6E6; height: 9px;">
                                                    <div class="d-flex my-auto" style="min-width: max-content; background-color: #7698D4; height: 9px;position: absolute; z-index: 999;" [ngStyle]="{'width': type.startValue + '%'}"> </div>
                                                    <div class="d-flex my-auto" style="min-width: max-content; background-color: rgb(146, 208, 80); height: 9px;position: absolute; z-index: 99;" [ngStyle]="{'width': type.startValue +  type.studentProgress + '%'}"> </div>      
                                                    <div class="d-flex my-auto" style="min-width: max-content; background-color: #FFCB25; height: 9px;position: absolute; z-index: 9;" [ngStyle]="{'width': type.targetValue + '%'}"> </div>      
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</div>        