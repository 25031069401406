<div class="bg-login">
  <form [formGroup]="logIn" (ngSubmit)="LogIn()" class="col-md-5 p-0 mx-auto pt-5" style="height:100vh;">
    <div *ngIf="formValid" class="form-control-feedback text-danger mx-5">
      El email o el password no son correctos
    </div>
    <div class="form-group"
      [ngClass]="{'text-danger': !logIn.controls['email'].pristine && !logIn.controls['email'].valid }">
      <label for="email" class="px-1">Email</label>
      <input id="loginemail" class="form-control bg-light" type="email" placeholder="Email" formControlName="email"
        [ngClass]="{'border border-danger': !logIn.controls['email'].pristine && !logIn.controls['email'].valid }">

      <div *ngIf="logIn.controls['email'].errors?.required && !logIn.controls['email'].pristine"
        class="form-control-feedback text-danger">
        Este campo es requerido
      </div>
      <div *ngIf="!logIn.controls['email'].valid && !logIn.controls['email'].pristine"
        class="form-control-feedback text-danger">
        No tiene formato de email válido
      </div>
    </div>
    <div class="form-group"
      [ngClass]="{'text-danger': !logIn.controls['password'].pristine && !logIn.controls['password'].valid }">
      <label for="password" class="px-1">Password</label>
      <input id="loginpassword" class="form-control bg-light" type="password" placeholder="Password"
        formControlName="password"
        [ngClass]="{'border border-danger': !logIn.controls['password'].pristine && !logIn.controls['password'].valid }">

      <div *ngIf="logIn.controls['password'].errors?.required && !logIn.controls['password'].pristine"
        class="form-control-feedback text-danger">
        Este campo es requerido
      </div>
      <div *ngIf="logIn.controls['password'].errors?.minlength && !logIn.controls['password'].pristine"
        class="form-control-feedback text-danger">
        Al menos 8 caracteres
      </div>
    </div>
    <div class="text-center">
      <button [disabled]="!logIn.valid" [routerLink]="[ '/landing' ]" [ngClass]="{'btn-outline-accent': !logIn.valid, 'btn-accent': logIn.valid }"
        class="btn btn-sm" type="submit">Login</button>
    </div>
  </form>
</div>