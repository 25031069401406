<div class="position-relative h-100 w-100">
    <div *ngIf="successfullySave == true">
        <div class="saveMessage"> {{message}} </div>
    </div>
    <div class="h-100 d-flex w-100 justify-content-around" style="padding: 1.5%;">
        <div *ngFor="let item of activityProfile; let indice=index" class="box-pod">
            <div class="yellow-color title">
                {{item.pod_name | titlecase}}
            </div>
            <div style="overflow-y: scroll;" class="row">
                <div *ngFor="let pod of activityProfile[indice].exercises" class="box-type mx-auto" >
                    <div class="yellow-color title">
                        {{pod.name}} - {{pod.exercise_name}}
                    </div>
                    <div class="info-box" [ngStyle]="{'display': category.key == 'difficulty' || category.key == 'resources' || category.key == 'skills' ? '' : 'none'}" *ngFor="let category of pod | keyvalue; trackBy: trackByFn; let i = index" >
                        <div  *ngIf="category.key == 'resources' || category.key == 'skills'" style="flex-direction: column;" class="d-flex w-100">
                            <div class="yellow-color" style="text-transform: capitalize;">
                                {{category.key}}
                            </div>
                            <div *ngFor="let cat of pod[category.key]; let j = index;trackBy: trackByFn" class="d-flex w-100">
                                <div *ngIf="cat.name != 'l_reasoning'" class="text-truncate progress-text">
                                    {{cat.name | titlecase}}
                                </div>
                                <div *ngIf="cat.name == 'l_reasoning'" class="text-truncate progress-text">
                                    Tech Reasoning
                                </div>
                                <label>{{cat.value}}</label>
                                <div class="bar">
                                    <mat-slider  [(ngModel)]="cat.value"  min="3"  max="99" aria-label="units" (change)="setActivityValues( pod, category.key, cat, j)"> </mat-slider>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="category.key == 'difficulty'" class="d-flex w-100">
                            <div class="text-truncate progress-text">
                                {{category.key | titlecase}}
                            </div>
                            <label>{{category.value}}</label>
                            <div class="bar">
                                <mat-slider [(ngModel)]="category.value" min="1"  max="100" aria-label="units" (change)="setActivityValues( pod, category.key, category)"> </mat-slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
