<div class="modal fade" id="placementReport"  tabindex="-1" role="dialog" aria-labelledby="placementReport" aria-hidden="true">
    <div class="modal-dialog" role="document"  style="overflow: hidden; height: 93%; max-width: 33%; background-color: white;">
        <div class="modal-content"  *ngIf="examsReports != 0">
            <div  style="padding: 1%">
                <div style="width: 98%; height: 3%; display: flex; flex-direction: row; margin: 1%;">
                    <img src="../../../assets/img/blue-logo.png" style="width: 7%; height: 7%;">
                    <div style="width: 48%; color: black;  font-weight: 600; padding: 0 2%;  display: flex; flex-direction: column;"   class="h-100">
                        <div style="height: 66%; font-size: 1.8vh;">
                                Georgetown ALTEA
                        </div>
                        <div style="height: 33%;  font-size: 1.5vh;">
                                PEC
                        </div>
                    </div>
                    <!-- <div style="width: 60%; margin: auto;">
                        <div  class="infoStudent my-auto" style="background-color:#D9E5EF; width: 54%; margin-left: auto;  color:rgb(13, 58, 114);">  Reference 0000000 </div>
                    </div> -->
                    <button type="button" class="btn btn-secondary btn-sm" style="margin-left: auto; width: 15%;" (click)="closeModal()"> 
                        Cerrar
                    </button>
                </div>
                <div style="margin: 2% 0; font-size: 1.2vh" *ngIf="admin.selectedStudent != undefined">
                    <p style="height: 3%; margin-bottom: 0; margin-left: 1%; color: #888787;">  Georgetown Academy: 
                        <span style=" color:rgb(13, 58, 114); font-weight: 700;"  *ngIf="selectedExamReport != 'performance'"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}"> {{selectedExamReport | titlecase}} test </span>
                        <span style=" color:rgb(13, 58, 114); font-weight: 700;"  *ngIf="selectedExamReport == 'performance'"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}"> Acción Formativa Otoño 2022 </span>
                    </p>
                    <p style="height: 3%; margin-bottom: 0; margin-left: 1%; color: #888787;">  Candidate target level: 
                        <span style=" color:rgb(13, 58, 114);font-weight: 700;" *ngIf="selectedExamReport != 'performance'"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}" > {{examsReports[selectedExamReport][indexReport].target_level}} </span>
                        <span style=" color:rgb(13, 58, 114);font-weight: 700;" *ngIf="selectedExamReport == 'performance'"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}"> {{admin.selectedStudent.group_name}} </span>
                    </p>
                </div>
                <div  style="width: 100%; display: flex; flex-direction: row;">
                    <div  style="width: 70%; height: 100%; display: flex; flex-direction: column; margin:0 1%">
                        <div class="infoBox"> Candidate name </div>
                        <div  class="infoStudent"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}"> {{userInfo.name}} {{userInfo.last_name}} </div>
                    </div>
                    <div *ngIf="userInfo.organization != ''" style="width: 30%; height: 100%; display: flex; flex-direction: column; margin:0 1%">
                        <div class="infoBox"> Organization </div>
                        <div  class="infoStudent"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">  {{userInfo.organization}} </div>
                    </div>
                </div>
                <div  style="width: 100%; display: flex; flex-direction: row;">
                    <!-- <div  style="width: 70%; height: 100%; display: flex; flex-direction: column; margin:0 1%">
                        <div class="infoBox"> Date of birth </div>
                        <div class="infoStudent"> XXXXX </div>
                    </div> -->
                    <div  style="width: 30%; height: 100%; display: flex; flex-direction: column; margin:0 1%">
                        <div class="infoBox"> Student ID </div>
                        <div class="infoStudent"  [ngStyle]="{'filter': admin.userView == true? 'blur(6px)': ''}">   {{userInfo.organization}} - {{userInfo.user_id}}</div>
                    </div>
                </div>
                <div style="width: 100%; display: flex; flex-direction: row;"  [ngStyle]="{'zoom': selectedExamReport == 'performance' ? '0.9' : ''}">
                    <div style="width:100%; height: 100%; display: flex; flex-direction: column" >
                        <div class="border-bg-yellow" style="height: 21%; margin: 0.9%;" >
                            <div  style="display: flex; flex-direction: row;" class="bg-yellow w-100">
                                <div  style="width: 52%; display: flex; flex-direction: column; margin: 1%">
                                    <div class="bg-yellow title2"> Average </div>
                                    <div  class="bg-white typeResult" > <strong> AVERAGE </strong> </div>
                                </div>
                                <div  style="width: 44%; display: flex; flex-direction: column; margin: 1%">
                                    <div  class="bg-yellow title2"> Score </div>
                                    <div  class="bg-white typeResult"> <strong> {{studentReport[indexReport].average}} </strong></div>
                                </div>
                                <!-- <div  style="width: 44%; display: flex; flex-direction: column; margin: 1%">
                                    <div  class="bg-yellow title2">  CEFR Level </div>
                                    <div  class="bg-white  typeResult"> <strong>  XXX </strong></div>
                                </div> -->
                            </div>
                            <div class="w-100">
                                <div style="margin: 1.5% 0.9%;">
                                    <div class="w-100">
                                        <div class="d-flex w-100" style="height: 21%;">
                                            <div class="d-flex my-auto" style="min-width: max-content;height: 21px; background-color: #41719C;"  [ngStyle]="{'width': studentReport[indexReport].average + '%'}"> </div>
                                            <div class="d-flex my-auto" style="min-width: max-content; height: 3px; background-color: #C6C6C6; " [ngStyle]="{'width':( 100 - studentReport[indexReport].average) + '%'}"> </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 totalLevelsBox"  >
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': studentReport[indexReport].average < 30}"> 15 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (studentReport[indexReport].average >= 30) && (studentReport[indexReport].average < 45)}" > 30 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (studentReport[indexReport].average >= 45) && (studentReport[indexReport].average  < 60)}"> 45 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (studentReport[indexReport].average >= 60) && (studentReport[indexReport].average  < 75)}"> 60 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (studentReport[indexReport].average >= 75) && (studentReport[indexReport].average  < 90)}"> 75 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': 90 < studentReport[indexReport].average }"> 90 </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%; height: 60%; display: flex; flex-direction: row;" [ngStyle]="{'zoom': selectedExamReport == 'performance' ? '0.9' : ''}">
                    <div style="width:50%; height: 100%; display: flex; flex-direction: column; margin: 2.1% 1.5%;">
                        <div class="border-bg-yellow" style="margin:1.8% 0.9%;" *ngFor="let resource of studentReport[indexReport].resources; let i = index" >
                            <div  style="display: flex; flex-direction: row;" class="bg-yellow w-100">
                                <div  style="width: 52%; display: flex; flex-direction: column; margin: 1%">
                                    <div class="bg-yellow title2"> Resource </div>
                                    <div  class="bg-white typeResult" > <strong> {{resource.name | uppercase}}</strong> </div>
                                </div>
                                <div  style="width: 44%; display: flex; flex-direction: column; margin: 1%">
                                    <div  class="bg-yellow title2"> Score </div>
                                    <div  class="bg-white typeResult"> <strong>   {{resource.value}}</strong></div>
                                </div>
                                <!-- <div  style="width: 44%; display: flex; flex-direction: column; margin: 1%">
                                    <div  class="bg-yellow title2">  CEFR Level </div>
                                    <div  class="bg-white  typeResult"> <strong>  XXX </strong></div>
                                </div> -->
                            </div>
                            <div class="w-100">
                                <div style="margin: 3% 1.5%;">
                                    <div class="w-100">
                                        <div class="d-flex w-100" style="height: 21%;">
                                            <div class=" d-flex my-auto" style="min-width: max-content;height: 21px; background-color: #41719C;" [ngStyle]="{'width': resource.percentage}"> </div>
                                            <div class="d-flex my-auto" style="min-width: max-content; height: 3px; background-color: #C6C6C6; " [ngStyle]="{'width': resource.restPercentage}"> </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 totalLevelsBox"  >
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': resource.value < 30}"> 15 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (resource.value >= 30) && (resource.value < 45)}" > 30 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (resource.value >= 45) && (resource.value  < 60)}"> 45 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (resource.value >= 60) && (resource.value  < 75)}"> 60 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (resource.value >= 75) && (resource.value  < 90)}"> 75 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': 90 < resource.value }"> 90 </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%; height: 100%; display: flex; flex-direction: column; margin: 2.1% 1.5%;">
                        <div class="border-bg-yellow" style="margin:1.8%  0.9%;"  *ngFor="let skill of studentReport[indexReport].skills; let i = index" >
                            <div  style="display: flex; flex-direction: row;" class="bg-yellow w-100">
                                <div  style="width: 52%; display: flex; flex-direction: column; margin: 1%">
                                    <div class="bg-yellow title2"> Skill </div>
                                    <div  class="bg-white typeResult" > <strong> {{skill.name | uppercase}}</strong> </div>
                                </div>
                                <div  style="width: 44%; display: flex; flex-direction: column; margin: 1%">
                                    <div  class="bg-yellow title2"> Score </div>
                                    <div  class="bg-white typeResult"> <strong> {{skill.value}}</strong></div>
                                </div>
                                <!-- <div  style="width: 44%; display: flex; flex-direction: column; margin: 1%">
                                    <div  class="bg-yellow title2">  CEFR Level </div>
                                    <div  class="bg-white  typeResult"> <strong>  XXX </strong></div>
                                </div> -->
                            </div>
                            <div class="w-100">
                                <div style="margin: 3% 1.5%;">
                                    <div class="w-100">
                                        <div class="d-flex w-100" style="height: 21%;">
                                            <div class=" d-flex my-auto" style="min-width: max-content;height: 21px; background-color: #41719C;" [ngStyle]="{'width': skill.percentage}"> </div>
                                            <div class="d-flex my-auto" style="min-width: max-content; height: 3px; background-color: #C6C6C6; " [ngStyle]="{'width': skill.restPercentage}"> </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 totalLevelsBox"  >
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': skill.value < 30}"> 15 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (skill.value >= 30) && (skill.value < 45)}" > 30 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (skill.value >= 45) && (skill.value  < 60)}"> 45 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (skill.value >= 60) && (skill.value  < 75)}"> 60 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': (skill.value >= 75) && (skill.value  < 90)}"> 75 </div>
                                    <div class="levelBox my-auto" [ngClass]="{'targetLevelBox': 90 < skill.value }"> 90 </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100 d-flex" style="justify-content: center;" *ngIf="selectedExamReport != 'performance'">
                <div class="my-auto" style="color: grey; width: 12%; cursor: pointer; font-size: 1.5vh;">
                    <i class="fa-solid fa-angle-left fas d-flex my-auto" *ngIf="examsReports[selectedExamReport].length != 0 && indexReport != 0" style="justify-content: flex-start;" (click)="indexReport = indexReport-1" ></i>
                </div>
                <div class="letterColor text-center" style="width: 18%; padding: 1.5%; cursor: pointer;" >
                    Página {{indexReport+1}}
                </div>
                <div class="my-auto" style="color: grey; width: 12%; cursor: pointer; font-size: 1.5vh;">
                    <i class="fa-solid fa-angle-right fas d-flex my-auto" style="justify-content: flex-end;" *ngIf="examsReports[selectedExamReport].length != 0 && indexReport != (examsReports[selectedExamReport].length - 1)" (click)="indexReport = indexReport+1" ></i>
                </div>
            </div>
        </div>
    </div>
</div>

