<div class="h-100">
  <div style="width: 100%;" >
    <div class="containerPlanner">
        <div class="navigator" *ngIf="navigatorCalendar == true"  style="transition-duration: .6s; transition-delay: 0.6s ease-in-out; position: absolute;  z-index: 1000;">
          <daypilot-navigator [config]="configNavigator" [events]="events" [(date)]="date" (dateChange)="changeDate($event)" style="transition-duration: .6s; transition-delay: 0.6s ease-in-out; " #navigator></daypilot-navigator>
        </div>
        <div class="content" [appTooltip]="90">
          <div class="d-flex">
            <div class="title h-100 d-flex" >
              <span style="padding-bottom: 6px;" [appTooltip]="91"> Planner:</span>
              <!-- <button (click)="viewDay()" [class]="this.configNavigator.selectMode == 'Day' ? 'selected' : ''">Day</button> -->
              <button (click)="viewWeek()" [class]="this.configNavigator.selectMode == 'Week' ? 'selected' : ''" [appTooltip]="92">Week</button>
              <!-- <button (click)="viewMonth()" [class]="this.configNavigator.selectMode == 'Month' ? 'selected' : ''">Month</button> -->
            </div>
            <div style="width: 51%;">
              <div class="buttons d-flex" style="justify-content: end;">
                <div style="margin: 0px 12px;"  [ngStyle]="{'width': teacherCalendar == true ? '60%': ''}" class="d-flex" *ngIf="auth.role != 'clientAdmin'">
                  <select  class="filterSelection d-flex" *ngIf="teacherCalendar == true" (change)="searchTeacherPlanner($event);" [appTooltip]="93">
                    <option style="color: #6e6e6e;" value="" disabled selected hidden [appTooltip]="94"> Select teacher </option>
                    <option *ngFor="let teacher of teachersList; let i=index" [value]="teacher.name" style="color: #6e6e6e;" >
                          {{teacher.name}}

                    </option>
                  </select>
                  <i class="fas fa-users" style="color: #1C4360; cursor: pointer; margin: auto 3%" (click)="teacherCalendar = !teacherCalendar; setInfoPlanner();" [appTooltip]="95"></i>
                </div>
              </div>
            </div>
          </div>
          <div>
          <!-- <daypilot-calendar [config]="configDay" [events]="events" #day ></daypilot-calendar> -->
          <daypilot-calendar [config]="configWeek" [events]="events" #week [appTooltip]="96"></daypilot-calendar>
          <!-- <daypilot-month [config]="configMonth" [events]="events" #month></daypilot-month> -->
          </div>
        </div>
    </div>
  </div>
</div>


