<div class="bg-info">
    <div class="row pr-5 pl-3 bg-success justify-content-between no-gutters">
        <p class="my-auto sectionHeader">{{campus}}</p>
        <div *ngIf="labInfo.alertMess" style="position: fixed; border: 1px solid orange; background-color: white" class="alert my-auto alert-dismissible fade show text-center">
            <span class="alert-close" data-dismiss="alert"></span>
            <i class="alert-success fe-icon-check"></i> &nbsp;&nbsp;
            <strong>Image Creator:</strong> Imagen guardada correctamente para {{labInfo.product}}
        </div>
        <div *ngIf="labInfo.alertMess2" style="position: fixed; border: 1px solid orange; background-color: white" class="alert my-auto alert-dismissible fade show text-center">
            <span class="alert-close" data-dismiss="alert"></span>
            <i class="alert-success fe-icon-check"></i> &nbsp;&nbsp;
            <strong>Image Creator:</strong> Color guardado correctamente para {{labInfo.product}}
        </div>
        <a style="width: max-content;" class="btn my-auto btn-style-6 btn-md btn-altea d-block letter3 text-white" (click)="changeCampus()"><i class="fas fa-exchange-alt"></i></a>
    </div>
    <div *ngIf="Certs" style="width: 100%">
        <div style="max-height: 400px; height: 400px; width: 50%" class="my-3">
            <p class="pl-3 sectionHeader">FACULTIES</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let faculty of labInfo.CertList.faculties">
                    <div class="d-flex">
                        <p class="my-auto">{{faculty.area.name}}</p>
                        <span *ngIf="faculty.area.picture != null" style="padding-top: .2rem;" class="ml-2 text-white">Image checked <i class="text-white ml-1 fe-icon-check"></i></span>
                        <span *ngIf="faculty.area.color != null" style="padding-top: .2rem;" class="ml-2 text-white">Background checked <i class="text-white ml-1 fe-icon-check"></i></span>
                    </div>

                    <div class="d-flex">
                        <button *ngIf="faculty.area.picture == null" data-target="#imgChange" data-toggle="modal" (click)="getId('faculty', faculty.area.id, faculty.area.name)" class="mr-3">Add image +</button>
                        <button *ngIf="!faculty.colorPicker && faculty.area.color == null" (click)="showColorPicker(faculty)">Add background +</button>
                        <div *ngIf="faculty.colorPicker" class="d-flex">
                            <button (click)="getId('faculty', faculty.area.id, faculty.area.name); setColor('faculty', faculty.area.name, faculty.area.id, color); showColorPicker(faculty)">OK!</button>
                            <input style="max-width: 50px;" [(colorPicker)]="color" [style.background]="color" />
                            <p class="ml-2 pt-1 mb-0 text-white">
                                <={{click}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div style="max-height: 400px; height: 400px; width: 50%" class="my-3">
            <p class="pl-3 sectionHeader">DEGREES</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let degree of labInfo.CertList.degrees">
                    <div class="d-flex">
                        <p class="my-auto">{{degree.area.name}}</p>
                        <span *ngIf="degree.area.picture != null" style="padding-top: .2rem;" class="ml-2 text-white">Image checked <i class="text-white ml-1 fe-icon-check"></i></span>
                        <span *ngIf="degree.area.color != null" style="padding-top: .2rem;" class="ml-2 text-white">Background checked <i class="text-white ml-1 fe-icon-check"></i></span>
                    </div>
                    <div class="d-flex">
                        <button *ngIf="degree.area.picture == null" data-target="#imgChange" data-toggle="modal" (click)="getId('degree', degree.area.id, degree.area.name)" class="mr-3">Add image +</button>
                        <button *ngIf="!degree.colorPicker && degree.area.color == null" (click)="showColorPicker(degree)">Add background +</button>
                        <div *ngIf="degree.colorPicker" class="d-flex">
                            <button (click)="getId('degree', degree.area.id, degree.area.name); setColor('degree', degree.area.name, degree.area.id, color); showColorPicker(degree)">OK!</button>
                            <input style="max-width: 50px;" [(colorPicker)]="color" [style.background]="color" />
                            <p class="ml-2 pt-1 mb-0 text-white">
                                <={{click}}</p>
                        </div>
                    </div>

                </li>
            </ul>
        </div>
        <div style="max-height: 400px; height: 400px; width: 50%" class="my-3">
            <p class="pl-3 sectionHeader">SUBJECTS</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let subject of labInfo.CertList.subjects">
                    <div class="d-flex">
                        <p class="my-auto">{{subject.area.name}}</p>
                        <span *ngIf="subject.area.picture != null" style="padding-top: .2rem;" class="ml-2 text-white">Image checked <i class="text-white ml-1 fe-icon-check"></i></span>
                        <span *ngIf="subject.area.color != null" style="padding-top: .2rem;" class="ml-2 text-white">Background checked <i class="text-white ml-1 fe-icon-check"></i></span>
                    </div>
                    <div class="d-flex">
                        <button *ngIf="subject.area.picture == null" data-target="#imgChange" data-toggle="modal" (click)="getId('subject', subject.area.id, subject.area.name)" class="mr-3">Add image +</button>
                        <button *ngIf="!subject.colorPicker && subject.area.color == null" (click)="showColorPicker(subject)">Add background +</button>
                        <div *ngIf="subject.colorPicker" class="d-flex">
                            <button (click)="getId('subject', subject.area.id, subject.area.name); setColor('subject', subject.area.name, subject.area.id, color); showColorPicker(subject)">OK!</button>
                            <input style="max-width: 50px;" [(colorPicker)]="color" [style.background]="color" />
                            <p class="ml-2 pt-1 mb-0 text-white">
                                <={{click}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div style="max-height: 400px; height: 400px; width: 50%" class="mt-3">
            <p class="pl-3 sectionHeader">LABS</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let lab of labInfo.CertList.labs">
                    <p class="my-auto">{{lab.name}}</p>
                    <button [routerLink]="['/flyer-editor', lab.id]">Add image +</button>
                </li>
            </ul>
        </div>
        <app-imgcropper></app-imgcropper>
    </div>
    <div *ngIf="PEC" style="width: 100%">
        <div style="max-height: 400px; height: 400px; width: 50%" class="my-3">
            <p class="pl-3 sectionHeader">FACULTIES</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let faculty of labInfo.PECList.faculties">
                    <div class="d-flex">
                        <p class="my-auto">{{faculty.area.name}}</p>
                        <span *ngIf="faculty.area.picture != null" style="padding-top: .2rem;" class="ml-2 text-white">Image checked <i class="text-white ml-1 fe-icon-check"></i></span>
                        <span *ngIf="faculty.area.color != null" style="padding-top: .2rem;" class="ml-2 text-white">Background checked <i class="text-white ml-1 fe-icon-check"></i></span>
                    </div>

                    <div class="d-flex">
                        <button *ngIf="faculty.area.picture == null" data-target="#imgChange" data-toggle="modal" (click)="getId('faculty', faculty.area.id, faculty.area.name)" class="mr-3">Add image +</button>
                        <button *ngIf="!faculty.colorPicker && faculty.area.color == null" (click)="showColorPicker(faculty)">Add background +</button>
                        <div *ngIf="faculty.colorPicker" class="d-flex">
                            <button (click)="getId('faculty', faculty.area.id, faculty.area.name); setColor('faculty', faculty.area.name, faculty.area.id, color); showColorPicker(faculty)">OK!</button>
                            <input style="max-width: 50px;" [(colorPicker)]="color" [style.background]="color" />
                            <p class="ml-2 pt-1 mb-0 text-white">
                                <={{click}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div style="max-height: 400px; height: 400px; width: 50%" class="my-3">
            <p class="pl-3 sectionHeader">DEGREES</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let degree of labInfo.PECList.degrees">
                    <div class="d-flex">
                        <p class="my-auto">{{degree.area.name}}</p>
                        <span *ngIf="degree.area.picture != null" style="padding-top: .2rem;" class="ml-2 text-white">Image checked <i class="text-white ml-1 fe-icon-check"></i></span>
                        <span *ngIf="degree.area.color != null" style="padding-top: .2rem;" class="ml-2 text-white">Background checked <i class="text-white ml-1 fe-icon-check"></i></span>
                    </div>
                    <div class="d-flex">
                        <button *ngIf="degree.area.picture == null" data-target="#imgChange" data-toggle="modal" (click)="getId('degree', degree.area.id, degree.area.name)" class="mr-3">Add image +</button>
                        <button *ngIf="!degree.colorPicker && degree.area.color == null" (click)="showColorPicker(degree)">Add background +</button>
                        <div class="d-flex" *ngIf="degree.colorPicker">
                            <button (click)="getId('degree', degree.area.id, degree.area.name); setColor('degree', degree.area.name, degree.area.id, color);showColorPicker(degree)">OK!</button>
                            <input style="max-width: 50px;" [(colorPicker)]="color" [style.background]="color" />
                            <p class="ml-2 pt-1 mb-0 text-white">
                                <={{click}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div style="max-height: 400px; height: 400px; width: 50%" class="my-3">
            <p class="pl-3 sectionHeader">SUBJECTS</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let subject of labInfo.PECList.subjects">
                    <div class="d-flex">
                        <p class="my-auto">{{subject.area.name}}</p>
                        <span *ngIf="subject.area.picture != null" style="padding-top: .2rem;" class="ml-2 text-white">Image checked <i class="text-white ml-1 fe-icon-check"></i></span>
                        <span *ngIf="subject.area.color != null" style="padding-top: .2rem;" class="ml-2 text-white">Background checked <i class="text-white ml-1 fe-icon-check"></i></span>
                    </div>
                    <div class="d-flex">
                        <button *ngIf="subject.area.picture == null" data-target="#imgChange" data-toggle="modal" (click)="getId('subject', subject.area.id, subject.area.name)" class="mr-3">Add image +</button>
                        <button *ngIf="!subject.colorPicker && subject.area.color == null" (click)="showColorPicker(subject)">Add background +</button>
                        <div *ngIf="subject.colorPicker" class="d-flex">
                            <button (click)="getId('subject', subject.area.id, subject.area.name);setColor('subject', subject.area.name, subject.area.id, color); showColorPicker(subject)">OK!</button>
                            <input style="max-width: 50px;" [(colorPicker)]="color" [style.background]="color" />
                            <p class="ml-2 pt-1 mb-0 text-white">
                                <={{click}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div style="max-height: 400px; height: 400px; width: 50%" class="mt-3">
            <p class="pl-3 sectionHeader">LABS</p>
            <ul style="overflow-y:scroll; height: 300px; max-height: 300px">
                <li class="justify-content-between px-3 my-3 d-flex" *ngFor="let lab of labInfo.PECList.labs">
                    <p class="my-auto">{{lab.name}}</p>
                    <button [routerLink]="['/flyer-editor', lab.id]">Add image +</button>
                </li>
            </ul>
        </div>
        <app-imgcropper></app-imgcropper>
    </div>
</div>