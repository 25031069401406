import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  logIn: UntypedFormGroup;
  formValid = false;
  success = false;

  constructor(private http: HttpClient, private auth: AuthService, private router: Router) {

    this.logIn = new UntypedFormGroup({
      "email": new UntypedFormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      "password": new UntypedFormControl('', [Validators.required, Validators.minLength(8)])
    })
  }

  LogIn() {
    this.http.post(`http://127.0.0.1:8000/api/auth/loginAdmin`, { "email": this.logIn.value.email, "password": this.logIn.value.password })
      .subscribe(data => {
        this.formValid = false;
        this.success = true;
        this.auth.setSession(data);
      });
    if (this.success === false) {
      this.formValid = true;
    }
    //console.log("Login Submit");
  }
}
