import { Component, OnInit, ViewChild, ViewEncapsulation, ViewChildren, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LabInfoService } from '../../services/lab-info.service';
import { ListsInfoService } from '../../services/lists-info.service';
// import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CreatorService } from '../../services/creator.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class valid {
  labNum = 0;
  levelArray = new Array();
}
@Component({
  selector: 'app-lab-creator',
  templateUrl: './lab-creator.component.html',
  styles: []
})
export class LabCreatorComponent implements OnInit {

  labEss;
  labKey;
  labUlt;
  constructor(private labInfo: LabInfoService, private listInfo: ListsInfoService, private _router: Router, private creator: CreatorService) {
    this.checkLists();
    this.labEss = new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      image: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      labtitle: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      list: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      price: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      offer_price: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      inputkeylines: new UntypedFormControl(''),
      inputobjectives: new UntypedFormControl(''),
      inputrequirements: new UntypedFormControl(''),
      keylines: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      objectives: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      requirements: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    });
    this.labKey = new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      image: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      labtitle: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      list: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      price: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      offer_price: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      inputkeylines: new UntypedFormControl(''),
      inputobjectives: new UntypedFormControl(''),
      inputrequirements: new UntypedFormControl(''),
      keylines: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      objectives: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      requirements: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    });
    this.labUlt = new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      image: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      labtitle: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      list: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      price: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      offer_price: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      inputkeylines: new UntypedFormControl(''),
      inputobjectives: new UntypedFormControl(''),
      inputrequirements: new UntypedFormControl(''),
      keylines: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      objectives: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
      requirements: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    });
  }

  imprimir() {
    console.log(this.labEss);
    console.log(this.labKey);
    console.log(this.labUlt);
  }
  ngOnInit() {
  }

  focused: boolean = true;
  listsName: any;
  searchList(globalPreview) {
    let list = '';
    if (globalPreview == 0) {
      list = this.labEss.controls['list'].value;
    } else if (globalPreview == 1) {
      list = this.labKey.controls['list'].value;
    } else if (globalPreview == 2) {
      list = this.labUlt.controls['list'].value;
    }
    this.listInfo.searchList(list).subscribe(data => {
      this.listsName = data;
      console.log(this.listsName);
    });

  }

  labNum = new valid();
  stacks;
  stacksSelectedEssential;
  stacksSelectedKey;
  stacksSelectedUltimate;
  stackBool = false;
  stackArrayEssential = new Array();
  stackArrayKey = new Array();
  stackArrayUltimate = new Array();
  selectList(id, preview) {
    console.log(id);
    console.log(preview);
    this.labNum.levelArray = new Array();
    this.listInfo.selectLists(id).subscribe(data => {
      console.log(data);
      this.Lists = data
      if (data[0].name.indexOf('vocabulary') != -1) {
        this.labEss.controls['type'].setValue('WL');
        this.labKey.controls['type'].setValue('WL');
        this.labUlt.controls['type'].setValue('WL');
        this.stacks = [1, 2, 3, 4, 5, 6, 7, 8];
        this.stacksSelectedEssential = [false, false, false, false, false, false, false, false];
        this.stacksSelectedKey = [false, false, false, false, false, false, false, false];
        this.stacksSelectedUltimate = [false, false, false, false, false, false, false, false];
      } else if (data[0].name.indexOf('terminology') != -1) {
        this.labEss.controls['type'].setValue('TL');
        this.labKey.controls['type'].setValue('TL');
        this.labUlt.controls['type'].setValue('TL');
        this.stacks = [1, 2, 3, 4, 5, 6];
        this.stacksSelectedEssential = [false, false, false, false, false, false];
        this.stacksSelectedKey = [false, false, false, false, false, false];
        this.stacksSelectedUltimate = [false, false, false, false, false, false];
      } else if (data[0].name.indexOf('phrasal verbs') != -1) {
        this.labEss.controls['type'].setValue('PH');
        this.labKey.controls['type'].setValue('PH');
        this.labUlt.controls['type'].setValue('PH');
        this.stacks = [];
        this.stacksSelectedEssential = [];
        this.stacksSelectedKey = [];
        this.stacksSelectedUltimate = [];
      }
      else if (data[0].name.indexOf('quizzes') != -1) {
        this.labEss.controls['type'].setValue('Q');
        this.labKey.controls['type'].setValue('Q');
        this.labUlt.controls['type'].setValue('Q');
        this.stacks = [];
        this.stacksSelectedEssential = [];
        this.stacksSelectedKey = [];
        this.stacksSelectedUltimate = [];
      }else if (data[0].name.indexOf('grammar') != -1) {
        this.labEss.controls['type'].setValue('G');
        this.labKey.controls['type'].setValue('G');
        this.labUlt.controls['type'].setValue('G');
        this.stacks = [];
        this.stacksSelectedEssential = [];
        this.stacksSelectedKey = [];
        this.stacksSelectedUltimate = [];
      }
      if (data[0].name != undefined && (data[1] != undefined && data[1].name != undefined) && (data[2] != undefined && data[2].name != undefined)) {
        this.labEss.controls['labtitle'].setValue(data[0].name);
        this.labNum.levelArray.push(1);
        this.labKey.controls['labtitle'].setValue(data[1].name);
        this.labNum.levelArray.push(2);
        this.labUlt.controls['labtitle'].setValue(data[2].name);
        this.labNum.levelArray.push(3);
        this.labNum.labNum = 3;
      } else if (data[0].name != undefined &&  (data[1] != undefined && data[1].name != undefined) && (data[2] == undefined && data[2].name == undefined)) {
        if (data[0].name.indexOf('Essential') != -1 || data[0].name.indexOf('Access') != -1 || data[0].name.indexOf('Junior') != -1) {
          this.labEss.controls['labtitle'].setValue(data[0].name);
          this.labNum.levelArray.push(1);
        } else if (data[0].name.indexOf('Key') != -1 || data[0].name.indexOf('Exchange') != -1 || data[0].name.indexOf('Senior') != -1) {
          this.labKey.controls['labtitle'].setValue(data[0].name);
          this.labNum.levelArray.push(2);
        } else if (data[0].name.indexOf('Ultimate') != -1 || data[0].name.indexOf('Graduate') != -1 || data[0].name.indexOf('Executive') != -1) {
          this.labUlt.controls['labtitle'].setValue(data[0].name);
          this.labNum.levelArray.push(3);
        }

        if (data[1].name.indexOf('Essential') != -1 || data[1].name.indexOf('Access') != -1 || data[1].name.indexOf('Junior') != -1) {
          this.labEss.controls['labtitle'].setValue(data[1].name);
          this.labNum.levelArray.push(1);
        } else if (data[1].name.indexOf('Key') != -1 || data[1].name.indexOf('Exchange') != -1 || data[1].name.indexOf('Senior') != -1) {
          this.labKey.controls['labtitle'].setValue(data[1].name);
          this.labNum.levelArray.push(2);
        } else if (data[1].name.indexOf('Ultimate') != -1 || data[1].name.indexOf('Graduate') != -1 || data[1].name.indexOf('Executive') != -1) {
          this.labUlt.controls['labtitle'].setValue(data[1].name);
          this.labNum.levelArray.push(3);
        }
        this.labNum.labNum = 2;
      } else if (data[0].name != undefined ) {
        if (data[0].name.indexOf('Essential') != -1 || data[0].name.indexOf('Access') != -1 || data[0].name.indexOf('Junior') != -1) {
          this.labEss.controls['labtitle'].setValue(data[0].name);
          this.labNum.levelArray.push(1);
        } else if (data[0].name.indexOf('Key') != -1 || data[0].name.indexOf('Exchange') != -1 || data[0].name.indexOf('Senior') != -1) {
          this.labKey.controls['labtitle'].setValue(data[0].name);
          this.labNum.levelArray.push(2);
        } else if (data[0].name.indexOf('Ultimate') != -1 || data[0].name.indexOf('Graduate') != -1 || data[0].name.indexOf('Executive') != -1) {
          this.labUlt.controls['labtitle'].setValue(data[0].name);
          this.labNum.levelArray.push(3);
        }
        this.labNum.labNum = 1;
      }
    });
    console.log(this.labNum.labNum);
  }

  selectStack(i, j) {
    console.log(j);
    if (j == 0) {
      this.stacksSelectedEssential[i] = !this.stacksSelectedEssential[i];
      console.log(this.stacksSelectedEssential);
      if (this.stacksSelectedEssential[i]) {
        this.stackArrayEssential.push(i + 1);
      } else {
        let index = this.stackArrayEssential.indexOf(i + 1);
        this.stackArrayEssential.splice(index, 1);
      }
    } else if (j == 1) {
      this.stacksSelectedKey[i] = !this.stacksSelectedKey[i];

      if (this.stacksSelectedKey[i]) {
        this.stackArrayKey.push(i + 1);
      } else {
        let index = this.stackArrayKey.indexOf(i + 1);
        this.stackArrayKey.splice(index, 1);
      }
    } else if (j == 2) {
      this.stacksSelectedUltimate[i] = !this.stacksSelectedUltimate[i];

      if (this.stacksSelectedUltimate[i]) {
        this.stackArrayUltimate.push(i + 1);
      } else {
        let index = this.stackArrayUltimate.indexOf(i + 1);
        this.stackArrayUltimate.splice(index, 1);
      }
    }



  }

  onClickedOutside() {
    this.focused = false;
  }



  setInput(listName: string) {
    let arrayName;
    let indexFlag1 = listName.indexOf('vocabulary for');
    if (indexFlag1 != -1) {
      arrayName = listName.split(' vocabulary for ');

    }

    let indexFlag2 = listName.indexOf('terminology for');
    if (indexFlag2 != -1) {
      arrayName = listName.split(' terminology for ');
    }

    let indexFlag3 = listName.indexOf('quizzes for');
    if (indexFlag3 != -1) {
      arrayName = listName.split(' quizzes for ');
    }

    let indexFlag4 = listName.indexOf('grammar for');
    if (indexFlag4 != -1) {
      arrayName = listName.split(' grammar for ');
    }
    console.log(arrayName);
    this.labEss.controls['list'].setValue(arrayName[1]);
    this.labKey.controls['list'].setValue(arrayName[1]);
    this.labUlt.controls['list'].setValue(arrayName[1]);
  }
  // Lab Editor
  title: string[] = ["Title", "Title", "Title"];
  description: string[] = ["Description", "Description", "Description"];
  price: number[] = [199, 199, 199];
  offerprice: number[] = [19, 19, 19];
  keyLine: string[][] = [[], [], []];
  Objectives: string[][] = [[], [], []];
  Requirements: string[][] = [[], [], []];
  Towho: string[][] = [[], [], []];
  sublist: string[] = ['ESSENTIAL', 'KEY', 'ULTIMATE'];


  prueba: any;

  Lists = this.creator.Lists;
  @ViewChild('who') who;

  checkLists() {
    console.log("Check All");
    console.log(this.title);
    console.log(this.description);
    console.log(this.price);
    console.log(this.offerprice);
    console.log(this.keyLine);
    console.log(this.Objectives);
    console.log(this.Requirements);
    console.log(this.Towho);
    console.log(this.sublist);
  }

  prevent(event) {
    event.preventDefault();
  }

  addKeyline(index: number) {
    console.log(this.labEss.controls['keylines'].value);
    if (this.labEss.controls['inputkeylines'].value != '' || this.labKey.controls['inputkeylines'].value != '' || this.labUlt.controls['inputkeylines'].value != '') {
      if (index == 0) {
        this.keyLine[0].push(this.labEss.controls['inputkeylines'].value);
        this.labEss.controls['keylines'].setValue(this.labEss.controls['keylines'].value + ',' + this.labEss.controls['inputkeylines'].value);
        this.labEss.controls['inputkeylines'].setValue('');
      } else if (index == 1) {
        this.keyLine[1].push(this.labKey.controls['inputkeylines'].value);
        this.labKey.controls['keylines'].setValue(this.labKey.controls['keylines'].value + ',' + this.labKey.controls['inputkeylines'].value);
        this.labKey.controls['inputkeylines'].setValue('');
      } else if (index == 2) {
        this.keyLine[2].push(this.labUlt.controls['inputkeylines'].value);
        this.labUlt.controls['keylines'].setValue(this.labUlt.controls['keylines'].value + ',' + this.labUlt.controls['inputkeylines'].value);
        this.labUlt.controls['inputkeylines'].setValue('');
      }
    } else {
      return;
    }
  }

  addObjectives(index: number) {
    if (this.labEss.controls['inputobjectives'].value != '' || this.labKey.controls['inputobjectives'].value != '' || this.labUlt.controls['inputobjectives'].value != '') {
      if (index == 0) {
        this.Objectives[0].push(this.labEss.controls['inputobjectives'].value);
        this.labEss.controls['objectives'].setValue(this.labEss.controls['objectives'].value + ',' + this.labEss.controls['inputobjectives'].value);
        this.labEss.controls['inputobjectives'].setValue('');
      } else if (index == 1) {
        this.Objectives[1].push(this.labKey.controls['inputobjectives'].value);
        this.labKey.controls['objectives'].setValue(this.labKey.controls['objectives'].value + ',' + this.labKey.controls['inputobjectives'].value);
        this.labKey.controls['inputobjectives'].setValue('');
      } else if (index == 2) {
        this.Objectives[2].push(this.labUlt.controls['inputobjectives'].value);
        this.labUlt.controls['objectives'].setValue(this.labUlt.controls['objectives'].value + ',' + this.labUlt.controls['inputobjectives'].value);
        this.labUlt.controls['inputobjectives'].setValue('');
      }
    } else {
      return;
    }
  }

  addRequirements(index: number) {
    console.log('dentro de funcion');
    if (this.labEss.controls['inputrequirements'].value != '' || this.labKey.controls['inputrequirements'].value != '' || this.labUlt.controls['inputrequirements'].value != '') {
      if (index == 0) {
        this.Requirements[0].push(this.labEss.controls['inputrequirements'].value);
        this.labEss.controls['requirements'].setValue(this.labEss.controls['requirements'].value + ',' + this.labEss.controls['inputrequirements'].value);
        this.labEss.controls['inputrequirements'].setValue('');
      } else if (index == 1) {
        this.Requirements[1].push(this.labKey.controls['inputrequirements'].value);
        this.labKey.controls['requirements'].setValue(this.labKey.controls['requirements'].value + ',' + this.labKey.controls['inputrequirements'].value);
        this.labKey.controls['inputrequirements'].setValue('');
      } else if (index == 2) {
        this.Requirements[2].push(this.labUlt.controls['inputrequirements'].value);
        this.labUlt.controls['requirements'].setValue(this.labUlt.controls['requirements'].value + ',' + this.labUlt.controls['inputrequirements'].value);
        this.labUlt.controls['inputrequirements'].setValue('');
      }
    } else {
      return;
    }
  }

  // addTowho(towho: any, index: number) {
  //   if (towho !== ' ') {
  //     this.Towho[index].push(towho);
  //     this.who.nativeElement.value = '';
  //   }
  // }

  saveLab() {
    if (window.confirm("¿Seguro que quieres guardar?")) {
      this.setLab();
    }
  }

  cancelConfirm() {
    if (window.confirm("¿No quieres guardar?")) {
      this._router.navigate(['/landing']);
    }
  }

  public editor;
  public placeholder = "Description goes here...";
  public editorConfig = {};


  onEditorBlured(quill) {
  }

  onEditorFocused(quill) {
  }

  onEditorCreated(quill) {
    console.log(quill);
    this.editor = quill;
  }

  onContentChanged({ quill, html, text }) {
  }

  changePreview = 0;
  preview() {
    if (this.changePreview === 0) {
      this.changePreview = 1;
    }
    else if (this.changePreview === 1) {
      this.changePreview = 2;
    }
    else {
      this.changePreview = 0;
    }
  }

  setLab() {
    let stackStringEssential = '';
    let stackStringKey = '';
    let stackStringUltimate = '';
    let index = 0;
    this.stackArrayEssential.forEach(stack => {
      if (index == 0) {
        stackStringEssential = String(stack);
      } else {
        stackStringEssential = stackStringEssential + ',' + String(stack);
      }
      index++;
    });
    this.stackArrayKey.forEach(stack => {
      if (index == 0) {
        stackStringKey = String(stack);
      } else {
        stackStringKey = stackStringKey + ',' + String(stack);
      }
      index++;
    });
    this.stackArrayUltimate.forEach(stack => {
      if (index == 0) {
        stackStringUltimate = String(stack);
      } else {
        stackStringUltimate = stackStringUltimate + ',' + String(stack);
      }
      index++;
    });
    if (this.labEss.controls['labtitle'].value != '') {
      this.labInfo.saveLab(this.labEss.controls['labtitle'].value, this.labEss.controls['image'].value, this.labEss.controls['description'].value, String(this.Lists[0].id), this.labEss.controls['keylines'].value, this.labEss.controls['requirements'].value, this.labEss.controls['objectives'].value, this.labEss.controls['type'].value, this.labEss.controls['price'].value, this.labEss.controls['offer_price'].value, stackStringEssential).subscribe(data => {
        this.labEss.controls['type'].setValue('');
        this.labEss.controls['image'].setValue('');
        this.labEss.controls['labtitle'].setValue('');
        this.labEss.controls['list'].setValue('');
        this.labEss.controls['price'].setValue('');
        this.labEss.controls['offer_price'].setValue('');
        this.labEss.controls['description'].setValue('');
        this.labEss.controls['inputkeylines'].setValue('');
        this.labEss.controls['inputobjectives'].setValue('');
        this.labEss.controls['inputrequirements'].setValue('');
        this.labEss.controls['keylines'].setValue('');
        this.labEss.controls['objectives'].setValue('');
        this.labEss.controls['requirements'].setValue('');
      });
    }
    if (this.labKey.controls['labtitle'].value != '') {
      this.labInfo.saveLab(this.labKey.controls['labtitle'].value, this.labKey.controls['image'].value, this.labKey.controls['description'].value, String(this.Lists[1].id), this.labKey.controls['keylines'].value, this.labKey.controls['requirements'].value, this.labKey.controls['objectives'].value, this.labKey.controls['type'].value, this.labKey.controls['price'].value, this.labKey.controls['offer_price'].value, stackStringKey).subscribe(data => {
        this.labKey.controls['type'].setValue('');
        this.labKey.controls['image'].setValue('');
        this.labKey.controls['labtitle'].setValue('');
        this.labKey.controls['list'].setValue('');
        this.labKey.controls['price'].setValue('');
        this.labKey.controls['offer_price'].setValue('');
        this.labKey.controls['description'].setValue('');
        this.labKey.controls['inputkeylines'].setValue('');
        this.labKey.controls['inputobjectives'].setValue('');
        this.labKey.controls['inputrequirements'].setValue('');
        this.labKey.controls['keylines'].setValue('');
        this.labKey.controls['objectives'].setValue('');
        this.labKey.controls['requirements'].setValue('');
      });
    }
    if (this.labUlt.controls['labtitle'].value != '') {
      this.labInfo.saveLab(this.labUlt.controls['labtitle'].value, this.labUlt.controls['image'].value, this.labUlt.controls['description'].value, String(this.Lists[2].id), this.labUlt.controls['keylines'].value, this.labUlt.controls['requirements'].value, this.labUlt.controls['objectives'].value, this.labUlt.controls['type'].value, this.labUlt.controls['price'].value, this.labUlt.controls['offer_price'].value, stackStringUltimate).subscribe(data => {
        this.labUlt.controls['type'].setValue('');
        this.labUlt.controls['image'].setValue('');
        this.labUlt.controls['labtitle'].setValue('');
        this.labUlt.controls['list'].setValue('');
        this.labUlt.controls['price'].setValue('');
        this.labUlt.controls['offer_price'].setValue('');
        this.labUlt.controls['description'].setValue('');
        this.labUlt.controls['inputkeylines'].setValue('');
        this.labUlt.controls['inputobjectives'].setValue('');
        this.labUlt.controls['inputrequirements'].setValue('');
        this.labUlt.controls['keylines'].setValue('');
        this.labUlt.controls['objectives'].setValue('');
        this.labUlt.controls['requirements'].setValue('');
        this.imageChangedEvent = ['', '', ''];
        this.croppedImage = ['', '', ''];
      });
    }
  }

  // onChange(id) {
  //   this.listId = id;
  // }

  changeGlobalPreview = 0;
  globalPreview() {
    if (this.changeGlobalPreview === 0) {
      this.changeGlobalPreview = 1;
    }
    else if (this.changeGlobalPreview === 1) {
      this.changeGlobalPreview = 2;
    }
    else {
      this.changeGlobalPreview = 0;
    }
    console.log(this.changeGlobalPreview);
  }


  // FUNCIONES DEL CROPPER IMAGE
  imageChangedEvent: any[] = ['', '', ''];
  croppedImage: any[] = ['', '', ''];

  fileChangeEvent(event: any, index: number): void {
    if (!event) {
      this.croppedImage[index] = null;
      // añadir pop-up en vez del console.log
      console.log('No has seleccionado ninguna imagen');
      return;
    }

    if (event) {
      this.imageChangedEvent[index] = event;
      return;
    }
  }
  changeImage(index: number) {
    if (index == 0) {
      this.labEss.controls['image'].setValue(this.croppedImage[index]);
    } else if (index == 1) {
      this.labKey.controls['image'].setValue(this.croppedImage[index]);
    } else if (index == 2) {
      this.labUlt.controls['image'].setValue(this.croppedImage[index]);
    }
  }

  imageCropped(event, index: number) {
    this.croppedImage[index] = event.base64;
  }
}
