import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { LandingDataService } from '../../services/landing-data.service';
import { SessionDataService } from '../../services/session-data.service';
import { HostListener } from "@angular/core";
import { AuthService } from 'src/app/services/auth.service';
// import { ImageCroppedEvent } from 'ngx-image-cropper';
import { UserDataService } from 'src/app/services/user-data.service';


@Component({
  selector: 'app-flyer-editor',
  templateUrl: './flyer-editor.component.html',
  styleUrls: ['./flyer-editor.component.css']
})
export class FlyerEditorComponent implements OnInit {

  constructor(private auth: AuthService, private activatedRoute: ActivatedRoute, private landingData: LandingDataService, public sessData: SessionDataService, private userData: UserDataService) {
    this.editObj = new Array(this.objs.length + 1);
    this.editWho = new Array(this.towho.length + 1);
    this.editReq = new Array(this.reqs.length + 1);
    this.pencilobjetives = new Array(this.objs.length + 1);
    this.penciltowho = new Array(this.towho.length + 1);
    this.pencilreq = new Array(this.reqs.length + 1);

    for (let index = 0; index < this.objs.length + 1; index++) {
      this.editObj[index] = false;
      this.pencilobjetives[index] = false;
    }
    for (let index = 0; index < this.towho.length + 1; index++) {
      this.editWho[index] = false;
      this.penciltowho[index] = false;
    }
    for (let index = 0; index < this.reqs.length + 1; index++) {
      this.editReq[index] = false;
      this.pencilreq[index] = false;
    }
  }

  isAuth() {
    let isauth = this.auth.isAuthenticated();
    return isauth;
  }

  editname: boolean;
  editoffer: boolean;
  editprice: boolean;
  editdesc: boolean;
  editKey: boolean;
  editObj;
  editWho;
  editReq;
  flyerName;
  flyerOffer;
  flyerPrice;
  pencilTitle: boolean;
  pencilkey: boolean;
  penciltowho;
  pencilobjetives;
  pencilreq;
  pencildesc: boolean;
  imagepencil: boolean;
  changeName(name) {
    this.flyerName = name;
  }

  changeOffer(offer) {
    this.flyerOffer = offer;
  }

  changePrice(price) {
    this.flyerPrice = price;
  }
  changeDesc(event) {
    console.log("event", event);
    this.desc = event.html;
  }
  changeKey(key) {
    this.keys = key;

  }
  addObjective(objective, index?) {
    if (index <= this.objs.length) {
      for (let i = 0; i < this.objs.length; i++) {
        if (i == index) {
          this.objs[i] = objective;
        }
      }
    } else {
      this.objs.push(objective);
      this.editObj[this.objs.length + 1] = false;
    }

  }
  deleteobj(index) {
    this.objs.splice(index, 1);
  }

  deletetowho(index) {
    this.towho.splice(index, 1);
  }
  deletereq(index) {
    this.reqs.splice(index, 1);
  }

  addTowho(toWho, index?) {
    if (index <= this.towho.length) {
      console.log('dentro');
      for (let i = 0; i < this.reqs.length; i++) {
        if (i == index) {
          this.towho[i] = toWho;
        }
      }
      console.log(this.towho);
    } else {
      console.log(this.towho);
      this.towho.push(toWho);
      this.editObj[this.towho.length + 1] = false;
    }

  }

  addReqs(requisites, index?) {
    if (index <= this.reqs.length) {
      for (let i = 0; i < this.reqs.length; i++) {
        if (i == index) {
          this.reqs[i] = requisites;
        }
      }
    } else {
      this.reqs.push(requisites);
      this.editReq[this.reqs.length + 1] = false;
    }

  }

  saveFlyer() {

    this.landingData.saveFlyer(this.flyerName, this.keys, this.desc, this.objs, this.towho, this.reqs, this.flyerimage, this.flyerPrice, this.flyerOffer, this.lab["id"]).subscribe(data => {
      window.location.reload();
    });
  }

  Empresas = [
    {
      "image": "../../../assets/img/universities/nhs.png",
      "name": "National Health Service"
    },
    {
      "image": "../../../assets/img/universities/abot.png",
      "name": "Abbott Pharmaceuticals"
    },
    {
      "image": "../../../assets/img/universities/cisco.png",
      "name": "Cisco Telecomunicación"
    },
    {
      "image": "../../../assets/img/universities/ac.png",
      "name": "AC Hotels"
    },
    {
      "image": "../../../assets/img/universities/santander2.jfif",
      "name": "Banco Santander"
    },
    {
      "image": "../../../assets/img/universities/ford3.png",
      "name": "Ford Motor Company"
    },
    {
      "image": "../../../assets/img/universities/shell.png",
      "name": "Shell Oil Corporation"
    },
    {
      "image": "../../../assets/img/universities/uni2.png",
      "name": "Unilever Consumer Group"
    },
    {
      "image": "../../../assets/img/universities/pyg2.jfif",
      "name": "Procter & Gamble"
    },
    {
      "image": "https://i.udemycdn.com/partner-logos/booking-logo.svg",
      "name": "Procter & Gamble"
    },
  ];

  filters: boolean;
  lab: any = [];
  list: any = [];
  carts: any = [];
  keys: any;
  objs: any = [];
  reqs: any = [];
  towho: any = [];
  desc: string;
  flyerimage: string = '';
  indexEdit: number;
  updated;
  arrayupdated = [];
  rating: any;
  reviews: any = [];
  count: any;
  numStudents: any;
  roundRating: any;
  roundRatingElse: any;
  starsPercent = new Array(5);
  barPercent = new Array(5);
  discount: number;
  loading = true;
  adq = false;
  editfieldobj = false;
  editfieldwho = false;
  editfieldreq = false;
  editfieldesc = false;
  levelFilter: number = 0;

  labSearch;


  loadLab(lab) {

    this.flyerName = lab.name;
    this.flyerOffer = lab.offer_price;
    this.flyerPrice = lab.price;
    this.keys = lab.keylines;
    this.objs = lab.objectives.split(",");
    this.reqs = lab.requirements.split(",");
    this.towho = lab.to_whom.split(",");
    this.desc = lab.description;
    this.flyerimage = lab.picture;
  }

  aplicar:boolean;
  searchLab(facultyName?, degreeName?, labName?) {
    this.aplicar = true;
    this.userData.getChargeFlyerInfo(labName, facultyName, degreeName, this.levelFilter).subscribe(data => {
      this.labSearch = data;
    });

  }
  setLevel(level) {
    this.levelFilter = level;
  }
  assignIndex(index) {
    this.indexEdit = index;
  }

  addName(name) {
    this.flyerName = name;
  }
  addKey(key) {
    this.keys = key;
  }
  addObj(obj) {
    if (this.indexEdit >= this.objs.length) {
      this.objs.push(obj);
    } else {
      this.objs[this.indexEdit] = obj;
    }

  }
  addWho(who) {
    if (this.indexEdit >= this.towho.length) {
      this.towho.push(who);
    } else {
      this.towho[this.indexEdit] = who;
    }

  }
  addReq(req) {
    if (this.indexEdit >= this.reqs.length) {
      this.reqs.push(req);
    } else {
      this.reqs[this.indexEdit] = req;
    }

  }
  addDesc(desc) {
    this.desc = desc;
  }
  getFlyer() {
    console.log('dentro');
    this.activatedRoute.params.subscribe(params => {

      this.landingData.getFlyer(params.id).subscribe(data => {
        this.lab = data['lab'][0]; console.log('lab', this.lab);
        localStorage.setItem("lab_id", this.lab["id"]);
        this.arrayupdated = this.lab.updated_at.split(":", 2);
        this.updated = this.arrayupdated[0] + ':' + this.arrayupdated[1];
        this.list = data['list'][0];
        this.count = data['count'];
        this.reviews = data['reviews'];
        this.flyerName = this.lab.name;
        this.flyerOffer = this.lab.offer_price;
        this.flyerPrice = this.lab.price;
        console.log(this.reviews);
        this.rating = data['avg_rating'];
        for (let i = 0; i < 5; i++) {
          this.starsPercent[i] = data['stars'][i];
        }
        for (let i = 0; i < 5; i++) {
          this.barPercent[i] = this.starsPercent[i] + '%';
        }
        this.roundRating = new Array(Number(Number(this.rating).toFixed(0)));
        this.roundRatingElse = new Array(5 - Number(Number(this.rating).toFixed(0)));

        this.numStudents = data['num_students'];
        this.sessData.currentFlyerId = this.lab['id'];
        this.keys = this.lab.keylines;
        this.objs = this.lab.objectives.split(",");
        this.reqs = this.lab.requirements.split(",");
      // this.towho = this.lab.to_whom.split(",");//No se va a usar??
        this.desc = this.lab.description;
        this.flyerimage = this.lab.picture;


        this.starsPercent
        if (this.sessData.totalOffer == this.sessData.total) {
          this.discount = 0;
        } else {
          this.discount = Number((100 - this.sessData.totalOffer * 100 / this.sessData.total).toPrecision(2));
        }

        this.adq = data['adq'];
        this.loading = false;
      });
      // this.sessData.currentFlyerId = params.id;
      // this.userData.getSearchAutoFill(this.flyerName).subscribe(data => {
      //   this.fillAuto = data;
      //   console.log(data);
      // });

    });

  }

  fillAuto;
  labsRecommended: any;
  loadingRecommended = true;
  total_price: number = 0;
  total_offerprice: number = 0;
  lab1RecommendedPrice: number = 0;
  lab2RecommendedPrice: number = 0;
  lab1RecommendedOfferPrice: number = 0;
  lab2RecommendedOfferPrice: number = 0;



  animacionFavourite = false;





  rateOk: boolean = false;




  cardFlyer = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 338) {
      this.cardFlyer = true;
    } else if (number < 338) {
      this.cardFlyer = false;
    }

  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {

    });
    this.getFlyer();



    this.imageChangedEvent = this.flyerimage;



    let percentage = Math.floor(100);


  }
  imageChangedEvent: string = '';
  croppedImage: string = '';
  imgcrop: boolean;

  fileChangeEvent(event?: any): void {
    if (!event) {
      this.croppedImage = null;
      // añadir pop-up en vez del console.log
      console.log('No has seleccionado ninguna imagen');
      return;
    }

    if (event) {
      this.imageChangedEvent = event;
      return;
    }
  }





  changeImage() {
    this.flyerimage = this.croppedImage;
  }

  imageCropped(event) {
    this.croppedImage = event.base64;
    console.log(this.croppedImage);
  }

}

