import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStax',
  pure: false
})
export class FilterStaxPipe implements PipeTransform {

  transform(stax: any[], currentStaxType: String): any {
    if (!stax || stax.length == 0 || !currentStaxType) {
      return stax;
    }
    
    stax =  stax.map(stack => {
      stack.stacks = stack.stacks.filter(st => st.stax_type == currentStaxType);
      return stack;
    });

    return stax;
  }

}
