import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { environment, roles } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  rolesAux;
  formulario: UntypedFormGroup;
  constructor(public auth: AuthService, private http: HttpClient, private router: Router) {
    this.formulario = new UntypedFormGroup({
      'incidencias': new UntypedFormControl('', Validators.required)
    });
    this.rolesAux = roles;
  }
  incidenciaHecha = false;
  offcanvas = false;
  catTree: any = [];
  campus: number = 0;
  cattree = true;
  Cards: any = [];
  id: string = "4";
  otros = false;
  tecnicos: boolean = false;
  sugerencias = false;
  focused: boolean = true;

  onClickedOutside() {
    this.focused = false;
  }
  url: string;
  refresh(): any {
    this.url = this.router.url;
    if (this.url == '/') {
      window.location.reload();
    } else {
      setTimeout(() => [this.router.navigate(['/']), window.location.reload()], 0.05);
    }
  }

  isAuth(): boolean {
    return this.auth.isAuthenticated();
  }

  logOut() {
    this.http
      .post(`${environment.apiUrl}/api/auth/logout`, {
        token: `${localStorage.getItem("access_token")}`
      });
    this.auth.logout();
    this.auth.isAuthenticated();
    // setTimeout(this.refresh(), 100);
  }

  quit_sesion = true;
  quitSess(to, event) {
    if (!event.ctrlKey) {
      if (to != this.auth.workingArea) {
        if (this.auth.workingArea != undefined && this.auth.workingArea != 'landing' && !this.isAuth()) {
          this.router.navigate(['/landing']);
          return;
        }
        let navigate = '/' + to + '/';
        this.router.navigate([navigate]);
      }
    } else {
      let navigate = '/' + to + '/';
      const url = this.router.serializeUrl(this.router.createUrlTree([navigate]));
      window.open('#' + url, '_blank');
    }
  }

  colaborador: boolean;
  ngOnInit() {
    // if (this.auth.checkRole() == roles.colaboratorRole) {
    //   this.colaborador = true;
    // }
  }
}
