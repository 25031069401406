import { ErrorHandler, Injectable } from '@angular/core';
import { UserDataService } from './services/user-data.service';

@Injectable({
    providedIn: 'root'
})
export class AppGlobalErrorhandler implements ErrorHandler {

    constructor(private _user: UserDataService) { }

    ngOnInit() {
    }

    handleError(error) {
        let send_error = null;
        try {
            send_error = error.stack;
        } catch (error) {
        }
        console.error(error);

        // this._user.saveError(send_error, '').subscribe(data => {
        // },
        //     err => {
        //     });
    }

}