import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { KeyValue } from "@angular/common";

@Component({
  selector: 'app-activity-profile',
  templateUrl: './activity-profile.component.html',
  styleUrls: ['./activity-profile.component.css']
})
export class ActivityProfileComponent implements OnInit {


  successfullySave: boolean = false;
  message = '¡GUARDADO!';
  activityProfile;

  constructor(private admin: AdminService){}

  ngOnInit(): void {
    

    this.getActivityProfile();

  }


  getActivityProfile(){

    let campus_id = 2;

    
    this.admin.getActivityProfiles(campus_id, 'learning_path').subscribe((data: any) => {
        this.activityProfile = data;      
    });

  } 


  trackByFn(value: any) { //Actualizar solo los valores que han cambiado 
    return value;
  }


  setActivityValues(pod, category, cat, index?){

    if (category != 'difficulty'){
      pod[category][index].value = String(cat.value);
    } else{
        pod[category] =  String(cat.value);
    }

    this.admin.updateActivityProfile(pod.campus_id, pod.podtype_id, pod.resources[0].value,  pod.resources[1].value,  pod.resources[2].value, pod.resources[3].value, pod.resources[4].value, pod.resources[5].value, pod.skills[0].value, pod.skills[1].value, pod.skills[2].value, pod.skills[3].value, pod.skills[4].value, pod.difficulty).subscribe(data => {
    
    });

  }

}
