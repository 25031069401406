<div style="height: 100vh" class="bg-login w-100">
    <div class="h-100 position-relative w-100">
        <div class="row  px-5 pt-3 no-gutters justify-content-start w-100">
            <div class="navbar-brand">
                <div>
                    <span class="altea-title">ALTEA</span>
                    <span class="labs-title">Labs</span>
                </div>
            </div>
        </div>
        <div style="height: calc(100% - 360px);" class="w-100 d-flex">
            <form [formGroup]="logIn" (ngSubmit)="LogIn()" class="col-md-5 p-0 my-auto mx-auto pt-5">
                <div *ngIf="formValid" class="form-control-feedback text-danger mx-5">
                    El email o el password no son correctos
                </div>
                <div class="form-group" [ngClass]="{'text-danger': !logIn.controls['email'].pristine && !logIn.controls['email'].valid }">
                    <label for="email" class="px-1">Email</label>
                    <input id="loginemail" class="form-control bg-light" type="email" placeholder="Email" formControlName="email" [ngClass]="{'border border-danger': !logIn.controls['email'].pristine && !logIn.controls['email'].valid }">

                    <div *ngIf="logIn.controls['email'].errors?.required && !logIn.controls['email'].pristine" class="form-control-feedback text-danger">
                        Este campo es requerido
                    </div>
                    <div *ngIf="!logIn.controls['email'].valid && !logIn.controls['email'].pristine" class="form-control-feedback text-danger">
                        No tiene formato de email válido
                    </div>
                </div>
                <div class="form-group" [ngClass]="{'text-danger': !logIn.controls['password'].pristine && !logIn.controls['password'].valid }">
                    <label for="password" class="px-1">Password</label>
                    <input id="loginpassword" class="form-control bg-light" type="password" placeholder="Password" formControlName="password" [ngClass]="{'border border-danger': !logIn.controls['password'].pristine && !logIn.controls['password'].valid }">

                    <div *ngIf="logIn.controls['password'].errors?.required && !logIn.controls['password'].pristine" class="form-control-feedback text-danger">
                        Este campo es requerido
                    </div>
                    <div *ngIf="logIn.controls['password'].errors?.minlength && !logIn.controls['password'].pristine" class="form-control-feedback text-danger">
                        Al menos 8 caracteres
                    </div>
                </div>
                <div class="text-center">
                    <button [disabled]="!logIn.valid" [routerLink]="[ '/group-admin' ]" [ngClass]="{'btn-outline-accent': !logIn.valid, 'btn-accent': logIn.valid }" class="btn btn-sm" type="submit">Login</button>
                </div>
            </form>
        </div>
        <h3 style="top: 0; left: 0; right: 0; bottom: 0; width: max-content; height: max-content; letter-spacing: inherit; font-weight: 600; margin-bottom: 12%;" class="position-absolute mt-auto mx-auto">ADMIN</h3>
    </div>
</div>