<div class="h-100" style="padding: 1% 0%;position: absolute; display: flex; transition-duration: 600ms; transition-timing-function: ease-in-out; color: grey; width: 85%;" [appTooltip]="480" [ngStyle]="{'left': admin.sidebarShow ? '15%' : '4%',  'width': admin.sidebarShow ? '85%' : '96%'}">
    <div style="width: 45%; flex-direction: column;" class="h-100 d-flex">
        <!-- <div style="height: 60%; margin-right: 1%; padding: 0 0% 1.5% 0%;"  [ngStyle]="{'filter': selectedProspect != undefined? 'blur(6px)': ''}"> -->
        <div style="height: 60%; margin-right: 1%; padding: 0 0% 1.5% 0%;" [appTooltip]="481" >
            <div style="background-color: white; padding: 2.1%; height: 50%;" [appTooltip]="482">
                <div class="w-100 d-flex" [appTooltip]="483">
                    <div class="title h-100" [appTooltip]="484">
                       Organización
                    </div>
                    <div *ngIf="removedProspect == true" class="savedMessage" style="justify-content: center;"> Prospecto eliminado </div>
                </div>
                <div  style="height: 84%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex" [appTooltip]="485">
                    <div class="w-100 d-flex" [appTooltip]="486">
                        <div style="width: 72%;" class="h-100 title2" [appTooltip]="487">
                            Nombre
                        </div>
                        <div style="width: 18%;" class="h-100 title2" [appTooltip]="488">
                            Empresa
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="flex-direction: column; overflow-y: scroll;" [appTooltip]="489">
                        <div *ngFor="let user of prospectUsers; let i=index" class="h-100 w-100 d-flex" [appTooltip]="490" (click)="selectProspect(user);" [ngClass]="{'selected-prospect': user == selectedProspect, 'selected-clone': cloneMode == true && targetClone == user}">
                            <div style="width: 72%; cursor: pointer;"  class="prospectInfo text-truncate" [appTooltip]="491">
                                {{user.prospect_name}}
                            </div>
                            <div style="width: 24%; cursor: pointer;" class="prospectInfo d-flex" [appTooltip]="492">
                                {{user.company_name}}
                            </div>
                            <i class="fas fa-times px-1 my-auto delete" (click)="removeProspectFromAnalysis(user.prospect_id, i)" [appTooltip]="493"></i>
                            <div *ngIf="cloneMode == true" style="font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex" (click)="targetClone = user; cloneSurveys()" [appTooltip]="494">
                                <img src="https://img.icons8.com/external-flat-icons-inmotus-design/67/203341/external-clone-clone-flat-icons-inmotus-design-2.png"  style="width: 36%; margin: auto;">
                            </div>     
                        </div>
                    </div>
                </div>
            </div>
            <div style="background-color: white; padding: 2.1%; height: 50%; " [appTooltip]="495">
                <div class="w-100 d-flex" [appTooltip]="496">
                    <div class="title h-100" [appTooltip]="497">
                        Empresas a revisar
                    </div>
                    <button class="newButton" type="submit" [appTooltip]="498" *ngIf="arrayCompanies.length != 0" (click)="addCompanies()"> Guardar </button>
                    <div *ngIf="savedCompanies == true" class="savedMessage" style="justify-content: center;">  {{warningMessage}} </div>
                </div>
                <div  style="height: 84%; margin: 1% 0; flex-direction: column;" class="w-100 d-flex" [appTooltip]="499">
                    <div class="w-100 d-flex" [appTooltip]="500">
                        <div style="width: 79%;" class="h-100 title2">
                            Empresa
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="flex-direction: column; overflow-y: scroll;" [appTooltip]="501">
                        <div *ngFor="let company of companies; let i=index" class="h-100 w-100 d-flex" >
                            <div style="width: 96%; cursor: pointer;"  class="prospectInfo text-truncate" *ngIf="editCompany == false" [appTooltip]="502">
                                {{company.company_name}}
                            </div>
                            <div  style="width: 93%; cursor: pointer;"  class="prospectInfo text-truncate" *ngIf="editCompany == true" [appTooltip]="503">
                                <input style="border: none; outline: none; color: #808080; width: 48%"  [(ngModel)]="company.company_name">
                            </div>
                            <div style="color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer; margin: auto" class="d-flex" >
                                <img src="https://img.icons8.com/windows/32/b5b5b5/edit--v1.png" style="width: 57%;" (click)="editCompany = true" [appTooltip]="504">          
                            </div>
                            <div style="color: rgb(32, 51, 65); font-weight: 900; font-size: 1.8vh; cursor: pointer; margin: auto;" class="d-flex" [appTooltip]="505" *ngIf="editCompany == true" (click)="addCompanies(company)">
                                +      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 40%; margin-right: 1%;" [appTooltip]="506">
            <div class="h-100 w-100 bg-white" style="padding: 2%; position: relative;">
                <div class="w-100 d-flex" [appTooltip]="507">
                    <div class="title h-100 d-flex" [appTooltip]="508">
                        Encuestas  
                        <span *ngIf="selectedProspect != undefined" style="margin: 0 1.2%;" [appTooltip]="509">
                                {{selectedProspect.prospect_name}}
                        </span>
                    </div>     
                    <div *ngIf="selectedProspect != undefined" style="font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex" (click)="createFolder()" [appTooltip]="600">
                        <img src="https://img.icons8.com/metro/52/203341/add-folder.png"  style="width: 36%; margin: auto;">
                    </div>     
                    <div *ngIf="selectedProspect != undefined" style="font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex" (click)="cloneMode = true" [appTooltip]="601">
                        <img src="https://img.icons8.com/external-flat-icons-inmotus-design/67/203341/external-clone-clone-flat-icons-inmotus-design-2.png"  style="width: 36%; margin: auto;">
                    </div>     
                    <div style="color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex" *ngIf="selectedProspect != undefined" >
                        <img src="https://img.icons8.com/windows/32/203341/visible--v1.png" style="width: 72%; margin: auto;" (click)="selectedProspect = undefined; closedSurveys = false" [appTooltip]="602">          
                    </div>
                    <!-- <button class="newButton" type="submit" (click)="createSurvey()"> Nueva encuesta </button> -->
                    <div *ngIf="savedClosedSurvey == true" class="savedMessage" style="justify-content: center;">  {{warningMessage}} </div>
                </div>
                <div style="margin: 1% 0; flex-direction: column;" class="w-100 d-flex" [appTooltip]="603">
                    <div class="d-flex w-100" style="flex-direction: column;">
                        <div class="w-100 d-flex" [appTooltip]="604">
                            <div style="width: 81%;" class="h-100 title2">
                                File
                            </div>
                        </div>
                        <div class="w-100 d-flex" style="flex-direction: column; overflow-y: scroll;" [appTooltip]="605">
                            <div *ngFor="let folder of surveysFiles; let i=index" style="flex-direction: column;" class="h-100 w-100 d-flex" (click)="selectedSurveyFolder = folder" [appTooltip]="606">
                                <div class="w-100 d-flex" [ngClass]="{'selected-prospect': selectedSurveyFolder != undefined &&  selectedSurveyFolder == folder}" [appTooltip]="607">
                                    <div style="width: 3%; color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex" [appTooltip]="608">
                                        <img *ngIf=" selectedProspect.files[folder].closed == '0'" src="https://img.icons8.com/ios/50/203341/lock--v1.png" [appTooltip]="609" style="width: 81%; margin: auto;" (click)="closeSurveyFile(folder)">
                                        <img  *ngIf=" selectedProspect.files[folder].closed == '1'" src="https://img.icons8.com/ios-filled/50/203341/lock.png" [appTooltip]="610" style="width: 81%; margin: auto;">
                                    </div>
                                    <!-- <div style="width: 96%; cursor: pointer;" class="prospectInfo text-truncate" (dblclick)="editFolder = folder" > -->
                                    <div style="width: 96%; cursor: pointer; display: flex; align-items: center;" class="prospectInfo text-truncate" [appTooltip]="611">
                                        <input *ngIf="editFolder != undefined && editFolder == folder" type="text" [appTooltip]="612" style="background-color: transparent; border: none; border-radius: 0;  border-bottom: 1px solid rgb(213, 213, 213); width: 60%;" [value]="folder" (keyup)="createFolderName($event.target.value, folder)" (keyup.esc)="editFolder = undefined">
                                        <!-- <input *ngIf="editFolder != undefined && editFolder == folder" type="text" [value]="folder" (keyup)="createFolderName($event.target.value, folder)" (keyup.enter)="changeFolderName($event.target.value, i)" (keyup.esc)="editFolder = undefined"> -->
                                        <span *ngIf="editFolder != folder" [appTooltip]="613">
                                            {{folder}}
                                        </span>
                                        <div *ngIf="editFolder != undefined && editFolder == folder" [appTooltip]="614" class="newButton d-flex justify-content-center" style="margin-left: auto; width: 9%;" (click)="createSurveyFile(folderName)">
                                            Save
                                        </div>
                                    </div>
                                    <div class="add mx-auto my-auto"  (click)="createSurvey()" [appTooltip]="615"> + </div>
                                </div>
                                <div class="w-100 d-flex" style="flex-direction: column; overflow-y: scroll;" *ngIf="selectedSurveyFolder != undefined && selectedSurveyFolder == folder" [appTooltip]="616">
                                    <div *ngFor="let surveyAux of selectedProspect.files[selectedSurveyFolder].surveys; let j=index" class="h-100 w-100 d-flex" [ngClass]="{'selected-prospect': survey != undefined && surveyAux.survey_id == survey.survey_id}">
                                        <div style="width: 96%; cursor: pointer; margin-left: 3%;" class="prospectInfo text-truncate" [appTooltip]="617">
                                            {{surveyAux.survey_name}}
                                        </div>
                                        <div style="color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer;" class="d-flex" >
                                            <img src="https://img.icons8.com/windows/32/203341/visible--v1.png" style="width: 72%;" (click)="editText = false; openSurveyModal(surveyAux)" [appTooltip]="618">          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="width: 55%;" class="h-100" [appTooltip]="619">
        <div style="height: 100%; margin: 0% 2% 0% 1%;">
            <div class="h-100 w-100 bg-white" style="padding: 2%; position: relative;" >   
                <div class="w-100 d-flex" [ngStyle]="{'filter': survey == undefined ? 'blur(6px)': ''}" [appTooltip]="620"> 
                    <div class="title h-100" [appTooltip]="621">
                        <span  *ngIf="survey != undefined && survey.survey_name != undefined">
                            {{survey.survey_name }}
                        </span>
                    </div>
                    <div style="font-weight: 900; font-size: 1.5vh; cursor: pointer;  width: 12%;" class="d-flex" *ngIf="survey != undefined &&  survey.prompts != 0" [appTooltip]="622">
                        <div style="color: #203341; display: flex; align-items: center; margin: 0 9%; font-family: times new roman;" [appTooltip]="623" *ngFor="let prompt of survey.prompts; let j = index" (mouseenter)="showPromptNumber = j; showPrompt = true" (mouseleave)="showPrompt = false">
                            {{j+1}}
                        </div>
                    </div>
                    <div style="color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer; margin: auto" class="d-flex"  >
                        <img src="https://img.icons8.com/windows/32/203341/visible--v1.png" style="width: 72%;" (click)="editText = false;" [appTooltip]="624">
                    </div>
                    <div style="color: white; font-weight: 900; font-size: 1.8vh; cursor: pointer; margin: auto" class="d-flex" >
                        <img src="https://img.icons8.com/windows/32/203341/edit--v1.png" style="width: 72%;" (click)="editText = true" [appTooltip]="625">          
                    </div>
                    <button class="newButton" type="submit" (click)="saveSurvey()" [appTooltip]="626"> Save </button>
                    <div *ngIf="savedSurvey == true" class="savedMessage" style="justify-content: center;">  {{warningMessage}} </div>
                </div>
                <div class="d-flex" style="width: 100%; padding: 1.5% 0; height: 96%; flex-direction: column;" *ngIf="survey != undefined" [appTooltip]="627">
                    <div  *ngIf="editText == true" class="w-100" [appTooltip]="628">
                        <textarea style="padding: 0.9%;" class="w-100 textarea-box" [appTooltip]="629" [(value)]="survey.survey_name" placeholder="Survey name" (change)="editSurvey('title', $event.target.value)"></textarea>
                    </div>
                    <div  *ngIf="editText == true" class="w-100 d-flex" style="margin: 1.5% 0; height: 12%; justify-content: centers;" [appTooltip]="630">
                        <textarea  style="padding: 0.9%; align-items: center;" class="w-100 textarea-box d-flex" placeholder="Prompt" [(value)]="survey.prompt" (change)="editSurvey('prompt', $event.target.value)"></textarea> 
                    </div>
                    <div  *ngIf="showPrompt == true && editText == false " class="w-100 d-flex" style="justify-content: center;" [appTooltip]="631">
                        <div class="text-center d-flex prompt">  
                            {{survey.prompts[showPromptNumber]}}
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="height: 93%;" [appTooltip]="480">
                        <textarea  *ngIf="editText == true" style="padding: 0.9%;"  class="w-100 h-100 textarea-box" placeholder="List" [(value)]="survey.text" (change)="editSurvey('text', $event.target.value)" [appTooltip]="632"></textarea>
                        <div class="d-flex w-100 drag-list" *ngIf="editText == false" cdkDropList (cdkDropListDropped)="drop($event)" [appTooltip]="633">
                            <div *ngFor="let bubble of survey.bubbles; let k = index" class="d-flex w-100 drag-option" >
                                <div class="number" [appTooltip]="634">
                                    {{k+1}}. 
                                </div>
                                <div class="bubble-box" cdkDrag [appTooltip]="635">
                                    {{bubble}}
                                </div>
                                <div style="display: flex; align-items: center;">
                                    <i class="fas fa-times px-1 my-auto delete" (click)="deleteBubble(k)" [appTooltip]="636"></i>         
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="height: 6%;" *ngIf="editText == false" [appTooltip]="367">
                        <input type="text" [(ngModel)]="newBubble" (keyup.enter)="editSurvey('bubble', $event.target.value)" [appTooltip]="638">
                        <img src="https://img.icons8.com/windows/32/203341/sent.png" style="margin: 1%; cursor: pointer;" (click)="editSurvey('bubble', newBubble)" [appTooltip]="639">
                    </div>
                </div>  
            </div>
        </div>
    </div>
    <div class="modal fade" id="surveys" tabindex="-1" role="dialog" aria-labelledby="surveys" aria-hidden="true" data-backdrop="static" data-keyboard="false" style="background-color: rgb(32, 51, 65);">
        <div class="modal-dialog"  style="overflow: hidden; height: 90%; max-width: 39%; background-color: white; padding: 1.5%; top: 6%; margin: auto">
            <div class="modal-content h-100" [appTooltip]="640">
                <div class="d-flex" [appTooltip]="641">
                    <div  style="width: 6%; margin-right: 3%; align-items: center; display: flex" >
                        <img src="../../../assets/img/blue-logo-2.png" [appTooltip]="642">
                    </div>
                    <div class="h-100" style="width: 90%;font-weight: 600; font-size: 1.5vh; color: #203341; display: flex; justify-content: center; align-items: center; text-transform: uppercase;" [appTooltip]="643" *ngIf="survey != undefined && survey.survey_name != undefined">
                        {{ survey.survey_name }}      
                    </div>
                    <div style="font-weight: 900; font-size: 1.5vh; cursor: pointer;  width: 12%;" class="d-flex" *ngIf="survey != undefined &&  survey.prompts != 0" [appTooltip]="644">
                        <div style="color: #203341; display: flex; align-items: center; margin: 0 9%; font-family: times new roman;"  *ngFor="let prompt of survey.prompts; let j = index" (mouseenter)="showPromptNumber = j; showPrompt = true" (mouseleave)="showPrompt = false">
                            {{j+1}}
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; margin: 0 0.6%;" class="newButton"  (click)="saveSurvey()" [appTooltip]="645">
                        Save
                    </div>
                    <div style="display: flex; align-items: flex-end; margin-left: auto;">
                        <i class="fas fa-times px-1 my-auto" style="cursor: pointer; color: rgb(181, 181, 181); font-size: 1.5vh;" (click)="closeModal()" [appTooltip]="646"></i>         
                    </div>
                </div>
                <div class="d-flex" style="width: 100%; padding-top: 3%; height: 96%; flex-direction: column;" *ngIf="survey != undefined" [appTooltip]="647">
                    <div  *ngIf="showPrompt == true" class="w-100 d-flex" style="justify-content: center;" [appTooltip]="648">
                        <div class="text-center d-flex prompt">  
                            {{survey.prompts[showPromptNumber]}}
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="height: 93%;" [appTooltip]="649">
                        <div class="d-flex w-100 drag-list" cdkDropList (cdkDropListDropped)="drop($event)" [appTooltip]="650">
                            <div *ngFor="let bubble of survey.bubbles; let k = index" class="d-flex w-100 drag-option" >
                                <div class="number" [appTooltip]="651" >
                                    {{k+1}}. 
                                </div>
                                <div class="bubble-box" [appTooltip]="652" cdkDrag (mouseenter)="dropNumber = k" (mouseleave)="dropNumber = -1"  [ngStyle]="{'background-color': dropNumber == k ? 'rgb(32, 51, 65)': '', 'color': dropNumber == k ? 'white': ''}">
                                    {{bubble}}
                                </div>
                                <div style="display: flex; align-items: center; margin-left: 0.9%;">
                                    <i class="fas fa-times px-1 my-auto delete" [appTooltip]="653" (click)="deleteBubble(k)"></i>         
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 d-flex" style="height: 6%; margin-top: auto;" [appTooltip]="654">
                        <input type="text" [(ngModel)]="newBubble" (keyup.enter)="editSurvey('bubble', $event.target.value)" [appTooltip]="655">
                        <img src="https://img.icons8.com/windows/32/203341/sent.png" style="margin: 1%; cursor: pointer;" (click)="editSurvey('bubble', newBubble)" [appTooltip]="656">
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>