<div class="h-100 w-100">
    <div *ngIf="phrasesFilters != undefined" class="position-relative py-2 px-3 row no-gutters d-flex" style="border: none !important; background-color: #F2F2F2;  height: 9%; justify-content: space-between;" [appTooltip]="961">
        <div *ngIf="successfullySave == true">
            <div class="saveMessage"> {{message}} </div>
        </div>
        <div class="d-flex" style="width: 85%;">
            <div class="mx-2 my-auto row no-gutters" style="width: 9%; font-size: 1.5vh; height: 75%;"  [appTooltip]="962">
                <select class="inputEdit my-auto mx-auto w-100" style="color: grey; cursor: pointer; text-align: inherit!important;"  [(ngModel)]="branch" (change)="selectBranch()" >
                    <option style="color: #6e6e6e;" value="" disabled selected hidden> Ámbito </option>
                    <option  *ngFor="let branch of phrasesFilters; let i = index" [value]="i" [ngStyle]="{'color': branch.length != 0 ? '#A8143E' : '#d5d5d5', 'font-weight': branch.length != 0 ? '600' : '500'}" >
                         {{branch.campus_name | titlecase}}
                    </option>
                </select>
            </div>
            <div *ngIf="branch != undefined" class="mx-2 my-auto row no-gutters" style="width: 15%; font-size: 1.5vh; height: 75%;"  [appTooltip]="963">
                <select class="inputEdit my-auto mx-auto w-100" style="color: grey; cursor: pointer; text-align: inherit!important;" [(ngModel)]="level" (change)="selectLevel(level); openArea = true">
                    <option style="color: #6e6e6e;" value="" disabled selected hidden> Nivel </option>
                    <option *ngFor="let level of phrasesFilters[branch][levelCampus]; let j = index" [ngValue]="level" style="font-weight: 600" [ngStyle]="{'color': level.phrases != undefined ? '#A8143E' : '#d5d5d5'}" >                     
                       <span *ngIf="levelCampus == 'levels'"> {{j + 1}} </span>
                       <span *ngIf="levelCampus == 'subject'"> {{level.name}} </span>
                    </option>
                    <option style="color: #6e6e6e;" value="All"> All </option>
                </select>
            </div>
            <div *ngIf="addingSubarea" class="mx-2 my-auto row no-gutters"  [appTooltip]="964">
              <div class="my-auto">
                <input type="text" name="" id="" (keyup.enter)="saveSubArea($event.target.value)" (clickOutside)="addingSubarea = false">
              </div>
            </div>
            <div class="d-flex my-auto row no-gutters" style="font-size: 1.5vh; height: 75%;" cdkDropList (cdkDropListDropped)="drop($event)">
              <div *ngFor="let filter of newPhrasesFilters; let i = index" class="h-100 d-flex my-auto mx-2" cdkDrag >
                  <select *ngIf="filter.newArea != undefined && filter.newArea == false" class="inputEdit my-auto mx-auto w-100" style="color: grey; cursor: pointer; text-align: inherit!important;"  [appTooltip]="909"  [(ngModel)]="filterSelected[i]" (change)="newSubArea($event, i, filter)" (dblclick)="filter.newArea = true; editOptions = true">
                    <option   style="color: #6e6e6e;"  value=""  disabled selected hidden> {{filter.filter_name}} </option>
                    <option *ngFor="let option of filter.options; let j = index" [ngValue]="option" style="font-weight: 600; color: grey" >
                        {{option.option_name}}
                    </option>
                    <option style="color: #6e6e6e;" value="New" > + New </option>
                    <option style="color: #6e6e6e;" value="Remove"> Remove </option>
                  </select>
                  <div *ngIf="filter.newArea != undefined && filter.newArea == true" class="mx-2 my-auto row no-gutters"  (clickOutside)="filter.newArea = false;">
                      <div class="my-auto">
                        <input *ngIf="editOptions == false" type="text" name="" id="" [appTooltip]="910"  (keyup.enter)="addNewFilterOptions($event.target.value, filter);"  [appTooltip]="961">
                        <input *ngIf="editOptions == true" type="text" value="{{filterSelected[i].option_name}}" [appTooltip]="911"  name="" id="" (keyup.enter)="editFiltersOptions($event.target.value, filterSelected[i]); filter.newArea = false; editOptions = false"  [appTooltip]="961">
                      </div>
                  </div>
              </div>
            </div>
  
            <div class="mx-2 my-auto row no-gutters" style="font-size: 1.5vh; height: 75%;">
              <div *ngIf="newFilter == false" class="add my-auto" (click)="newFilter = true;" [appTooltip]="913">
                  +
              </div>
              <div *ngIf="newFilter == true" class="mx-2 my-auto row no-gutters" [appTooltip]="914">
                  <div class="my-auto">
                    <input type="text" name="" id=""  (keyup.enter)="addNewFilter($event.target.value, filter)">
                  </div>
              </div>
            </div>
            <div *ngIf="arrayFiltersSelected != 0" class="mx-2 my-auto  row no-gutters" style="width: auto; font-size: 1.5vh; height: 75%; color: grey;" [appTooltip]="965">
                <i class="fa-solid fa-filter my-auto" style="cursor:pointer" (click)="getPhrases()"></i>
            </div>
        </div>
        <div class="d-flex" style="width: 15%; margin-left: auto;">
              <div class="mx-2 my-auto row no-gutters inputEdit" style="width: 75%; font-size: 1.5vh; height: 60%; position: relative;">
                <div class="d-flex my-auto mx-auto w-100 my-auto" style="color: grey; cursor: pointer;"  [appTooltip]="916"  (click)="showTotalFilters = !showTotalFilters"  (clickOutside)="showTotalFilters = false">    
                        Filters
                    <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto;"></i>
                </div>
                <div style="height: max-content; flex-direction: column; " class="d-flex dropDown" *ngIf="showTotalFilters == true"  [appTooltip]="917">
                    <div  *ngFor="let filter of filtersImporters; let i = index" class="w-100 dropOptions" style="padding: 0 6%; cursor: pointer; color: grey; position: relative;" 
                    (mouseenter)="showFilterOptions = filter" (mouseleave)="showFilterOptions = undefined" [appTooltip]="918"  (click)="includeFilter(filter);" > 
                        {{filter.filter_name}}
                        <div  class="d-flex dropDown dropDownOptions" *ngIf="showFilterOptions != undefined && filter.filter_id == showFilterOptions.filter_id">
                            <div *ngFor="let option of showFilterOptions.options; let j = index" class="bg-white" style="color: grey;">
                                {{option.option_name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="saveButton my-auto text-center"  (click)="setPhrasesConfig();">
                <span *ngIf="updateExplanation == false" [ngClass]="{'disabled': level == undefined}" [appTooltip]="920"> Save</span>
                <span *ngIf="updateExplanation == true" [appTooltip]="920"> Update</span>
            </div>
        </div>
    </div>
    <div style="width: 96%; margin: auto; height: 90%; color: #6e6e6e" class="d-flex">
        <div style="margin-top: 3%; padding: 1.2%; font-size: 1.5vh; overflow-y: scroll;" [ngStyle]="{'width': admin.sidebarShow ? '39%': '15%'}">
            <div class="h-100 w-100 d-flex" style="flex-direction: column;">
                <div style="height: 6%;" [ngClass]="{'disabled': level == undefined}">
                    <div class="searchBox d-flex" (keyup)="searchPhrasesArea($event.target.value);" [appTooltip]="922">                      
                        <i class="fa-solid fa-magnifying-glass my-auto"></i>
                        <input type="text" class="w-100" style="outline: none; border: none;"  [appTooltip]="923">
                        <div style="height: max-content; flex-direction: column" class="d-flex dropDownSearch" *ngIf="dropDownSearch == true" (clickOutside)="dropDownSearch = false" [appTooltip]="924">
                            <div  *ngFor="let phrase of level.phrases; let i = index" style="cursor: pointer; color: grey;" [appTooltip]="925">
                                <div *ngIf="phrase.found == true">
                                    {{phrase.option_name}}
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div *ngIf="branch != undefined" [appTooltip]="927">
                    <div style="font-size: 1.8vh; font-weight: 500; margin: 3% 0;">
                        {{phrasesFilters[branch].campus_name}} 
                    </div>
                    <div *ngIf="level != undefined" [ngClass]="{'hasContent': level != undefined}" class="d-flex" style="cursor: pointer; font-size: 1.5vh;">
                        {{level.name}}
                        <i class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto; padding: 0 3%;" [appTooltip]="931"  *ngIf="level.phrases != undefined" (click)="openArea = !openArea"></i>
                    </div>
                    <div *ngIf="level != undefined && level.phrases != undefined" style="margin: 1.5% 0% 1.5% 3%;">
                        <div *ngFor="let phrase of level.phrases; let i = index">
                            <div style="cursor: pointer;" class="d-flex" [ngStyle]="{'margin-left': (phrase.indexChild*3) + '%'}" [ngClass]="{'areaSelected': setIndexOptions(phrase) == true}" (click)="selectFiltersFromIndex(phrase)">
                                <div style="margin: 1.5%; padding: 0 3%;" class="d-flex hasContent" (click)="getPhrases(phrase)">
                                    {{phrase.option_name}}
                                </div>
                                <i *ngIf="level.phrases[i+1] != undefined && level.phrases[i].indexChild < level.phrases[i+1].indexChild" class="fa-solid fa-angle-down fas d-flex my-auto" style="margin-left: auto; padding: 0 3%;" ></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="h-100" style="padding: 1.5%; width: 81%;">
            <div class="position-relative py-2 px-3 row no-gutters" style="border: none !important; background-color: #A8143E;">
                <div class="px-1 d-flex w-100" style="color: white;">
                    <img style="height: 35px;" src="../../../assets/img/logo-white.png" alt="Geo Logo" (click)="viewText = !viewText">
                    <div class="w-100 d-flex my-auto">
                        <span *ngIf="branch != undefined"  [appTooltip]="938" style="margin: 0 1.5%;"> {{phrasesFilters[branch].campus_name}}   </span>
                        <span *ngIf="level != undefined"  [appTooltip]="939" style="margin: 0 1.5%;"> {{level.name}}</span>
                        <div *ngFor="let filter of arrayFiltersSelected; let i = index" class="d-flex"  style="margin: 0 1.5%;">
                            <div>{{filter.option_name}}</div>
                            <i class="fas fa-times px-1 my-auto delete" style="cursor: pointer; font-size: 1.2vh;" (click)="removeFilterSelection(filter);"></i>
                        </div>
                    </div>
                    <i class="fe-icon-trash-2 my-auto" style="font-weight: 400; color: white; cursor: pointer; margin: 0 1.5%;" *ngIf="phraseExplanationSelected != undefined" (click)="closeContent()"></i>
                    <div class="d-flex my-auto" style="padding: 0 1.2%; background-color: white;border-radius: 3px; color: #a8143e; cursor: pointer;" [appTooltip]="945" (click)="cleanTextEditor();">
                        Clear
                    </div>
                </div>
            </div>
            <div *ngIf="viewText == true" style="width: 69%; height: 72%; position: absolute;z-index: 9999; border-radius: 6px; box-shadow: 3px 1px 6px 3px #e3e3e3; overflow-y: scroll;" class="bg-white" [appTooltip]="946">
                <div style="padding: 3%;"  [innerHTML]="textEdition | safeDef">  </div>
            </div>
            <ckeditor #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)" (change)="onChange($event)" [(ngModel)]="EditorText" [appTooltip]="947"></ckeditor>
        </div>
    </div>
  </div>
  <div *ngIf="warningMessage == true" class="messagePanel" >
    <div class="d-flex w-100 textMessage" style="height: 63%;">
        <p style="margin-bottom: 0;"> ¿Estás seguro de que desea eliminar el filtro?</p>
    </div>
    <div class="d-flex w-100">
        <div class="deleteConfirmation" (click)="removeFilter()">
            Sí, eliminar filtro
        </div>
        <div class="deleteConfirmation" (click)="closeWarningMessage();">
            No, mantener el filtro
        </div>
    </div>
</div>