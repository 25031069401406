import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-gap',
  templateUrl: './modal-gap.component.html',
  styleUrls: ['./modal-gap.component.css']
})
export class ModalGapComponent {
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer ) { }


  ngOnInit() {
    console.log(this.data)
    this.separateText()
    this.orderRanking()
  }

  ranking
  orderRanking(){
    this.ranking = this.data.gap[3].sort((a, b) => b.times - a.times);
    console.log(this.ranking)
  }



  texts
  textsColored = []

  getFormattedText(text){
    const htmlString = text.join(' ');
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }



  separateText(){
    let indexline = 0
    for (let texts in this.data.text){
      let content = this.data.text[texts].content
      content = content.split(" ")
      let textByLines = []
      let lista = []
      for (let word in content){
        lista.push(content[word])
        if(lista.length >= 15){
          textByLines.push(lista)
          this.data.text[texts].textByLines = textByLines
          lista = []
        }
      }
      console.log(this.data.text[texts].textByLines)
      const gapData = this.data.gap[2];

      for (let i = 0; i < gapData[texts].length; i++){
        for (let j = 0; j < this.data.text[texts].textByLines[i].length; j++){
          this.data.text[texts].textByLines[i][j] = this.data.text[texts].textByLines[i][j].replace(/\./g, ' ');
          this.data.text[texts].textByLines[i][j] = this.data.text[texts].textByLines[i][j].replace(/[^\p{L}\p{N}%$#-]/gu, '');



          if (this.data.text[texts].textByLines[i][j].toLowerCase() == gapData[texts][i].toLowerCase()){
            this.data.text[texts].textByLines[i][j] = "<span style='background-color: lightgreen;'>" + this.data.text[texts].textByLines[i][j] + "</span>"
            indexline ++
          }
        }
      }
  }
}





}





