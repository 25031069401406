import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Subject } from 'rxjs';


@Injectable({
  providedIn: "root"
})
export class UserDataService {

  private notify = new Subject<any>();

  notifyObservable$ = this.notify.asObservable();
  constructor(private http: HttpClient) {
  }

  public notifyExercise(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  // saveError(error, request) {
  //   if (error) {
  //     error = JSON.stringify(error);
  //     request = JSON.stringify(request);
  //   }
  //   return this.http.post(`${environment.apiUrl}/api/user/saveError`, { 'error': error, 'req': request });
  // }
  
  changeEdit(user_id, role_id: string, name: string, last_name: string, email: string, phone: number, office_id: string, image?: string) {
    return this.http.post(`${environment.apiUrl}/api/listadmin/changeProfileUser`, {'id': user_id, 'role_id': role_id, 'name': name, 'last_name': last_name, 'email': email, 'phone': phone, 'office_id': office_id, 'picture': image});
  }

  userCheckPassword(password: string, user_id) {
    return this.http.get(`${environment.apiUrl}/api/user/userCheckPassword`, { params: { 'user_id': user_id, 'password': password } });
  }

  getUserCart(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/cart/getUserCart`, { params: { 'user_id': user_id } });
  }

  getUserWishlist(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/cart/getUserWishlist`, { params: { 'user_id': user_id } });
  }

  getCountries() {
    return this.http.get(`${environment.apiUrl}/api/getCountries`, { params: {} });
  }
  
  insert(user_id: string, lab_id: string) {
    return this.http.put(`${environment.apiUrl}/api/cart/insert`, { 'user_id': user_id, 'lab_id': lab_id });
  }

  insertFavourite(user_id: string, lab_id: string) {
    return this.http.put(`${environment.apiUrl}/api/cart/insertFavouriteList`, { 'user_id': user_id, 'lab_id': lab_id });
  }

  delete(user_id: string, lab_id) {
    return this.http.delete(`${environment.apiUrl}/api/cart/delete`, { params: { 'user_id': user_id, 'lab_id': lab_id } });
  }

  deleteFavourite(user_id: string, lab_id) {
    return this.http.delete(`${environment.apiUrl}/api/cart/deleteFavouriteList`, { params: { 'user_id': user_id, 'lab_id': lab_id } });
  }

  userLabs(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/user/userLabs`, { params: { 'user_id': user_id } });
  }

  getCustomerInfo(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/getCustomerInfo`, { params: { 'user_id': user_id } });
  }



  getCharges(user_id: string) {
    return this.http.get(`${environment.apiUrl}/api/getCharges`, { params: { 'user_id': user_id } });
  }


  sendMail(user_id: string, comment: string, type: string) {
    return this.http.get(`${environment.apiUrl}/api/report/sendMail`, { params: { 'comment': comment, 'user_id': user_id, 'type': type } });
  }

  getSearchAutoFill(nameFlyer: string){
    return this.http.get(`${environment.apiUrl}/api/flyerEditor/searchAutoFill`, { params: { 'name': nameFlyer } });
  }

  getChargeFlyerInfo(labName?, facultyName?, degreeName?, level_id?){
    return this.http.get(`${environment.apiUrl}/api/flyerEditor/chargeFlyerInfo`, { params: { 'labName': labName, 'facultyName': facultyName, 'degreeName': degreeName, 'level_id': level_id } });
  }

}
