<div class="search-container" style="height: 7%;">
  <span *ngIf="data[1].subcat != null">{{data[1].type}} - {{data[1].subcat.subcat_name}}</span>
  <span *ngIf="data[1].subcat == null">{{data[1].type}} - Globals</span>
  <span>NºWords: {{filteredWords.length}}</span>
  <span *ngIf="selectedWords.length > 0">Words Selected: {{selectedWords.length}}</span>

  <button (click)="handleTime()"><i class="fa-regular fa-calendar"></i></button>
  <div style="display: flex; height: 100%;">
  <div style="display: flex; height: 100%;">
<span> <input type="checkbox" [(ngModel)]="Downloadtrans" class="time-checkbox"> Translation</span>
<span><input type="checkbox" [(ngModel)]="DownloadDate" class="time-checkbox">Date</span>
  </div>
  <button (click)="generateTxtFile()" style="border: 2px solid #A8143E;height: 100%;">
  TXT
</button>
</div>
    <button (click)="deleteWordArray()" style="color: black; background-color: rgba(255, 255, 0, 0.315);">DELETE SELECTED</button>
    <button (click)="deleteAllWords()" style="color: white; background-color: #A8143E;">DELETE ALL</button>
  <input type="text" placeholder="Buscar palabra..." [(ngModel)]="searchTerm" class="search-input">
  <i class="fa-regular fa-circle-xmark" style="font-size: 2rem;" (click)="closeDialog()"></i>
</div>

<div class="words-list-container">
  <div class="word-item" style="cursor: pointer;" [ngClass]="{'selected': isSelected(word.id)}" (click)="onItemClick($event, word.id)" *ngFor="let word of filteredWords">
    <span class="word-text" >{{ word.word }} - <span *ngIf="isTime">({{ word.day }} / {{ word.month }} / {{ word.year }})</span></span>
    <span *ngIf="word.translations">
      <span *ngFor="let trans of word.translations">{{ trans.translation }},</span>
    </span>
  </div>
</div>
