import { Component, ViewChild, OnInit, HostListener } from '@angular/core';
import Editor from "src/assets/js/ckeditor.js";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular";
import { AdminService } from 'src/app/services/admin.service';
import * as $ from 'jquery';
import { element } from 'protractor';
declare var $: any;

@Component({
  selector: 'app-wisereader-importer',
  templateUrl: './wisereader-importer.component.html',
  styleUrls: ['./wisereader-importer.component.css'],
})
export class WisereaderImporterComponent implements OnInit {

  @ViewChild("myEditor") myEditor: any;
  public Editor = Editor;
  public EditorText: string = ``;
  textEdition;

  public Config = {
    removePlugins: ['Title'],
  };

  successfullySave: boolean = false;

  showSubcats: boolean = false;
  showReadingsModal: boolean = false;
  checkText;
  updateReading: boolean = false;

  @HostListener('dblclick', ['$event']) dblClick() {
    this.addCustomGap();
    this.searchWord();
  }

  constructor(public admin: AdminService) { }


  //// HACER RECIBIENDO DE LA BD CUANDO ESTE ARREGLADO
  plans = [
    { id: 1, name: 'academic', selected: false },
    { id: 2, name: 'professional', selected: false },
    { id: 3, name: 'managment', selected: false }
  ];



  selectedPlan: any;

  selectPlan(plan: any) {
    this.plans.forEach(pl => {
      pl.selected = (pl === plan);
    });
    this.selectedPlan = plan;
    console.log(this.selectedPlan);
  }




  ngOnInit() {
    this.getCatTree();
  }

  coursesCatTree = new Array();
  writingTypes;
  getCatTree() {
    this.admin.getReadingFilters().subscribe((data: any) => {
      this.coursesCatTree = data['content'];
      this.writingTypes = data['writing_types'];

      // this.coursesCatTree = data;
      // this.writingTypes = {
      //   filter_id : '38',
      //   filter_name: 'Tipo writing',
      //   options:[{
      //     option_id: '126',
      //     option_name : 'article'
      //    }]
      // }
    });

  }

  categoriesCatTree = new Array();
  category = new Array();
  getCatTreeCategories(course, subcat) {
    if (this.categoriesCatTree != subcat) {
      this.showSubcats = true;
    } else {
      this.showSubcats = !this.showSubcats;
    }
    this.categoriesCatTree = subcat;
    this.category = course;
    this.subcategory = undefined;
  }

  catImporter;
  selectCat(cat) {
    if (this.catImporter != undefined && cat != this.catImporter) {
      this.cleanTextEditor();
    }
    this.catImporter = cat;
    if (this.subcategory != undefined) {
      this.subcategory = undefined;
    }
  }

  subcategory;
  selectSubcat(subcat) {
    if ((this.subcategory != undefined || this.catImporter != undefined) && subcat != this.subcategory) {
      this.cleanTextEditor();
    }
    if (this.catImporter != undefined) {
      this.catImporter = undefined
    }
    this.subcategory = subcat;
  }

  readingsSubcat;
  showReadings: boolean = false;
  selectedReading;
  getReadingsNames(subcat) {
    this.showReadingsModal = true;
    this.cloneReadingsSelection = new Array();
    this.readingsSubcat = subcat.readings;
    this.readingsSubcat.map(reading => {
      if (reading.origin == 'subcat') {
        this.setCloneReading(reading, 'manual');
      } else {
        reading.checked = false;
      }
    });
  }

  closeReadingsModal() {
    this.showReadingsModal = false;
  }

  writingName = '';
  newWritingType: boolean = false;
  selectedWritingName = '';
  selectWriting(event) {
    if (event.target.value == "new") {
      this.newWritingType = true;
      this.writingName = '';
    } else {
      this.selectedWritingName = event.target.value;
      this.setPlaceHolder();
    }
  }

  addNewWritingType() {
    let filter_options = new Array();
    filter_options.push(this.writingName);
    this.admin.addFiltersOption(this.writingTypes.filter_id, filter_options, 'wisereader', '', '', '').subscribe((data) => {
      this.messageArray.push('¡GUARDADO!');
      // let optionAux = {
      //   option_name: this.writingName
      // }
      this.writingTypes.options.push(data);
      this.newWritingType = false;
    });
  }

  readingContent = new Array();
  content_parts;
  checkReadingContent() {
    this.EditorText = this.matchTags(this.EditorText);

    this.admin.checkReadingImport(this.EditorText, this.writingTypes, this.selectedPlan.name).subscribe((data: any) => {
      this.messageArray.push(data[0])
      this.content_parts = data[1];
      let readingContentAux = data[1];

      this.checkedSubcats.forEach(subcat => {
        subcat.readings.forEach(reading => {
          if (reading.name == readingContentAux.name) subcat.hasReading = true;
        });
      });

      if (readingContentAux.title) {
      }

      let textAux: string;
      let readingPart;
      this.readingContent = new Array();
      let subpartAux;

      for (let part in readingContentAux) {

        readingPart = readingContentAux[part];
        let type = typeof (readingPart);

        if (readingPart != null) {

          switch (part) {
            case ('title'):
            case ('article'):
            case ('essay'):
            case ('letter'):
            case ('report'):

              readingPart = this.matchTags(readingPart);
              textAux = part + ': ' + '<span style="background-color: #abffab;">' + readingPart + '</span>';

              break;

            case ('questions'):
            case ('proposed solution statements'):
              subpartAux = part + ': ';
              readingPart.forEach(types => {
                if (types.question == null || types.correct_answer == null) {
                  if (types.question == null) {
                    subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + '(' + types.correct_answer + ')' + '</span>';
                  } else if (types.correct_answer == null) {
                    subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + types.question + '</span>';
                  }
                } else {
                  subpartAux = subpartAux + '<br><span style="background-color: #abffab;">' + types.question + '(' + types.correct_answer + ')' + '</span>';
                }
              });

              textAux = subpartAux;
              break;

            case ('multiple choice'):
              subpartAux = part + ': ';
              readingPart.forEach(types => {
                if (types.question == null || types.correct_answer == null || types.options == null) {
                  let optionsString = '';
                  if (types.options != null) {
                    let optionsArray = new Array()
                    types.options.forEach(option => {
                      let optionAux = '("' + option + '")';
                      optionsArray.push(optionAux)
                    })

                    optionsString = optionsArray.join();
                  }

                  if (types.question == null) {
                    subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + optionsString + '</span>';
                  } else if (types.correct_answer == null) {
                    subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + 'QQQ' + types.question + optionsString + '</span>';
                  } else if (types.options == null) {
                    subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + 'QQQ' + types.question + '(' + types.correct_answer + '%%%' + ')' + '</span>';

                  }
                } else {

                  let optionsArray = new Array()
                  types.options.forEach(option => {
                    let optionAux = '("' + option + '")';
                    optionsArray.push(optionAux)
                  })

                  let optionsString = optionsArray.join()
                  subpartAux = subpartAux + '<br><span style="background-color: #abffab;">' + 'QQQ' + types.question + optionsString + '</span>';
                }
              });
              textAux = subpartAux;
              break;

            case ('idiomatic expressions'):
            case ('technical terms'):
            case ('transition expressions'):
            case 'time references':

              subpartAux = part + ': ';
              readingPart.forEach(types => {
                if (types.term == null || types.def == null) {
                  if (types.term == null) {
                    subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + ': ' + types.def + '</span>';
                  } else if (types.def == null) {
                    subpartAux = subpartAux + '<br><span style="background-color: #ff7b7be3;">' + types.term + '</span>';
                  }
                } else {
                  if (!types.found) {
                    subpartAux += '<br><span style="background-color: #E05D5D; color:white">' + types.term + '</span>' + ': ' + '<span>' + types.def + '</span>'
                  } else {
                    subpartAux = subpartAux + '<br><span style="background-color: #abffab;">' + types.term + ': ' + types.def + '</span>';
                  }
                }
              });

              textAux = subpartAux;
              break;

            case 'action verbs':
            case 'self-descriptive adjectives':
              textAux = part + ':';
              readingPart.forEach(types => {
                if (!types.found) {
                  textAux += '<span style="background-color: #E05D5D; color:white">' + types.word + '</span>,';
                } else {
                  textAux += '<span>' + types.word + '</span>,';
                }
              });
              break;

            case 'custom':
              readingPart = this.matchTags(readingPart);
              textAux = part + ':' + readingPart + ', ';

              break;

            default:
              readingPart = this.matchTags(readingPart);
              textAux = part + ': ' + '<span style="background-color: #abffab;">' + readingPart + '</span>';
              break;
          }

        } else {
          textAux = part + ': ' + '<span style="background-color: #ff7b7be3">' + readingPart + '</span>';
        }

        this.readingContent.push(textAux);
      }

      let customTag = this.readingContent[11];
      this.readingContent.pop();
      this.readingContent.unshift(customTag);
      this.readingContent = this.readingContent.map(txt => txt = '<p>' + txt + '</p>')
      this.EditorText = this.readingContent.join('');
      this.finalize = true;
    });
  }

  matchTags(text) {
    //Eliminar las <p> y poner en su lugar un <br> para que haga el salto de línea
    let regex1 = /<\/p>/g;
    let regex2 = /<p\b[^>]*>/g;

    text = text.replace(regex1, '');
    text = text.replace(regex2, '<br><br>');

    return text;
  }

  readingCard;
  readingContentKey;
  contentNames;
  writingTypesNames;
  getReadingCards(event, reading) {
    if (event.ctrlKey) {
      this.showReadingsModal = false;
      this.readingCard = reading;
      this.admin.getReadingCard(reading.readingcard_id).subscribe((data: any) => {
        let readingContent = data['reading_info'];
        let readingPart;

        let readingObject = {
          contents: new Array(),
          readingcard_id: '',
          type: ''
        }

        this.contentNames = ['custom', 'title', 'questions', 'idiomatic expressions', 'technical terms', 'transition expressions', 'proposed solution statements', 'multiple choice', 'time references'];
        this.writingTypesNames = this.writingTypes.options.map(element => element.option_name.toLowerCase());
        this.contentNames = this.contentNames.concat(this.writingTypesNames);
        this.readingCard = readingObject;
        for (let part in readingContent) {

          readingPart = readingContent[part];
          let readingContentAux = {
            type: '',
            contents: []
          };

          if (this.contentNames.indexOf(part.toLowerCase()) > -1) {

            readingContentAux.type = part;
            readingContentAux.contents = readingPart;
            this.readingCard.contents.push(JSON.parse(JSON.stringify(readingContentAux)))
          }
        }

        this.readingCard.readingcard_id = readingContent.readingcard_id
        this.readingCard.type = readingContent.type

      });
    }
  }


  subcatWord;
  searchWord() {
    var range = window.getSelection() || document.getSelection();
    let text = $.trim(range.toString());
    if (text != '') {
      this.subcatWord = text;
      this.searchSubcat(this.subcatWord);
    }
  }

  addCustomGap() {
    if (this.content_parts != undefined && this.content_parts['custom'] != '') {
      var range = window.getSelection() || document.getSelection();
      let text = $.trim(range.toString());
      if (text != '') {
        this.EditorText = this.EditorText.replace(/<p>custom:([^<>]*).*?<\/p>/, `<p>custom: $1 ${text},<p>`);
        this.content_parts['custom'] += text + ', ';
      }
    }
  }

  searchingSubcat: boolean = false;
  dropDownSearch: boolean = false;
  searchedSubcats = new Array();
  searchedReadings = new Array();
  searchSubcat(search_value) {
    this.searchingSubcat = true;
    search_value = search_value.toLowerCase();

    if (search_value.length > 2) {
      this.searchedSubcats = new Array();
      this.searchedReadings = new Array();
      this.checkedSubcats = new Array();
      this.coursesCatTree.forEach(cat => {
        cat.subcats.forEach(subcat => {
          if (subcat.subcat_name != undefined) {
            if (subcat.subcat_name.toLowerCase().indexOf(search_value) != -1) {
              subcat.checked = false;
              this.searchedSubcats.push(subcat);
              this.dropDownSearch = true;
            }
          }
        })
      });
      if (this.readingsSubcat.length > 0) {
        this.readingsSubcat.forEach(reading => {
          if (reading.origin == 'subcat') {
            this.searchedSubcats.forEach(subcat => {
              subcat.readings.forEach(subcatReading => {
                if (reading.name == subcatReading.name) subcat.hasReading = true;
              });
            });
          }
        });
      }
    } else {
      this.checkedSubcats = new Array();
    }
  }

  checkedSubcats = new Array();
  setSearchedSubcats(subcat, event) {
    if (event.target.checked == true) {
      subcat.checked = true;
      this.checkedSubcats.push(subcat);
    } else {
      let index = this.checkedSubcats.indexOf(subcat);
      subcat.checked = false;
      this.checkedSubcats.splice(index, 1);
    }
  }

  messageArray = new Array();

  setReadingCard() {
    let cat_id, subcat_id, course_id;
    if (this.catImporter != undefined) {
      cat_id = this.catImporter.cat_id
    } else if (this.subcategory != undefined) {
      subcat_id = this.subcategory.subcat_id;
      course_id = this.subcategory.course_id;
    }

    let subcat_ids;
    if (this.checkedSubcats.length == 0) {
      subcat_ids = [subcat_id];
    } else {
      subcat_ids = this.checkedSubcats.map(subcat => subcat.subcat_id);
    }

    this.admin.setReadingCard(course_id, cat_id, subcat_ids, this.content_parts, this.selectedPlan).subscribe((data: any) => {
      this.messageArray.push(data['message']);
      if (data['cats'].length > 0) {
        data['cats'].forEach(cat => {
          this.messageArray.push('Cat: ' + cat);
        });
      }
      if (data['subcats'].length > 0) {
        data['subcats'].forEach(cat => {
          this.messageArray.push('Subcat: ' + cat);
        });
      }
      subcat_ids.forEach(subcat_id => {
        this.coursesCatTree.forEach((cat, cat_index) => {
          cat.subcats.forEach((subcat, subcat_index) => {
            if (subcat.subcat_id == subcat_id) {
              this.coursesCatTree[cat_index].subcats[subcat_index].readings.push(data['reading']);
            }
          });
        });
      });
    });
  }

  cloneReadingsSelection = new Array();
  setCloneReading(reading, event) {
    if (event == 'manual' || event.target.checked == true) {
      reading.checked = true;
      this.cloneReadingsSelection.push(reading.readingcard_id);
    } else {
      let index = this.cloneReadingsSelection.indexOf(reading.readingcard_id);
      reading.checked = false;
      this.cloneReadingsSelection.splice(index, 1);
    }
  }

  cloneReadingContent() {
    let checkedSubcatsIds = this.checkedSubcats.map(subcat => subcat.subcat_id);
    this.admin.cloneReadingContent(this.cloneReadingsSelection, checkedSubcatsIds).subscribe((data: any) => {
      data.forEach(obj => {
        this.categoriesCatTree.forEach(subcat => {
          if (obj.subcat_id != undefined) {
            if (subcat.subcat_id == obj.subcat_id) {
              obj['readings'].forEach(reading => {
                subcat.readings.push(reading);
              });
            }
          }
        });
      });
      this.messageArray.push("Guardado correctamente");
    }, err => {
      this.messageArray.push("Ha habido un problema");
    });
  }

  removeMessage(index) {
    this.messageArray = new Array();
    // if (index != undefined) {
    //   this.messageArray.splice(index, 1);
    // }
  }

  udpateReadingCard() {
    this.admin.udpateReadingCard(this.readingCard.readingcard_id, this.content_parts).subscribe((data: any) => {
      this.messageArray.push(data['message']);
    })
  }


  newCard() {
    this.EditorText = '';
    this.readingCard = undefined;
    this.catImporter = undefined;
    this.subcategory = undefined;
    this.content_parts = undefined;
  }


  /** Funciones del editor */


  onReady(editor) {
    console.log(this.myEditor);
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      );
  }

  finalize = false;
  onChange({ editor }: ChangeEvent) {
    this.EditorText = editor.getData();
    // if (this.finalize == false ) {
    //   setTimeout(() => {
    //   this.checkReadingContent()
    //   }, 300);
    // }
  }



  cleanTextEditor() {
    this.EditorText = '';
    this.checkText = undefined;
    this.content_parts = undefined;
    this.messageArray = new Array();
    this.checkedSubcats = new Array();
    this.subcatWord = '';
    this.searchedSubcats.map(subcat => subcat.checked = false);
  }

  setPlaceHolder() {
    switch (this.selectedWritingName.toLowerCase()) {
      case 'letter':
      case 'article':
      case 'report':
      case 'essay':
      case 'encyclopedic entry':
      case 'encyclopedic intro':
        this.EditorText = `
          <p>
            <b>Title:</b> texto del título
          </p>

          <p>
            <b>`+ this.selectedWritingName + `: </b> <br>
            cuerpo del texto</b>
          </p>

          <p>
          <b>Questions:</b>  <br> *
          <b>Proposed solution statements:</b>   <br> la explicación es optativa<br>
            pregunta (True/False : explicación)<br>
            <b>ó </b><br>
            pregunta (Yes/No : explicación)
          </p>

          <p>
          <b>Multiple choice:</b>  <br>
          QQQ pregunta (opción)(opción)(opción)(opción correcta%%%)
          </p>

          <p>
            <b>Technical terms:</b>  <br>
            <b>Idiomatic expressions:</b>  <br>
            <b>Transition expressions:</b> <br>
            <b>Time references:</b> <br>
              term : def
          <p>

          <p>
            <b>Action verbs:</b> <br>
            <b>Self-descriptive adjectives:</b> <br>
            item 1 , item 2, ..., item N
          </p>

          <b>Ejercicios:</b>  Wise Reader, Paper Pilot, Paper Pilot Audio, Audio Gap TESLA (Si tiene uno de estos: <b>Technical terms</b>/<b>Idiomatic expressions</b>/<b>Action verbs</b>, etc)<br>
          <b>Ejercicios:</b>  Wise Reader QZ (Si tiene <b>Questions</b>/<b>Multiple Choice</b>/<b>Proposed solution statements</b>)
          `;
        break;
      case 'case study':
        this.EditorText = `
          <p>
            <b>Title:</b> texto del título
          </p>

          <p>
            <b>`+ this.selectedWritingName + `: </b> <br>
            cuerpo del texto</b>
          </p>

          <p>
          <b>Proposed solution statements:</b>   <br>
            pregunta (True/False : explicación)<br>
            <b>ó </b><br>
            pregunta (Yes/No : explicación)
          </p>

          <b>Ejercicios:</b> Case Study y Audio Case Study
          `;
        break;
      case 'job interview presenting yourself':
      case 'job interview, why company & position':
        this.EditorText = `
          <p>
            <b>Title:</b> texto del título
          </p>

          <p>
            <b>`+ this.selectedWritingName + `: </b> <br>
            cuerpo del texto</b>
          </p>

          <p>
          <b>Questions:</b>  <br> *la explicación es optativa<br>
            QQQ pregunta (opción)(opción)(opción)(opción correcta%%%)
          </p>

          <p>
          <b>Multiple choice:</b>  <br>
            pregunta (True/False : explicación)<br>
            <b>ó </b><br>
            pregunta (Yes/No : explicación)
          </p>

          <p>
            <b>Technical terms:</b>  <br>
            <b>Idiomatic expressions:</b>  <br>
            <b>Transition expressions:</b> <br>
            <b>Time references:</b> <br>
              term : def
          <p>

          <p>
            <b>Action verbs:</b> <br>
            <b>Self-descriptive adjectives:</b> <br>
            item 1 , item 2, ..., item N
          </p>

          <b>Ejercicios:</b>  Wise Reader Pro, Job interview Pilot, Job interview Audio, Job Interview Audio Gap (Si tiene uno de estos: <b>Technical terms</b>/<b>Idiomatic expressions</b>/<b>Action verbs</b>, etc)<br>
          <b>Ejercicios:</b>  Wise Reader QZ (Si tiene <b>Questions</b>/<b>Multiple Choice</b>/<b>Proposed solution statements</b>)
          `;
        break;
      default:
        break;
    }
  }

  /*------*/


}
