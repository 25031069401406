<div class="h-100 w-100 position-relative ">
  <div class="position-relative py-2 px-3 row no-gutters d-flex" style="border: none !important; background-color: #F2F2F2;  height: 9%; justify-content: flex-end;">
    <div *ngIf="successfullySave == true">
      <div class="saveMessage"> {{message}} </div>
    </div>


    <div class="toggle-container" style="width:12%">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="status" (ngModelChange)="toggleStatus()">
        <span style="color: white !important; font-weight: 300 !important;" class="slider round">Status</span>
      </label>
    </div>
<!--
    <div class="saveButton my-auto text-center" (click)="checkDictionaryWords();">
      <span>Status</span>
    </div> -->

      <!-- <div class="d-flex" style="width: 93%;">
        <div *ngIf="stax['option_name'] != undefined" class="mx-2 my-auto  row no-gutters" style="width: auto; font-size: 1.5vh; height: 75%;">
            <i class="fa-solid fa-filter my-auto" style="cursor:pointer" [appTooltip]="915" (click)="getStaxContent()"></i>
        </div>
      </div> -->
      <div style="display: flex; width:88%; flex-direction: row; justify-content: right;">
        <div class="saveButton my-auto text-center" (click)="checkStax();">
          <span> Check</span>
        </div>
        <div class="saveButton my-auto text-center" (click)="cleanEditor();">
        <span> Clear</span>
      </div>
      <div class="saveButton my-auto text-center" (click)="setDictionaryWords();">
        <span> Save</span>
      </div>

    </div>
    </div>
    <div style="width: 96%; margin: auto; height: 90%; color: #6e6e6e; justify-content: center; justify-content: flex-start;" class="d-flex flex-column">
      <div style="width: 99%;">
          <ckeditor #myEditor [editor]="Editor" [config]="Config" (ready)="onReady($event)"  (change)="onChange($event);"  [(ngModel)]="EditorText"></ckeditor>
      </div>
      <div *ngIf="status" style="height: 50% ;">
<div style="display: flex; flex-direction: row;">
    <div class="toggle-container" style="width:12%">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="InDict">
        <span style="color: white !important; font-weight: 300 !important;" class="slider round">Dictionary</span>
      </label>
    </div>

    <div class="toggle-container" style="width:12%">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="notInFreq" >
        <span style="color: white !important; font-weight: 300 !important;" class="slider round">No Freq</span>
      </label>
    </div>

    <div class="toggle-container" style="width:12%">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="notInDict">
        <span style="color: white !important; font-weight: 300 !important;" class="slider round">null</span>
      </label>
    </div>
  </div>
      <div style="border: 1px solid #6e6e6e; height: 500px; overflow-y: auto;">
        <div *ngIf='this.words != ""'>
        <div *ngIf="InDict">
        <div *ngFor="let word of this.words.inDict" style="background-color: #d4efc2;">
         <span style="color: black;">{{word.word}} - ({{ word.frequency}}) - <span *ngFor="let trans of word.translations"> {{ trans }}, </span></span>
          <br>
        </div>
        </div>
        <span *ngFor="let word of this.words.notInFreq">
          <span *ngIf="notInFreq" style="color: black; background-color: #fff6f9;">{{word.word}} - (null) - <span *ngFor="let trans of word.translations"> {{ trans }}, </span></span>
          <br>
        </span>
        <span *ngFor="let word of this.words.notInDict">
          <span *ngIf="notInDict" style="color: black; background-color: #edd0d8;">{{word.word}} - (null) - (null)</span>
          <br>
        </span>

        </div>

      </div>
      </div>
    </div>
  </div>
