<div class="modal fade" id="placementResults" tabindex="-1" role="dialog" aria-labelledby="activityProfile" aria-hidden="true">
    <div class="modal-dialog" role="document" style="height: 93%; max-width: 90%; background-color: rgb(253, 253, 253);">
        <div class="modal-content h-100" style="background-color: rgb(243 239 239)">
            <div *ngIf="placementResults != undefined" class="w-100" style="overflow: auto; display: grid; grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));">
                <div *ngFor="let profile of placementResults; let i = index" style="margin: 3%; background-color: white;">

                    <div style="background-color: rgb(6, 109, 139);" class="row py-1 no-gutters position-relative justify-content-center">
                        <p style="color: white; font-weight: 500;" class="mb-0 text-uppercase text-center">        
                            {{profile.name}}
                        </p>            
                    </div>
                    <div class="w-100" style="flex-direction: column; padding: 3%;">
                        <!-- <div class="w-100" >            
                            <div class="w-100 title">
                                Name <span class="title2" style="padding:3px 6px; color: grey" > {{profile.name}} </span>
                            </div>
                        </div>                -->
                        <!-- <div class="w-100" >            
                            <div class="w-100 title">
                                Score <span class="title2" style="padding:3px 6px; color: grey" > {{profile.score}} </span>
                            </div>
                        </div>                 -->
                        <div class="w-100" >            
                            <div class="w-100 title">
                                Average
                            </div>
                            <div class="title2 d-flex " style="padding:3px 6px;"  *ngFor="let average of profile.average; let i = index" >
                                <div style="width: 24%; margin-left: 6%;" class="my-auto">
                                    {{average.name}}
                                </div>                   
                                <div class="d-flex my-auto" style="height: 21%; width: 60%;">    
                                    <div class=" d-flex my-auto" style="min-width: max-content;height: 9px; background-color: #1C4360;" [ngStyle]="{'width': average.percentage}"> </div>
                                    <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color:rgb(6 109 139 / 15%); " [ngStyle]="{'width': average.restPercentage}"> </div>
                                </div>
                                <div class="d-flex my-auto" style="margin-left: 3%">    
                                    <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                        {{average.value}}
                                    </div>
                                </div>          
                            </div>
                        </div>                
                        <div class="w-100" >            
                            <div class="w-100 title">
                                Resources
                            </div>
                            <div class="title2 d-flex " style="padding: 3px 6px;"  *ngFor="let resource of profile.resources; let i = index" >
                                <div style="width: 24%; margin-left: 6%;" class="my-auto">
                                    {{resource.name}}
                                </div>                   
                                <div class="d-flex my-auto" style="height: 21%; width: 60%;">    
                                    <div class=" d-flex my-auto" style="min-width: max-content;height: 9px; background-color: #1C4360; ;" [ngStyle]="{'width': resource.percentage}"> </div>
                                    <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color: rgb(6 109 139 / 15%); " [ngStyle]="{'width': resource.restPercentage}"> </div>
                                </div>               
                                <div class="d-flex my-auto" style="margin-left: 3%">    
                                    <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                        {{resource.value}}
                                    </div>
                                </div>               
                            </div>
                        </div>                
                        <div class="w-100" >            
                            <div class="w-100 title">
                                Skills
                            </div>
                            <div class="title2 d-flex " style="padding:3px 6px;"  *ngFor="let skill of profile.skills; let i = index" >
                                <div style="width: 24%; margin-left: 6%; " class="my-auto">
                                    {{skill.name}}
                                </div>                   
                                <div class="d-flex my-auto" style="height: 21%; width: 60%;">    
                                    <div class=" d-flex my-auto" style="min-width: max-content;height: 9px; background-color: #1C4360;" [ngStyle]="{'width': skill.percentage}"> </div>
                                    <div class="d-flex my-auto" style="min-width: max-content; height: 9px; background-color:rgb(6 109 139 / 15%); " [ngStyle]="{'width': skill.restPercentage}"> </div>
                                </div>  
                                <div class="d-flex my-auto" style="margin-left: 3%">    
                                    <div class="h-100 w-100" style="color: #1C4360; font-weight: 700;">
                                        {{skill.value}}
                                    </div>
                                </div>                
                            </div>
                        </div>                                 
                    </div>      
                </div>
            </div> 
        </div>
    </div>
</div>

