import { Component, ViewChild, OnInit } from '@angular/core';
import { DatePipe} from '@angular/common';
import {Observable} from "rxjs";
import { LmsService } from '../../services/lms.service';
import {
  DayPilot,
  DayPilotCalendarComponent,
  DayPilotMonthComponent,
  DayPilotNavigatorComponent
} from "@daypilot/daypilot-lite-angular";
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from 'src/app/services/auth.service';
import { AdminService } from 'src/app/services/admin.service';
import { UserDataService } from 'src/app/services/user-data.service';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.css']
})
export class PlannerComponent implements OnInit {

  @ViewChild("navigator") navigator!: DayPilotNavigatorComponent;
  @ViewChild("calendar") calendar!: DayPilotCalendarComponent;


  teachers = new Array();
  navigatorCalendar: boolean = false;
  subscription: Subscription;
  teacherCalendar: boolean = false;
  selectCalendar;
  events: DayPilot.EventData[] = [];
  infoPlanner;
  fullInfoPlanner;
  letters = '0123456789ABCDEF';
  color;
  teachersList;
  teacherSelected = [];



  constructor( public lms: LmsService, public auth: AuthService, public admin: AdminService, private userData: UserDataService, private datePipe: DatePipe ) {
    this.viewWeek();
   }


   ngOnInit(): void {
     this.setPlanner();
     this.getEvents;    
     this.subscription = this.userData.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'update_planner') {
        this.setPlanner();
      }
      if (res.hasOwnProperty('option') && res.option === 'user_view') {
        this.setPlanner();
      }
    });
   }
    

    get date(): DayPilot.Date {
      return this.configWeek.start as DayPilot.Date;
    }
    
    set date(value: DayPilot.Date) {
      this.configWeek.start = value;
    }
    
    configNavigator: DayPilot.NavigatorConfig = {
      showMonths: 2,
      cellWidth: 25,
      cellHeight: 25,
      selectMode: "Week",
      onTimeRangeSelected: args => {
        this.setPlanner();
        }
    };

    
    configDay: any = {
      startDate: DayPilot.Date.today(),
      viewType: "Day",
      headerDateFormat: "dddd M/d/yyyy",
      timeHeaderCellDuration: 60,
      cellDuration: 60,
      cellHeight: 15,
      cellWidth: 10,
      disposeOnClose: false,
      dayBeginsHour: 9,
      dayEndsHour: 22,
      businessBeginsHour: 9,
      businessEndsHour: 22,
      hourWidth: 60,
      eventResizeHandling: "Disabled",
      eventMoveHandling: "Disabled",
      onEventClicked: async (args) => {
        this.admin.selectedGroupPlanner = args.e.data;      
        this.admin.selectGroup(this.admin.selectedGroupPlanner.index, this.admin.selectedGroupPlanner.id);
      },
    };
    


    configWeek: any = {
      start: DayPilot.Date.today(),
      viewType: "WorkWeek",
      weekStarts: 1,
      locale: "ES-es",
      headerDateFormat: "dddd",
      cellDuration: 60,
      timeHeaderCellDuration: 20,
      cellheightDuration: 60,
      cellHeight: 15,
      cellWidth: 9,
      dayBeginsHour: 8,
      dayEndsHour: 22,
      dayHeaderHeight: 60,
      disposeOnClose: false,
      businessBeginsHour: 9,
      businessEndsHour: 22,
      eventResizeHandling: "Disabled",
      eventMoveHandling: "Disabled",
      timeFormat:  "Clock24Hours",
      eventClickHandling: "Select",
      onEventClicked: async (args) => {
        // args.control.message("Event selected: " + args.e.text());
        this.admin.selectedGroupPlanner = args.e.data;      
        this.admin.selectGroup(this.admin.selectedGroupPlanner.index, this.admin.selectedGroupPlanner.id);
      },
      onTimeRangeSelected: (args) => {
        // const modal = await DayPilot.Modal.prompt("Introduce un nuevo evento", '');
        args.control.loadingLabelBackColor = '#e03bc2';
        const dp = args.control;
        this.setTeacherTimetable(args);         
        dp.clearSelection();

      }
    };


    configMonth: any = {
      startDate: DayPilot.Date.today(),
      start: DayPilot.Date.today(),
      firstDayOfWeek: 1,
      viewType: "Month",
      showWeekend: false,
      disposeOnClose: false,
      headerDateFormat: "dddd",
      cellDuration: 60,
      dayBeginsHour: 8,
      dayEndsHour: 22,
      dayHeaderHeight: 60,
      hourWidth: 60,
      cellHeight: 60,
      maxHeight: 60,
      weekStarts: 4,
      initScrollPos: 4,
      // eventDeleteHandling: "Update",
      eventArrangement: "",
      showToolTip: true,
      eventResizeHandling: "Disabled",
      eventMoveHandling: "Disabled",
      onEventClicked: async (args) => {
        this.admin.selectedGroupPlanner = args.e.data;      
        this.admin.selectGroup(this.admin.selectedGroupPlanner.index, this.admin.selectedGroupPlanner.id);
      },   
      onTimeRangeSelected: (args) => {
        args.control.loadingLabelBackColor = '#e03bc2';
        const dp = args.control;
        // this.setTeacherTimetable(args);         
        dp.clearSelection();
      },
    };


    changePlannerHeight(){
      if (this.auth.role == 'clientAdmin'){
        document.getElementsByClassName('calendar_default_main')[0]['style'].height = '366px'
      }
    }


    setPlanner(){
      this.admin.getInfoPlanner(this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe((data: any) => {
        this.infoPlanner = data[0];  
        this.teachersList = data[1];
        this.setInfoPlanner();   
        // this.setTeachers();    
      }); 
    }


    setInfoPlanner(){
      let modePlannerInfo;
      let daysWeek;
      this.events = [];
      this.admin.getInfoPlanner(this.admin.view_role, this.admin.userId_view, this.admin.organizationId_view).subscribe((data: any) => {
        this.infoPlanner = data[0];
        this.teachersList = data[1];
      });

      
      
      for(let i = 0; i < this.infoPlanner.length ; i++){
        let countWeeks = 0;
        if (this.infoPlanner[i].duration != null && this.infoPlanner[i].startHour != null && this.infoPlanner[i].endHour != null){
          daysWeek = this.infoPlanner[i].startHour.length;       
          if (this.teacherCalendar == false){
            modePlannerInfo = this.infoPlanner[i].group_name;
          } else{
            modePlannerInfo = this.infoPlanner[i].teacher_name;
          }
          // if (this.infoPlanner[i].group_name.toLowerCase().indexOf('trainer') == -1){
            while (countWeeks < this.infoPlanner[i].duration){
              for (let j = 0; j < daysWeek ; j++ ){
                let event1;
                let event2;
                if (countWeeks != 0){
                  event1 = new Date( this.infoPlanner[i].startEvent[j]);
                  event1.setDate(event1.getDate() + 7);
                  this.infoPlanner[i].startEvent[j] = event1.toJSON();
                  event2 = new Date( this.infoPlanner[i].endEvent[j]);
                  event2.setDate(event2.getDate() + 7);
                  this.infoPlanner[i].endEvent[j] = event2.toJSON();
                }
  
                this.setColor(this.infoPlanner[i].course_id);
                if (this.infoPlanner[i].startEvent[j] != undefined && this.infoPlanner[i].endEvent[j] != undefined){
                  let infoClases = {
                    text: modePlannerInfo,
                    start: this.infoPlanner[i].startEvent[j] ,     
                    id: this.infoPlanner[i].group_id, 
                    index: i,
                    end: this.infoPlanner[i].endEvent[j] ,   
                    barColor: this.color,     
                  }
                  this.events.push(infoClases);           
                }
              }   
              countWeeks++;
            }
          // } 
        } 
      }
      this.changePlannerHeight();
    }

    setTeachers(){
      this.infoPlanner.forEach(group => {
        this.teachers.push(group.teacher_name);
      });         
      this.teachers = this.teachers.filter( (teacher, index)=> {
          return this.teachers.indexOf(teacher) === index;
      })
    }

    setTeacherTimetable(selection){

     let startEvent = selection.start.value;
     let endEvent = selection.end.value;
     let startHour = startEvent.split('T')[1];
     let endHour = endEvent.split('T')[1];
     
     this.admin.setTeacherPlanner(this.teacherSelected[0].teacher_id, startEvent, endEvent, startHour, endHour ).subscribe((data: any) => {});

    }


    searchTeacherPlanner(teacher_name){

      teacher_name = teacher_name.target.value;
      this.teachersList.forEach( teacher =>{
        if (teacher.name == teacher_name){
          this.teacherSelected.push(teacher);
        }
      });


      this.events = [];
      let daysWeek;
      // this.admin.getInfoPlanner().subscribe((data: any) => {
      //   this.infoPlanner = data[0];
      //   this.teachersList = data[1];
      //   });
        
      this.infoPlanner.forEach((group, i) => {
        let countWeeks = 0;
        if (this.infoPlanner[i].duration != null && this.infoPlanner[i].startHour != null && this.infoPlanner[i].endHour != null){
          daysWeek = this.infoPlanner[i].startHour.length;       
          if (group.teacher_name == teacher_name){
            daysWeek = group.startHour.length;
            while (countWeeks < this.infoPlanner[i].duration){
              for (let j = 0; j < daysWeek ; j++ ){
                let event1;
                let event2
                if (countWeeks != 0){
                  event1 = new Date( this.infoPlanner[i].startEvent[j]);
                  event1.setDate(event1.getDate() + 7);
                  this.infoPlanner[i].startEvent[j] = event1.toJSON();
                  event2 = new Date( this.infoPlanner[i].endEvent[j]);
                  event2.setDate(event2.getDate() + 7);
                  this.infoPlanner[i].endEvent[j] = event2.toJSON();
                } 
                this.setColor(this.infoPlanner[i].course_id);
                let infoClases = {
                  text: group.group_name,
                  start: this.infoPlanner[i].startEvent[j] ,     
                  id: group.group_id, 
                  index: i,
                  end: this.infoPlanner[i].endEvent[j] ,   
                  barColor: this.color,
                }
                this.events.push(infoClases);
              } 
              countWeeks++;
          }
          }     
        }
      });      
    }

    setColor(course_id) {
      switch (course_id) {
        case 353:
          this.color = '#6fa84b';
          break;
  
        case 351:
          this.color = '#e0ebc2';
          break;
  
        case 352:
          this.color = '#309191';
          break;
  
        case 354:
          this.color = '#34b5aa';
          break;
  
        default:
          this.color =  '#' + Math.floor(0x1000000 * Math.random()).toString(16);
          break;
      }
    }

    showCalendar(){
      this.navigatorCalendar = !this.navigatorCalendar;       
      setTimeout(() => {
        this.navigatorCalendar = !this.navigatorCalendar; 
      }, 3000);
    }


    getEvents(from: DayPilot.Date, to: DayPilot.Date): Observable<any[]> {
      return new Observable(observer => {
        setTimeout(() => {
          observer.next(this.events);
          observer.complete();
        }, 200);
      });
    }
 
    viewDay():void {
      this.configNavigator.selectMode = "Day";
      this.configDay.visible = true;
      this.configWeek.visible = false;
      this.configMonth.visible = false;
    }
    
    viewWeek():void {
      this.configNavigator.selectMode = "Week";
      this.configDay.visible = false;
      this.configWeek.visible = true;
      this.configMonth.visible = false;
    }

    viewMonth():void {
      this.configNavigator.selectMode = "Month";
      this.configDay.visible = false;
      this.configWeek.visible = false;
      this.configMonth.visible = true;
    }



    ngOnDestroy(){

      // this.configMonth.dispose();
      // this.configWeek.dispose();
      // this.configDay.dispose();

      
    }
  
}
