import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CryptoService } from 'src/app/services/crypto-js.service';
import { roles } from 'src/environments/environment';
import {ThemePalette} from '@angular/material/core';
import { user } from '../../groups-component/groups-component.component';
import { LmsService } from 'src/app/services/lms.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AdminService } from 'src/app/services/admin.service';



class userProfile {
  name: string = '';
  id: string = '';
  last_name: string = '';
  phone: string = '';
  email: string = '';
  role_id: string = '';
  role_name: string = '';
  deleted: number = 0;
  office_id: string = '';
  office_name: string = '';
}
@Component({
  selector: 'app-role-admin',
  templateUrl: './role-admin.component.html',
  styleUrls: ['./role-admin.component.css']
})
export class RoleAdminComponent implements OnInit {

  constructor(public auth: AuthService,  private cryptoService: CryptoService,  public lms: LmsService,  public _userData: UserDataService, public admin: AdminService) { }

  ngOnInit(): void {
    this.auth.workingArea = 'role admin';
    this.getUsersInfo();
  }


  userList;
  offices = new Array();
  user_role = -1;

  searchFilter: boolean = false;
  userListSearch;

  getUsersInfo(){

   this.auth.getUsers(this.user_role).subscribe((data: any) => {

    data = JSON.parse(this.cryptoService.CryptoJSAesDecrypt('12345678A*', data));
        this.userList = new Array();
       for( let index = 0; index < data[0].length; index++){
        let userAux = new userProfile();
        userAux.name =  data[0][index].name;
        userAux.last_name =  data[0][index].last_name;
        userAux.id =  data[0][index].id;
        userAux.phone =  data[0][index].phone;
        userAux.email =  data[0][index].email;
        userAux.role_id =  data[0][index].role_id;
        userAux.role_name =  data[0][index].role_name;
        if (  data[0][index].deleted == 0){
          userAux.deleted = 0;
        } else{
          userAux.deleted = 1;
        }

        this.userList.push(userAux);
       } 
       this.userListSearch = this.userList;

       for( let index = 0; index < data[1].length; index++){
          this.offices.push(data[1][index]);
       }  
    });
  }



  selectedUserProfile;
  userProfileInfo;
  selectUser(user){
    this.selectedUserProfile = user;
    let rolesAux;
    let rolesUser
    this.auth.getUserInfo(user.id).subscribe((data: any) => {

      data = JSON.parse(this.cryptoService.CryptoJSAesDecrypt('12345678A*', data));

      let userAux = new userProfile();
      userAux.name = data.name;
      userAux.last_name = data.last_name;
      userAux.phone = data.phone;
      userAux.email = data.email;
      userAux.role_id = data.role_id;
      userAux.role_name = data.role_name;
      userAux.office_name = data.office_name;
      rolesUser = roles;
      this.roles = Object.keys(roles);
      for (let i = 0; i < this.roles.length; i++){
        if (rolesUser[this.roles[i]] == userAux.role_id ){
          userAux.role_name = this.roles[i];
        }
      }
      this.userProfileInfo = userAux;
      this.getUserLogInfo(user.id);

      });
      

  }

  userLogInfo = new Array();

  getUserLogInfo(user_id){

    this.admin.getUserLogInfo(user_id).subscribe((data: any) => { 
      // data = JSON.parse(this.cryptoService.CryptoJSAesDecrypt('12345678A*', data));
      this.userLogInfo = data;
    });

  }


  searchUsersNew(search_value, group_id?) {
    if (search_value.length > 2) {
      this.lms.searchUsers(search_value, group_id).subscribe((data: any) => {
        this.userListSearch= new Array();
        data.forEach(element => {
          this.userListSearch.push(element);
        });
      });
    } else {
      this.userListSearch = this.userList;
    }
  }



  roles;
  rolesAux
  searchUserRole: boolean = false;
  searchRole(){
    this.searchUserRole = true;
    this.rolesAux = roles;
    this.roles = Object.keys(this.rolesAux);
    
  }

researchRole(){
  this.user_role = -1;
  this.selectedRole = undefined;
  this.getUsersInfo();
}



  searchRoleType(search_value) {
    search_value = search_value.toLowerCase();
      this.userListSearch = this.userList;
      this.userListSearch = new Array;
  
      if (search_value.length > 2) {     
        this.rolesAux = roles;
        this.roles = Object.keys(this.rolesAux);

       let userListSearchAux = this.userListSearch;

        this.roles.forEach( role => {
          if (role.toLowerCase().indexOf(search_value) != -1){
            this.user_role =  this.rolesAux[role];
            this.getUsersInfo();   
            userListSearchAux.push(this.userListSearch);
          } 
          this.userListSearch = userListSearchAux;
        });
        // this.userList.forEach(type => {
        //     if ( type.role_name.toLowerCase().indexOf(search_value) != -1){
        //       this.userListSearch.push(type);
        //     } 
        //   });
      } else{
        this.user_role = -1;
        this.getUsersInfo();       
      }
  }


  selectedRole;
  selectRole(selectedRole){
    this.selectedRole = selectedRole;
    this.user_role = this.rolesAux[selectedRole];
    this.searchUserRole = false;
    this.getUsersInfo();

  }


  changeUserRole(userProfileInfo, role){
    userProfileInfo.role_name = role; 
    userProfileInfo.role_id = roles[role];
    this.changeRole = false;

  }

  chooseOffice: boolean = false;
  changeOffice(userProfileInfo, office){

    userProfileInfo.office_id = office.id;
    userProfileInfo.office_name = office.name;
    this.chooseOffice = false;
  }

  changeRole: boolean = false;
  changeUserProfile(){

    this._userData.changeEdit(this.selectedUserProfile.id, this.userProfileInfo.role_id, this.userProfileInfo.name, this.userProfileInfo.last_name, this.userProfileInfo.email, this.userProfileInfo.phone, this.userProfileInfo.office_id, this.userProfileInfo.picture).subscribe(data => {
      this.getUsersInfo();
    });

  }


  changeStatusUser(user){

    if (user.deleted == 1){
        user.deleted = 0;
    } else if (user.deleted == 0){
      user.deleted = 1;
    }
    this.auth.changeUserStatus(user.id).subscribe((data: any) =>{ });;
  }



}
